import { useState, useEffect } from 'react';

export const useRerender = () => {
  const [, setState] = useState({});
  return () => setState({});
};

export const useIterator = (
  list: any[],
  options?: { onFinished: () => any; onNext?: (index: number) => any }
) => {
  const [index, setIndex] = useState(0);
  const isFinished = index >= list.length;

  const next = () => {
    if (index === list.length - 1) {
      options?.onFinished();
    }
    options?.onNext?.(index + 1);
    setIndex(index + 1);
  };

  return {
    index: isFinished ? undefined : index,
    item: list[index],
    next,
    isFinished,
  };
};

// borrowed from https://usehooks.com/useDebounce/
export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
