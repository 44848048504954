import { BacsPro, FamillesMetiers, BACS_PROS_GATL_RC, BACS_PROS_AGRO } from "@inspire/data/static/bacs-pro";
import { Bacs2019, FilieresTechnos } from "@inspire/data/static/static";
export var classeFiliere = function(classe) {
    if (classe.filiere === "bac_techno" && classe.filiereTechno) {
        return FilieresTechnos[classe.filiereTechno];
    }
    if (classe.filiere === "bac_pro" && classe.filiereBacPro) {
        return BacsPro[classe.filiereBacPro];
    }
    if (classe.filiere === "bac_pro" && classe.familleMetiers) {
        return FamillesMetiers[classe.familleMetiers];
    }
    return Bacs2019[classe.filiere];
};
export var isGatlRc = function(classe) {
    if (!classe) {
        return false;
    }
    return classe.filiere === "bac_pro" && BACS_PROS_GATL_RC.includes(classe.familleMetiers);
};
export var isAgro = function(classe) {
    if (!classe) {
        return false;
    }
    return classe.filiere === "bac_pro" && BACS_PROS_AGRO.includes(classe.familleMetiers);
};
export var hasModules = function(classe) {
    if (!classe) {
        return false;
    }
    return classe.filiere === "bac_pro";
};
