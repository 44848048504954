import { useEffect, useRef } from 'react';

import {
  Button,
  Box,
  Center,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';
import { take } from 'lodash';

import { validateSchema } from '@inspire/data/helpers/schemas';
import { getPistesReco } from '@inspire/data/helpers/algo';
import { PisteOpinionSchema } from '@inspire/data/schemas/user/profile/lyceen';

import PisteItem from 'components/piste/PisteItem';
import FavoriteButton from 'components/piste/FavoriteButton';
import Link from 'components/general/Link';
import ProfTips from 'components/activites/ProfTips';
import {
  activitePath,
  activiteStepPath,
  moduleEditPath,
  modulePath,
  pistePath,
  PISTES_PATH,
} from 'lib/paths';

import { useModules, useMyProfile, usePistes, useUser } from 'lib/hooks';
import {
  ActiviteFooter,
  FormActiviteFooter,
} from 'components/activites/ActiviteFooter';
import { useState } from 'react';
import { useRouter } from 'next/router';

export const DecouvertePisteConsignes = ({ goNext }) => {
  const { isProf } = useUser();
  const { query } = useRouter();
  const { getModuleById } = useModules();

  const moduleId = Array.isArray(query.moduleId)
    ? query.moduleId[0]
    : query.moduleId;
  const activiteId = query.params?.[0];
  const isEnd = query.params?.[1] === 'fin';
  const step = !isEnd && Number(query.params?.[1]);
  const moduleData = getModuleById(moduleId);
  // @ts-ignore
  const activiteData: any = moduleData?.activites.find(
    (a) => a.activiteId === activiteId
  );

  const hasSteps = !!activiteData?.steps;
  const steps = activiteData?.steps;

  let backButtonPath;

  if (isEnd) {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, steps.length)
      : activitePath(moduleId, activiteId);
  } else if (step === 1 || !hasSteps) {
    backButtonPath = isProf ? moduleEditPath(moduleId) : modulePath(moduleId);
  } else {
    backButtonPath = hasSteps
      ? activiteStepPath(moduleId, activiteId, step - 1)
      : activitePath(moduleId, activiteId);
  }

  return (
    <>
      <ProfTips>
        <Text>
          Les pistes recommandées aux élèves sont déduites en fonction de leurs
          réponses au questionnaire d'aide à l'orientation rempli lors du module
          précédent. Le calcul des recommandations prend ainsi en compte les
          résultats scolaires des élèves mais également leurs expériences
          personnelles, leurs aspirations et leurs envies
        </Text>
        <Text>
          Ces résultats ne sont que des suggestions, il ne s’agit en aucun cas
          d’une décision finale que vos élèves doivent absolument suivre. Les
          modules suivants que nous proposons ainsi que votre accompagnement et
          votre connaissance de vos élèves permettront d’affiner leur projet
          post-bac.{' '}
        </Text>
        <Text>
          Ces résultats sont à même d'évoluer et ce questionnaire sera de
          nouveau proposé aux élèves dans le parcours de niveau Terminale afin
          de prendre en compte la progression de leur réflexion.
        </Text>
        <Text>
          Vous pouvez accompagner vos élèves dans la découverte des pistes
          recommandées :
        </Text>
        <UnorderedList>
          <ListItem>
            Conseillez aux élèves de <b>regarder chaque piste proposée</b> pour
            se faire leur propre idée (qu’est-ce qu’on y fait, qu’est-ce qu’on
            apprend, quelles sont les débouchées, les possibilités d’évolution,
            etc.)
          </ListItem>
          <ListItem>
            Proposez aux élèves de <b>mettre en favori les pistes</b> qui leur
            plaisent.
          </ListItem>
          <ListItem>
            Si aucune des propositions ne correspondent, les élèves pourront
            découvrir <b>d'autres choix possibles</b> dans la page{' '}
            <Link href={PISTES_PATH}>Les pistes d’avenir</Link>.
          </ListItem>
        </UnorderedList>
      </ProfTips>
      <Box>
        <Text>
          En fonction de tes réponses au questionnaire d'aide à l'orientation,
          nous te proposons des pistes que tu peux envisager après l’obtention
          de ton bac :
        </Text>
        <UnorderedList>
          <ListItem>
            Regarde chaque piste proposée pour te faire ta propre idée
            (qu’est-ce qu’on y fait, qu’est-ce qu’on apprend, quelles sont les
            débouchées, les possibilités d’évolution, etc.)
          </ListItem>
          <ListItem>Mets en favori les pistes qui te plaisent ❤️</ListItem>
          <ListItem>
            Si aucune de ces propositions ne correspondent à ce que tu veux
            faire après ton Bac, tu découvriras d'autres choix possibles dans la
            page Les pistes d’avenir.
          </ListItem>
        </UnorderedList>
        <Text>
          Les pistes que nous te recommandons ne sont que des suggestions. Elles
          ne déterminent pas ton projet pour le futur, car toi seul peux décider
          de ton avenir. Un projet professionnel se construit avec le temps.
          C'est normal de ne pas avoir toutes les réponses dès le début.
          N'hésite pas à demander conseil à l’ensemble de l'équipe pédagogique
          de ton lycée.
        </Text>
      </Box>

      <ActiviteFooter>
        <HStack spacing={4}>
          <Link href={backButtonPath} textDecoration="none" display="inline">
            Précédent
          </Link>

          <Button
            onClick={(e) => {
              e.preventDefault();
              goNext();
            }}
          >
            Découvrir mes pistes recommandées
          </Button>
        </HStack>
      </ActiviteFooter>
    </>
  );
};

const PisteOpinionForm = ({ piste, onSuccess }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess,
    });

  const schema = PisteOpinionSchema.pick('like', 'dislike');
  const initialValues = {
    pisteId: piste._id,
    pisteName: piste.name,
    like:
      myProfile?.pistesOpinions?.find((p) => p.pisteId === piste._id)?.like ||
      '',
    dislike:
      myProfile?.pistesOpinions?.find((p) => p.pisteId === piste._id)
        ?.dislike || '',
  };

  const formik: FormikConfig<FormikValues> = {
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      const opinion = {
        pisteId: piste._id,
        pisteName: piste.name,
        ...schema.clean(values),
      };
      const pistesOpinions = (
        myProfile?.pistesOpinions?.filter((p) => p.pisteId !== piste._id) || []
      ).concat([opinion]);
      return setAndRefetchMyProfile({ pistesOpinions });
    },
  };

  return (
    <Formik {...formik}>
      <Form>
        <VStack spacing={4}>
          <TextareaControl
            label={'Ce qui te plait dans cette piste'}
            name={'like'}
            textareaProps={{
              placeholder: 'Donne ton avis ici...',
            }}
          />
          <TextareaControl
            label={'Ce qui ne te plait pas dans cette piste'}
            name={'dislike'}
            textareaProps={{
              placeholder: 'Donne ton avis ici...',
            }}
          />
        </VStack>
        <Center mt={6}>
          <FavoriteButton
            pisteId={piste._id}
            gtmLabel="Clic sur le bouton (page découverte)"
          />
        </Center>
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const PisteRecoForm = ({ goNext }) => {
  const [pisteRecoIndex, setpisteRecoIndex] = useState(0);
  const consigneRef = useRef(null);
  const { isProf } = useUser();
  const { myProfile } = useMyProfile();
  const { pistes } = usePistes();
  const pistesReco = isProf
    ? take(pistes, 2)
    : getPistesReco(myProfile.algoResults, pistes);
  const p = pistesReco && pistesReco[pisteRecoIndex];

  const onSuccess = () => {
    if (pisteRecoIndex < pistesReco?.length - 1) {
      setpisteRecoIndex(pisteRecoIndex + 1);
    } else {
      goNext();
    }
  };

  useEffect(() => {
    consigneRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [pisteRecoIndex]);

  return (
    <>
      <Text textAlign="center" ref={consigneRef}>
        Pour chaque piste recommandée, note <b>ce qui te plait</b> et{' '}
        <b>ce qui te plait moins</b>. Si cette piste te plait, tu peux la mettre
        en favoris.
      </Text>
      {p && (
        <>
          <Box maxWidth={'400px'} my={8} mx="auto">
            <Text textAlign="center" textTransform="uppercase">
              <b>
                Recommandation {pisteRecoIndex + 1} / {pistesReco?.length} :
              </b>
            </Text>
            <Link
              href={pistePath(p.slug)}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <PisteItem piste={p} />
            </Link>
          </Box>

          <ProfTips mt={8} mb={4}>
            <Text mb={0}>
              Si l'élève veut travailler directement après le bac...
            </Text>
          </ProfTips>

          {(isProf || myProfile?.previsionOccupationPostBac === 'travail') && (
            <>
              <Text>
                Les questions que tu peux te poser pour savoir si un métier ou
                un secteur d'activité te correspond :
              </Text>
              <UnorderedList>
                <ListItem>
                  Envisages-tu de travailler plutôt dans une entreprise, une
                  association ou bien dans le secteur public ?
                </ListItem>
                <ListItem>
                  Préfères-tu faire un métier où tu es sur le terrain ou bien
                  faire un métier où tu es dans un bureau ?
                </ListItem>
                <ListItem>
                  Souhaites-tu faire un métier où tu es en contact avec les gens
                  ?
                </ListItem>
                <ListItem>
                  Te vois tu salarié d’une entreprise ou bien monter ta propre
                  activité ?
                </ListItem>
                <ListItem>
                  Souhaites-tu un métier avec des possibilités d'évolution ?
                </ListItem>
              </UnorderedList>
            </>
          )}

          <ProfTips mt={8} mb={4}>
            <Text mb={0}>
              Si l'élève veut poursuivre ses études après le bac...
            </Text>
          </ProfTips>

          {(isProf || myProfile?.previsionOccupationPostBac === 'etudes') && (
            <>
              <Text>
                Les questions que tu peux te poser pour savoir si une formation
                te correspond :
              </Text>
              <UnorderedList>
                <ListItem>
                  Combien d'années d'études imagines-tu faire après l'obtention
                  de ton Bac ?
                </ListItem>
                <ListItem>
                  Souhaites-tu suivre une formation en alternance ?
                </ListItem>
                <ListItem>
                  Est-ce que les matières enseignées te plaisent ?
                </ListItem>
                <ListItem>
                  As-tu envie d’apprendre de nouvelles choses dans ce domaine ?
                </ListItem>
                <ListItem>
                  Est-ce que tu préfères être autonome ou très encadré par tes
                  professeurs ?
                </ListItem>
              </UnorderedList>
            </>
          )}

          <ProfTips mt={8} mb={4}>
            <Text mb={0}>Si l'élève ne sais pas encore...</Text>
          </ProfTips>

          {(isProf ||
            myProfile?.previsionOccupationPostBac === 'ne-sait-pas') && (
            <>
              <Text>
                Les questions que tu peux te poser pour savoir si un choix
                d’orientation te correspond :
              </Text>
              <UnorderedList>
                <ListItem>
                  Préfères-tu faire un métier où tu es sur le terrain ou bien
                  faire un métier où tu es dans un bureau ?
                </ListItem>
                <ListItem>
                  Souhaites-tu faire un métier où tu es en contact avec les gens
                  ?
                </ListItem>
                <ListItem>
                  Combien d'années d'études imagines-tu faire après l'obtention
                  de ton Bac ?
                </ListItem>
                <ListItem>
                  Souhaites-tu suivre une formation en alternance ?
                </ListItem>
                <ListItem>
                  Est-ce qu'un service civique pourrait t'intéresser ?
                </ListItem>
              </UnorderedList>
            </>
          )}
          <Box mt={8}>
            <PisteOpinionForm onSuccess={onSuccess} piste={p} />
          </Box>
        </>
      )}
    </>
  );
};

export const DecouvertePisteConclusion = () => (
  <>
    <Box textAlign={'center'}>
      <Text>
        Tu as envie de découvrir les autres possibilités qui s'offrent à toi
        après ton Bac ?
      </Text>
      <Text>
        Rendez-vous sur la page{' '}
        <Link href={PISTES_PATH}>Les pistes d'avenir</Link>.
      </Text>
    </Box>
  </>
);
