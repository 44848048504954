import { map } from "lodash";
import SimpleSchema from "@inspire/data/schemas/SimpleSchema";
import { BoostOptions, BoursierOptions } from "@inspire/data/static/static";
export var BoostFields = {
    boostAlternanceApprentissage: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "J'ai envie de faire une formation post-bac en alternance / apprentissage",
        displayFunction: function(value) {
            if (value === "4" || value === "3") {
                return "<i>Beaucoup de nos \xe9tudiants \xc9claireurs ont suivi un apprentissage pendant leurs \xe9tudes et pourront te raconter leur exp\xe9rience !</i>  \uD83E\uDDD0";
            }
        }
    },
    boostPartir: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Partir de chez mes parents me fait peur",
        displayFunction: function(value) {
            if (value === "4" || value === "3") {
                return "<i>Plus de la moiti\xe9 de nos \xe9tudiants \xc9claireurs ont d\xe9m\xe9nag\xe9 apr\xe8s leur Bac, ils pourront t’expliquer comment \xe7a s’est pass\xe9 pour eux .</i>  \uD83D\uDCE6";
            }
        }
    },
    boostConnaisPersonne: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Je me demande si je suis capable d'aller dans une formation o\xf9 je ne connais personne",
        displayFunction: function(value) {
            if (value === "4" || value === "3") {
                return "<i>Plus de 3 \xe9tudiants \xc9claireurs sur 4 ne connaissaient personne en arrivant dans leur formation, ils sauront te rassurer .</i>  \uD83D\uDE09";
            }
        }
    },
    boostBudget: {
        type: Number,
        optional: true,
        allowedValues: map(BoostOptions, "value"),
        autoform: {
            options: BoostOptions
        },
        label: "Je me demande si j'aurai un budget suffisant pour financer mes \xe9tudes",
        displayFunction: function(value) {
            if (value === "4" || value === "3") {
                return "<i>La majorit\xe9 de nos \xe9tudiants \xc9claireurs se sont pos\xe9 la question aussi et ont r\xe9ussi \xe0 financ\xe9 leurs \xe9tudes. Des solutions existent, renseigne-toi aupr\xe8s d’eux !</i> \uD83D\uDCB8";
            }
        }
    },
    isBoursierSecondaire: {
        type: String,
        allowedValues: Object.keys(BoursierOptions),
        optional: true,
        autoform: {
            options: BoursierOptions
        },
        label: "Je suis boursier"
    }
};
export var BoostQuestions = Object.keys(BoostFields).map(function(k) {
    return {
        key: k,
        label: BoostFields[k].label,
        displayFunction: BoostFields[k].displayFunction
    };
});
export var BoostSchema = new SimpleSchema(BoostFields);
