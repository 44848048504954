import { Button, ButtonProps } from '@chakra-ui/react';
import { find, reject } from 'lodash';

import { AddFavoriteIcon, RemoveFavoriteIcon } from '@inspire/ui/icons';

import { useMyProfile } from 'lib/hooks';
import { successToast } from 'lib/toasts';
import {
  GTM_ACTION_MISE_EN_FAVORIS,
  GTM_CATEGORY_FAVORIS,
  pushEvent,
} from 'lib/gtm';

interface Props {
  pisteId: string;
  gtmLabel: string;
  variant?: 'round';
}

const FavoriteButton = ({
  pisteId,
  variant,
  gtmLabel,
  ...props
}: Props & ButtonProps) => {
  const {
    myProfile,
    setAndRefetchMyProfile,
    isSettingAndRefetchingMyProfile,
    isMyProfileLoading,
  } = useMyProfile(null, {
    onSuccess: (_, variables: any) => {
      successToast(
        find(variables?.input?.favoritePistes, { id: pisteId })
          ? 'Piste ajoutée aux favoris.'
          : 'Piste retirée des favoris.'
      );
    },
  });

  const favoritePistes = myProfile?.favoritePistes || [];
  const isPisteFavorite = find(favoritePistes, { id: pisteId });

  const addPiste = () => favoritePistes.concat([{ id: pisteId }]);
  const removePiste = () => reject(favoritePistes, (p) => p.id === pisteId);

  const roundStyle = variant && {
    borderRadius: '999px',
    height: '44px',
    width: '44px',
  };
  return (
    <Button
      leftIcon={
        !variant &&
        (isPisteFavorite ? <RemoveFavoriteIcon /> : <AddFavoriteIcon />)
      }
      isLoading={isMyProfileLoading || isSettingAndRefetchingMyProfile}
      loadingText={
        !variant &&
        isSettingAndRefetchingMyProfile &&
        (isPisteFavorite ? 'Retrait en cours' : 'Ajout en cours')
      }
      onClick={() => {
        if (!isPisteFavorite) {
          pushEvent(
            GTM_CATEGORY_FAVORIS,
            GTM_ACTION_MISE_EN_FAVORIS,
            gtmLabel,
            pisteId
          );
        }
        setAndRefetchMyProfile({
          favoritePistes: isPisteFavorite ? removePiste() : addPiste(),
        });
      }}
      {...roundStyle}
      {...props}
    >
      {isPisteFavorite ? (
        variant ? (
          <RemoveFavoriteIcon />
        ) : (
          'Retirer des favoris'
        )
      ) : variant ? (
        <AddFavoriteIcon />
      ) : (
        'Ajouter aux favoris'
      )}
    </Button>
  );
};

export default FavoriteButton;
