/* eslint-disable no-use-before-define */ import { union, keys, range, map, includes, without } from "lodash";
import SimpleSchema from "@inspire/data/schemas/SimpleSchema";
import { MatieresLycee } from "@inspire/data/static/matieres";
import { Bacs, Bacs2019, TypesBac, Classes, Sexe, SpesPremiere2019, FilieresTechnos, EncadrementOptions, EnvironnementOptions, TerminaleOptions, MatieresLyceeForTerminale } from "@inspire/data/static/static";
import { AllMotivationsOptions, MotivationOptions } from "@inspire/data/static/motivations";
import { StaticHelpers } from "@inspire/data/helpers/static";
import { BacsPro, FamillesMetiers } from "@inspire/data/static/bacs-pro";
import { DomainesWithLegacyDomainesIncluded } from "@inspire/data/static/domaines";
import { Departments } from "@inspire/data/static/departments";
var MatiereSchema = new SimpleSchema({
    matiere: {
        type: String,
        allowedValues: union(keys(MatieresLycee), keys(SpesPremiere2019))
    },
    grade: {
        type: Number,
        optional: true,
        min: -1,
        max: 20,
        // Bac Pro uses intervals. 2.5 corresponds to 0-5, 6.5 to 5-8
        allowedValues: range(-1, 20 + 1).concat(2.5, 6.5),
        autoform: {
            options: map(range(1, 20 + 1), function(v) {
                return {
                    label: String(v),
                    value: v
                };
            })
        }
    },
    like: {
        type: Number,
        label: "Dans quelle mesure appr\xe9cies-tu cette mati\xe8re\xa0?",
        optional: true
    },
    isSpe: {
        type: Boolean,
        optional: true
    },
    isSpeAbandonnee: {
        type: Boolean,
        optional: true
    }
});
var DomainSchema = new SimpleSchema({
    domain: {
        type: String,
        optional: true,
        allowedValues: Object.keys(DomainesWithLegacyDomainesIncluded)
    },
    interest: {
        type: Number,
        optional: true,
        allowedValues: [
            -1,
            0,
            1
        ],
        defaultValue: 0
    }
});
var EnvironnementSchema = new SimpleSchema({
    type: {
        type: String,
        optional: true,
        allowedValues: Object.keys(EnvironnementOptions)
    },
    interest: {
        type: Number,
        optional: true,
        allowedValues: [
            1,
            0,
            -1
        ],
        defaultValue: 0
    }
});
// Notes on AlgoSchema:
// - "isAnswered" and "conditions" fields are used by isQuestionnaireComplete helper
// - left autoForm fields as they might still be used by admin. Should check and remove them if possible.
var AlgoSchema = new SimpleSchema({
    // classe: {
    //   type: String,
    //   optional: true,
    //   allowedValues: Object.keys(Classes),
    //   autoform: {
    //     options: omit(Classes, 'Post-bac'),
    //   },
    //   label: 'À la rentrée 2021, es-tu en Première ou en Terminale ?',
    // },
    niveau: {
        type: String,
        optional: true,
        allowedValues: Object.keys(Classes),
        question: {
            isAnswered: function(data) {
                var ref;
                return data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.find(function(s) {
                    return typeof s.niveau !== "undefined";
                });
            }
        }
    },
    filiere: {
        type: String,
        optional: true,
        allowedValues: union(Object.keys(Bacs), Object.keys(Bacs2019), Object.keys(MatieresLyceeForTerminale), Object.keys(TypesBac)),
        autoform: {
            options: Bacs2019
        },
        label: "Quel bac fais-tu ?",
        question: {
            isAnswered: function(data) {
                var ref;
                return data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.find(function(s) {
                    return typeof s.filiere !== "undefined";
                });
            }
        }
    },
    filiereBacPro: {
        type: String,
        optional: true,
        allowedValues: Object.keys(BacsPro),
        autoform: {
            options: BacsPro
        },
        label: "Bac Pro",
        question: {
            isAnswered: function(data) {
                var ref, ref1;
                return typeof ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : ref1[0].filiereBacPro) !== "undefined";
            },
            conditions: function(data) {
                var ref, ref1, ref2;
                return ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.filiere) === "bac_pro";
            }
        }
    },
    filiereTechno: {
        type: String,
        optional: true,
        allowedValues: Object.keys(FilieresTechnos),
        autoform: {
            options: FilieresTechnos
        },
        label: "Quelle s\xe9rie as-tu choisie ?",
        question: {
            name: "techno",
            isAnswered: function(data) {
                var ref, ref1, ref2;
                return typeof ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.filiereTechno) !== "undefined";
            },
            conditions: function(data) {
                var ref, ref1, ref2;
                return ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.filiere) === "bac_techno";
            }
        }
    },
    familleMetiersPro: {
        type: String,
        optional: true,
        allowedValues: Object.keys(FamillesMetiers),
        autoform: {
            options: FamillesMetiers
        },
        label: "Famille M\xe9tier Pro",
        question: {
            isAnswered: function(data) {
                var ref, ref1;
                return typeof ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : ref1[0].familleMetiersPro) !== "undefined";
            },
            conditions: function(data) {
                var ref, ref1, ref2;
                return ((ref1 = data === null || data === void 0 ? void 0 : (ref = data.lyceeStudies) === null || ref === void 0 ? void 0 : ref.sort(function(a, b) {
                    return b.annee - a.annee;
                })) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.filiere) === "bac_pro";
            }
        }
    },
    spes_premiere: {
        type: Array,
        label: "Quels enseignements de sp\xe9cialit\xe9 as-tu choisis en Premi\xe8re ? (s\xe9lectionnes-en 3)",
        optional: true,
        minCount: 3,
        maxCount: 3,
        question: {
            conditions: function(data) {
                return (data === null || data === void 0 ? void 0 : data.niveau) === "Premi\xe8re" && data.filiere === "bac_general";
            },
            isAnswered: function(data) {
                return (data === null || data === void 0 ? void 0 : data.spes_premiere) && (data === null || data === void 0 ? void 0 : data.spes_premiere.length) === 3;
            }
        }
    },
    "spes_premiere.$": {
        type: String,
        optional: true,
        allowedValues: Object.keys(SpesPremiere2019)
    },
    spes_terminale: {
        type: Array,
        label: "Quels enseignements de sp\xe9cialit\xe9 as-tu choisis en Terminale ? (s\xe9lectionnes-en 2)",
        optional: true,
        minCount: 2,
        maxCount: 2,
        autoform: {
            options: SpesPremiere2019,
            noselect: true
        },
        question: {
            conditions: function(data) {
                return (data === null || data === void 0 ? void 0 : data.niveau) === "Terminale" && (data === null || data === void 0 ? void 0 : data.filiere) === "bac_general";
            },
            isAnswered: function(data) {
                var ref;
                return (data === null || data === void 0 ? void 0 : (ref = data.spes_terminale) === null || ref === void 0 ? void 0 : ref.length) === 2;
            }
        }
    },
    "spes_terminale.$": {
        type: String,
        optional: true,
        allowedValues: Object.keys(SpesPremiere2019)
    },
    spe_abandonnee_premiere: {
        type: Array,
        label: "Quel enseignement de sp\xe9cialit\xe9 as-tu abandonn\xe9 en fin de Premi\xe8re ? (s\xe9lectionnes-en 1)",
        optional: true,
        minCount: 1,
        maxCount: 1,
        autoform: {
            options: SpesPremiere2019,
            noselect: true
        },
        question: {
            conditions: function(data) {
                return (data === null || data === void 0 ? void 0 : data.niveau) === "Terminale" && (data === null || data === void 0 ? void 0 : data.filiere) === "bac_general";
            },
            isAnswered: function(data) {
                var ref;
                return (data === null || data === void 0 ? void 0 : (ref = data.spe_abandonnee_premiere) === null || ref === void 0 ? void 0 : ref.length) === 1;
            }
        }
    },
    "spe_abandonnee_premiere.$": {
        type: String,
        optional: true,
        allowedValues: Object.keys(SpesPremiere2019)
    },
    hasOptions: {
        type: Boolean,
        label: "As-tu choisis une ou des options en Terminale ?",
        optional: true,
        autoform: {
            type: "boolean-radios",
            trueLabel: "Oui",
            falseLabel: "Non"
        },
        question: {
            conditions: function(data) {
                return (data === null || data === void 0 ? void 0 : data.niveau) === "Terminale" && (data === null || data === void 0 ? void 0 : data.filiere) === "bac_general";
            },
            isAnswered: function(data) {
                return typeof data.hasOptions !== "undefined";
            }
        }
    },
    options_terminale: {
        type: Array,
        label: "As-tu choisi une ou des options en Terminale parmi les suivantes ?",
        optional: true,
        maxCount: 3,
        autoform: {
            options: TerminaleOptions
        },
        question: {
            conditions: function(data) {
                return !!(data === null || data === void 0 ? void 0 : data.hasOptions) && (data === null || data === void 0 ? void 0 : data.niveau) === "Terminale" && (data === null || data === void 0 ? void 0 : data.filiere) === "bac_general";
            },
            isAnswered: function(data) {
                return !!(data === null || data === void 0 ? void 0 : data.hasOptions);
            }
        },
        custom: function custom() {
            if (this.value && this.value.length > 1 && includes(this.value, "mathematiques_expertes") && includes(this.value, "mathematiques_complementaires")) {
                return "doubleMaths";
            }
        }
    },
    "options_terminale.$": {
        type: String,
        optional: true,
        allowedValues: Object.keys(TerminaleOptions)
    },
    domains: {
        type: Array,
        label: "Parmi ces domaines disciplinaires, lesquels t’int\xe9ressent ou pas du tout ?",
        optional: true,
        question: {
            isAnswered: function(data) {
                return data.domains && data.domains.length !== 0;
            }
        }
    },
    "domains.$": {
        type: DomainSchema,
        optional: true
    },
    matieres: {
        type: Array,
        optional: true,
        question: {
            isAnswered: function(data) {
                var ref;
                // const qs = this.generator(data);
                // let answered = true;
                // forEach(qs, (q) => {
                //   if (!q.isAnswered(data)) {
                //     answered = false;
                //   }
                // });
                // TODO
                return (data === null || data === void 0 ? void 0 : (ref = data.matieres) === null || ref === void 0 ? void 0 : ref.length) !== 0;
            }
        }
    },
    "matieres.$": {
        type: MatiereSchema,
        optional: true
    },
    moyennePremiere: {
        type: Number,
        optional: true,
        label: "Quelle \xe9tait ta moyenne g\xe9n\xe9rale en Premi\xe8re ?",
        autoform: {
            min: 1,
            max: 20,
            allowedValues: range(0, 20 + 1),
            options: map(range(1, 20 + 1), function(v) {
                return {
                    label: String(v),
                    value: v
                };
            })
        },
        question: {
            conditions: function(data) {
                return (data === null || data === void 0 ? void 0 : data.niveau) === "Terminale" && (data === null || data === void 0 ? void 0 : data.filiere) === "bac_general";
            },
            isAnswered: function(data) {
                return typeof data.moyennePremiere !== "undefined";
            }
        }
    },
    motivation: {
        type: Array,
        label: "Pendant tes ann\xe9es lyc\xe9e, tu as :",
        optional: true,
        autoform: {
            options: MotivationOptions,
            noselect: true
        },
        question: {
            isAnswered: function(data) {
                return typeof (data === null || data === void 0 ? void 0 : data.motivation) !== "undefined";
            }
        },
        autoValue: function autoValue() {
            if (!this.isSet || !this.value) {
                return;
            }
            if (!this.value.length) {
                return [
                    "aucun"
                ];
            }
            if (this.value.length > 1 && this.value.includes("aucun")) {
                return without(this.value, "aucun");
            }
        }
    },
    "motivation.$": {
        type: String,
        optional: true,
        allowedValues: Object.keys(AllMotivationsOptions).concat("aucun")
    },
    environnement: {
        type: Array,
        label: "Apr\xe8s le bac tu t'imagines \xe9tudier :",
        optional: true,
        question: {
            isAnswered: function(data) {
                var ref;
                return (data === null || data === void 0 ? void 0 : (ref = data.environnement) === null || ref === void 0 ? void 0 : ref.length) !== 0;
            }
        }
    },
    "environnement.$": {
        type: EnvironnementSchema,
        optional: true
    },
    encadrement: {
        type: String,
        label: "Tu pr\xe9f\xe8res :",
        optional: true,
        allowedValues: Object.keys(EncadrementOptions),
        autoform: {
            options: EncadrementOptions
        },
        question: {
            isAnswered: function(data) {
                return typeof data.encadrement !== "undefined";
            }
        }
    }
});
var ExploreSchema = new SimpleSchema(AlgoSchema);
ExploreSchema.extend({
    gender: {
        type: String,
        label: "Tu es... ?",
        optional: true,
        allowedValues: Object.keys(Sexe),
        autoform: {
            options: Sexe
        },
        question: {
            conditions: function(data) {
                return typeof data.gender === "undefined";
            },
            isAnswered: function(data) {
                return typeof data.gender !== "undefined";
            }
        }
    },
    departement: {
        type: String,
        optional: true,
        allowedValues: Object.keys(Departments),
        autoform: {
            options: StaticHelpers.getPrettyDepartments()
        },
        label: "D\xe9partement"
    },
    lyceeId: {
        type: String,
        optional: true,
        label: "Lyc\xe9e",
        question: {
            conditions: function(data) {
                return typeof data.lyceeId === "undefined";
            },
            isAnswered: function(data) {
                return typeof data.lyceeId !== "undefined";
            }
        }
    },
    lycee: {
        type: String,
        optional: true,
        label: "Nom du lyc\xe9e",
        custom: function custom() {
            var isAutreLycee = this.field("lyceeId").value === "Autres";
            if (isAutreLycee && !this.isSet && (!this.operator || this.value === null || this.value === "")) {
                return "required";
            }
            return null;
        }
    },
    lyceeCity: {
        type: String,
        optional: true,
        label: "Ville du lyc\xe9e",
        custom: function custom() {
            var isAutreLycee = this.field("lyceeId").value === "Autres";
            if (isAutreLycee && !this.isSet && (!this.operator || this.value === null || this.value === "")) {
                return "required";
            }
            return null;
        }
    },
    isLyceenPrioritaire: {
        type: Boolean,
        optional: true
    },
    inscriptionPedagogique: {
        type: Boolean,
        optional: true,
        label: "Pour t'aider dans ton orientation, INSPIRE propose \xe0 l'\xe9quipe \xe9ducative de ton lyc\xe9e (par exemple ton professeur principal) de t'accompagner en ayant acc\xe8s \xe0 ton profil*",
        autoform: {
            type: "boolean-radios",
            trueLabel: "Oui, j'accepte que l'\xe9quipe p\xe9dagogique de mon lyc\xe9e ait acc\xe8s \xe0 ces informations",
            falseLabel: "Non, je n'accepte pas que l'\xe9quipe p\xe9dagogique de mon lyc\xe9e ait acc\xe8s \xe0 ces informations"
        }
    }
});
export { ExploreSchema, AlgoSchema };
