import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import ProfTips from 'components/activites/ProfTips';
import VideoPlayer from 'components/general/VideoPlayer';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';

const questions = [
  'Quel a été le parcours scolaire de Nassim ? Peux-tu détailler chaque étape ?',
  'Est-ce que ça a été un parcours linéaire? A-t-il tout réussi d’un coup ?',
  'Quel était son plus grand projet personnel ? Que voulait-il réaliser à travers ce projet ?',
  'Que lui a apporté cette expérience en termes de compétences ?',
  'Ces compétences lui ont-elles été utiles par la suite ?',
  'Quel est son principal conseil ?',
];

const VIDEO_URL = '/videos-bacpro/5.+Nassim%2C+Un+Parcours+Inspirant.mp4';

export const ParcoursLineaire = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>Regarde la vidéo puis répond oralement aux questions.</i>
    </Text>
    <Box margin="auto">
      <VideoPlayer url={VIDEO_URL} />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      {questions.map((question, index) => (
        <ListItem key={`question-parcours-lineaire-${index}`}>
          {question}
        </ListItem>
      ))}
    </UnorderedList>
  </>
);
