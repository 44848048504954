export var Qualites = {
    ecoute: "A l’\xe9coute",
    genereux: "G\xe9n\xe9reux(euse)",
    ambitieux: "Ambitieux(euse)",
    meneur: "Meneur(euse)",
    autonome: "Autonome",
    observateur: "Observateur(trice)",
    bienveillant: "Bienveillant(e)",
    organise: "Organis\xe9(e)",
    calme: "Calme",
    ouvert: "Ouvert(e) d’esprit",
    concentration: "Capable de me concentrer",
    patient: "Patient(e)",
    courageux: "Courageux(euse)",
    perseverant: "Pers\xe9v\xe9rant(e)",
    creatif: "Cr\xe9atif(ve)",
    ponctuel: "Ponctuel(le)",
    curieux: "Curieux(se)",
    responsable: "Responsable",
    debrouillard: "D\xe9brouillard(e)",
    serviable: "Serviable",
    determine: "D\xe9termin\xe9(e)",
    sociable: "Sociabl(e)",
    confiance: "Digne de confiance",
    souriant: "Souriant(e)",
    discipline: "Disciplin\xe9(e)",
    sportif: "Sportif(ve)",
    drole: "Dr\xf4le",
    stratege: "Strat\xe8ge",
    dynamique: "Dynamique",
    tolerant: "Tol\xe9rant(e)",
    equipe: "Dot\xe9(e) d’esprit d’\xe9quipe",
    travailleur: "Travailleur(euse)"
};
