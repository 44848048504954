import { useEffect } from 'react';

import {
  Box,
  Text,
  SimpleGrid,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import Image from 'next/image';

import TransportImage from '@inspire/ui/assets/activites/photolanguage/transport.jpg';
import TelephoneImage from '@inspire/ui/assets/activites/photolanguage/telephone.jpg';
import NourritureImage from '@inspire/ui/assets/activites/photolanguage/nourriture.jpg';
import LoisirsImage from '@inspire/ui/assets/activites/photolanguage/loisirs.jpg';
import LogementImage from '@inspire/ui/assets/activites/photolanguage/logement.jpg';
import FinanceImage from '@inspire/ui/assets/activites/photolanguage/finances.jpg';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import Link from 'components/general/Link';

import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import { CheckboxGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';
import SortingGame from 'components/activites/SortingGame';
import Euros from 'components/general/Euros';

export const PhotoLanguage = () => (
  <Box textAlign={'center'}>
    <ReflexionOralHeader />
    <Text my={8}>
      En s’inspirant des photos proposées, donne à l’oral des exemples de{' '}
      <b>dépenses d’un étudiant</b>.
    </Text>
    <SimpleGrid columns={[1, 2, 2, 3]} spacing="40px">
      <Image src={TransportImage} alt="Transport" />
      <Image src={TelephoneImage} alt="Telephone" />
      <Image src={NourritureImage} alt="Nourriture" />
      <Image src={LoisirsImage} alt="Loisirs" />
      <Image src={LogementImage} alt="Logement" />
      <Image src={FinanceImage} alt="Finance" />
    </SimpleGrid>
  </Box>
);

export const VieEtudiante = () => (
  <Box textAlign={'center'}>
    <ReflexionOralHeader />
    <Text mt={8}>
      Donne des exemples des <b>différents types de logement</b> pour les
      étudiants.
    </Text>
  </Box>
);

const logements = [
  {
    id: 'famille',
    label: 'Famille',
    isCorrect: null,
  },
  {
    id: 'logementUniv',
    label: 'Logement en résidence étudiante',
    isCorrect: null,
  },
  {
    id: 'intergenerationnel',
    label: 'Logement intergénérationnel',
    isCorrect: null,
  },
  {
    id: 'colocation',
    label: 'Colocation',
    isCorrect: null,
  },
  {
    id: 'studio',
    label: 'Studio',
    isCorrect: null,
  },
];

export const TrierLogementsEtudiants = ({ allowNext }) => (
  <SortingGame
    sortOptions={logements}
    allowNext={allowNext}
    label={
      <>
        Trie ces logements du <b>moins</b> cher au <b>plus</b> cher.
      </>
    }
  />
);

export const Crous = ({ allowNext }) => {
  const questions = [
    {
      text: "Qu'est-ce que propose le CROUS ?",
      answerValues: ['finance', 'logements', 'restau'],
    },
  ];

  const options = [
    { shortName: 'Une aide financière', value: 'finance' },
    { shortName: 'Des logements', value: 'logements' },
    { shortName: 'Une aide aux devoirs', value: 'devoirs' },
    { shortName: 'Des restaurants', value: 'restau' },
    { shortName: 'Une mutuelle santé', value: 'mutuelle' },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <CheckboxGame
        questions={questions}
        getOptions={() => options}
        onFinished={() => allowNext(true)}
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame>
              <UnorderedList>
                {questions.slice(0, questionIndex).map((q) => (
                  <ListItem key={q.text}>
                    <b>{q.text} :</b>{' '}
                    {q.answerValues
                      .map(
                        (av) => options.find((o) => o.value === av).shortName
                      )
                      .join(', ')}
                  </ListItem>
                ))}
              </UnorderedList>
              <Text>
                <i>
                  Le CROUS aide les étudiants dans la vie de tous les jours pour
                  faciliter la réussite de leurs études avec notamment une aide
                  financière, des logements et des restaurants universitaires.
                  La bourse du Crous est attribuée sur critères sociaux.
                </i>
              </Text>
            </SummaryFrame>
          )
        }
      />
      <ProfTips mt={8}>
        <Text>
          <b>Bourse du Crous :</b> La bourse du CROUS est attribuée en fonction
          des ressources et des charges du foyer fiscal de l’étudiant, du nombre
          d’enfants à charge et de l’éloignement entre le domicile et le lieu
          d’études.
        </Text>
        <Text>
          <b>Pour savoir si on a droit à cette bourse :</b> faire une simulation
          sur le site du Crous{' '}
          <Link href="https://simulateur.lescrous.fr/" target="_none">
            https://simulateur.lescrous.fr/
          </Link>
          .
        </Text>
        <Text>
          <b>Échelons :</b> Le montant de la bourse va de l’échelon 0 bis (
          <Euros>104</Euros>/mois) à l’échelon 7 (<Euros>573</Euros>/mois). Être
          boursier exonère également des frais d’inscriptions, permet d’avoir
          les transports moins chers voire gratuits, d’être prioritaire pour un
          logement CROUS…
        </Text>
        <Text>
          <b>Pour faire sa demande :</b> constituer un Dossier Social Etudiant
          entre mi-janvier et mi-mai de l’année de terminale. La demande se fait
          maintenant sur Parcoursup.
        </Text>
      </ProfTips>
    </>
  );
};
