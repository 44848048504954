import { FormControlProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { LabelledSliderControl } from 'components/forms/LabelledSliderField';

const RessentiFormationControl = (formControlProps: FormControlProps) => {
  const { values } = useFormikContext<{ ressentiFormation: any }>();

  return (
    <LabelledSliderControl
      label="Je me sens bien dans la formation dans laquelle je suis
    inscrit.e"
      name="ressentiFormation"
      value={values.ressentiFormation}
      {...formControlProps}
    />
  );
};

export default RessentiFormationControl;
