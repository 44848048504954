import {
  Text,
  Radio,
  HStack,
  Alert,
  ListItem,
  List,
  Box,
  Heading,
  UnorderedList,
} from '@chakra-ui/react';
import Required from 'components/general/Required';
import { useEffect, useState } from 'react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormStack } from '@inspire/ui/chakra/forms/Form';
import { RadioGroupControl, InputControl, FormControl } from 'formik-chakra-ui';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { useMyProfile, useUser } from 'lib/hooks';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { validateSchema } from '@inspire/data/helpers/schemas';
import {
  MesPreferencesDapprentissageOptions,
  MesPrioritesPourMonAvenirOptions,
  PreferencesDapprentissageLabel,
  PrioritesPourMonAvenirLabel,
} from './japprends-a-me-connaitre';
import Span from '@inspire/ui/chakra/Span';
import { YesNoOptions } from 'components/activites/recap-helpers';

export const MaFormationActuelle = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    choixFormation: myProfile?.choixFormation ?? {},
  };
  let initialChoisi = 'none';
  let initialInteressant = 'none';
  let initialAllowNext = false;
  if (myProfile.choixFormation?.choisiFormation) {
    initialChoisi = myProfile.choixFormation.choisiFormation;
  }
  if (myProfile.choixFormation?.interessant) {
    initialInteressant = myProfile.choixFormation.interessant;
  }
  if (
    myProfile.choixFormation?.choisiFormation === 'yes' ||
    (myProfile.choixFormation?.choisiFormation === 'no' &&
      myProfile.choixFormation?.interessant)
  ) {
    initialAllowNext = true;
  }

  const [choisi, setChoisi] = useState(initialChoisi);
  const [interessant, setInteressant] = useState(initialInteressant);
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  const schema =
    LyceenBacProProfileSchema.pick('choixFormation').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChangeChoisiFormation = (value) => {
    const currentChoisi = value.target.value;
    setChoisi(currentChoisi);
    if (choisi === 'yes') setAllowNext(true);
    else if (choisi === 'no' && interessant !== 'none') setAllowNext(true);
    else setAllowNext(false);
  };

  const handleChangeInteressant = (value) => {
    setInteressant(value.target.value);
    if (choisi !== 'none') setAllowNext(true);
  };

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <FormStack>
            <RadioGroupControl
              name={'choixFormation.choisiFormation'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>As-tu choisi ta formation actuelle ?</>
                  </Required>
                </Text>
              }
              stackProps={{ direction: 'column' }}
              onChange={handleChangeChoisiFormation}
            >
              <Text>
                <i>
                  Coche la réponse de ton choix (il n'y a pas de bonne réponse,
                  il s'agit de faire un bilan concernant ton orientation).
                </i>
              </Text>
              <Radio value="yes">Oui</Radio>
              <Radio value="no">Non, on a choisi pour moi</Radio>
            </RadioGroupControl>

            {choisi === 'yes' && (
              <InputControl
                name="choixFormation.pourquoiChoisi"
                label="Pourquoi as-tu choisi cette formation ?"
                isRequired
                inputProps={{
                  placeholder: "J'ai choisi cette filière car ...",
                }}
                onChange={(e: any) => {
                  if (e.target.value.length) setAllowNext(true);
                  else setAllowNext(false);
                }}
              />
            )}

            {choisi === 'no' && (
              <RadioGroupControl
                name={'choixFormation.interessant'}
                label={"Et finalement ça t'intéresse ?"}
                stackProps={{ direction: 'column' }}
                onChange={handleChangeInteressant}
                isRequired
              >
                <Radio value="yes">Oui, totalement</Radio>
                <Radio value="partly">Oui, en partie</Radio>
                <Radio value="notReally">Non, pas vraiment</Radio>
              </RadioGroupControl>
            )}
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const AdequationPreferencesApprentissage = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    adequationPreferencesApprentissage:
      myProfile?.adequationPreferencesApprentissage ?? {},
    preferencesApprentissage: myProfile?.preferencesApprentissage ?? [],
  };
  let initialCorrespond = 'none';
  let initialAllowNext = false;
  if (myProfile.adequationPreferencesApprentissage?.correspond) {
    initialCorrespond = myProfile.adequationPreferencesApprentissage.correspond;
    initialAllowNext = true;
  }
  const [correspond, setCorrespond] = useState(initialCorrespond);
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  const schema = LyceenBacProProfileSchema.pick(
    'adequationPreferencesApprentissage'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChange = (value) => {
    if (value.target.type === 'radio') {
      setCorrespond(value.target.value);
      setAllowNext(true);
    }
  };

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <Alert padding={0}>
            <List>
              <Text>
                <b>Tes préférences d’apprentissage </b>
                <i>
                  (celles que tu as renseignées dans l’activité précédente)
                </i>{' '}
                :
              </Text>
              {initialValues.preferencesApprentissage.map((value, i) => (
                <ListItem key={i}>
                  • {PreferencesDapprentissageLabel(value)}{' '}
                </ListItem>
              ))}

              {isProf &&
                MesPreferencesDapprentissageOptions.map((value, i) => (
                  <ListItem key={i}>• {value.label} </ListItem>
                ))}
            </List>
          </Alert>

          <FormStack>
            <RadioGroupControl
              name={'adequationPreferencesApprentissage.correspond'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>
                      Est-ce que ta formation actuelle correspond à tes
                      préférences d’apprentissage ?
                    </>
                  </Required>
                </Text>
              }
              stackProps={{ direction: 'column' }}
              onChange={handleChange}
            >
              <Radio value="yes">Oui, totalement</Radio>
              <Radio value="partly">Oui, en partie</Radio>

              <HStack>
                <Radio value="no">
                  {' '}
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    Non, pas vraiment / <i>Pourquoi ?</i>
                  </Text>
                </Radio>
                {window.innerWidth > 600 && (
                  <InputControl
                    name="adequationPreferencesApprentissage.pourquoiNon"
                    inputProps={{
                      placeholder: 'Tape ta réponse ici',
                      isDisabled: correspond !== 'no',
                    }}
                  />
                )}
              </HStack>
              {window.innerWidth <= 600 && (
                <InputControl
                  name="adequationPreferencesApprentissage.pourquoiNon"
                  inputProps={{
                    placeholder: 'Tape ta réponse ici',
                    isDisabled: correspond !== 'no',
                  }}
                />
              )}
            </RadioGroupControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const AdequationMetierSecteurReve = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    adequationMetierOuSecteurReve:
      myProfile?.adequationMetierOuSecteurReve ?? 'none',
  };

  let saitMetierOuSecteur;
  if (myProfile?.metierOuSecteurReve?.saitMetierOuSecteur) {
    saitMetierOuSecteur = myProfile?.metierOuSecteurReve?.saitMetierOuSecteur;
  }
  let metierOuSecteur;
  if (myProfile?.metierOuSecteurReve?.metierOuSecteur) {
    metierOuSecteur = myProfile?.metierOuSecteurReve?.metierOuSecteur;
  }

  let initialAllowNext = false;
  if (myProfile.adequationMetierOuSecteurReve) {
    initialAllowNext = true;
  }
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  const schema = LyceenBacProProfileSchema.pick(
    'adequationMetierOuSecteurReve'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChange = (value) => {
    if (value.target.type === 'radio') {
      setAllowNext(true);
    }
  };

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <Alert>
            <Span>
              <b>Métier ou secteur dans lequel tu aimerais exercer </b>
              <i>(renseigné dans l’activité précédente)</i> : &nbsp;
              {!isProf &&
                (saitMetierOuSecteur ? (
                  <>
                    <br></br>
                    {metierOuSecteur}
                  </>
                ) : (
                  <>
                    <br></br>
                    Tu ne sais pas.
                  </>
                ))}
              {isProf && (
                <>
                  <br></br>
                  Exemple : J’aimerais travailler dans les métiers de la mode en
                  tant que styliste car j’aime beaucoup dessiner des vêtements.
                </>
              )}
            </Span>
          </Alert>

          <FormStack>
            <RadioGroupControl
              name={'adequationMetierOuSecteurReve'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>
                      Est-ce que ta formation actuelle correspond au métier que
                      tu souhaites faire ou au secteur professionnel qui
                      t’intéresse ?
                    </>
                  </Required>
                </Text>
              }
              stackProps={{ direction: 'column' }}
              onChange={handleChange}
            >
              <Radio value="yes">Oui, totalement</Radio>
              <Radio value="partly">Oui, en partie</Radio>
              <Radio value="notReally">Non, pas vraiment</Radio>
              <Radio value="unknown">
                Je ne connais pas la filière qui mène au métier que je veux
                faire
              </Radio>
            </RadioGroupControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const AdequationCentresInteretsPriorites = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    adequationCentresInteretsPriorites:
      myProfile?.adequationCentresInteretsPriorites ?? 'none',
    prioritesAvenir: myProfile.prioritesAvenir ?? [],
  };
  let initialAllowNext = false;
  if (myProfile.adequationCentresInteretsPriorites) {
    initialAllowNext = true;
  }
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  const schema = LyceenBacProProfileSchema.pick(
    'adequationCentresInteretsPriorites'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChange = (value) => {
    if (value.target.type === 'radio') {
      setAllowNext(true);
    }
  };

  let centresDinterets = [];
  if (myProfile.centresDinterets?.centresDinterets) {
    centresDinterets = myProfile.centresDinterets?.centresDinterets;
  }
  let autresInterets = '';
  if (myProfile.centresDinterets?.autre) {
    autresInterets = myProfile.centresDinterets?.autre;
  }

  return (
    <>
      <Alert padding={0}>
        <List>
          <Text>
            <b>Tes centres d'intérêts</b>{' '}
            <i>(ceux que tu as renseignés dans l’activité précédente)</i> :
          </Text>
          <ListItem>
            •{' '}
            {centresDinterets &&
              centresDinterets.map((value, i) => (
                <>
                  {value}
                  {value === 'autre' && autresInterets !== '' && (
                    <>
                      {' ('}
                      {autresInterets}
                      {')'}
                    </>
                  )}
                  {i !== centresDinterets.length - 1 && ', '}
                </>
              ))}
            {isProf && (
              <>
                Exemple : Le basket, la musique, le dessin, l’engagement
                associatif
              </>
            )}
          </ListItem>
          <Text>
            <b>Dans tes études et ton futur métier, tu préfères prioriser</b>{' '}
            <i>(tel que renseigné dans l’activité précédente)</i> :
          </Text>
          {initialValues.prioritesAvenir.map((value, i) => (
            <ListItem key={i}>• {PrioritesPourMonAvenirLabel(value)} </ListItem>
          ))}
          {isProf &&
            MesPrioritesPourMonAvenirOptions.map((value, i) => (
              <ListItem key={i}>• {value.label} </ListItem>
            ))}
        </List>
      </Alert>
      <Formik {...formik}>
        {() => (
          <Form>
            <FormStack>
              <RadioGroupControl
                name={'adequationCentresInteretsPriorites'}
                label={
                  <Text fontWeight={'bold'}>
                    <Required>
                      <>
                        Est-ce que ta formation actuelle te permet d’avoir un
                        projet qui se rapproche de tes centres d'intérêts et de
                        tes priorités ?
                      </>
                    </Required>
                  </Text>
                }
                stackProps={{ direction: 'column' }}
                onChange={handleChange}
              >
                <Radio value="yes">Oui totalement</Radio>
                <Radio value="partly">Oui, en partie</Radio>
                <Radio value="notReally">Non, pas vraiment</Radio>
              </RadioGroupControl>
            </FormStack>
            <FormActiviteFooter
              isLoading={isSettingAndRefetchingMyProfile}
              allowNext={isProf ? true : allowNext}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export const AdequationQualites = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    adequationQualites: myProfile?.adequationQualites ?? 'none',
  };
  let initialAllowNext = false;
  if (myProfile.adequationQualites) {
    initialAllowNext = true;
  }
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  const schema =
    LyceenBacProProfileSchema.pick('adequationQualites').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChange = (value) => {
    if (value.target.type === 'radio') {
      setAllowNext(true);
    }
  };

  let qualites = [];
  if (myProfile.meConnaitreQualites) {
    qualites = myProfile.meConnaitreQualites;
  }

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <Alert padding={0}>
            <List>
              <Text>
                <b>Tes principales qualités</b>{' '}
                <i>(celles que tu as renseignées dans l’activité précédente)</i>{' '}
                :
              </Text>
              <ListItem>
                •{' '}
                {qualites &&
                  qualites.map((value, i) => (
                    <>
                      {value}
                      {i !== qualites.length - 1 && ', '}
                    </>
                  ))}
                {isProf && (
                  <>
                    Exemple : Empathie, Bienveillance, A l’écoute, dotée d’un
                    esprit d’équipe, organisé
                  </>
                )}
              </ListItem>
            </List>
          </Alert>

          <FormStack>
            <RadioGroupControl
              name={'adequationQualites'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>
                      Est-ce que tes qualités sont utiles dans ta formation
                      actuelle ?
                    </>
                  </Required>
                </Text>
              }
              stackProps={{ direction: 'column' }}
              onChange={handleChange}
            >
              <Radio value="yes">Oui, totalement</Radio>
              <Radio value="partly">Oui, en partie</Radio>
              <Radio value="notReally">Non, pas vraiment</Radio>
            </RadioGroupControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const MesPointsFortsScolaires = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    pointsFortsScolaires: myProfile?.pointsFortsScolaires ?? {},
  };

  let initialAllowNext = false;
  let initialCorrespondentAuxMatieresPreferees = 'none';
  let initialMatiere1 = false;
  let initialMatiere2 = false;
  let initialMatiere3 = false;
  if (myProfile.pointsFortsScolaires?.correspondentAuxMatieresPreferees) {
    initialCorrespondentAuxMatieresPreferees =
      myProfile.pointsFortsScolaires?.correspondentAuxMatieresPreferees;
  }
  if (myProfile.pointsFortsScolaires?.meilleuresMatieres.length === 3) {
    initialMatiere1 = true;
    initialMatiere2 = true;
    initialMatiere3 = true;
  }
  if (
    myProfile.pointsFortsScolaires?.correspondentAuxMatieresPreferees &&
    myProfile.pointsFortsScolaires?.meilleuresMatieres.length === 3
  ) {
    initialAllowNext = true;
  }
  const [allowNext, setAllowNext] = useState(initialAllowNext);
  const [matiere1, setMatiere1] = useState(initialMatiere1);
  const [matiere2, setMatiere2] = useState(initialMatiere2);
  const [matiere3, setMatiere3] = useState(initialMatiere3);
  const [
    correspondentAuxMatieresPreferees,
    setCorrespondentAuxMatieresPreferees,
  ] = useState(initialCorrespondentAuxMatieresPreferees);
  const schema = LyceenBacProProfileSchema.pick(
    'pointsFortsScolaires'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  const handleChange = (value) => {
    setCorrespondentAuxMatieresPreferees(value.target.value);
    if (value.target.type === 'radio' && matiere1 && matiere2 && matiere3) {
      setAllowNext(true);
    }
  };

  useEffect(() => {
    if (
      matiere1 &&
      matiere2 &&
      matiere3 &&
      correspondentAuxMatieresPreferees !== 'none'
    ) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [matiere1, matiere2, matiere3]);

  let matieresPeferes = [];
  if (myProfile.matieresPreferes) {
    matieresPeferes = myProfile.matieresPreferes;
  }

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <FormControl
            name={'pointsFortsScolaires.meilleuresMatieres'}
            label={
              'Quelles sont les trois matières dans lesquelles tu as obtenu les meilleurs résultats au cours du 1er trimestre ?'
            }
            isRequired
          >
            <InputControl
              name="pointsFortsScolaires.meilleuresMatieres.0"
              label="Matière 1"
              onChange={(e: any) => {
                if (e.target.value.length) setMatiere1(true);
                else setMatiere1(false);
              }}
              mt={5}
              labelProps={{
                style: { fontSize: '15px' },
              }}
            />
            <InputControl
              name="pointsFortsScolaires.meilleuresMatieres.1"
              label="Matière 2"
              onChange={(e: any) => {
                if (e.target.value.length) setMatiere2(true);
                else setMatiere2(false);
              }}
              mt={5}
              labelProps={{
                style: { fontSize: '15px' },
              }}
            />
            <InputControl
              name="pointsFortsScolaires.meilleuresMatieres.2"
              label="Matière 3"
              onChange={(e: any) => {
                if (e.target.value.length) setMatiere3(true);
                else setMatiere3(false);
              }}
              mt={5}
              labelProps={{
                style: { fontSize: '15px' },
              }}
            />
          </FormControl>

          <br></br>
          <Alert padding={0}>
            <List>
              <Text>
                <b>Tes matières préférées</b>{' '}
                <i>(celles que tu as renseignées dans l’activité précédente)</i>{' '}
                :
              </Text>
              {matieresPeferes &&
                matieresPeferes.map((value, i) => (
                  <ListItem key={i}>• {value} </ListItem>
                ))}

              {isProf && (
                <Text>
                  {' '}
                  <ListItem>• Mathématiques</ListItem>
                  <ListItem>• Histoire</ListItem>
                  <ListItem>• Anglais</ListItem>
                </Text>
              )}
            </List>
          </Alert>
          <br></br>
          <FormStack>
            <RadioGroupControl
              name={'pointsFortsScolaires.correspondentAuxMatieresPreferees'}
              label={
                'Ces matières correspondent-elles à tes matières préférées ?'
              }
              stackProps={{ direction: 'column' }}
              onChange={handleChange}
              isRequired
            >
              <Radio value="yes">Oui, totalement</Radio>
              <Radio value="partly">Oui, en partie</Radio>
              <Radio value="notReally">Non, pas vraiment</Radio>
            </RadioGroupControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapMonParcoursScolaire = ({ profile }) => {
  const choixFormation = profile.choixFormation;
  const adequationPreferencesApprentissage =
    profile.adequationPreferencesApprentissage;
  const adequationMetierOuSecteurReve = profile.adequationMetierOuSecteurReve;
  const adequationCentresInteretsPriorites =
    profile.adequationCentresInteretsPriorites;
  const adequationQualites = profile.adequationQualites;
  const pointsFortsScolaires = profile.pointsFortsScolaires;

  return (
    <>
      {profile &&
      choixFormation &&
      choixFormation.choisiFormation !== undefined ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Ma formation actuelle'}
          </Heading>
          <UnorderedList>
            <ListItem>
              {' '}
              As-tu choisi ta formation actuelle ?{' '}
              {choixFormation.choisiFormation === 'no' ? (
                <> Non, on a choisi pour moi</>
              ) : (
                YesNoOptions[choixFormation.choisiFormation]
              )}{' '}
            </ListItem>
            {choixFormation.choisiFormation === 'yes' &&
              choixFormation.pourquoiChoisi && (
                <ListItem>
                  {' '}
                  Pourquoi as-tu choisi cette formation ?{' '}
                  {choixFormation.pourquoiChoisi}{' '}
                </ListItem>
              )}
            {choixFormation.choisiFormation === 'no' &&
              choixFormation.interessant && (
                <ListItem>
                  {' '}
                  Et finalement ça t'intéresse ?{' '}
                  {choixFormation.interessant === 'yes' ? (
                    <> Oui, totalement</>
                  ) : (
                    YesNoOptions[choixFormation.interessant]
                  )}{' '}
                </ListItem>
              )}
          </UnorderedList>
        </Box>
      ) : null}

      {profile && adequationPreferencesApprentissage ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Adéquation avec mes préférences d’apprentissage'}
          </Heading>
          <UnorderedList>
            <ListItem>
              Est-ce que ta formation actuelle correspond à tes préférences
              d’apprentissage ?{' '}
              {adequationPreferencesApprentissage.correspond === 'yes' ? (
                <> Oui, totalement</>
              ) : adequationPreferencesApprentissage.correspond === 'no' ? (
                <> Non, pas vraiment</>
              ) : (
                YesNoOptions[adequationPreferencesApprentissage.correspond]
              )}
            </ListItem>
            {adequationPreferencesApprentissage.correspond === 'no' && (
              <ListItem>
                Pourquoi ? {adequationPreferencesApprentissage.pourquoiNon}
              </ListItem>
            )}
          </UnorderedList>
        </Box>
      ) : null}

      {profile && adequationMetierOuSecteurReve ? (
        <Box>
          <Heading size="sm" as="h3">
            Adéquation avec mon métier ou secteur de rêve
          </Heading>
          <UnorderedList>
            <ListItem>
              Est-ce que ta formation actuelle correspond au métier que tu
              souhaites faire ou au secteur professionnel qui t’intéresse ?
              {adequationMetierOuSecteurReve === 'yes' ? (
                <> Oui, totalement</>
              ) : adequationMetierOuSecteurReve === 'unknown' ? (
                <>
                  {' '}
                  Je ne connais pas la filière qui mène au métier que je veux
                  faire
                </>
              ) : (
                YesNoOptions[adequationMetierOuSecteurReve]
              )}
            </ListItem>
          </UnorderedList>
        </Box>
      ) : null}

      {profile && adequationCentresInteretsPriorites ? (
        <Box>
          <Heading size="sm" as="h3">
            Adéquation avec mes centres d’intérêts et priorités
          </Heading>
          <UnorderedList>
            <ListItem>
              Est-ce que ta formation actuelle te permet d’avoir un projet qui
              se rapproche de tes centres d'intérêts et de tes priorités ?
              {adequationCentresInteretsPriorites === 'yes' ? (
                <> Oui, totalement</>
              ) : (
                <> {YesNoOptions[adequationCentresInteretsPriorites]}</>
              )}
            </ListItem>
          </UnorderedList>
        </Box>
      ) : null}

      {profile && adequationQualites ? (
        <Box>
          <Heading size="sm" as="h3">
            Adéquation avec mes qualités
          </Heading>
          <UnorderedList>
            <ListItem>
              Est-ce que tes qualités sont utiles dans ta formation actuelle ?
              {adequationQualites === 'yes' ? (
                <> Oui, totalement</>
              ) : (
                <> {YesNoOptions[adequationQualites]}</>
              )}
            </ListItem>
          </UnorderedList>
        </Box>
      ) : null}

      {profile &&
      pointsFortsScolaires?.meilleuresMatieres &&
      pointsFortsScolaires?.meilleuresMatieres.length > 0 ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Mes points forts scolaires'}
          </Heading>
          <UnorderedList>
            {pointsFortsScolaires?.meilleuresMatieres?.map((q, i) => (
              <ListItem key={i}>{q}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      ) : null}

      {profile && pointsFortsScolaires?.correspondentAuxMatieresPreferees && (
        <Text>
          {' '}
          Ces matières correspondent-elles à tes matières préférées ?{' '}
          {pointsFortsScolaires?.correspondentAuxMatieresPreferees === 'yes' ? (
            <> Oui, totalement</>
          ) : (
            YesNoOptions[
              pointsFortsScolaires?.correspondentAuxMatieresPreferees
            ]
          )}{' '}
        </Text>
      )}
    </>
  );
};
