export var ERROR_CODE_SIMPLE_SCHEMA_VALIDATION = "SIMPLE_SCHEMA_VALIDATION";
export var ERROR_CODE_EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS";
export var ERROR_CODE_TOKEN_EXPIRED = "TOKEN_EXPIRED";
export var ERROR_CODE_INTERNAL_SERVER = "INTERNAL_SERVER_ERROR";
export var ERROR_CODE_BAD_SIGNUP_TOKEN = "BAD_SIGNUP_TOKEN";
export var ERROR_CODE_NO_PROFILE_FOUND_TO_CREATE_CLASSE = "ERROR_CODE_NO_PROFILE_FOUND_TO_CREATE_CLASSE";
export var ERROR_CODE_PROF_ALREADY_HAS_CLASSE = "ERROR_CODE_PROF_ALREADY_HAS_CLASSE";
export var ERROR_CODE_PROF_EMAIL_NOT_FOUND = "ERROR_CODE_PROF_EMAIL_NOT_FOUND";
export var ERROR_CODE_USER_IS_NOT_PROF = "USER_IS_NOT_PROF";
// For situations where we have to rely on messages instead of codes
export var ERROR_MESSAGE_EXPIRED_TOKEN = "Token expired";
export var ERROR_MESSAGE_EMAIL_EXISTS = "Email already exists.";
export var ERROR_MESSAGE_USER_NOT_FOUND = "User not found";
export var ERROR_MESSAGE_EXPIRED_VERIFY_EMAIL_TOKEN = "Verify email link expired";
