import { isNumber } from 'lodash';
import { ReactNode } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Panel from '@inspire/ui/general/Panel';
import PisteGauge from '@inspire/ui/pistes/PisteGauge';
import { Piste } from 'lib/generated';
import { cdnURL } from '@inspire/util/cdn';

interface PisteWithScore extends Piste {
  score?: number;
}

interface PisteItemProps {
  footer?: ReactNode;
  piste: PisteWithScore;
}

const PisteItem = ({ piste, footer }: PisteItemProps) => (
  <Box as={Panel} p={0}>
    <Box
      pos="relative"
      height="180px"
      padding={[4, null, 8]}
      overflow="hidden"
      _after={{
        boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.15)',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        display: 'block',
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        content: '""',
        zIndex: 1,
      }}
    >
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        _after={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          content: '""',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        }}
      >
        <Box
          pos="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          backgroundImage={cdnURL(piste.coverPicture)}
          backgroundSize={'cover'}
        ></Box>
      </Box>
      <Heading
        size="md"
        color="white"
        textShadow="1px 1px 0px rgba(0, 0, 0, 0.6)"
        pos="relative"
        fontFamily="Roboto Condensed"
        m={0}
      >
        {piste.name}
      </Heading>
    </Box>

    {isNumber(piste.score) && (
      <Box mt={'-40px'}>
        <PisteGauge score={piste.score} />
      </Box>
    )}
    {footer ? footer : null}
  </Box>
);

export default PisteItem;
