import {
  Button,
  ButtonProps,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import Link, { LinkProps } from 'next/link';

import ConditionalWrapper from '@inspire/ui/general/ConditionalWrapper';

interface Props {
  href: string;
  nextLinkProps?: LinkProps;
}

export type LinkButtonProps = Props & ButtonProps & ChakraLinkProps;

const LinkButton = ({
  href,
  nextLinkProps,
  children,
  ...buttonProps
}: LinkButtonProps) => (
  <ConditionalWrapper
    condition={!buttonProps?.disabled}
    thenWrapper={(thenChildren) => (
      <Link href={href} passHref {...nextLinkProps}>
        {thenChildren}
      </Link>
    )}
  >
    <Button as="a" {...buttonProps}>
      {children}
    </Button>
  </ConditionalWrapper>
);

export default LinkButton;
