import { Text } from '@chakra-ui/react';

import ProfTips from 'components/activites/ProfTips';
import ClickableImage from 'components/general/ClickableImage';

import InfographieAttitudesSrc from '../../../../../public/img/infographies/attitudes.png';

const LanguageProRecap = () => (
  <>
    <ProfTips>
      <Text>
        Reprendre oralement avec les élèves les éléments présentés dans
        l’infographie. Il s’agit de conseils de bonnes pratiques à adopter
        durant les stages.
      </Text>
    </ProfTips>
    <Text textAlign="center">
      <i>As-tu bien en tête les attitudes à adopter durant ton stage ?</i>
    </Text>
    <ClickableImage
      src={InfographieAttitudesSrc}
      href="/img/infographies/attitudes.png"
      alt="Infographie : Le top 4 des attitudes à adopter pendant son stage"
    />
  </>
);

export default LanguageProRecap;
