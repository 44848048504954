import { Box, Text } from '@chakra-ui/react';

import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';

export const SoftSkillsReflexion = () => (
  <>
    <ReflexionOralHeader />
    <Text my={8} textAlign={'center'}>
      Qu'est-ce qu'une compétence ?
    </Text>
    <ProfTips>
      Définition : Les "soft skills" désignent les capacités ou les aptitudes
      d’une personne qui ont été acquises durant les expériences qu’elle a
      vécues. Alors que les "hard skills" désignent les compétences techniques.
      Les soft skills sont aussi appelées compétences transversales car elles
      peuvent être utilisables dans des contextes très différents.
    </ProfTips>
  </>
);

export const SoftSkillsDefinition = () => (
  <Box
    bg="gray.100"
    px={6}
    py={5}
    borderRadius={4}
    mb={8}
    mx={'auto'}
    width={['100%', '80%', '66%']}
  >
    <Text>
      <b>Définition des soft skills / compétences transversales :</b>
    </Text>
    <Text>
      <i>
        Capacités acquises durant toutes les expériences vécues (y compris en
        dehors de l’école et / ou du travail). Elles sont aussi appelées
        compétences transversales car elles peuvent être utilisées dans des
        contextes très différents. Par exemple : l’autonomie et le travail en
        équipe.
      </i>
    </Text>
    <Text>
      <i>
        Les softs skills se distinguent des hard skills qui sont des compétences
        techniques, c’est-à-dire des connaissances et savoir-faire spécifiques à
        un métier ou à un secteur d’activité. Par exemple : la maîtrise d’une
        langue étrangère ou d’un logiciel informatique.
      </i>
    </Text>
  </Box>
);
