import { Text, Box, Center, UnorderedList, ListItem } from '@chakra-ui/react';

import Spinner from '@inspire/ui/chakra/Spinner';
import VideoPlayer from 'components/general/VideoPlayer';

import { useJobreadyIframe } from 'lib/hooks';

export const Chatbot = () => {
  const [iframeSrc, isIframeLoading] = useJobreadyIframe('chatbot');

  return iframeSrc ? (
    <Box h="500px">
      <iframe src={iframeSrc} frameBorder="none" width="100%" height="100%" />
    </Box>
  ) : isIframeLoading ? (
    <Center py={5}>
      <Spinner size="xl" thickness="4px" />
    </Center>
  ) : (
    "Cela n'a pas fonctionné."
  );
};

export const ChatbotResultats = () => {
  return <Text>Synthèse des résultats et éléments de compréhension.</Text>;
};

export const ChatbotConclusion = () => {
  return (
    <>
      <Text>
        Cette vidéo présente le témoignage de deux personnes ayant mobilisées
        les mêmes soft skills sur des métiers et activités extra-scolaires
        différentes.
      </Text>

      <Text>
        Le programme Erasmus + vise à donner aux étudiants, aux stagiaires, au
        personnel et d'une manière générale aux jeunes de moins de 30 ans avec
        ou sans diplôme, la possibilité de séjourner à l’étranger pour renforcer
        leurs compétences et accroître leur employabilité.
      </Text>

      <Text textAlign={'center'}>
        <i>Regarde la vidéo et réponds aux questions à l’oral.</i>
      </Text>

      <VideoPlayer url="/videos-bacpro/soft-skills.mp4" />

      <UnorderedList>
        <ListItem>
          Quelles sont les compétences présentées par les deux personnes qui
          témoignent ?
        </ListItem>
        <ListItem>
          Comment Cheik a-t-il utilisé ses compétences dans ses activités
          professionnelles ?
        </ListItem>
        <ListItem>
          Comment Imtinen a utilisé ses compétences dans ses activités extra
          scolaires ?
        </ListItem>
      </UnorderedList>
    </>
  );
};
