import { Radio } from '@chakra-ui/react';
import { Level } from '@inspire/data/static/types';

const LevelOptions = ({ level }: { level: Level }) => {
  const RadioSeconde = <Radio value="Seconde">Seconde</Radio>;
  const RadioPremiere = <Radio value="Première">Première</Radio>;
  const RadioTerminale = <Radio value="Terminale">Terminale</Radio>;
  const RadioPostBac = <Radio value="Post-bac">J'ai eu mon bac</Radio>;
  const options = (level: Level) => {
    switch (level) {
      case Level.SECONDE:
      case Level.PREMIERE: {
        return (
          <>
            {RadioSeconde}
            {RadioPremiere}
          </>
        );
      }
      case Level.TERMINAL: {
        return (
          <>
            {RadioPremiere}
            {RadioTerminale}
          </>
        );
      }
      default: {
        return (
          <>
            {RadioTerminale}
            {RadioPostBac}
          </>
        );
      }
    }
  };
  return <>{options(level)}</>;
};

export default LevelOptions;
