import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import VideoPlayer from 'components/general/VideoPlayer';

const LanguageProVideoTemoignage = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        La vidéo regroupe les témoignages d’anciens lycéens de bac pros
        racontant des anecdotes sur leur stage et leur relation avec les
        collègues ou la hiérarchie.
      </Text>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>Regarde la vidéo et réponds aux questions oralement.</i>
    </Text>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/3.+Anecdotes+De+Stages-1.mp4'} />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      <ListItem>Quelle est l’anecdote négative de chaque témoin ?</ListItem>
      <ListItem>
        Qu’est-ce qui aurait pu être fait pour corriger cela ?
      </ListItem>
      <ListItem>Quelle est l’anecdote positive de chaque témoin ?</ListItem>
      <ListItem>
        Quels sont les sentiments éprouvés par Océane et Taysir dans leurs
        anecdotes positives ?
      </ListItem>
    </UnorderedList>
  </>
);

export default LanguageProVideoTemoignage;
