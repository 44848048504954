import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import Image from 'next/image';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import ImageCV from '@inspire/ui/assets/activites/recherche-stage/cv-seconde.png';

import { CheckboxGame } from 'components/activites/iterative-games';
import TodoActivite from 'components/activites/TodoActivite';

export const QuizzCV = ({ allowNext }) => {
  const questions = [
    {
      subject: 'Quelle est la longueur idéale d’un CV ?',
      answerValues: ['2'],
      options: [
        { shortName: 'Entre 3 et 5 lignes', value: '1' },
        { shortName: '1 page', value: '2' },
        { shortName: '2 pages', value: '3' },
      ],
    },
    {
      subject: 'Qu’est-ce que je dois mettre obligatoirement dans mon CV ?',
      answerValues: ['1', '3', '4', '5'],
      options: [
        {
          shortName: 'Mes coordonnées (nom, prénom, téléphone, etc.)',
          value: '1',
        },
        { shortName: 'Ma photo', value: '2' },
        { shortName: 'Mes formations', value: '3' },
        { shortName: 'Mes expériences', value: '4' },
        { shortName: 'Mes compétences', value: '5' },
      ],
    },
    {
      subject: 'Pour mes expériences il vaut mieux indiquer :',
      answerValues: ['1', '2', '3'],
      options: [
        { shortName: 'Le nom de l’entreprise', value: '1' },
        { shortName: 'Le lieu', value: '2' },
        { shortName: 'La durée de l’expérience', value: '3' },
        {
          shortName: 'La qualité de mes relations avec mes collègues',
          value: '4',
        },
      ],
    },
    {
      subject: 'Il est déconseillé de noter ses loisirs dans un CV.',
      answerValues: ['2'],
      options: [
        { shortName: 'Vrai', value: '1' },
        { shortName: 'Faux', value: '2' },
      ],
    },
    {
      subject:
        'Je peux classer mes formations et mes expériences de la plus récente (en haut) à la plus ancienne (en bas)',
      answerValues: ['1'],
      options: [
        { shortName: 'Vrai', value: '1' },
        { shortName: 'Faux', value: '2' },
      ],
    },
    {
      subject: 'Si je trouve mon CV pas assez rempli, je peux :',
      answerValues: ['3'],
      options: [
        { shortName: 'Ajouter de fausses expériences', value: '1' },
        {
          shortName:
            'Ajouter de fausses compétences comme la maîtrise de plusieurs langues',
          value: '2',
        },
        {
          shortName: "Faire un jolie design pour qu'il paraisse moins vide",
          value: '3',
        },
      ],
    },
    {
      subject: 'Comment envoyer mon CV aux entreprises ?',
      answerValues: ['2'],
      options: [
        {
          shortName:
            'Je rédige un CV type et j’envoie le même à toutes les entreprises',
          value: '1',
        },
        {
          shortName:
            'Je rédige un CV type mais je personnalise certaines sections',
          value: '2',
        },
        {
          shortName: 'Je refais entièrement mon CV pour chaque candidature',
          value: '3',
        },
      ],
    },
  ];
  return (
    <CheckboxGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'} mb={8}>
          Réponds aux questions du quiz pour tester tes connaissances sur la
          rédaction d’un CV.
        </Text>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question?.subject}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={5}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.subject}>
                  <b>{q.subject} </b>{' '}
                  {q.answerValues
                    .map(
                      (av) => q.options.find((o) => o.value === av).shortName
                    )
                    .join(', ')}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

const optionsTodoCV = {
  coordonnees:
    'J’ai complété mes coordonnées → attention à ne pas utiliser une adresse mail à l’intitulé peu professionnel, type bogossdu78@hotmail.fr !',
  photo:
    'Si j’ai mis une photo, j’ai choisi un portrait professionnel → pas de photo de vacances !',
  objet:
    'J’ai indiqué un objet en précisant le poste recherché (par exemple “CV pour un stage de découverte du métier de vendeur”',
  transversales: 'J’ai indiqué mes compétences transversales',
  techniques:
    'J’ai indiqué mes compétences techniques : langues, compétences informatiques...',
  formations: 'J’ai indiqué mes formations',
  experiences:
    'J’ai indiqué mes expériences, y compris mes expériences extra-scolaires (activités culturelles, sportives,...)',
  design: 'J’ai fait un joli design',
  ecriture: 'J’ai fait attention à la taille et police d’écriture',
  titre: 'J’ai mis des titres et laissé des espaces',
  page: 'Mon CV ne fait pas plus d’une page',
  orthographe:
    'J’ai fait relire mon CV et corrigé TOUTES LES FAUTES D’ORTHOGRAPHE',
};

export const ChecklistCV = ({ goNext }) => (
  <>
    <Text mb={6}>
      Inspire-toi de ce modèle pour compléter ton CV, puis coche les éléments de
      la liste pour t’assurer de n’avoir rien oublié.
    </Text>
    <Flex flexDirection={['column', null, 'column', 'row']}>
      <Box flex={2} mx={3} textAlign={'center'} mb={6}>
        <Image src={ImageCV} alt="CV" />
      </Box>
      <Box flex={3}>
        <TodoActivite
          goNext={goNext}
          options={optionsTodoCV}
          fieldName={'checklistCV'}
        />
      </Box>
    </Flex>
  </>
);
