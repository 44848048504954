import { Box, HStack, RadioGroup, Radio } from '@chakra-ui/react';
import { Field } from 'formik';
import { cdnURL } from '@inspire/util/cdn';

export const smileyDefaultOptions = [
  {
    label: '😢',
    value: '-2',
    url: cdnURL('/twemoji-13.1.0/1f622.png'),
  },
  {
    label: '🙁',
    value: '-1',
    url: cdnURL('/twemoji-13.1.0/1f641.png'),
  },
  {
    label: '😐',
    value: '0',
    url: cdnURL('/twemoji-13.1.0/1f610.png'),
  },
  {
    label: '🙂',
    value: '1',
    url: cdnURL('/twemoji-13.1.0/1f642.png'),
  },
  {
    label: '😍',
    value: '2',
    url: cdnURL('/twemoji-13.1.0/1f60d.png'),
  },
];

const SmileyRadioRange = ({
  name,
  value,
  lowLabel = 'Pas du tout',
  highLabel = 'Beaucoup',
  options = smileyDefaultOptions,
  ...hstackProps
}) => (
  <HStack spacing={[0, 3]} justify={['center', 'normal']} {...hstackProps}>
    <Box display={['none', 'block']}>{lowLabel}</Box>
    <RadioGroup
      as={HStack}
      name={name}
      value={value}
      justify="space-between"
      spacing={[1, 2]}
      maxW="500px"
      sx={{ '.chakra-radio__control': { boxSize: 10, border: 'none' } }}
    >
      {options.map(({ url, value: val }) => (
        <Field
          as={Radio}
          filter="grayscale(1) brightness(1.05)"
          _checked={{ filter: 'grayscale(0) brightness(1)' }}
          _hover={{ filter: 'grayscale(0) brightness(1)' }}
          // https://stackoverflow.com/questions/58712209/weird-filtergrayscale-bug-in-safari-ios
          sx={{ WebkitTransform: 'translateZ(0)' }}
          value={val}
          key={val}
          bgImage={url}
          bgSize="30px"
          bgRepeat="no-repeat"
          bgPos="center"
        />
      ))}
    </RadioGroup>
    <Box display={['none', 'block']}>{highLabel}</Box>
  </HStack>
);

export default SmileyRadioRange;
