import { useEffect } from 'react';

import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { RadioGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ClickableImage from 'components/general/ClickableImage';

import InfographiePointAlternanceSrc from '../../../../../public/img/infographies/point-alternance.png';
import Euros from 'components/general/Euros';

export const ApprofondirNotionAlternance = ({ allowNext }) => {
  const questions = [
    {
      text: 'On peut faire de l’alternance à l’université ?',
      answerValue: 'vrai',
    },
    {
      text: 'L’alternance c’est toujours sous la forme 1 jour à l’école 1 jour en entreprise ?',
      answerValue: 'faux',
    },
    {
      text: 'L’apprentissage et l’alternance c’est exactement la  même chose ?',
      answerValue: 'faux',
    },
  ];

  const options = [
    {
      value: 'vrai',
      shortName: 'Vrai',
    },
    {
      value: 'faux',
      shortName: 'Faux',
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={<Text textAlign={'center'}>Réponds au questionnaire</Text>}
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question.text}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.text}>
                  <b>{q.text} :</b>{' '}
                  {options.find((c) => c.value === q.answerValue).shortName}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const InfographieAlternance = () => <Box textAlign="center"></Box>;

export const AlternancesQuestionsOrales = () => (
  <>
    <ReflexionOralHeader />
    <Text textAlign={'center'}>
      A l’aide de l’infographie, réponds aux questions à l’oral.
    </Text>
    <ClickableImage
      src={InfographiePointAlternanceSrc}
      href="/img/infographies/point-alternance.png"
      alt="Infographie Le point sur l'alternance"
    />
    <UnorderedList spacing={6}>
      <ListItem>
        Cite les différents types d’établissements où l'on peut faire de
        l’alternance.
      </ListItem>
      <ProfTips>
        <Text>Lycée professionnel, CFA, université, école</Text>
      </ProfTips>
      <ListItem>
        Donne deux exemples de rythme possible pour l’alternance.
      </ListItem>
      <ProfTips>
        <Text>
          2 jours à l’école et 3 jours en entreprise 1 semaine à l’école et 2
          semaines en entreprise
        </Text>
      </ProfTips>
      <ListItem>
        Explique la différence entre l’apprentissage et l’alternance.
      </ListItem>
      <ProfTips>
        <Text>
          L’alternance c’est de manière générale un système de formation qui
          permet d’alterner cours à l’école et expériences professionnelles en
          entreprise. L’alternance peut se faire via des stages ou via un
          contrat d’apprentissage (ou contrat de professionnalisation).{' '}
        </Text>
      </ProfTips>
    </UnorderedList>
  </>
);

export const DebatAlternanceProfInstruction = () => (
  <>
    <Text>
      Suite à une affirmation mettant volontairement en avant des idées reçues
      sur l’alternance, chaque élève a le choix d’être “d’accord” ou “pas
      d’accord”. Demandez à chaque camp d’argumenter son choix de
      positionnement. Ce débat permettra de déconstruire les idées reçues sur
      l’alternance.
    </Text>
    <Text>
      Ce débat peut être proposé en “débat mouvant” pour favoriser la prise de
      parole des élèves. Les élèves se positionnent dans la salle de classe
      selon leur choix (d’un côté ceux qui sont d’accord et de l’autre ceux qui
      ne sont pas d’accord), argumentent et peuvent changer de côté s’ils se
      font convaincre.
    </Text>
  </>
);

export const DebatAlternance = () => (
  <>
    <ReflexionOralHeader />

    <Box mt={8}>
      <Text>
        Réponds aux questions oralement en disant si tu es <b>"d’accord"</b> ou{' '}
        <b>"pas d’accord"</b>.
      </Text>

      <UnorderedList spacing={6}>
        <ListItem>
          L’alternance, c’est pour les élèves qui veulent vite arrêter les
          études.
        </ListItem>
        <ProfTips>
          <Text>
            L’alternance c’est aussi pour les longues études, c’est possible
            jusqu’au bac +5.
          </Text>
        </ProfTips>

        <ListItem>L’alternance c’est pour les mauvais élèves.</ListItem>
        <ProfTips>
          <Text>
            L’alternance requiert une grande motivation et des qualités
            d’adaptation pour concilier avec succès études et travail. Un
            diplôme obtenu en alternance a la même valeur qu’un diplôme
            équivalent obtenu par la voie classique… l’expérience en plus !
          </Text>
        </ProfTips>

        <ListItem>
          L’alternance c’est surtout adapté aux métiers manuels.
        </ListItem>
        <ProfTips>
          <Text>
            Aujourd’hui il existe des formations en alternance pour pour accéder
            à presque tous les métiers, y compris dans les services. Le commerce
            est le secteur en tête pour le recrutement d’alternants en 2020.
          </Text>
        </ProfTips>

        <ListItem>L’alternance ne mène pas aux métiers d’avenir.</ListItem>
        <ProfTips>
          <Text>
            De nombreux secteurs porteurs recrutent des étudiants en alternance
            : l'énergie, le digital, les assurances, les banques, l'intelligence
            artificielle, etc.
          </Text>
        </ProfTips>

        <ListItem>L’alternance est mal rémunérée.</ListItem>
        <ProfTips>
          <Text>
            La rémunération varie entre <Euros>370</Euros> mensuel pour les -18
            ans en 1ère année à <Euros>1 150</Euros> pour les +21 ans en 3ème
            année.
          </Text>
        </ProfTips>

        <ListItem>L’alternance est plutôt pour les garçons.</ListItem>
        <ProfTips>
          <Text>
            La part de femme en apprentissage ne cesse d’augmenter, elle reste
            cependant minoritaire avec 34% contre 66% en 2018.
          </Text>
        </ProfTips>

        <ListItem>
          L’alternance, ça ne compte pas comme véritable expérience
          professionnelle.
        </ListItem>
        <ProfTips>
          <Text>
            A compétences égales, les entreprises préféreront un candidat ou une
            candidate ayant profité d'une expérience solide acquise dans le
            cadre d'un contrat en alternance.
          </Text>
        </ProfTips>
      </UnorderedList>
    </Box>
  </>
);
