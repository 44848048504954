import { useEffect } from 'react';

import {
  Box,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  Radio,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { map } from 'lodash';
import { RadioGroupControl } from 'formik-chakra-ui';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import {
  SoftSkillsOptions,
  ActiviteOptions,
} from '@inspire/data/static/soft-skills';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { EntretienFeedbackQuestions } from '@inspire/data/static/static';

import { RadioGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';
import SelectRadioActivite from 'components/activites/SelectRadioActivite';
import SelectCheckboxActivite from 'components/activites/SelectCheckboxActivite';
import { useMyProfile } from 'lib/hooks';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';

export const RappelCompetences = (props) => (
  <SelectRadioActivite
    fieldName="competencesActivite"
    label="Sélectionne une activité que tu as réalisée dans la liste ci-dessous."
    options={ActiviteOptions}
    {...props}
  />
);

export const ListCompetences = (props) => (
  <>
    <Box
      bg="gray.100"
      px={6}
      py={5}
      borderRadius={4}
      mb={8}
      mx={'auto'}
      width={['100%', '80%', '66%']}
    >
      <Text>
        <b>Définition des compétences :</b>
      </Text>
      <Text>
        <i>
          Capacités acquises durant toutes les expériences vécues (y compris en
          dehors de l’école et/ou du travail). Elles sont aussi appelées
          compétences transversales car elles peuvent être utilisées dans des
          contextes très différents.
        </i>
      </Text>
      <Text>
        <i>
          Les softs skills se distinguent des hard skills qui sont des
          compétences techniques, c’est-à-dire des connaissances et savoir-faire
          spécifiques à un métier ou à un secteur d’activité.
        </i>
      </Text>
    </Box>
    <SelectCheckboxActivite
      fieldName="competencesList"
      label="Sélectionne 3 compétences que tu as utilisées pendant cette activité."
      options={SoftSkillsOptions}
      {...props}
    />
  </>
);

export const RecapCompetences = ({ profile }) => (
  <>
    {profile?.competencesActivite && (
      <Box>
        <Heading size="sm" as="h3">
          Mon activité
        </Heading>
        <Text>{ActiviteOptions[profile?.competencesActivite]}</Text>
      </Box>
    )}
    {profile?.competencesList?.length > 0 && (
      <Box>
        <Heading size="sm" as="h3">
          Les compétences que tu as utilisées pendant cette activité
        </Heading>
        <UnorderedList>
          {profile?.competencesList?.map((q, i) => (
            <ListItem key={i}>{SoftSkillsOptions[q]}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    )}
  </>
);

export const MyRecapCompetences = () => {
  const { myProfile } = useMyProfile();

  return (
    <>
      <ProfTips>
        <UnorderedList>
          <ListItem>
            Demandez à 3 élèves de partager leurs réponses avec la classe.
          </ListItem>
          <ListItem>
            Rappelez que les compétences acquises par toutes nos expériences
            scolaires ou pas sont fondamentales et qu'il faut savoir les
            présenter car ces exemples peuvent nous aider à mieux nous présenter
            et mieux nous faire connaître au cours d’un entretien d’embauche.
          </ListItem>
          <ListItem>
            Dites aux élèves qu’ils trouveront le référentiel complet des soft
            skills dans le kit de survie sur la plateforme.
          </ListItem>
        </UnorderedList>
      </ProfTips>
      <RecapCompetences profile={myProfile} />
    </>
  );
};

export const CommunicationNonVerbale = ({ allowNext }) => {
  const questions = [
    {
      text: 'Quelle est l’importance de la communication verbale pour les recruteurs ?',
      answerValue: '7',
      options: [
        {
          value: '50',
          shortName: '50%',
        },
        {
          value: '35',
          shortName: '35%',
        },
        {
          value: '7',
          shortName: '7%',
        },
      ],
    },
    {
      text: 'Quelle est l’importance de la communication vocale pour les recruteurs dans un entretien ?',
      answerValue: '38',
      options: [
        {
          value: '20',
          shortName: '20%',
        },
        {
          value: '38',
          shortName: '38%',
        },
        {
          value: '30',
          shortName: '30%',
        },
      ],
    },
    {
      text: 'Quelle est l’importance de la communication corporelle pour les recruteurs ?',
      answerValue: '55',
      options: [
        {
          value: '55',
          shortName: '55%',
        },
        {
          value: '35',
          shortName: '35%',
        },
        {
          value: '30',
          shortName: '30%',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <RadioGame
        questions={questions}
        getOptions={(question) => question.options}
        onFinished={() => allowNext(true)}
        introSection={
          <Text textAlign={'center'}>
            Lors d’un entretien d’embauche, tous les types de communication sont
            mobilisés. Selon-toi, quel type est le plus important ?
          </Text>
        }
        infoSection={
          <Box bg="gray.100" px={6} py={5} borderRadius={4} mb={8}>
            Il existe 3 types de communication : verbale, vocale, corporelle.
            <UnorderedList>
              <ListItem>
                Communication verbale : vocabulaire professionnel, phrases
                complètes et compréhensibles
              </ListItem>
              <ListItem>
                Communication vocale : l’intonation et le son de la voix
              </ListItem>
              <ListItem>
                Communication corporelle : sourire, regard, attitudes, langage
                corporel, look vestimentaire
              </ListItem>
            </UnorderedList>
          </Box>
        }
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8}>
              <UnorderedList>
                {questions.slice(0, questionIndex).map((q) => (
                  <ListItem key={q.text}>
                    <b>{q.text} :</b>{' '}
                    {q.options.find((c) => c.value === q.answerValue).shortName}
                  </ListItem>
                ))}
              </UnorderedList>
            </SummaryFrame>
          )
        }
      />
      <ProfTips>
        <UnorderedList>
          <ListItem>
            La communication non verbale, c’est à la fois les éléments corporels
            (gestes, posture, expression...) et la manière dont les mots sont
            prononcés (intonations, timbre de voix, rythme...).
          </ListItem>
          <ListItem>
            Si les mots prononcés sont importants, les recruteurs vont avant
            tout faire attention à la manière de parler et aux gestes. Il est
            donc important que l’attitude reflète le discours porté.
          </ListItem>
          <ListItem>
            82% des recruteurs reconnaissent se fier à l’image pour départager
            deux candidats ayant les mêmes compétences.
          </ListItem>
        </UnorderedList>
      </ProfTips>
    </>
  );
};

export const SimulationEntretien = () => (
  <>
    <ReflexionOralHeader />
    <Text mt={8}>
      Tu vas participer à des simulations d’entretien d’embauche avec ta classe.
      Note sur une feuille les éléments clés de ta présentation :
    </Text>
    <UnorderedList spacing={6}>
      <ListItem>Ton nom et ton prénom</ListItem>
      <ListItem>Ta filière et ta classe</ListItem>
      <ListItem>Intitulé du stage recherché</ListItem>
      <ListItem>Durée et période du stage</ListItem>
      <ListItem>
        Missions souhaitées et tâches à réaliser durant le stage
      </ListItem>
      <ListItem>
        Les raisons qui te motivent à choisir cette entreprise
      </ListItem>
      <ListItem>
        Les compétences transversales pouvant être utiles pour ce stage (que tu
        possèdes où que tu souhaites développer pendant cette expérience)
      </ListItem>
      <ListItem>
        Les expériences dans lesquelles tu as utilisé ces compétences
      </ListItem>
    </UnorderedList>
    <ProfTips mt={8}>
      <Text>
        Informer les lycéens que vous représentez un responsable RH au sein
        d’une entreprise ayant reçu une candidature spontanée pour une demande
        de stage.
      </Text>
      <Text>
        Vous réaliserez quelques simulations d’entretien d’embauche pour un
        stage.
      </Text>
      <Text>Exemples de questions que vous pourrez adresser au lycéen :</Text>{' '}
      <UnorderedList>
        <ListItem>
          Présentez-vous s’il vous plaît Pourquoi souhaitez-vous nous rejoindre
          ?
        </ListItem>
        <ListItem>
          Quelles sont, selon vous, vos qualités pour nous rejoindre ?
        </ListItem>
        <ListItem>
          Pouvez-vous me citer un exemple où vous avez pu les démontrer ?
        </ListItem>
        <ListItem>Quels sont, selon vous, vos points à améliorer ?</ListItem>
        <ListItem>Avez-vous des hobbies ?</ListItem>
        <ListItem>Qu’aimeriez-vous faire pendant votre stage ?</ListItem>
        <ListItem>Avez-vous des questions ?</ListItem>
      </UnorderedList>
    </ProfTips>
  </>
);

export const SimulationEntretienFeedback = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'entretienFeedback',
    'entretienFeedback.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);
  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      entretienFeedback: map(EntretienFeedbackQuestions, (value, key) => ({
        key: key,
        value: (initValues.entretienFeedback || []).find((ef) => ef.key === key)
          ?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };
  return (
    <>
      <Text>
        Tu viens de participer à une simulation d'entretien ou bien d'observer
        tes camarades se prêter au jeu ? Indique si les points suivants ont été
        réussis ou s'ils peuvent encore être améliorés.
      </Text>
      <Formik {...formik}>
        <Form>
          <VStack spacing={6}>
            {Object.keys(EntretienFeedbackQuestions).map((key, i) => (
              <RadioGroupControl
                name={`entretienFeedback[${i}].value`}
                label={EntretienFeedbackQuestions[key]}
                isRequired
                key={key}
              >
                <Radio value="reussi">Réussi</Radio>
                <Radio value="a-ameliorer">À améliorer</Radio>
              </RadioGroupControl>
            ))}
          </VStack>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};
