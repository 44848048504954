export var Level;
(function(Level) {
    Level["SECONDE"] = "Seconde";
    Level["PREMIERE"] = "Premi\xe8re";
    Level["TERMINAL"] = "Terminale";
    Level["POST_BAC"] = "Post-bac";
})(Level || (Level = {}));
export var DeletedReason;
(function(DeletedReason) {
    DeletedReason["MODERATION"] = "moderation";
    DeletedReason["SELF_DELETED"] = "self-deleted";
    DeletedReason["ARCHIVED"] = "archived";
})(DeletedReason || (DeletedReason = {}));
export var Role;
(function(Role) {
    Role["ROLE_LYCEEN"] = "lyceen";
    Role["ROLE_ECLAIREUR"] = "eclaireur";
    Role["ROLE_PROF"] = "pedagogie";
})(Role || (Role = {}));
