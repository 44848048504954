import { useEffect } from 'react';

import {
  ListItem,
  Link,
  // SimpleGrid,
  Text,
  UnorderedList,
  Box,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormFrame } from '@inspire/ui/chakra/forms/Form';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';

import VideoPlayer from 'components/general/VideoPlayer';
import { CheckboxGame } from '../iterative-games';
import ProfTips from '../ProfTips';
import ReflexionOralHeader from '../ReflexionOralHeader';
import SortingGame from '../SortingGame';
import { useMyProfile } from 'lib/hooks';
import { LabelledSliderControl } from 'components/forms/LabelledSliderField';
import ClickableImage from 'components/general/ClickableImage';
import SelectCheckboxActivite from 'components/activites/SelectCheckboxActivite';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import ConsigneTag from 'components/activites/ConsigneTag';

import InfographieApprentissageSrc from '../../../../public/img/infographies/6etapes-pour-creer-en-entreprise.png';
import { RecapCheckbox, RecapSlider } from '../recap-helpers';
import Euros from 'components/general/Euros';

export const ParcoursEntrepreneurs = () => {
  return (
    <>
      <ProfTips mb={3}>
        <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
        <UnorderedList>
          <ListItem>
            Utiliser un vidéoprojecteur pour une diffusion collective.
          </ListItem>
          <ListItem>
            Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
            sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
          </ListItem>
        </UnorderedList>
      </ProfTips>
      <ReflexionOralHeader />
      <Text my={4} textAlign="center">
        <i>
          Choisis et regarde les vidéos qui présentent le parcours
          d'entrepreneurs et après chacune d’entre elles, répond aux questions
          oralement.
        </i>
      </Text>
      <Box maxWidth={'400px'} margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/terminale-entrepreneuriat-taysir.mp4'}
        />
        <Text textAlign="center" mt={2}>
          <b>Taysir</b>, auto-entrepreneure dans la vente de produits de
          bien-être
        </Text>
      </Box>
      <Box maxWidth={'400px'} margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/terminale-entrepreneuriat-cynthia.mp4'}
        />
        <Text textAlign="center" mt={2}>
          <b>Cynthia</b>, créatrice d'une start-up dans le commerce de la mode
          éthique
        </Text>
      </Box>
      <Box maxWidth={'400px'} margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/terminale-entrepreneuriat-khalid.mp4'}
        />
        <Text textAlign="center" mt={2}>
          <b>Khalid</b>, propriétaire de deux <i>barber shops</i>
        </Text>
      </Box>
      <Box maxWidth={'400px'} margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/terminale-entrepreneuriat-arthur.mp4'}
        />
        <Text textAlign="center" mt={2}>
          <b>Arthur</b>, fondateur d’un centre formation et de coaching
        </Text>
      </Box>
      <UnorderedList mt={9}>
        <ListItem>
          Quelles sont les principales raisons pour lesquelles cet entrepreneur
          a choisi de créer son entreprise ?
        </ListItem>
        <ListItem>
          Quelles sont les principales difficultés mises en avant par cet
          entrepreneur ?
        </ListItem>
        <ListItem>
          Quels sont les principaux avantages mis en avant par cet entrepreneur
          ?
        </ListItem>
      </UnorderedList>
    </>
  );
};

const creationEntrepriseOpinionOptions = {
  complique: "C'est compliqué car il faut des ressources pour se lancer",
  tentant:
    "Ça se tente dès qu'on a une bonne idée et qu'on est en accord avec son projet personnel",
  debut: 'Ça se fait en début de carrière',
  experience: "Ça se fait après avoir un peu d'expérience",
  risque: "C'est trop risqué",
};

export const ParcoursEntrepreneursConclusion = (props) => (
  <>
    <ReflexionOralHeader />
    <Text></Text>

    <SelectCheckboxActivite
      fieldName="creationEntrepriseOpinion"
      label="Selon toi créer son entreprise : (plusieurs réponses possibles)"
      options={creationEntrepriseOpinionOptions}
      wrapProps={{ width: '100%' }}
      {...props}
    />
  </>
);

const creationEntrepriseSteps = [
  {
    id: 'idee',
    label: 'Trouver une idée',
    isCorrect: null,
  },
  {
    id: 'projet',
    label: "Définir son projet d'entreprise",
    isCorrect: null,
  },
  {
    id: 'etude',
    label: 'Faire une étude de marché',
    isCorrect: null,
  },
  {
    id: 'businessPlan',
    label: "Faire son plan d'affaire (Business Plan)",
    isCorrect: null,
  },
  {
    id: 'finance',
    label: "Financer son projet d'entreprise",
    isCorrect: null,
  },
  {
    id: 'nature',
    label: 'Choisir sa nature juridique',
    isCorrect: null,
  },
  {
    id: 'creer',
    label: 'Créer son entreprise',
    isCorrect: null,
  },
  {
    id: 'done',
    label: 'Entreprise créée',
    isCorrect: null,
  },
];

export const TrierCreationEntreprise = ({ allowNext }) => (
  <SortingGame
    label={
      <>
        Classe dans <strong>l’ordre chronologique</strong> les étapes à suivre
        pour créer son entreprise :
      </>
    }
    sortOptions={creationEntrepriseSteps}
    allowNext={allowNext}
  />
);

export const QuizEntrepreneuriat1 = (props) => (
  <QuizEntrepreneuriat
    {...props}
    questions={[
      {
        subject: 'Quel est l’objectif d’une étude de marché ?',
        answerValues: ['1', '2', '3', '4'],
        options: [
          {
            shortName: 'Définir quelle sera ma clientèle et ses attentes',
            value: '1',
          },
          { shortName: 'Déterminer mon offre commerciale', value: '2' },
          {
            shortName:
              'Connaître la concurrence et ce qui se pratique dans le marché',
            value: '3',
          },
          {
            shortName:
              'Vérifier que mon idée peut devenir une activité commerciale',
            value: '4',
          },
          {
            shortName: 'Acheter ses fournitures pour sa future entreprise',
            value: '5',
          },
        ],
        summary: (
          <Text>
            L’étude de marché est une analyse réalisée pour récolter des
            informations sur l'environnement de la future entreprise : l’offre
            et la demande, la concurrence, la réglementation, l’évolution des
            pratiques. L'objectif de l'étude de marché est de savoir si le bien
            ou le service proposé va répondre à un besoin existant.
          </Text>
        ),
      },
    ]}
  />
);

export const QuizEntrepreneuriat2 = (props) => (
  <QuizEntrepreneuriat
    {...props}
    questions={[
      {
        subject: 'Quel est l’objectif d’un plan d’affaire (Business plan) ?',
        answerValues: ['1', '2', '3', '4'],
        options: [
          {
            shortName: 'Définir mon modèle économique',
            value: '1',
          },
          {
            shortName:
              'Savoir au bout de combien de temps et sous quelles conditions mon entreprise peut devenir rentable',
            value: '2',
          },
          {
            shortName:
              'Savoir combien je dois investir au départ pour me lancer',
            value: '3',
          },
          {
            shortName: 'Déterminer quel serait mon chiffre d’affaire',
            value: '4',
          },
        ],
        summary: (
          <Text>
            Le business plan est un document qui présente les objectifs
            commerciaux et financiers de la future entreprise à court, moyen et
            long terme. La partie commerciale définit les objectifs commerciaux
            au niveau des ventes potentielles, la stratégie sur le marché et les
            moyens nécessaires. La partie financière comporte un budget
            prévisionnel et le plan de financement (trésorerie et investissement
            nécessaire). En résumé, le business plan est une présentation
            concrète et chiffrée du projet de création d'entreprise.
          </Text>
        ),
      },
    ]}
  />
);

export const QuizEntrepreneuriat3 = (props) => (
  <QuizEntrepreneuriat
    {...props}
    questions={[
      {
        subject:
          "Quels sont les modes de financements possibles pour la création d'une entreprise ?",
        answerValues: ['1', '2', '3', '4', '5'],
        options: [
          { shortName: 'Banque', value: '1' },
          { shortName: 'Associés', value: '2' },
          { shortName: 'Son épargne', value: '3' },
          { shortName: 'Aides de l’état', value: '4' },
          { shortName: 'Son entourage (famille, amis)', value: '5' },
        ],
        summary: (
          <Text>
            Les modes de financement les plus communs sont : l’autofinancement,
            les prêts bancaires, les prêts de proches mais également l’entrée
            d’investisseurs ou d’associés dans le capital de l'entreprise. Il
            est aussi possible de bénéficier d’aides de l'État en fonction de la
            situation personnelle de l’entrepreneur (exemple : chômeur lançant
            son activité), de sa localisation géographique (primes
            d'aménagements du territoire, réductions d’impôts) ou de subventions
            pour les projets considérés comme innovants.
          </Text>
        ),
      },
    ]}
  />
);

export const QuizEntrepreneuriat4 = (props) => (
  <QuizEntrepreneuriat
    {...props}
    questions={[
      {
        subject: 'Lesquels de ces statuts d’entreprise existent vraiment ?',
        answerValues: ['1', '2', '3', '4', '5'],
        options: [
          {
            shortName: 'Micro entreprise / Entreprise individuelle',
            value: '1',
          },
          { shortName: 'Société à responsabilité limitée (SARL)', value: '2' },
          {
            shortName:
              'Entreprise unipersonnelle à responsabilité limitée (EURL)',
            value: '3',
          },
          {
            shortName: 'Société par action simplifiée unipersonnelle (SASU)',
            value: '4',
          },
          { shortName: 'Société par actions simplifiées (SAS)', value: '5' },
        ],
        summary: (
          <>
            <Text>Si on veut entreprendre seul :</Text>
            <UnorderedList>
              <ListItem>
                Micro entreprise : responsabilité illimité, chiffre d’affaire
                maximum de <Euros>176 200</Euros> HT pour les activités
                d’achat-revente et <Euros>72 600</Euros> pour des services
              </ListItem>
              <ListItem>
                Entreprise individuelle : responsabilité illimitée et pas de
                limite de chiffre d’affaire
              </ListItem>
              <ListItem>
                EURL (entreprise unipersonnelle à responsabilité limitée) :
                responsabilité limitée aux apports fournis, le gérant peut être
                assimilé à un salarié
              </ListItem>
              <ListItem>
                SASU (société par actions simplifiée unipersonnelle) : le
                président est assimilé à un salarié, sa responsabilité est
                limitée aux apports fournis
              </ListItem>
            </UnorderedList>
            Si on veut entreprendre à plusieurs :
            <UnorderedList>
              <ListItem>
                SARL (société à responsabilité limitée) : le gérant est la
                personne physique et on peut compter jusqu’à 100 associés. La
                responsabilité est limitée aux apports
              </ListItem>
              <ListItem>
                SAS (société par actions simplifiée) : il peut y avoir un nombre
                illimité d’associés/actionnaires et un président qui peut être
                désigné. La responsabilité est limitée aux apports.
              </ListItem>
            </UnorderedList>
          </>
        ),
      },
    ]}
  />
);

export const QuizEntrepreneuriat5 = (props) => (
  <QuizEntrepreneuriat
    {...props}
    questions={[
      {
        subject:
          'Dans quel organisme dois-je me diriger pour créer mon entreprise',
        answerValues: ['1', '3', '4'],
        options: [
          { shortName: 'Le centre de formalité des entreprises', value: '1' },
          { shortName: 'La mairie', value: '2' },
          { shortName: 'La chambre de commerce et de l’industrie', value: '3' },
          {
            shortName: 'Sur Internet sur le site guichet-entreprise.fr.',
            value: '4',
          },
          { shortName: 'La préfecture', value: '5' },
        ],
        summary: (
          <>
            <Text>
              On peut faire sa démarche de création d’entreprise en déposant un
              dossier d'immatriculation auprès d’un Centre de formalités des
              entreprises (CFE) compétent. On peut le faire directement en
              quelques clics sur une plateforme en ligne guichet-entreprise.fr.
              Les chambres de commerces et d'industrie (CCI), les chambres de
              métiers et de l'artisanat (CMA), les chambres d'agriculture, de
              l'Urssaf, ou du greffe du tribunal de commerce du lieu de
              domiciliation de votre entreprise, sont aussi des options.
            </Text>
            <Text>
              Pour les auto entrepreneurs (micro entreprise), on s’inscrit sur
              le site internet dédié :{' '}
              <Link href="https://www.autoentrepreneur.urssaf.fr" isExternal>
                www.autoentrepreneur.urssaf.fr
              </Link>
            </Text>
            <Text>
              Une fois l’inscription réalisée on obtient un numéro
              d’identification SIREN et un SIRET (le numéro qui identifie chaque
              établissement de l’entreprise).
            </Text>
          </>
        ),
      },
    ]}
  />
);

export const QuizEntrepreneuriat = ({ questions, allowNext }) => {
  useEffect(() => {
    allowNext(false);
  }, []);
  return (
    <CheckboxGame
      questions={questions}
      getOptions={(q) => q?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'} mb={8} fontWeight="bold">
          Coche la bonne réponse à ces questions (plusieurs réponses possibles).
        </Text>
      }
      getQuestionSection={(q) => (
        <Text mt={0}>
          <i>{q?.subject}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame>
            {questions.slice(0, questionIndex).map((q) => q.summary)}
          </SummaryFrame>
        )
      }
    />
  );
};

const etapesCreationEntreprise = [
  {
    key: 'idee',
    label: 'Evaluer et valider ton idée',
  },
  {
    key: 'marche',
    label: 'Étudier le marché',
  },
  {
    key: 'chiffrer',
    label: 'Chiffrer le projet',
  },
  {
    key: 'financer',
    label: 'Financer le projet',
  },
  {
    key: 'juridique',
    label: 'Choisir la structure juridique',
  },
  {
    key: 'creer',
    label: "Créer l'entreprise",
  },
];

export const EtapesEntreprise = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'etapesEntreprise',
    'etapesEntreprise.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      etapesEntreprise: etapesCreationEntreprise.map((e) => ({
        key: e.key,
        value: (initValues.etapesEntreprise || []).find(
          (ea) => ea.key === e.key
        )?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <>
      <ReflexionOralHeader />
      <Text fontStyle="italic" textAlign="center">
        <ConsigneTag /> : Après avoir regardé l'infographie, réponds aux
        questions.
      </Text>

      {/* TODO: update infographie  */}
      <Box maxWidth="400px" textAlign="center" margin="auto">
        <ClickableImage
          src={InfographieApprentissageSrc}
          href="/img/infographies/chiffres-cles-apprentissage.png"
          alt={'Infographie "Les chiffres clés de l\'apprentissage"'}
        />
      </Box>

      <Text mt={8}>
        Pour chaque étape dans la création d’entreprise, coche la réponse qui te
        semble être la plus évidente :{' '}
      </Text>

      <Formik {...formik}>
        {({ values }) => (
          <Form>
            <FormFrame stackProps={{ spacing: 5 }}>
              {etapesCreationEntreprise.map((a, i) => (
                <LabelledSliderControl
                  key={i}
                  name={`etapesEntreprise[${i}].value`}
                  label={`Étape ${i + 1} : ${
                    etapesCreationEntreprise[i].label
                  }`}
                  value={values.etapesEntreprise?.[i]?.value}
                  sliderProps={{
                    lowLabel: 'Facile',
                    highLabel: 'Difficile',
                    min: 1,
                    max: 4,
                    defaultValue: 2.5,
                    valueLabels: {
                      1: 'Facile',
                      2: 'Plutôt facile',
                      3: 'Plutôt difficile',
                      4: 'Difficile',
                    },
                  }}
                  isRequired
                />
              ))}
            </FormFrame>
            <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export const RecapEntrepreneuriat = ({ profile }) => (
  <Box>
    <RecapCheckbox
      profile={profile}
      header="Selon toi créer son entreprise :"
      fieldName="creationEntrepriseOpinion"
      options={creationEntrepriseOpinionOptions}
    />
    <RecapSlider
      profile={profile}
      header="Pour chaque étape dans la création d’entreprise, elle te semble :"
      fieldName="etapesEntreprise"
      options={etapesCreationEntreprise}
      sliderOptions={{
        lowLabel: 'Facile',
        highLabel: 'Difficile',
        min: 1,
        max: 4,
        valueLabels: {
          1: 'Facile',
          2: 'Plutôt facile',
          3: 'Plutôt difficile',
          4: 'Difficile',
        },
      }}
    />
  </Box>
);
