import { Box, Text } from '@chakra-ui/react';
import Image from 'next/image';

import OralImage from '@inspire/ui/assets/undraw/oral.svg';

const ReflexionOralHeader = () => (
  <Box textAlign={'center'}>
    <Image
      src={OralImage}
      alt={"Réflexion à l'oral"}
      width={120}
      height={120}
    />
    <Text fontStyle={'italic'}>Réflexion à l'oral</Text>
  </Box>
);

export default ReflexionOralHeader;
