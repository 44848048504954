import { Link, LinkProps } from '@chakra-ui/react';
import Image, { ImageProps } from 'next/image';

interface Props {
  src?: any;
  alt: string;
  imageProps?: Omit<ImageProps, 'src' | 'alt'>;
}

const ClickableImage = ({
  src,
  href,
  alt,
  imageProps,
  ...linkProps
}: Props & LinkProps) => (
  <Link isExternal href={href} {...linkProps}>
    <Image src={src} alt={alt} {...imageProps} />
  </Link>
);

export default ClickableImage;
