import { Text } from '@chakra-ui/react';

import {
  SoftSkillsOptions,
  ActiviteOptions,
} from '@inspire/data/static/soft-skills';

import SelectRadioActivite from 'components/activites/SelectRadioActivite';
import SelectCheckboxActivite from 'components/activites/SelectCheckboxActivite';
import ProfTips from 'components/activites/ProfTips';

// TODO: USE NEW FIELDS ?

export const DeclicExperience = (props) => (
  <>
    <SelectRadioActivite
      fieldName="competencesActivite"
      label="Sélectionne une activité que tu as réalisée dans la liste ci-dessous."
      options={ActiviteOptions}
      {...props}
    />
    <ProfTips mt={6}>
      <Text>
        Rappelez que les compétences acquises par toutes nos expériences
        (scolaires ou pas) sont fondamentales et qu'il faut savoir les
        présenter. Ces exemples peuvent nous aider à mieux nous présenter et
        mieux nous faire connaître au cours d’un entretien d’embauche. Le
        référentiel des compétences transversales (soft skills) est disponible
        dans le kit de survie.
      </Text>
    </ProfTips>
  </>
);

export const DeclicListCompetences = (props) => (
  <>
    <SelectCheckboxActivite
      fieldName="competencesList"
      label="Sélectionne 3 compétences que tu as utilisées pendant cette activité."
      options={SoftSkillsOptions}
      {...props}
    />
    <ProfTips mt={6}>
      <Text>
        Rappelez que les compétences acquises par toutes nos expériences
        (scolaires ou pas) sont fondamentales et qu'il faut savoir les
        présenter. Ces exemples peuvent nous aider à mieux nous présenter et
        mieux nous faire connaître au cours d’un entretien d’embauche. Le
        référentiel des compétences transversales (soft skills) est disponible
        dans le kit de survie.
      </Text>
    </ProfTips>
  </>
);
