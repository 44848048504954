import { Button, ButtonProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

// SubmitButton is for Formik forms only, it provides the default
// enabled state for the submit button, until the users submits
// an invalid form. Then it becomes disabled until the form is
// corrected. By default, a Chakra loading button is disabled, but since
// we override `disabled` with some custom logic, we also need to check
// `isLoading` in the `disabled` condition.

// Note: When defining onSubmit, always put `async` in front of the function
// or isSubmitting will always be true and the button will stay disabled.
// https://github.com/formium/formik/issues/739

const SubmitButton = ({ children, ...buttonProps }: ButtonProps) => {
  const { isSubmitting, isValid, submitCount } = useFormikContext();
  const isLoading = isSubmitting || buttonProps.isLoading;

  return (
    <Button
      type="submit"
      disabled={isLoading || (submitCount && !isValid)}
      isLoading={isLoading}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
