import { useEffect } from 'react';

import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Flex,
} from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import VideoPlayer from 'components/general/VideoPlayer';
import ProfTips from 'components/activites/ProfTips';
import { RadioGame } from 'components/activites/iterative-games';
import ClickableImage from 'components/general/ClickableImage';

import InfographieApprentissageSrc from '../../../../../public/img/infographies/chiffres-cles-apprentissage.png';
import InfographieEtapesAlternanceSrc from '../../../../../public/img/infographies/calendrier-alternance-ANAF.png';

export const TemoignageAlternance = ({
  title,
  url,
  firstName,
  gender,
  reponsesRaisons,
  reponsesAppris,
  reponsesAvantages,
}) => (
  <Box>
    <Heading size="md" textAlign={'center'} mb={8}>
      {title}
    </Heading>
    <Flex direction={['column', null, null, 'row-reverse']} mb={8}>
      <Box w="full" h="full" flex={1}>
        <VideoPlayer url={url} />
      </Box>
      <Box
        w={['100%', null, null, '700px']}
        pr={[0, null, null, 5]}
        pt={[4, null, null, 0]}
        flex={1}
      >
        <UnorderedList spacing={6}>
          <ListItem>
            Pour quelles raisons {firstName} a choisi de faire de l’alternance ?
          </ListItem>
          <ProfTips isShortVersion>
            <UnorderedList mt={3}>
              {reponsesRaisons.map((r) => (
                <ListItem key={r}>{r}</ListItem>
              ))}
            </UnorderedList>
          </ProfTips>
          <ListItem>Qu’est-ce qu'{gender} a appris ?</ListItem>
          <ProfTips isShortVersion>
            <UnorderedList mt={3}>
              {reponsesAppris.map((r) => (
                <ListItem key={r}>{r}</ListItem>
              ))}
            </UnorderedList>
          </ProfTips>
          <ListItem>
            Quels sont les 2 avantages de l’apprentissage selon {firstName} ?
          </ListItem>
          <ProfTips isShortVersion>
            <UnorderedList mt={3}>
              {reponsesAvantages.map((r) => (
                <ListItem key={r}>{r}</ListItem>
              ))}
            </UnorderedList>
          </ProfTips>
        </UnorderedList>
      </Box>
    </Flex>
  </Box>
);

export const TemoignageNaomi = () => (
  <TemoignageAlternance
    title="Témoignage de Naomi BTS SAM (Support à l'Action Managériale)"
    url="/videos-bacpro/Apprentissage+%C2%B7+Te%CC%81moignage+de+Naomi.mp4"
    firstName="Naomi"
    gender="elle"
    reponsesRaisons={[
      'L’enseignement purement théorique dispensé à l’université ne lui a pas plu.',
    ]}
    reponsesAppris={[
      'Découverte du monde professionnel',
      "Prise d'initiative",
      'Autonomie',
      'Maturité',
    ]}
    reponsesAvantages={[
      'Impression de servir a quelque chose.',
      'Pouvoir financer sa vie étudiante et prendre son indépendance.',
    ]}
  />
);

export const TemoignageEric = () => (
  <TemoignageAlternance
    title="Témoignage d’Eric BTS assistance technique d'ingénieur puis école
    d’ingénieur en apprentissage"
    url="/videos-bacpro/Apprentissage+%C2%B7+Te%CC%81moignage+d'Eric.mp4"
    firstName="Eric"
    gender="il"
    reponsesRaisons={['L’indépendance financière', 'Responsabilisation']}
    reponsesAppris={[
      'La connaissance de soi et développement personnel',
      'Le savoir vivre et capacité d’intégration dans une entreprise',
    ]}
    reponsesAvantages={[
      'L’indépendance financière',
      'L’apprentissage d’un métier sur le terrain',
    ]}
  />
);

export const TemoignagePaolandrea = () => (
  <TemoignageAlternance
    title="Témoignage de Paolandrea (DUT Gestion des entreprises et administration en alternance / Bachelor Marketing et communication en alternance / Master 1 communication en alternance)"
    url="/videos-bacpro/Apprentissage+%C2%B7+Te%CC%81moignage+de+Paolandrea.mp4"
    firstName="Paloandrea"
    gender="elle"
    reponsesRaisons={[
      'La mise en pratique réelle des enseignements vus en cours.',
    ]}
    reponsesAppris={['La capacité d’intégration dans une entreprise.']}
    reponsesAvantages={[
      'L’expérience professionnelle',
      'L’acquisition de compétences professionnelles sur le terrain',
    ]}
  />
);

export const DefinitionApprentissage = () => (
  <Box
    bg="gray.100"
    px={6}
    py={5}
    borderRadius={4}
    mb={8}
    mx={'auto'}
    width={['100%', '80%', '66%']}
  >
    <Text>
      <b>Définition de l'apprentissage :</b>
    </Text>
    <Text>
      <i>
        L’apprentissage est un système de formation par alternance, accessible
        de 16 à 30 ans. Il t’offre la possibilité de préparer un diplôme
        professionnel allant du CAP jusqu’au BAC+5. Il se concrétise par la mise
        en place d’un contrat d’apprentissage, d’une durée maximale de 3 ans. Tu
        seras alors salarié de l’entreprise avec un statut d’apprenti.
      </i>
    </Text>
  </Box>
);

export const LesEtapesAvantApprentissage = ({ allowNext }) => {
  const questions = [
    {
      text: 'S’informer et réfléchir à son projet',
      answerValue: 'octobre / decembre',
    },
    {
      text: 'Contacter les entreprises avec son CV et sa lettre de motivation',
      answerValue: 'janvier / mars',
    },
    {
      text: 'Chercher un CFA et continuer à contacter les entreprises si nécessaire',
      answerValue: 'avril / juin',
    },
    {
      text: 'Signer son contrat d’apprentissage',
      answerValue: 'juillet / aout',
    },
    {
      text: 'C’est parti ! Commencer son apprentissage',
      answerValue: 'septembre',
    },
  ];

  const options = [
    'Octobre / Décembre',
    'Janvier / Mars',
    'Avril / Juin',
    'Juillet / Aout',
    'Septembre',
  ].map((m) => ({
    value: m.replace('é', 'e').toLowerCase(),
    shortName: m,
    fullName: m,
  }));

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      isVertical={true}
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Box textAlign={'center'}>
          <Text mb={8}>
            A quel moment de l'année dois-tu faire l'action suivante ?
          </Text>
        </Box>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question?.text} ?</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8} label="Les étapes de l'alternance">
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.answerValue}>
                  <b>
                    {options.find((c) => c.value === q.answerValue).fullName} :
                  </b>{' '}
                  {q.text + '.'}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const InfographieChiffresApprentissage = () => (
  <Box textAlign="center">
    <Text>
      <i>Découvre-en un peu plus sur l’apprentissage.</i>
    </Text>
    <ClickableImage
      src={InfographieApprentissageSrc}
      href="/img/infographies/chiffres-cles-apprentissage.png"
      alt={'Infographie "Les chiffres clés de l\'apprentissage"'}
    />
  </Box>
);

export const InfographieEtapesAlternance = () => (
  <Box textAlign="center">
    <Text>
      <i>Les étapes avant d’entrer en alternance.</i>
    </Text>
    <ClickableImage
      src={InfographieEtapesAlternanceSrc}
      href="/img/infographies/calendrier-alternance-ANAF.png"
      alt={'Infographie "Les étapes avant d’entrer en alternance"'}
    />
  </Box>
);
