import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxContainer } from 'formik-chakra-ui';

import PageSpinner from '@inspire/ui/chakra/layout/PageSpinner';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import {
  FormStack,
  CheckboxControlOptions,
} from '@inspire/ui/chakra/forms/Form';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';

import { useMyProfile } from 'lib/hooks';

const TodoActivite = ({
  goNext,
  options,
  fieldName,
  todoLabel = 'À FAIRE :',
  doneLabel = 'FAIT :',
}) => {
  const {
    myProfile,
    setAndRefetchMyProfile,
    isSettingAndRefetchingMyProfile,
    isFetching,
  } = useMyProfile(null, {
    onSuccess: goNext,
  });

  const schema = LyceenBacProProfileSchema.pick(
    fieldName,
    fieldName + '.$'
  ).requireFields();
  const initialValues = initFormikValuesFromSchema(schema, myProfile);
  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return isFetching ? (
    <PageSpinner />
  ) : (
    <>
      <Formik {...formik}>
        {({ values }) => (
          <Form>
            <FormStack>
              <CheckboxContainer
                as="fieldset"
                name={fieldName}
                label={todoLabel}
                stackProps={{ pl: 0 }}
              >
                <CheckboxControlOptions
                  name={fieldName}
                  options={Object.keys(options)
                    .filter((k) => !values[fieldName]?.includes(k))
                    .map((k) => ({
                      label: options[k],
                      value: k,
                    }))}
                />
              </CheckboxContainer>
              {values[fieldName]?.length > 0 && (
                <CheckboxContainer
                  as="fieldset"
                  name={fieldName}
                  label={doneLabel}
                  stackProps={{ pl: 0 }}
                >
                  <CheckboxControlOptions
                    name={fieldName}
                    options={Object.keys(options)
                      .filter((k) => values[fieldName]?.includes(k))
                      .map((k) => ({
                        label: options[k],
                        value: k,
                      }))}
                  />
                </CheckboxContainer>
              )}
            </FormStack>
            <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TodoActivite;
