import { useEffect } from 'react';

import { Box, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import Image from 'next/image';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import VideoThumbnail from '@inspire/ui/assets/activites/entreprise/video-thumbnail.png';
import { PlayIcon } from '@inspire/ui/icons';

import Link from 'components/general/Link';
import { RadioGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';
import VideoPlayer from 'components/general/VideoPlayer';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ClickableImage from 'components/general/ClickableImage';

import OrganigrammeSrc from '../../../../../public/img/infographies/organigramme.png';

export const QuizzEntreprise = ({ allowNext }) => {
  const questions = [
    {
      text: 'Qu’est-ce que veut dire PME ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Petite Moyenne Entreprise',
          value: '1',
        },
        {
          shortName: 'Petite Moyenne Entrepôts',
          value: '2',
        },
        {
          shortName: 'Petite Moyenne Economie',
          value: '3',
        },
      ],
    },
    {
      text: 'Que veut dire SA ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Société anonyme',
          value: '1',
        },
        {
          shortName: 'Société artisanale',
          value: '2',
        },
        {
          shortName: 'Société aléatoire',
          value: '3',
        },
      ],
    },
    {
      text: 'Que veut dire chiffre d’affaire ?',
      answerValue: '1',
      options: [
        {
          shortName: "Combien de richesse l'entreprise a créé",
          value: '1',
        },
        {
          shortName: 'Combien de richesse l’entreprise a créé après impôts',
          value: '2',
        },
        {
          shortName: 'Combien l’entreprise à dépenser',
          value: '3',
        },
      ],
    },
    {
      text: 'Que veut dire SARL ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Société à responsabilité limitée',
          value: '1',
        },
        {
          shortName: 'Société associée restrictive limitée',
          value: '2',
        },
        {
          shortName: 'Société artistique lauréate',
          value: '3',
        },
      ],
    },
    {
      text: 'Qu’est-ce qu’une startup ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Une entreprise écologique',
          value: '1',
        },
        {
          shortName: 'Une entreprise encore en développement',
          value: '2',
        },
        {
          shortName: 'Une entreprise qui n’emploie que des jeunes',
          value: '3',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      isVertical
      introSection={
        <Text textAlign={'center'}>
          Teste tes connaissances sur l’entreprise en répondant au quiz.
        </Text>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question.text}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.text}>
                  <b>{q.text} :</b>{' '}
                  {q.options.find((c) => c.value === q.answerValue).shortName}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const VideoDecouvrirEntreprise = ({ allowNext }) => {
  const questions = [
    {
      text: 'Dans une entreprise, la fonction « achats » sert à :',
      answerValue: '1',
      options: [
        {
          shortName:
            'Acheter les matières premières pour fabriquer un produit et faire fonctionner l’entreprise',
          value: '1',
        },
        {
          shortName: 'Faire du shopping avec les bénéfices de la société',
          value: '2',
        },
      ],
    },
    {
      text: 'Une entreprise est une organisation :',
      answerValue: '1',
      options: [
        {
          shortName: 'Humaine',
          value: '1',
        },
        {
          shortName: 'Robotisée',
          value: '2',
        },
        {
          shortName: 'Complexe',
          value: '3',
        },
      ],
      profTips: 'Une entreprise est avant tout une aventure humaine',
    },
    {
      text: 'Le secteur de production dit secondaire regroupe les activités...',
      answerValue: '1',
      options: [
        {
          shortName: 'De transformation des matières premières',
          value: '1',
        },
        {
          shortName: "D'importance secondaire",
          value: '2',
        },
        {
          shortName: 'De transformation des matières secondaires',
          value: '3',
        },
      ],
      profTips:
        'Le secteur secondaire transforme les matières premières naturelles (produites par le secteur primaire) en produit commercialisables',
    },
    {
      text: "Qu’est-ce qu'un actionnaire ?",
      answerValue: '2',
      options: [
        {
          shortName: 'Une personne qui aime les films d’action',
          value: '1',
        },
        {
          shortName: 'Une personne propriétaire d’une entreprise',
          value: '2',
        },
      ],
      profTips:
        "Un actionnaire est propriétaire d'une entreprise à hauteur de son investissement",
    },
    {
      text: 'Vrai ou faux : le produit vendu doit répondre à un besoin ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Vrai',
          value: '1',
        },
        {
          shortName: 'Faux',
          value: '2',
        },
      ],
      profTips:
        'Même si ce besoin n’est pas forcément conscient pour le client !',
    },
    {
      text: 'Vrai ou faux : le produit proposé par une entreprise peut être un bien ou un service ?',
      answerValue: '1',
      options: [
        {
          shortName: 'Vrai',
          value: '1',
        },
        {
          shortName: 'Faux',
          value: '2',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <>
          <ProfTips mb={3}>
            <Text>
              Vous avez plusieurs possibilités pour lancer les vidéos :
            </Text>
            <UnorderedList>
              <ListItem>
                Utiliser un vidéoprojecteur pour une diffusion collective.
              </ListItem>
              <ListItem>
                Chaque élève lance les vidéos depuis la plateforme sur
                ordinateur ou sur mobile (dans l’idéal les élèves ont chacun des
                écouteurs).
              </ListItem>
            </UnorderedList>
          </ProfTips>

          <Text textAlign={'center'}>
            Regarde la vidéo puis réponds au quiz.
          </Text>

          <Box
            maxWidth={'300px'}
            margin={'auto'}
            mb={6}
            border="1px solid #dedede"
            rounded="md"
            overflow="hidden"
            textAlign="center"
            position="relative"
          >
            <Link
              href="https://player.kumullus.com/k-player/index.html?kumullus_id=5d6919207b98a80ceb1b767c&version=1d26a730-ad3f-469e-9b4a-2e3989b9b97a&"
              target="_blank"
            >
              <Icon
                as={PlayIcon}
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                zIndex={1}
                margin="auto"
                w={14}
                h={14}
                color="yellow.500"
              />
              <Image
                src={VideoThumbnail}
                alt="Vidéo Qu’est ce qu’une entreprise ?"
              />
            </Link>
          </Box>
        </>
      }
      isVertical
      getQuestionSection={(question) => (
        <Box>
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
          {question.profTips && (
            <ProfTips mt={2} mb={6} isShortVersion>
              <Text>{question.profTips}</Text>
            </ProfTips>
          )}
        </Box>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.text}>
                  <b>{q.text}</b>{' '}
                  {q.options.find((c) => c.value === q.answerValue).shortName}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const OrganigrammeEntreprise = ({ allowNext }) => {
  const questions = [
    {
      text: 'Quelles sont les responsabilités du service des Ressources Humaines ?',
      answerValue: '1',
      options: [
        {
          shortName:
            'Responsable des recrutements, de l’organisation du personnel et son épanouissement dans l’organisation',
          value: '1',
        },
        {
          shortName:
            'Prospecte et finalise les contrats de ventes de l’entreprises',
          value: '2',
        },
        {
          shortName:
            'Réalise les règlements de toutes les transactions financières de l’entreprise, salaires compris',
          value: '3',
        },
      ],
    },
    {
      text: 'Quel service rédige les contrats et toutes les démarches administratives liées à l’activité de l’entreprise ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Service Logistique',
          value: '1',
        },
        {
          shortName: 'Service Juridique et Administratif',
          value: '2',
        },
        {
          shortName: 'Service Production',
          value: '3',
        },
      ],
    },
    {
      text: 'Quelles sont les responsabilités du Service Financier ?',
      answerValue: '3',
      options: [
        {
          shortName:
            'Réalise les livraisons et l’approvisionnement de l’entreprise',
          value: '1',
        },
        {
          shortName:
            'Responsable de la fabrication des biens et création des services',
          value: '2',
        },
        {
          shortName:
            'Réalise les règlements de toutes les transactions financières de l’entreprise, salaires compris',
          value: '3',
        },
      ],
    },
    {
      text: 'Quel service prospecte et finalise les contrats de ventes de l’entreprise ?',
      answerValue: '2',
      options: [
        {
          shortName: 'Service Logistique',
          value: '1',
        },
        {
          shortName: 'Service Commercial',
          value: '2',
        },
        {
          shortName: 'Juridique et Administratif',
          value: '3',
        },
      ],
    },
    {
      text: 'Quelles sont les responsabilités du Service de Production ?',
      answerValue: '1',
      options: [
        {
          shortName:
            'Responsable de la fabrication des biens et création des services',
          value: '1',
        },
        {
          shortName:
            'Prospecte et finalise les contrats de ventes de l’entreprise',
          value: '2',
        },
        {
          shortName:
            'Rédige les contrats et toutes les démarches administratives liées à l’activité de l’entreprise',
          value: '3',
        },
      ],
    },
    {
      text: 'Quel Service est responsable de la façon dont l’entreprise communique vers l’extérieur ?',
      answerValue: '3',
      options: [
        {
          shortName: 'Service Ressources Humaines',
          value: '1',
        },
        {
          shortName: 'Service Financier',
          value: '2',
        },
        {
          shortName: 'Service Marketing',
          value: '3',
        },
      ],
    },
    {
      text: 'Quelles sont les Responsabilités du Service Logistique ?',
      answerValue: '2',
      options: [
        {
          shortName:
            'Prospecte et finalise les contrats de ventes de l’entreprise',
          value: '1',
        },
        {
          shortName:
            'Réalise les livraisons et l’approvisionnement de l’entreprise',
          value: '2',
        },
        {
          shortName:
            'Rédige les contrats et toutes les démarches administratives liées à l’activité de l’entreprise',
          value: '3',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Text textAlign={'center'}>
        En fonction de la taille d’une entreprise, son organisation peut varier.
        L'organigramme suivant représente l’organisation la plus courante.
        Réponds aux questions suivantes pour relier les différents services à
        leur description.
      </Text>
      <Box maxWidth={'300px'} margin="auto" my={4}>
        <ClickableImage
          src={OrganigrammeSrc}
          href="/img/infographies/organigramme.png"
          alt="Organigramme d'une entreprise"
        />
      </Box>
      <RadioGame
        questions={questions}
        getOptions={(question) => question?.options}
        onFinished={() => allowNext(true)}
        isVertical
        getQuestionSection={(question) => (
          <Box>
            <Text mt={0}>
              <i>{question.text}</i>
            </Text>
            {question.profTips && (
              <ProfTips mt={2} mb={6} isShortVersion>
                <Text>{question.profTips}</Text>
              </ProfTips>
            )}
          </Box>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8}>
              <UnorderedList>
                {questions.slice(0, questionIndex).map((q) => (
                  <ListItem key={q.text}>
                    <b>{q.text}</b>{' '}
                    {q.options.find((c) => c.value === q.answerValue).shortName}
                  </ListItem>
                ))}
              </UnorderedList>
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const TemoignagesPro = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>
        Regarde les vidéos témoignages de professionnels RH qui parlent de leur
        secteur d’activité et des attentes de leur entreprise lors des
        recrutements, puis réponds oralement aux questions.
      </i>
    </Text>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer
        url={'/videos-bacpro/4.+Les+Professionnels+Du+Recrutement.mp4'}
      />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      <ListItem>
        Que recherche Julien Ridé en priorité chez un candidat ?
      </ListItem>
      <ListItem>
        Que recherche Véronique Lugiéry en priorité chez un candidat?
      </ListItem>
      <ListItem>Qu’est-ce qu'un métier élastique à la saisonnalité ?</ListItem>
      <ListItem>Qu’est-ce qu’un métier en tension ?</ListItem>
      <ListItem>Qu’est-ce qu’une évolution verticale ?</ListItem>
      <ListItem>
        Les perspectives de carrière sont-elles réelles, pouvez-vous citer un
        exemple mentionné dans la vidéo ?
      </ListItem>
    </UnorderedList>
  </>
);
