import ModulesSeconde, {
  DECOUVRIR_SECTEUR_AGRO_ID,
  MIEUX_SE_CONNAITRE_ID,
} from 'lib/modules/seconde';
import ModulesPremiere, {
  DECOUVRIR_METIERS_ID,
  DECOUVRIR_PISTES_AVENIR_ID,
} from 'lib/modules/premiere';
import ModulesTerminale, {
  DECRYPTER_UNE_OFFRE,
  QUESTIONNAIRE_TERMINALE,
  VALORISER_SES_COMPETENCES,
} from 'lib/modules/terminale';
import { useClasse } from 'lib/hooks/queries';
import { modulePath, questionnaireStepPath } from 'lib/paths';

export const useModules = () => {
  const { classe, isClasseLoading, classeIsGatlRc, classeIsAgro } = useClasse();
  let modules = null;
  if (classe?.niveau === 'seconde') {
    modules = ModulesSeconde;

    if (!classeIsAgro) {
      modules = modules?.filter(
        (module) => module.moduleId !== DECOUVRIR_SECTEUR_AGRO_ID
      );
    }
  }
  if (classe?.niveau === 'premiere') {
    modules = ModulesPremiere;

    if (!classeIsGatlRc)
      modules = modules?.filter(
        (module) => module.moduleId !== DECOUVRIR_METIERS_ID
      );
  }
  if (classe?.niveau === 'terminale') {
    modules = ModulesTerminale;

    if (!classeIsGatlRc)
      modules = modules?.filter(
        (module) =>
          module.moduleId !== QUESTIONNAIRE_TERMINALE &&
          module.moduleId !== VALORISER_SES_COMPETENCES &&
          module.moduleId !== DECRYPTER_UNE_OFFRE
      );
  }

  modules = modules?.filter(
    (module) => module.moduleId !== DECOUVRIR_PISTES_AVENIR_ID
  );

  return {
    modules,
    areModulesLoading: isClasseLoading,
    getModuleById: (id: string) => modules?.find((m) => m.moduleId === id),
    allModules: ModulesSeconde.concat(ModulesPremiere as any).concat(
      ModulesTerminale as any
    ),
    allModulesByNiveau: [ModulesSeconde, ModulesPremiere, ModulesTerminale],
  };
};

export const useQuestionnairePath = () => {
  const {
    classeIsGatlRc,
    isSeconde: isClasseSeconde,
    isPremiere: isClassePremiere,
    isTerminale: isClasseTerminale,
  } = useClasse();

  return !classeIsGatlRc
    ? questionnaireStepPath(1)
    : isClasseSeconde
    ? modulePath(MIEUX_SE_CONNAITRE_ID)
    : isClassePremiere
    ? questionnaireStepPath(1)
    : isClasseTerminale
    ? questionnaireStepPath(1)
    : '';
};
