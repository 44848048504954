import Image from 'next/image';
import {
  Box,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import { RadioGame } from '../iterative-games';
import ProfTips from '../ProfTips';
import ReflexionOralHeader from '../ReflexionOralHeader';

import Photo1 from '@inspire/ui/assets/activites/id-digitale/photo_1.jpg';
import Photo2 from '@inspire/ui/assets/activites/id-digitale/photo_2.jpg';
import Photo3 from '@inspire/ui/assets/activites/id-digitale/photo_3.jpg';
import Photo4 from '@inspire/ui/assets/activites/id-digitale/photo_4.jpg';
import Photo5 from '@inspire/ui/assets/activites/id-digitale/photo_5.jpg';
import Photo6 from '@inspire/ui/assets/activites/id-digitale/photo_6.jpg';
import Photo7 from '@inspire/ui/assets/activites/id-digitale/photo_7.jpg';
import Photo8 from '@inspire/ui/assets/activites/id-digitale/photo_8.jpg';
import Photo9 from '@inspire/ui/assets/activites/id-digitale/photo_9.jpg';
import Photo10 from '@inspire/ui/assets/activites/id-digitale/photo_10.jpg';
import Photo11 from '@inspire/ui/assets/activites/id-digitale/photo_11.png';
import ClickableImage from 'components/general/ClickableImage';
import InfographieIdVirtuelleSrc from '../../../../public/img/infographies/5actions-pour-ameliorer-votre-identite-virtuelle.png';
import InfographieRecrutementSrc from '../../../../public/img/infographies/recrutement-reseaux-sociaux.png';
import VideoPlayer from 'components/general/VideoPlayer';

export const JePosteOuPasIntro = ({ allowNext }) => {
  return (
    <>
      <RadioGame
        questions={[
          {
            text: 'A ton avis, combien de recruteurs regardent l’identité digitale d’un candidat sur les réseaux sociaux pour un recrutement ?',
            answerValue: '1',
            options: [
              {
                shortName: 'Plus de la moitié',
                value: '1',
              },
              {
                shortName: 'Moins de la moitié',
                value: '2',
              },
            ],
          },
        ]}
        getOptions={(question) => question?.options}
        onFinished={() => allowNext(true)}
        introSection={
          <Text textAlign={'center'}>Réponds à la question suivante :</Text>
        }
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8}>
              <Text>
                85% des responsables RH tapent le nom et prénom des candidats
                sur internet pour rechercher des informations (Enquête de 2017
                de RegionsJob sur le recrutement).
              </Text>
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const JePosteOuPasQuiz1 = () => {
  return (
    <>
      <ReflexionOralHeader />
      <Text textAlign="center" fontWeight="bold">
        Donne ton avis à l’oral sur les propositions suivantes.
      </Text>

      <Text my={6} textAlign="center">
        Je peux poster une photo de moi dans une fête ?
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <Box>
          <Image
            width="1200px"
            height="675px"
            src={Photo1}
            alt="fête colorée"
            layout="responsive"
          />
          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
        <Box>
          <Image
            width="1200px"
            height="841px"
            src={Photo2}
            alt="alcool soirée"
            layout="responsive"
          />
          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
      </SimpleGrid>

      <ProfTips mt={6}>
        <Text>
          Qu’est ce que risquent les élèves en postant des photos aussi
          personnelles ?
        </Text>
        <Text>
          Certains comportements mis en avant peuvent être utilisés contre eux
          notamment pendant une phase de recrutement.
        </Text>
        <Text>
          En étant en poste dans une entreprise, ces activités peuvent être
          perçues comme une atteinte à l’image de celle-ci. S’il s’agit de
          contenu trop personnel, il est préférable de le mettre en mode privé.
        </Text>
        <Text>
          Faire la fête est normal et montrer qu’on s’amuse est tout à fait
          légitime. Toutefois des images montrant un jeune alcoolisé ou autre
          peuvent engendrer un effet négatif, même si cela est dans un cadre
          totalement privé.
        </Text>
      </ProfTips>
    </>
  );
};

export const JePosteOuPasQuiz2 = () => {
  return (
    <>
      <ReflexionOralHeader />
      <Text textAlign="center" fontWeight="bold">
        Donne ton avis à l’oral sur les propositions suivantes.
      </Text>

      <Text textAlign="center" my={6}>
        Je peux poster une photo de mes loisirs, de mes activités personnelles ?
      </Text>

      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <Box>
          <Image
            width="1200px"
            height="1400px"
            src={Photo3}
            alt="graffiti"
            layout="responsive"
          />
          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
        <Box>
          <Image
            width="1000px"
            height="800px"
            src={Photo4}
            alt="volontariat"
            layout="responsive"
          />
          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
      </SimpleGrid>

      <ProfTips mt={6}>
        <Text>
          Qu’est ce que risquent les élèves en postant des photos aussi
          personnelles ?
        </Text>
        <Text>
          Certains comportements mis en avant peuvent être utilisés contre eux
          notamment pendant une phase de recrutement.
        </Text>
        <Text>
          En étant en poste dans une entreprise, ces activités peuvent être
          perçues comme une atteinte à l’image de celle-ci. S’il s’agit de
          contenu trop personnel, il est préférable de le mettre en mode privé.
        </Text>
        <Text>
          Cependant, certaines photos peuvent permettre aux élèves de mettre en
          valeur leur personnalité ou leurs activités. Celles-ci peuvent être
          mises en mode public s’ils souhaitent qu’elles soient vu par les
          recruteurs.
        </Text>
      </ProfTips>
    </>
  );
};

export const JePosteOuPasQuiz3 = () => {
  return (
    <>
      <ReflexionOralHeader />
      <Text textAlign="center" fontWeight="bold">
        Donne ton avis à l’oral sur les propositions suivantes :{' '}
      </Text>

      <Text textAlign="center" my={6}>
        Je peux poster une photo de moi au travail ?
      </Text>
      <SimpleGrid columns={[1, null, 3]} spacing={4}>
        <Box>
          <Image
            width="1200px"
            height="801px"
            src={Photo5}
            alt="selfie entre copines"
            layout="responsive"
          />

          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
        <Box>
          <Image
            width="1200px"
            height="1200px"
            src={Photo6}
            alt="sieste sur le bureau"
            layout="responsive"
          />

          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
        <Box>
          <Image
            width="1200px"
            height="800px"
            src={Photo7}
            alt="photo d'équipe"
            layout="responsive"
          />
          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
      </SimpleGrid>

      <ProfTips mt={6}>
        <Text>
          On peut poster des photos de soi au travail, mais rien qui donne une
          image négative ou laxiste, ni qui puisse compromettre une certaine
          confidentialité.
        </Text>
        <Text>
          Si une photo où on se met en valeur durant son travail sera bien
          perçue, il est déconseillé de poster une photo qui affiche une
          attitude non conforme à ce qu’on attend de son poste.
        </Text>
        <Text>
          Par ailleurs, il faut impérativement demander l’accord de ses
          collègues pour publier une photo où ils apparaissent.
        </Text>
      </ProfTips>
    </>
  );
};

export const JePosteOuPasQuiz4 = () => {
  return (
    <>
      <ReflexionOralHeader />
      <Text textAlign="center" fontWeight="bold">
        Donne ton avis à l’oral sur les propositions suivantes.
      </Text>

      <Text textAlign="center" my={6}>
        Je peux poster des photos sur ce que je fais au travail ?
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <Box>
          <Image
            width="1200px"
            height="800px"
            src={Photo8}
            alt="photo de réunion"
            layout="responsive"
          />

          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
        <Box>
          <Image
            width="1200px"
            height="1600px"
            src={Photo9}
            alt="compte rendu budget"
            layout="responsive"
          />

          <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
        </Box>
      </SimpleGrid>

      <ProfTips mt={6}>
        <Text>
          Le contenu posté sur les réseaux sociaux ne doit pas porter préjudice
          à l’image de l’entreprise ou à ses collègues.{' '}
        </Text>
        <Text>
          Les réseaux sociaux ne sont pas l’endroit pour montrer le contenu d’un
          projet effectué au sein d’une entreprise.
        </Text>
        <Text>
          Les entreprises peuvent d’ailleurs faire signer des clauses de
          confidentialité pour éviter des fuites d’informations sensibles.
        </Text>
      </ProfTips>
    </>
  );
};

export const JePosteOuPasQuiz5 = () => (
  <>
    <ReflexionOralHeader />
    <Text textAlign="center" fontWeight="bold">
      Donne ton avis à l’oral sur les propositions suivantes.
    </Text>

    <Text my={6} textAlign="center">
      J’ai le droit de critiquer mon entreprise sur les réseaux sociaux ?
    </Text>

    <SimpleGrid columns={[1, null, 2]} spacing={4}>
      <Box>
        <Image
          width="572px"
          height="335px"
          src={Photo10}
          alt="feedback négatif Glassdoor"
          layout="responsive"
        />

        <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
      </Box>
      <Box>
        <Image
          width="630px"
          height="299px"
          src={Photo11}
          alt="insulte sur Twitter"
          layout="responsive"
        />

        <Text textAlign="center">Je poste ou je ne poste pas ?</Text>
      </Box>
    </SimpleGrid>

    <ProfTips mt={6}>
      <Text>
        La loi permet de critiquer son entreprise sur les réseaux tout en
        respectant certaines limites, mais c’est fortement déconseillé de le
        faire.
      </Text>
      <Text>
        Cela est interdit dès que cela devient injurieux ou diffamatoire, et
        cela peut-être un motif de licenciement et même de poursuites pénales.
      </Text>
    </ProfTips>
  </>
);

export const InfographieReseauxSociaux = () => {
  return (
    <>
      <ReflexionOralHeader />
      <Text fontStyle="italic" textAlign="center">
        Regarde l’infographie et réponds oralement aux questions.
      </Text>

      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={InfographieRecrutementSrc}
          href="/img/infographies/recrutement-reseaux-sociaux.png"
          alt="Infographie études supérieurs"
        />
      </Box>
      <UnorderedList>
        <ListItem>
          Es-tu surpris de voir le pourcentage accordé aux fautes d'orthographe
          pour refuser un candidat ?
        </ListItem>
        <ListItem>
          Connais-tu Linkedin et à quoi sert ce réseau social ? Pourquoi est-il
          le plus utilisé par les recruteurs ?
        </ListItem>
      </UnorderedList>

      <ProfTips mt={8}>
        <Text>
          LinkedIn est un réseau social à vocation professionnelle. Il est
          destiné aux entrepreneurs, salariés, recruteurs, personnes en
          recherche de stages ou d’emploi de tous les domaines possibles.
        </Text>
        <Text>
          Le principe de LinkedIn c’est qu’il permet de créer un profil
          professionnel et ainsi se faire connaître, élargir son réseau dans le
          but de trouver de nouveaux collaborateurs, de nouveaux clients ou un
          nouvel emploi. On peut diffuser du contenu (publications, articles,
          vidéo) directement depuis la plateforme et interagir avec les membres.
          Il est aujourd’hui le réseau social le plus utilisé par les recruteurs
          pour “dénicher” des talents, vérifier le parcours des candidats (y
          compris des références).
        </Text>
      </ProfTips>
    </>
  );
};

export const VideoTemoignageReseauxSociaux = () => {
  return (
    <>
      <ProfTips mb={3}>
        <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
        <UnorderedList>
          <ListItem>
            Utiliser un vidéoprojecteur pour une diffusion collective.
          </ListItem>
          <ListItem>
            Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
            sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
          </ListItem>
        </UnorderedList>
      </ProfTips>

      <ReflexionOralHeader />

      <Text my={4} textAlign={'center'}>
        <i>
          Regarde cette vidéo d’un professionnel des ressources humaines qui
          explique comment il utilise les réseaux sociaux pour effectuer des
          recrutements et répond aux questions à l’oral.
        </i>
      </Text>

      <Box maxWidth={'400px'} margin="auto">
        <VideoPlayer url={'/videos-bacpro/terminale-rh-reseaux-sociaux.mp4'} />
        <Text textAlign="center" mt={2}>
          Les RH et les réseaux sociaux
        </Text>
      </Box>

      <UnorderedList>
        <ListItem>
          Pourquoi les RH regardent les réseaux sociaux pour leur recrutement ?
        </ListItem>
        <ListItem>Qu’est-ce que selon toi la e-réputation ?</ListItem>

        <ProfTips my={6}>
          <Text>
            Définition de la E-réputation : L’e-réputation c’est tout simplement
            la réputation d’une entreprise, d’une marque, d’un particulier ou
            d’un produit sur Internet. En d’autres termes, l’e-réputation, c’est
            l’image d’une entité sur Internet. Cette image peut être positive ou
            négative.
          </Text>
        </ProfTips>

        <ListItem>
          Pourquoi cette utilisation des réseaux sociaux prend de plus en plus
          d’importance ?
        </ListItem>

        <ProfTips my={6}>
          <Text>
            Les réseaux sociaux sont un outil supplémentaire pour s’informer sur
            un candidat en plus des informations qu’il peut fournir (CV, LM,
            etc.).
          </Text>
          <Text>
            De plus, la nouvelle génération de professionnels des ressources
            humaines a grandi avec les réseaux sociaux et est donc plus
            familiarisée avec cet usage pour effectuer des recrutements.
          </Text>
          <Text>
            Enfin, les réseaux sociaux permettent une massification de la
            recherche de candidats “Les réseaux sociaux leur permettent en effet
            d’augmenter de 44 % le nombre des candidats et un gain de temps de
            34% dans le recrutement”. (Source : une étude de Jobvite)
          </Text>
        </ProfTips>
      </UnorderedList>
    </>
  );
};

export const InfographieConseilsPreserverSonImage = () => {
  return (
    <>
      <ReflexionOralHeader />

      <Text fontStyle="italic" textAlign="center">
        Réponds à cette question puis regarde les conseils proposés pour
        préserver son image sur Internet.
      </Text>

      <UnorderedList>
        <ListItem>Que veut dire "Googliser" ?</ListItem>
      </UnorderedList>

      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={InfographieIdVirtuelleSrc}
          href="/img/infographies/5actions-pour-ameliorer-votre-identite-virtuelle.png"
          alt="Infographie études supérieurs"
        />
      </Box>

      <ProfTips mt={6}>
        <Text>
          Définition de Googliser : Rechercher des informations (en particulier
          sur quelqu'un) sur Internet en utilisant le moteur de recherche
          Google. (On dit aussi googler.) [définition du Larousse]
        </Text>
      </ProfTips>
    </>
  );
};
