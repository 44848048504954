import { Wrap, WrapItem, Radio } from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { RadioGroupControl } from 'formik-chakra-ui';

import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';

import { useMyProfile } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';

const SelectCheckboxActivite = ({ goNext, fieldName, label, options }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(fieldName).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      <Form>
        <RadioGroupControl
          as="fieldset"
          name={fieldName}
          label={label}
          stackProps={{ pl: 0 }}
        >
          <Wrap>
            {Object.keys(options)
              .map((k) => ({
                label: options[k],
                value: k,
              }))
              .map((o) => (
                <WrapItem key={o.value} width={['100%', '45%', '45%', '30%']}>
                  <Radio name={fieldName} value={o.value}>
                    {o.label}
                  </Radio>
                </WrapItem>
              ))}
          </Wrap>
        </RadioGroupControl>
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export default SelectCheckboxActivite;
