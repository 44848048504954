import { Text, UnorderedList, ListItem, Box } from '@chakra-ui/react';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import VideoPlayer from 'components/general/VideoPlayer';

const ActiviteProfTip = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        La vidéo regroupe les témoignages d’anciens lycéens de bac pros
        racontant des anecdotes sur leur stage et leur relation avec les
        collègues ou la hiérarchie.
      </Text>
    </ProfTips>
  </>
);

export const CommentFaireDeSaPassionUnMetier = () => {
  return (
    <>
      <ActiviteProfTip />
      <ReflexionOralHeader />
      <Text fontWeight={'bold'}>
        Regarde cette vidéo puis réponds oralement aux questions.
      </Text>
      <Box margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/Vid+04+Parcours+Scolaire.mp4'}
          light={'/img/activites/parcours-inspirants/thumb_metier_passion.png'}
        />
      </Box>
      <UnorderedList>
        <ListItem>Quel bac pro a choisi Furkan et pourquoi ?</ListItem>
        <ListItem>
          Quel a été le critère le plus important lors de son recrutement ?
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const CommentFaireDeSonParcoursUneForce = () => {
  return (
    <>
      <ActiviteProfTip />
      <ReflexionOralHeader />
      <Text fontWeight={'bold'}>
        Regarde cette vidéo puis réponds oralement aux questions.
      </Text>
      <Box margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/1.+Parcours+Une+Force-1.mp4'}
          light={'/img/activites/parcours-inspirants/thumb_parcours_force.png'}
        />
      </Box>
      <UnorderedList>
        <ListItem>
          Qu’est ce qui a motivé les témoins à poursuivre dans des formations
          qu’ils n’avaient pas forcément choisies au départ ?
        </ListItem>
        <ListItem>
          Cite quelques points forts qui ont permis aux témoins de faire de leur
          parcours une force.
        </ListItem>
        <ListItem>
          Quels conseils peux-tu donner aux témoins pour améliorer leurs points
          faibles ?
        </ListItem>
      </UnorderedList>
    </>
  );
};

export const CommentTrouverSaVoie = () => {
  return (
    <>
      <ActiviteProfTip />
      <ReflexionOralHeader />
      <Text fontWeight={'bold'}>
        Regarde cette vidéo puis réponds oralement aux questions.
      </Text>
      <Box margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/Vid+03+Parcours+Pro.mp4'}
          light={
            '/img/activites/parcours-inspirants/thumb_comment_trouver_sa_voie.png'
          }
        />
      </Box>
      <UnorderedList>
        <ListItem>
          Sur quels critères s’est basée Alexandra pour accepter les choix de
          formation proposés ?
        </ListItem>
        <ListItem>
          D’après toi, a-t-elle pu faire le lien entre ses centres d'intérêts et
          son métier ?
        </ListItem>
        <ListItem>Que peux-tu dire de son parcours général ?</ListItem>
      </UnorderedList>
    </>
  );
};
