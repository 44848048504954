import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import ReactCardFlip from 'react-card-flip';
import Panel from '@inspire/ui/general/Panel';

import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ConsigneTag from 'components/activites/ConsigneTag';
import VideoPlayer from 'components/general/VideoPlayer';
import Image from 'next/image';
import principalesEtapesRechercheEmploiSrc from '../../../../public/img/activites/ma-recherche-emploi/principales-etapes-recherche-emploi.png';

export const TemoignageRechercheEmploi = () => (
  <>
    <ProfTips my={8}>
      <Text>Vous avez plusieurs possibilités pour lancer la vidéo :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance la vidéo depuis la plateforme sur ordinateur ou sur
          mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        Cette vidéo présente des témoignages d’anciens élèves de bac
        professionnel qui parlent de leur recherche d’emploi et de leur choix de
        métier.
      </Text>
    </ProfTips>

    <Text fontStyle="italic">
      <ConsigneTag /> : Regarde la vidéo en étant attentif aux témoignages de
      ces anciens élèves de bac pro et à la manière dont ils ont mené leur
      recherche d’emploi. Tu pourras ensuite répondre aux questions à l’oral.
    </Text>

    <UnorderedList>
      <ListItem>
        Comment Océane et Kévin ont trouvé ce qu’ils voulaient faire comme
        métier ?
      </ListItem>
      <ListItem>Comment ont-ils fait leurs recherches d’emplois ?</ListItem>
      <ListItem>Dans quels domaines travaillent Océane et Kévin ?</ListItem>
      <ListItem>Sur quel poste Kévin se voit-il évoluer ?</ListItem>
      <ListItem>
        Quels sont les conseils de Kévin et Océane pour la recherche d’emploi ?
      </ListItem>
    </UnorderedList>

    <Box margin="auto">
      <VideoPlayer
        url={'/videos-bacpro/kevin-bac-pro-commerce.mp4'}
        light="/thumbnail_recherche%20emploi.png"
      />
    </Box>
  </>
);

export const PrincipalesEtapesRechercheEmploi = () => (
  <>
    <Text fontStyle="italic">
      <ConsigneTag /> : Prends connaissance de cette infographie puis réponds
      aux questions à l’oral après avoir fait tes recherches sur internet.
    </Text>
    <Box textAlign={'center'} mt={10}>
      <Image
        src={principalesEtapesRechercheEmploiSrc}
        alt="Les principales étapes de la recherche d’emploi"
      />
    </Box>
    <Box mt={10}>
      <UnorderedList>
        <ListItem>
          Cite un ou deux sites permettant de trouver des informations sur des
          métiers ?
        </ListItem>
        <ListItem>
          Cite un site qui te permet de trouver les domaines et les métiers qui
          recrutent en fonction d’une région ?
        </ListItem>
        <ListItem>
          Cite un ou deux sites d’offres d’emploi (jobbards) ?
        </ListItem>
        <ListItem>
          Quelles sont les informations à mettre sur ton tableau de suivi de
          candidatures ?
        </ListItem>
      </UnorderedList>
    </Box>

    <ProfTips mt={10}>
      <Text>
        <b>Site permettant de trouver des informations sur des métiers :</b>{' '}
        Onisep
      </Text>
      <Text>
        <b>
          Site permettant de trouver les domaines et métiers qui recrutent en
          fonction d’une région:
        </b>
        <br />
        La météo de l’emploi via Pôle emploi
      </Text>
      <Text>
        <b>Exemples de jobbards:</b>
        <br /> Pôle Emploi, Indeed, Monster, France Emploi, Keljob, Hellowork,
        Météo Job, Direct Emploi, Le bon coin, Viva Street, jobteaser...
      </Text>
      <Text as="b">
        Les informations à mettre sur un tableau de suivi de candidatures:
      </Text>
      <UnorderedList>
        <ListItem>Date d’envoi de la candidature</ListItem>
        <ListItem>Intitulé de poste</ListItem>
        <ListItem>Entreprise</ListItem>
        <ListItem>Lien vers l’offre (URL) ou adresse de l’entreprise</ListItem>
        <ListItem>Réponse reçue O/N + date</ListItem>
        <ListItem>
          Date de relance + méthode (mail, téléphone, courrier)
        </ListItem>
        <ListItem>Date d’entretien</ListItem>
        <ListItem>Nom du contact + mail + n° de téléphone</ListItem>
      </UnorderedList>
    </ProfTips>
  </>
);

export const QuestionsEntretien = () => (
  <>
    <ReflexionOralHeader />

    <Text fontStyle="italic">
      <ConsigneTag /> : Regarde cette vidéo pour prendre connaissance des
      questions compliquées qui peuvent être posées en entretien.
    </Text>

    <ProfTips my={8}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        Après chaque vidéo, vous pouvez demander aux élèves de donner des
        éléments de réponses aux questions.
      </Text>
    </ProfTips>

    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/terminale-10-questions.mp4'} />
      <Text textAlign="center" mt={2}>
        10 questions à la suite
      </Text>
    </Box>
  </>
);

const Flashcard = ({ index, question, answer }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <Box
        as={Panel}
        onClick={() => setIsFlipped(!isFlipped)}
        p={4}
        borderRadius={8}
        // bg="yellow.400"
        _hover={{ cursor: 'pointer' }}
      >
        <Flex alignItems="center">
          <Box>
            <Box p={3} borderRadius={4} mr={4} bg="gray.100">
              <Heading size="md" m={0} lineHeight="1">
                {index}
              </Heading>
            </Box>
          </Box>
          <Text>{question}</Text>
        </Flex>
      </Box>

      <Box
        as={Panel}
        onClick={() => setIsFlipped(!isFlipped)}
        p={8}
        bg="yellow.400"
        borderRadius={8}
        _hover={{ cursor: 'pointer' }}
      >
        <Text m={0}>{answer}</Text>
      </Box>
    </ReactCardFlip>
  );
};

export const ReponsesEntretien = () => {
  return (
    <>
      <Text fontStyle="italic" textAlign="center">
        <ConsigneTag /> : Entraîne toi à répondre aux questions à l’oral comme
        si tu étais en entretien.
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={6}>
        <Flashcard
          index={1}
          question="Pouvez-vous me parler un peu de vous-même&nbsp;?"
          answer="
              Ici, parle de qui tu es (ton âge, ce que tu fais, ta formation) et
              ce que tu viens rechercher (exemple&nbsp;: l’obtention d’un stage).
            "
        />
        <Flashcard
          index={2}
          question="Selon vous, quelles sont vos faiblesses&nbsp;?"
          answer="
              Ici, présente les compétences que tu dois améliorer (exemple&nbsp;: je
              sais utiliser ce programme mais pas celui-ci, j’ai besoin de plus
              d’expérience pour bien maîtriser cet outil de saisie ou encore je
              suis rigoureux mais j’ai encore besoin de travailler mon
              organisation). Il faut présenter les exemples comme des points à
              améliorer plutôt que comme des choses qu’on ne sait pas faire.
            "
        />
        <Flashcard
          index={3}
          question="Selon vous, quels sont vos points forts&nbsp;?"
          answer="
              Ici, mets en valeur tes compétences techniques (exemple&nbsp;: je sais
              utiliser ce programme informatique, je sais faire un inventaire)
              et tes compétences transversales en citant des exemples précis où
              tu en as fait preuve (exemple&nbsp;: je suis un leader car j’ai été
              capitaine de mon équipe de basket pendant 4 ans).
            "
        />
        <Flashcard
          index={4}
          question="Que pouvez-vous me dire sur l'entreprise&nbsp;? Pourquoi nous avoir choisi&nbsp;?"
          answer="
              Ici, montre que tu as fait des recherches sur l’entreprise et son
              activité, et parle de quelque chose qui a retenu ton attention et
              qui du coup, te motive pour la rejoindre (exemple&nbsp;: j’ai vu que
              vous avez lancé une campagne de recrutement pour des postes
              d’apprentis. J'ai vu que votre entreprise s’engage beaucoup dans
              le développement durable ce qui correspond à mes valeurs).
            "
        />
        <Flashcard
          index={5}
          question="Qu’est-ce qui vous distingue des autres candidats&nbsp;?"
          answer="
              Ici, tu dois montrer ce que tu peux apporter (exemple : je peux
              vous apporter ma rigueur et ma motivation). Pour te mettre en
              valeur, n’hésite pas à reprendre des expériences d’anciens stages
              ou tes compétences transversales (exemple&nbsp;: j’ai pu le démontrer
              au cours d’un stage lorsqu’on m’a confié la mission de créer un
              listing client seul. J’ai pu le démontrer lorsque je me suis
              engagé en tant que bénévole dans l'association X qui fait de
              l’aide à la personne.)
            "
        />
        <Flashcard
          index={6}
          question="Qu’est-ce qui vous motive&nbsp;?"
          answer="
              Ici, mets en valeur ce qui te motive par rapport au poste (exemple&nbsp;: 
              j’ai vu que le poste implique de faire telle activité et cela
              m’intéresse fortement) ou dans l’entreprise (exemple&nbsp;: j’ai vu que
              dans votre entreprise vous proposez des formations qualifiantes
              pour évoluer professionnellement et cela me motive fortement).
            "
        />
        <Flashcard
          index={7}
          question="Où vous voyez-vous dans trois ans&nbsp;?"
          answer="
              Ici, parle de ta projection (exemple&nbsp;: je souhaite déjà pouvoir
              travailler, acquérir de l’expérience et pouvoir évoluer
              personnellement et professionnellement. Je souhaite monter en
              compétences et devenir un meilleur professionnel.)
            "
        />
        <Flashcard
          index={8}
          question="Etes-vous un bon joueur d’équipe&nbsp;?"
          answer="
              Ici, utilise un exemple dans ta vie professionnelle ou personnelle
              qui puisse le mettre en valeur (exemple&nbsp;: durant mon stage je
              travaillais dans une équipe de 5 personnes et nous devions tous
              réaliser la même mission, donc tout le monde faisait l’effort et
              je restais un peu au-delà de mon horaire de stage pour qu’on
              puisse terminer à temps. Je pratique le football en compétition
              depuis plusieurs années et donc le collectif est une notion qui
              fait sens pour moi.)
            "
        />
        <Flashcard
          index={9}
          question="En quoi consiste ce métier / poste pour lequel vous postulez&nbsp;?"
          answer="
              Ici, tu dois avoir fait une analyse précise de l’offre pour
              laquelle tu as postulé et montrer que tu comprends ce qu’on attend
              de toi (exemple : pour ce poste vous attendez quelqu’un qui
              travaille en autonomie car il est dit que le candidat doit faire
              la prise de commande, l’emballage et l’envoi des commandes tout
              seul).
            "
        />
        <Flashcard
          index={10}
          question="Souhaiteriez-vous poser des questions&nbsp;?"
          answer="
              Il est fondamental d’avoir des questions à poser. Les recruteurs
              attendent de toi que tu les interroges, sans cela ils peuvent
              penser que tu n’as pas compris ou que tu n’es pas suffisamment
              intéressé par le poste (exemple&nbsp;: j’aimerai savoir comment se
              passe l’évolution du poste&nbsp;? J'aimerai savoir quelle est la taille
              de l’équipe à laquelle je serai rattaché&nbsp;? J’aimerai savoir si je
              serai accompagné ou formé sur ce type d’outils que je ne connais
              pas encore mais que j’aspire à maîtriser&nbsp;?).
            "
        />
      </SimpleGrid>
    </>
  );
};

export const SimulationEntretien = () => (
  <>
    <ReflexionOralHeader />
    <Text fontStyle="italic" textAlign="center">
      <ConsigneTag /> : Entraîne-toi à répondre aux questions à l’oral comme si
      tu étais en entretien.
    </Text>
    <ProfTips mt={8}>
      <Text>
        Maintenant que les élèves ont travaillé leur argumentaire, vous pouvez
        proposer une simulation d'entretien à un élève en reprenant les 10
        questions de la professionnelle des Ressources Humaines.
      </Text>
    </ProfTips>
    <OrderedList spacing={2} mt={8}>
      <ListItem>Pouvez-vous me parler un peu de vous-même&nbsp;?</ListItem>

      <ListItem>Selon vous, quelles sont vos faiblesses&nbsp;?</ListItem>

      <ListItem>Selon vous, quels sont vos points forts&nbsp;?</ListItem>

      <ListItem>
        Que pouvez-vous me dire sur l'entreprise&nbsp;? Pourquoi nous avoir
        choisi&nbsp;?
      </ListItem>

      <ListItem>
        Qu’est-ce qui vous distingue des autres candidats&nbsp;?
      </ListItem>

      <ListItem>Qu’est-ce qui vous motive&nbsp;?</ListItem>

      <ListItem>Où vous voyez-vous dans trois ans&nbsp;?</ListItem>

      <ListItem>Etes-vous un bon joueur d’équipe&nbsp;?</ListItem>

      <ListItem>
        En quoi consiste ce métier / poste pour lequel vous postulez&nbsp;?
      </ListItem>

      <ListItem>Souhaiteriez-vous poser des questions&nbsp;?</ListItem>
    </OrderedList>
  </>
);

export const ConseilsEntretien = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
    </ProfTips>

    <Text fontStyle="italic" textAlign="center" mb={6}>
      <ConsigneTag /> : Regarde cette vidéo d’une professionnelle du recrutement
      qui présente quelques conseils à suivre durant les entretiens.
    </Text>

    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/terminale-top-5-conseils.mp4'} />
      <Text textAlign="center" mt={2}>
        Le top 5 des conseils
      </Text>
    </Box>
  </>
);
