var Domaines = {
    agriculture_agro_animaux: "Agriculture - Agro-alimentaire - Animaux",
    construction_archi: "Architecture - BTP",
    arts_artisanat: "Arts - Artisanat - Design",
    banque_assurance: "Banque - Assurance - Immobilier",
    chimie_svt: "Chimie - SVT",
    commerce_marketing: "Commerce - Marketing",
    gestion: "Compta - Gestion - RH",
    histoire: "Culture - Histoire",
    armee: "D\xe9fense - S\xe9curit\xe9",
    droit: "Droit",
    economie: "Economie",
    environnement: "Environnement - Energies",
    geo_psycho_socio: "G\xe9ographie - Psychologie - Sociologie",
    hotellerie_restau_tourisme: "Hotellerie - Restauration - Tourisme",
    industrie: "Industrie - Mat\xe9riaux",
    info_communication: "Information - Comm - Multimedia",
    informatique: "Informatique",
    lettres_langues: "Lettres - Langues",
    logistique: "Logistique - Transport",
    math_physique: "Maths - Physique",
    sante_social: "Sant\xe9 - Social",
    sport: "Sport"
};
// Legacy / Old Domains
var DomainesWithLegacyDomainesIncluded = Object.assign({}, Domaines, {
    eco_droit: "Economie - Droit",
    elec_robo: "Electronique - Robotique",
    histoire_geo: "Histoire - G\xe9ographie",
    mecanique: "M\xe9canique",
    sciences: "Sciences",
    hotellerie: "Hotellerie"
});
export { Domaines, DomainesWithLegacyDomainesIncluded };
