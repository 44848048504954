export var SoftSkillsOptions = {
    comEcrite: "Communication \xe9crite",
    comOrale: "Communication orale",
    comVisuelle: "Communication visuelle",
    comNonVerbale: "Communication non verbale",
    comNumerique: "Communication num\xe9rique",
    confianceEnSoi: "Confiance en soi",
    responsabilite: "Responsabilit\xe9",
    autonomie: "Autonomie",
    motivationImplication: "Motivation et implication",
    travailEquipe: "Travail en \xe9quipe",
    gestionStress: "Gestion du stress",
    organisation: "Organisation",
    strategie: "Strat\xe9gie",
    priceDecision: "Prise de d\xe9cisions",
    gestionTemps: "Gestion du temps",
    curiosite: "Curiosit\xe9",
    imagination: "Imagination",
    ecoute: "\xc9coute",
    perseverance: "Pers\xe9v\xe9rance"
};
export var SoftSkillsDifficultesOptions = {
    organisation: "Manque d’organisation",
    dissipe: "\xcatre dissip\xe9",
    assiduite: "Assiduit\xe9",
    communication: "Manque de communication avec les autres / avec mon environnement",
    ecoute: "Ne pas \xe9couter",
    retard: "Retard",
    demander: "Ne pas oser demander",
    travailler: "Ne pas assez travailler",
    preparation: "Ne pas \xeatre assez bien pr\xe9par\xe9(e)",
    comprendre: "Ne pas oser dire qu’on ne sait pas, qu’on ne comprend pas",
    relire: "Ne pas relire ses cours",
    motivation: "Ne pas \xeatre motiv\xe9(e)"
};
export var SoftSkillsRessentiOptions = {
    joie: "Joie",
    fierte: "Fiert\xe9",
    deception: "D\xe9ception",
    enthousiasme: "Enthousiasme",
    regainConfiance: "Regain de confiance en soi",
    perteConfiance: "Perte de confiance en soi",
    colere: "Col\xe8re",
    espoir: "Espoir",
    peur: "Peur",
    surprise: "Surprise",
    tristesse: "Tristesse",
    sympathie: "Sympathie"
};
export var ActiviteOptions = {
    sportive: "Activit\xe9 sportive",
    jeuxVideos: "Jeux vid\xe9os",
    artistique: "Activit\xe9 artistique / culturelle",
    petitsBoulots: "Petits boulots",
    associatif: "Engagement associatif",
    famille: "Aide famille",
    organisation: "Organisation d’une soir\xe9e, d’un anniversaire, d’une sortie",
    recherche: "Expos\xe9, travail de recherche",
    langue: "Apprentissage d’une langue"
};
