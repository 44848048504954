import { Box, Stack, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FormControl } from 'formik-chakra-ui';

import { MatieresLycee } from '@inspire/data/static/static';
import { FormFrame, FormStack } from '@inspire/ui/chakra/forms/Form';

import SmileyRadioRange from 'components/forms/SmileyRadioRange';
import GradeControl from 'components/forms/controls/GradeControl';

// Technically it's not just 1 Control, there are multiple controls

const MatieresControl = ({ matieresForBacPro }) => {
  const { values, errors, touched } = useFormikContext<{
    matieres: { grade: number; like: number }[];
  }>();

  return (
    <FormStack>
      {matieresForBacPro?.map((m, i) => (
        <FormFrame
          key={m}
          legend={
            <Box fontWeight="bold" fontSize="lg">
              {MatieresLycee[m]}
            </Box>
          }
          stackProps={{ spacing: 2 }}
        >
          <FormControl
            // label="Apprécies-tu cette matière ?"
            // labelProps={{ mb: 1, fontWeight: 'normal !important' }}
            name={`matieres[${i}].like`}
            // isRequired
            data-test={m}
          >
            <SmileyRadioRange
              name={`matieres[${i}].like`}
              value={values.matieres?.[i]?.like}
              lowLabel="Je n'aime pas"
              highLabel="J'adore !"
            />
          </FormControl>
          <Stack
            maxW="400px"
            w="full"
            align="center"
            direction={['column', 'row']}
          >
            <Box>Ta moyenne</Box>
            <GradeControl
              labelProps={{ fontWeight: 'normal !important' }}
              name={`matieres[${i}].grade`}
              flex={1}
              pt={1.5}
            />
          </Stack>
          {touched?.matieres?.[i] && errors?.matieres?.[i] && (
            <Text fontSize="sm" color="red.500">
              Tu dois sélectionner un smiley et donner ta moyenne pour cette
              matière.
            </Text>
          )}
        </FormFrame>
      ))}
    </FormStack>
  );
};
export default MatieresControl;
