import { Box, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';
import { TextareaControl } from 'formik-chakra-ui';
import { useMount } from 'react-use';

import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';

import VideoPlayer from 'components/general/VideoPlayer';
import { useMyProfile } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { CheckboxGame } from 'components/activites/iterative-games';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import { RecapTextArea } from 'components/activites/recap-helpers';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';

export const VideoRechercheDeStage = () => (
  <>
    <Text textAlign={'center'}>
      Regarde la vidéo pour découvrir comment faire une recherche de stage sur
      Internet.
    </Text>
    <VideoPlayer url="/videos-bacpro/recherche-stage.mp4" />
  </>
);

const stageFields = [
  {
    key: 'stageDuree',
    label: 'Quelle est la durée de ton stage ?',
  },
  {
    key: 'stageSecteurActivite',
    label: "Dans quel secteur d'activité cherches-tu ton stage ?",
  },
  {
    key: 'stageMetier',
    label: 'Quel métier as-tu envie de découvrir ?',
  },
  {
    key: 'stageGeographie',
    label: 'Quelle zone géographique cibles-tu ?',
  },
  {
    key: 'stageMissions',
    label: "Quelles sont les activités ou missions qui t'intéressent ?",
  },
  {
    key: 'stageTypeEntreprise',
    label:
      'Dans quel type d’entreprise envisages-tu de faire ce stage (grosse entreprise, PME, etc.) ?',
  },
  {
    key: 'stageMotsCles',
    label:
      'Quels mots-clés pourrais-tu taper dans un moteur de recherche pour trouver une offre de stage (nom du secteur d’activité, ville, etc.) ?',
  },
  {
    key: 'stageSite',
    label: 'Quels sont les sites où tu peux trouver des offres de stage ?',
  },
];

export const QuestionsRechercheDeStage = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    ...stageFields.map((f) => f.key)
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <>
      <Text mb={6} textAlign={'center'}>
        Pour préparer ta recherche de stage, tu dois répondre à ces questions :
      </Text>
      <Formik {...formik}>
        <Form>
          <VStack spacing={6}>
            {stageFields.map((f, i) => (
              <TextareaControl
                key={i}
                label={f.label}
                name={f.key}
                isRequired
              />
            ))}
          </VStack>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

export const PreparerRechercheDeStage = () => {
  return (
    <>
      <ProfTips>
        <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
        <Text>
          Utiliser un vidéoprojecteur pour une diffusion collective. <br />
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </Text>
      </ProfTips>
      <ReflexionOralHeader />
      <Text fontWeight={'bold'}>
        Pour te préparer dans ta recherche de stage, écoute les conseils donnés
        dans ce témoignage, puis réponds oralement aux questions.
      </Text>
      <Box margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/Vid+03+Parcours+Pro.mp4'}
          light={
            '/img/activites/parcours-inspirants/thumb_comment_trouver_sa_voie.png'
          }
        />
      </Box>
      <UnorderedList>
        <ListItem>
          Cite un ou deux conseils donnés par Alexandra Pignot concernant la
          recherche de stage.
        </ListItem>
        <ListItem>Comment Alexandra a-t-elle trouvé son stage ?</ListItem>
        <ListItem>
          Avait-elle déjà travaillé avant d’intégrer le monde du travail après
          son Bac pro ?
        </ListItem>
        <ListItem>Que lui a apporté cette expérience ?</ListItem>
      </UnorderedList>
    </>
  );
};

export const QCMRechercheDeStage = ({ allowNext }) => {
  useMount(() => allowNext(false));

  const questions = [
    {
      subject: 'Je trouve une offre qui me plait, que faire ?',
      answerValues: ['4', '5', '6'],
      options: [
        {
          shortName:
            'J’attends, je ne voudrais pas qu’on pense que je suis désespéré',
          value: '1',
        },
        {
          shortName: "J’envoie seulement un mail pour dire que ça m'intéresse",
          value: '2',
        },
        { shortName: 'J’envoie seulement mon CV', value: '3' },
        {
          shortName: 'J’appelle l’entreprise pour demander des renseignements',
          value: '4',
        },
        {
          shortName:
            'J’envoie mon CV et une lettre de motivation personnalisée',
          value: '5',
        },
        {
          shortName: 'Je me présente directement dans l’entreprise',
          value: '6',
        },
      ],
    },
    {
      subject: 'Qu’est-ce qu’une candidature spontanée ?',
      answerValues: ['3'],
      options: [
        {
          shortName: 'C’est une candidature à une offre de stage',
          value: '1',
        },
        {
          shortName:
            'C’est lorsque la qualité de “spontanéité” est indiquée dans mon CV',
          value: '2',
        },
        {
          shortName:
            'C’est lorsque je postule directement dans une entreprise sans répondre à une offre de stage',
          value: '3',
        },
      ],
    },
  ];
  return (
    <CheckboxGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'} mb={8}>
          Pour préparer ta recherche de stage, tu dois répondre à ces questions
          :
        </Text>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question?.subject}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={5}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.subject}>
                  <b>{q.subject} :</b>{' '}
                  {q.answerValues
                    .map(
                      (av) => q.options.find((o) => o.value === av).shortName
                    )
                    .join(', ')}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const RecapRechercheDeStage = ({ profile }) => (
  <>
    {stageFields.map((f, i) => (
      <RecapTextArea
        key={i}
        profile={profile}
        fieldName={f.key}
        header={f.label}
      />
    ))}
  </>
);
