import { useEffect, useRef, useState } from 'react';

import { Input, ListItem, Link, Text, UnorderedList } from '@chakra-ui/react';
import { useMyProfile } from 'lib/hooks';
import { Formik, Form, useField, FormikConfig, FormikValues } from 'formik';
import { InputControl, TextareaControl } from 'formik-chakra-ui';

import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import Span from '@inspire/ui/chakra/Span';
import { FormFrame } from '@inspire/ui/chakra/forms/Form';

import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ConsigneTag from 'components/activites/ConsigneTag';
import { cdnURL } from '@inspire/util/cdn';

export const AnalyseOffre = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema =
    LyceenBacProProfileSchema.pick('analyseOffreEmploi').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      analyseOffreEmploi:
        myProfile?.analyseOffreEmploi?.length === 3
          ? myProfile?.analyseOffreEmploi
          : ['', '', ''],
    },
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
    enableReinitialize: true,
  };

  return (
    <>
      <ProfTips my={8}>
        <Text mb={0}>
          Les élèves vont devoir identifier leurs compétences en lien avec une
          offre d’emploi pour compléter une lettre de motivation à trous. Pour
          cette activité, vous pouvez proposer une offre à la classe ou chaque
          élève peut choisir une offre de son choix. Puis, demandez aux élèves
          de citer les compétences techniques et transversales demandées dans
          cette offre.
        </Text>
        <Text mb={0}>
          Vous trouverez également ici un exemple d’offre d’emploi :{' '}
          <Link
            isExternal
            href={cdnURL(
              '/Offre_d_emploi_Agent_administratif_et_d_accueil.pdf'
            )}
          >
            Offre d'emploi Agent administratif et d'accueil
          </Link>
        </Text>
      </ProfTips>
      <Text fontStyle="italic">
        <ConsigneTag /> : Choisi une offre d'emploi, lis la en détail puis cite
        les compétences demandées pour le poste proposé.
      </Text>
      <Text fontStyle="italic">
        Tu trouveras également ici un exemple d’offre d’emploi :{' '}
        <Link
          isExternal
          href={cdnURL('/Offre_d_emploi_Agent_administratif_et_d_accueil.pdf')}
        >
          Offre d'emploi Agent administratif et d'accueil
        </Link>
      </Text>

      <Formik {...formik}>
        <Form>
          <InputControl
            name="analyseOffreEmploi.0"
            label="Compétence 1"
            mb={4}
            isRequired
          />
          <InputControl
            name="analyseOffreEmploi.1"
            label="Compétence 2"
            mb={4}
            isRequired
          />
          <InputControl
            name="analyseOffreEmploi.2"
            label="Compétence 3"
            mb={4}
            isRequired
          />
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

export const StageEnEntreprise = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'stageEntrepriseActivite'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
    enableReinitialize: true,
  };

  return (
    <Formik {...formik}>
      <Form>
        <TextareaControl
          name="stageEntrepriseActivite"
          label={`Liste deux ou trois activités que tu as réalisées durant tes
            stages, ainsi que les compétences techniques que tu as utilisées et qui
            sont en lien avec l’offre d’emploi.`}
          textareaProps={{
            placeholder:
              'Ex: J’ai utilisé un logiciel du type Excel pour créer des bases de données, un logiciel de comptabilité pour le calcul des salaires, ou un logiciel de présentation du type PowerPoint.',
          }}
          isRequired
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const SoftSkills = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'stageEntrepriseSoftSkills'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
    enableReinitialize: true,
  };

  return (
    <Formik {...formik}>
      <Form>
        <TextareaControl
          name="stageEntrepriseSoftSkills"
          label={`Liste deux ou trois soft skills que tu as identifiées et les expériences dans lesquelles tu les as utilisées en lien avec l’offre d’emploi.`}
          textareaProps={{
            placeholder:
              'Ex: Je suis persévérant car je pratique un sport en compétition depuis plusieurs années.',
          }}
          isRequired
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

const AutoWidthInput = ({ name, placeholder }) => {
  const inputRef = useRef<HTMLInputElement>();
  const [field] = useField(name);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(inputRef?.current?.offsetWidth);
  }, [inputRef.current, field.value]);
  return (
    <Span display="inline-block">
      <Span pos="absolute" visibility="hidden" ref={inputRef}>
        {field.value || placeholder}
      </Span>
      <Input
        {...field}
        name={name}
        border="none"
        variant="flushed"
        placeholder={placeholder}
        color="blue.500"
        width={`${width}px`}
        height="1.6rem"
        isRequired
      />
    </Span>
  );
};

export const LettreDeMotivATrous = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });
  const letterKeys = [
    'interlocuteur',
    'site',
    'poste1',
    'poste2',
    'poste3',
    'diplome',
    'spe',
    'entreprise',
    'secteur',
    'stage',
    'taches',
    'nombre',
    'competences',
    'realisation',
    'activite',
    'cadreActivite',
    'softSkills1',
    'softSkills2',
  ];

  const initialValues = letterKeys.reduce(
    (res, q) => ({
      ...res,
      [q]: myProfile?.lettreDeMotiv?.find((k) => k.key === q)?.value || '',
    }),
    {}
  );
  const formik: FormikConfig<FormikValues> = {
    initialValues,
    onSubmit: (values) =>
      setAndRefetchMyProfile({
        lettreDeMotiv: Object.entries(values)
          .map(([key, value]) => ({
            value: value,
            key: key,
          }))
          .filter((o) => !!o.value),
      }),
    enableReinitialize: true,
  };

  return (
    <>
      <Text fontStyle="italic">
        <ConsigneTag /> : Remplis les parties{' '}
        <Span color="gray.400">en gris</Span> dans la lettre de motivation
        suivante avec tes expériences de stage et tes soft skills.
      </Text>
      <Formik {...formik}>
        <Form>
          <FormFrame lineHeight="2">
            <Text>
              Madame, Monsieur{' '}
              <AutoWidthInput
                name="interlocuteur"
                placeholder="[nom de l’interlocuteur s’il est donné dans l’offre]"
              />
              .
            </Text>

            <Text>
              C'est avec un vif intérêt que j'ai remarqué votre annonce parue
              sur le site{' '}
              <AutoWidthInput
                name="site"
                placeholder="[nom du site ou autre support où a été vue l'annonce]"
              />
              . En effet, le poste de{' '}
              <AutoWidthInput
                placeholder="[intitulé du poste que vous aimeriez avoir]"
                name="poste1"
              />{' '}
              que vous proposez correspond en tout point à ma recherche.
              Réellement motivé à l'idée de rejoindre votre entreprise, je vous
              fais parvenir ma candidature.
            </Text>
            <Text>
              Je suis actuellement en train de passer mon diplôme de{' '}
              <AutoWidthInput placeholder="[votre diplôme]" name="diplome" />{' '}
              spécialisé en{' '}
              <AutoWidthInput
                placeholder="[intitulé de votre spécialisation]"
                name="spe"
              />
              . Dans ce cadre, j'ai eu l'occasion de réaliser des stages au sein
              d'entreprises{' '}
              <AutoWidthInput
                placeholder="[nom de l'entreprise principale ou en énumérer quelques-unes]"
                name="entreprise"
              />
              . Ces premières expériences professionnelles ont été aussi
              l'occasion de faire mes preuves et de démontrer ma capacité
              d'adaptation. Travailler dans le secteur{' '}
              <AutoWidthInput
                placeholder="[secteur d'activité]"
                name="secteur"
              />{' '}
              m'a plu et m'a décidé à poursuivre dans cette voie. La renommée de
              votre entreprise et les possibilités d'évolution professionnelle
              m'intéressent. Motivé, je suis prêt à m'investir sur le poste de{' '}
              <AutoWidthInput
                placeholder="[intitulé du poste que vous aimeriez avoir]"
                name="poste2"
              />{' '}
              et relever vos défis.
            </Text>

            <Text>
              Durant mon stage, j'ai travaillé comme{' '}
              <AutoWidthInput
                placeholder="[nom du poste durant le / les stage(s)]"
                name="stage"
              />{' '}
              au sein d'une équipe composée de{' '}
              <AutoWidthInput placeholder="[nombre]" name="nombre" /> personnes.
              Mes principales tâches ont été de{' '}
              <AutoWidthInput
                placeholder="[citer la ou les tâches réalisées]"
                name="taches"
              />
              . A cette occasion j'ai pu mobiliser{' '}
              <AutoWidthInput
                placeholder="[citer la ou les compétences techniques mobilisées]"
                name="competences"
              />
              . J'ai eu la satisfaction de recevoir des retours positifs de mon
              / mes responsable(s) de stage pour{' '}
              <AutoWidthInput
                placeholder="[réalisation / mise en pratique d’un savoir technique ]"
                name="realisation"
              />
              .
            </Text>

            <Text>
              Enfin, je tiens à souligner que je suis une personne{' '}
              <AutoWidthInput placeholder="[Soft Skills]" name="softSkills1" />{' '}
              et{' '}
              <AutoWidthInput placeholder="[Soft Skills]" name="softSkills2" />,
              qualités que j’ai pu développer notamment au cours de{' '}
              <AutoWidthInput
                placeholder="[activité où vous l’avez développé]"
                name="activite"
              />{' '}
              et{' '}
              <AutoWidthInput
                placeholder="[cadre où vous l’avez développé]"
                name="cadreActivite"
              />
              . Je pense que ces qualités me seront utiles pour satisfaire à vos
              exigences et rapidement opérationnel.
            </Text>
            <Text>
              En tant que jeune diplômé, je souhaiterais mettre à votre profit
              mes compétences et mon professionnalisme. Rejoindre votre société
              serait pour moi la possibilité de travailler dans un secteur
              d’activité qui me plaît et dans lequel je souhaite me spécialiser.
              J’espère avoir le plaisir de vous rencontrer et de vous exprimer
              de vive voix mes motivations pour ce poste de{' '}
              <AutoWidthInput
                placeholder="[intitulé du poste souhaité]"
                name="poste3"
              />
              . En attendant, je reste à votre disposition pour toute demande
              complémentaire.
            </Text>

            <Text>
              Je vous remercie par avance pour l’attention accordée à ma
              candidature et je vous prie d’accepter, Madame, Monsieur{' '}
              <AutoWidthInput
                placeholder="[nom de l’interlocuteur
                s’il est donné dans l’offre]"
                name="interlocuteur"
              />
              , mes meilleures salutations.
            </Text>
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

export const LectureDuResultat = () => (
  <>
    <ReflexionOralHeader />
    <ProfTips mt={8}>
      <Text mb={0}>
        Choisissez deux ou trois élèves pour lire leurs lettres.
      </Text>
    </ProfTips>

    <UnorderedList>
      <ListItem>
        Est-ce que les compétences techniques sont bien valorisées ?
      </ListItem>
      <ListItem>Est-ce qu’elles sont en rapport avec le poste ?</ListItem>
      <ListItem>
        Est-ce que les compétences transversales (soft skills) sont bien
        valorisées ?
      </ListItem>
      <ListItem>Est-ce qu’elles sont en rapport avec le poste ?</ListItem>
    </UnorderedList>
    <ProfTips mt={8}>
      <Text mb={0}>
        Information professeur : ATTENTION il est important de rappeler aux
        élèves de ne pas faire un copier-coller de cette lettre pour l’utiliser
        dans une réponse à une offre d’emploi car cela se verrait. Chaque lettre
        de motivation doit être adaptée à une offre.
      </Text>
    </ProfTips>
  </>
);
