import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { pick, flatten, without } from "lodash";
import { MatieresLycee } from "./matieres";
export var BacsProIndustriels = {
    "aeronautique-option-avionique": "A\xe9ronautique option avionique",
    "aeronautique-option-systeme": "A\xe9ronautique option syst\xe8me",
    "aeronautique-option-structure": "A\xe9ronautique option structure",
    "amenagement-et-finition-du-batiment": "Am\xe9nagement et finition du b\xe2timent",
    "aviation-generale": "Aviation g\xe9n\xe9rale",
    "conducteur-transport-routier-marchandises": "Conducteur transport routier marchandises",
    "construction-des-carrosseries": "Construction des carrosseries",
    "cybersecurite-informatique-et-reseaux-electronique": "Cybers\xe9curit\xe9, informatique et r\xe9seaux, \xe9lectronique (ex-Syst\xe8mes num\xe9riques)",
    "etude-et-realisation-dagencement": "Etude et r\xe9alisation d'agencement",
    "faconnage-de-produits-imprimes-routage": "Fa\xe7onnage de produits imprim\xe9s, routage",
    fonderie: "Fonderie",
    "gestion-des-pollutions-et-protection-de-lenvironnement": "Gestion des pollutions et protection de l'environnement",
    "hygiene-proprete-et-sterilisation": "Hygi\xe8ne, propret\xe9 et st\xe9rilisation",
    "installateur-en-chauffage-climatisation-energies-renouvelables": "Installateur en chauffage, climatisation et \xe9nergies renouvelables",
    "interventions-sur-le-patrimoine-bati-A-maconnerie": "Interventions sur le patrimoine b\xe2ti option A ma\xe7onnerie",
    "interventions-sur-le-patrimoine-bati-B-charpente": "Interventions sur le patrimoine b\xe2ti option B charpente",
    "interventions-sur-le-patrimoine-bati-C-couverture": "Interventions sur le patrimoine b\xe2ti option C couverture",
    logistique: "Logistique",
    "maintenance-des-materiels-A-materiels-agricoles": "Maintenance des mat\xe9riels option A mat\xe9riels agricoles",
    "maintenance-des-materiels-B-materiels-de-construction-et-manutention": "Maintenance des mat\xe9riels option B mat\xe9riels de construction et manutention",
    "maintenance-des-materiels-C-materiels-despaces-verts": "Maintenance des mat\xe9riels option C mat\xe9riels d’espaces verts",
    "maintenance-des-vehicules-A-voitures-particulieres": "Maintenance des v\xe9hicules option A voitures particuli\xe8res",
    "maintenance-des-vehicules-B-vehicules-de-transport-routier": "Maintenance des v\xe9hicules option B v\xe9hicules de transport routier",
    "maintenance-des-vehicules-C-motocycles": "Maintenance des v\xe9hicules option C motocycles",
    "maintenance-des-systemes-de-production-connectes": "Maintenance des syst\xe8mes de production connect\xe9s",
    "maintenance-et-efficacite-energetiques": "Maintenance et efficacit\xe9 \xe9nerg\xe9tique",
    "maintenance-nautique": "Maintenance nautique",
    "menuiserie-aluminium-verre": "Menuiserie Aluminium-Verre",
    "metiers-de-lelectricite-et-de-ses-environnements-connectes": "M\xe9tiers de l’\xe9lectricit\xe9 et de ses environnements connect\xe9s",
    "metiers-du-froid-et-des-energies-renouvelables": "M\xe9tiers du froid et des \xe9nergies renouvelables",
    microtechniques: "Microtechniques",
    "modelisation-prototypage-3D": "Mod\xe9lisation et prototypage 3D",
    transport: "Organisation de transport de marchandises",
    "ouvrage-du-batiment-metallerie": "Ouvrage du b\xe2timent : m\xe9tallerie",
    "pilote-de-ligne-de-production": "Pilote de ligne de production",
    "plastiques-et-composites": "Plastiques et composites",
    "procedes-de-la-chimie-de-leau-et-des-papiers-cartons": "Proc\xe9d\xe9s de la chimie, de l'eau et des papiers-cartons",
    "production-en-industries-pharmaceutiques": "Production en industries pharmaceutiques, alimentaires et cosm\xe9tiques (ex-Bio-industries de transformation)",
    "realisation-de-produits-imprimes-et-plurimedia-A-productions-graphiques": "R\xe9alisation de produits imprim\xe9s et plurim\xe9dia option A productions graphiques",
    "realisation-de-produits-imprimes-et-plurimedia-B-productions-imprimees": "R\xe9alisation de produits imprim\xe9s et plurim\xe9dia option B productions imprim\xe9es",
    "reparation-des-carrosseries": "R\xe9paration des carrosseries",
    "technicien-en-appareillage-orthopedique": "Technicien en appareillage orthop\xe9dique",
    "technicien-en-prothese-dentaire": "Technicien en proth\xe8se dentaire",
    "technicien-en-realisation-de-produits-m\xe9caniques-option-realisation-et-suivi-de-productions": "Technicien en r\xe9alisation de produits m\xe9caniques option r\xe9alisation et suivi de productions",
    "technicien-en-realisation-de-produits-mecaniques-option-realisation-et-maintenance-des-outillages": "Technicien en r\xe9alisation de produits m\xe9caniques option r\xe9alisation et maintenance des outillages",
    "technicien-constructeur-bois": "Technicien constructeur bois",
    "technicien-de-fabrication-bois-et-materiaux-associes": "Technicien de fabrication bois et mat\xe9riaux associes",
    "technicien-detudes-du-batiment-A-etudes-et-economie": "Technicien d’\xe9tudes du b\xe2timent option A \xe9tudes et \xe9conomie",
    "technicien-detudes-du-batiment-B-assistant-en-architecture": "Technicien d’\xe9tudes du b\xe2timent option B assistant en architecture",
    "technicien-de-scierie": "Technicien de scierie",
    "technicien-realisation-produits-mecaniques-option-realisation-maintenance-outillages": "Technicien en r\xe9alisation de produits m\xe9caniques option r\xe9alisation et maintenance des outillages",
    "techniques-dinterventions-sur-installations-nucleaires": "Techniques d'interventions sur installations nucl\xe9aires",
    "traitements-des-materiaux": "Traitements des mat\xe9riaux",
    "technicien-du-batiment-organisation-et-realisation-du-gros-oeuvre": "Technicien du b\xe2timent : organisation et r\xe9alisation du gros oeuvre",
    "technicien-en-chaudronnerie-industrielle": "Technicien en chaudronnerie industrielle",
    "technicien-gaz": "Technicien gaz",
    "technicien-geometre-topographe": "Technicien g\xe9om\xe8tre topographe",
    "technicien-menuisier-agenceur": "Technicien menuisier agenceur",
    "technicien-modeleur": "Technicien modeleur",
    "transport-fluvial": "Transport fluvial",
    "transports-cables-remontees-mecaniques": "Transports par c\xe2bles et remont\xe9es m\xe9caniques",
    "travaux-publics": "Travaux publics"
};
export var BacsProTertiaireCommercial = {
    assistance_gestion_organisations: "Assistance \xe0 la gestion des organisations et de leurs activit\xe9s",
    "boucher-charcutier-traiteur": "Boucher charcutier traiteur",
    "boulanger-patissier": "Boulanger - p\xe2tissier",
    "commercialisation-et-services-en-restauration": "Commercialisation et services en restauration",
    cuisine: "Cuisine",
    "esthetique-cosmetique-parfumerie": "Esth\xe9tique cosm\xe9tique parfumerie",
    commerce_option_A: "M\xe9tiers du commerce et de la vente option A animation et gestion de l'espace commercial",
    commerce_option_B: "M\xe9tiers du commerce et de la vente option B prospection client\xe8le et valorisation de l'offre commerciale",
    accueil: "M\xe9tiers de l'accueil",
    metiers_coiffure: "M\xe9tiers de la coiffure",
    "optique-lunetterie": "Optique lunetterie",
    "optique-photonique-technologies-lumiere": "Optique photonique : technologies de la lumi\xe8re",
    "poissonnier-ecailler-traiteur": "Poissonnier \xe9cailler traiteur"
};
export var BacsProArtisanats = {
    "artisanat-metiers-dart-a-organier": "Artisanat et m\xe9tiers d’art - facteur d’orgues option : organier",
    "artisanat-metiers-dart-a-tuyautier": "Artisanat et m\xe9tiers d’art - facteur d’orgues option : tuyautier",
    "artisanat-metiers-dart-communication-visuelle-pluri-media": "Artisanat et m\xe9tiers d’art option : communication visuelle pluri-media",
    "artisanat-metiers-dart-marchandisage-visuel": "Artisanat et m\xe9tiers d’art option : marchandisage visuel",
    "artisanat-metiers-dart-metiers-enseigne-signaletique": "Artisanat et m\xe9tiers d’art option : m\xe9tiers de l’enseigne et de la signal\xe9tique",
    "artisanat-metiers-dart-tapisserie-dameublement": "Artisanat et m\xe9tiers d’art option : tapisserie d’ameublement",
    "artisanat-metiers-dart-verrerie-scientifique-technique": "Artisanat et m\xe9tiers d’art option : verrerie scientifique et technique",
    "metiers-de-la-mode-vetements": "M\xe9tiers de la mode - v\xeatements",
    "metiers-du-cuir-chaussures": "M\xe9tiers du cuir option chaussures",
    "metiers-du-cuir-maroquinerie": "M\xe9tiers du cuir option maroquinerie",
    "metiers-du-cuir-sellerie-garnissage": "M\xe9tiers du cuir option sellerie garnissage",
    "metiers-et-arts-de-la-pierre": "M\xe9tiers et arts de la pierre",
    "perruquier-posticheur": "Perruquier posticheur",
    photographie: "Photographie"
};
export var BacsProTertiaireServices = {
    "accompagnement-soins-services": "Accompagnement soins et services \xe0 la personne",
    "animation-enfance-personnes-agees": "Animation-Enfance et personnes \xe2g\xe9es",
    "metiers-entretien-textiles-A-blanchisserie": "M\xe9tiers de l’entretien des textiles option A blanchisserie",
    "metiers-entretien-textiles-B-pressing": "M\xe9tiers de l’entretien des textiles option B pressing",
    "metiers-de-la-securite": "M\xe9tiers de la s\xe9curit\xe9"
};
export var BacsProAgricoles = {
    agroequipement: "Agro\xe9quipement",
    "amenagements-paysagers": "Am\xe9nagements paysagers",
    "conduite-et-gestion-de-lentreprise-agricole": "Conduite et gestion de l’entreprise agricole",
    "conduite-et-gestion-de-lentreprise-vitivinicole": "Conduite et gestion de l’entreprise vitivinicole",
    "conduite-et-gestion-da-une-entreprise-du-secteur-canin-et-felin": "Conduite et gestion d’une entreprise du secteur canin et f\xe9lin",
    "conduite-et-gestion-de-lentreprise-hippique": "Conduite et gestion de l'entreprise hippique",
    "conduite-et-gestion-des-entreprises-maritimes-commerce-plaisance-professionnelle": "Conduite et gestion des entreprises maritimes commerce/plaisance professionnelle, option voile, option yacht",
    "conduite-et-gestion-des-entreprises-maritimes-peche": "Conduite et gestion des entreprises maritimes p\xeache",
    "conduite-de-productions-aquacoles": "Conduite de productions aquacoles",
    "conduite-de-productions-horticoles": "Conduite de productions horticoles",
    "cultures-marines": "Cultures marines",
    "electromecanicien-de-marine": "\xc9lectrom\xe9canicien de marine",
    foret: "For\xeat",
    "gestion-des-milieux-naturels-et-de-la-faune": "Gestion des milieux naturels et de la faune",
    "gestion-des-pollutions-et-protection-de-lenvironnement": "Gestion des pollutions et protection de l'environnement",
    "laboratoire-controle-qualite": "Laboratoire contr\xf4le Qualit\xe9",
    "polyvalent-navigant-pont-machine": "Polyvalent navigant pont/machine",
    "service-aux-personnes-et-aux-territoires": "Services aux personnes et animation dans les territoires",
    "technicien-conseil-vente-en-animalerie": "Technicien conseil vente en Animalerie",
    "technicien-conseil-vente-en-alimentation": "Technicien conseil vente en Alimentation",
    "technicien-en-experimentation-animale": "Technicien en exp\xe9rimentation animale",
    "technicien-conseil-vente-univers-jardinerie": "Technicien conseil vente univers jardinerie"
};
export var BacsPro = _object_spread({}, BacsProArtisanats, BacsProIndustriels, BacsProTertiaireServices, BacsProTertiaireCommercial, BacsProAgricoles);
export var BacsProTypes = {
    BPindustriel: "Bac pro Industriel",
    BPcommercial: "Bac pro Commercial",
    BPservice: "Bac pro Services",
    BPart: "Bac pro Artisanat",
    BPagricole: "Bac pro Agricole"
};
export function getFiliereForBacPro(bacPro) {
    if (Object.keys(BacsProIndustriels).includes(bacPro)) {
        return "BP_industriel";
    }
    if (Object.keys(BacsProTertiaireCommercial).includes(bacPro)) {
        return "BP_tertiaire_commercial";
    }
    if (Object.keys(BacsProTertiaireServices).includes(bacPro)) {
        return "BP_tertiaire_services";
    }
    if (Object.keys(BacsProArtisanats).includes(bacPro)) {
        return "BP_artisanat";
    }
    if (Object.keys(BacsProAgricoles).includes(bacPro)) {
        return "BP_agricole";
    }
    return null;
}
// warning: keys differentes sur INSPIRE GT ("Première", "Terminale")
// à voir si on harmonise
export var Niveaux = {
    seconde: "Seconde",
    premiere: "Premi\xe8re",
    terminale: "Terminale"
};
export var Diplomes = {
    brevet: "Brevet des coll\xe8ges",
    cfg: "CFG",
    bep: "BEP",
    cap: "CAP"
};
export var MatieresTroncCommunBP = pick(MatieresLycee, [
    "eco_droit",
    "mathematiques",
    "francais",
    "lv1",
    "lv2",
    "histoire_geo",
    "eps",
    "arts_plastiques",
    "prevention_sante_environnement",
    "unites_facultatives", 
]);
export var SpesForBacPro = function(filiereBacPro) {
    if (filiereBacPro === "assistance_gestion_organisations") {
        return pick(MatieresLycee, [
            "gestion_relation_client",
            "etude_situations_organisation_production",
            "administration_personnel", 
        ]);
    }
    if (filiereBacPro === "logistique") {
        return pick(MatieresLycee, [
            "etude_situations_pros",
            "pratique_logistique_milieu_pro",
            "conduite_engins_manutention", 
        ]);
    }
    if (filiereBacPro === "transport") {
        return pick(MatieresLycee, [
            "prepa_operation_transport",
            "mise_en_oeuvre_suivi_transport",
            "amelioration_transport", 
        ]);
    }
    if (filiereBacPro === "commerce_option_A") {
        return pick(MatieresLycee, [
            "vente_conseil",
            "suivi_ventes",
            "fideliser_clientele",
            "animer_gerer_espace_comercial", 
        ]);
    }
    if (filiereBacPro === "commerce_option_B") {
        return pick(MatieresLycee, [
            "vente_conseil",
            "suivi_ventes",
            "fideliser_clientele",
            "prospecter_valoriser_offre_commerciale", 
        ]);
    }
    if (filiereBacPro === "accueil") {
        return pick(MatieresLycee, [
            "analyse_situation_pro_relation_commerciale",
            "gestion_accueil_multicanal",
            "gestion_information_preventions", 
        ]);
    }
};
export var FamillesMetiers = {
    aeronautique: "M\xe9tiers de l'a\xe9ronautique",
    agencement_menuiserie_ameublement: "M\xe9tiers de l’agencement, de la menuiserie et de l’ameublement",
    alimentation: "M\xe9tiers de l’alimentation",
    alimentation_bio: "M\xe9tiers de l'alimentation-bio-industrie-laboratoire",
    hotellerie_restauration: "M\xe9tiers de l’h\xf4tellerie et restauration",
    beaute_bien_etre: "M\xe9tiers de la beaut\xe9 et du bien-\xeatre",
    construction_durable: "M\xe9tiers de la construction durable, du batiment et des travaux publics",
    ga_transport_logistique: "M\xe9tiers de la gestion administrative, du transport, de la logistique",
    maintenance_materiels_et_vehicules: "M\xe9tiers de la maintenance des mat\xe9riels et des v\xe9hicules",
    mer: "M\xe9tiers de la mer",
    nature_jardin_paysage: "M\xe9tiers de la nature - jardin - paysage - for\xeat",
    ensembles_mecaniques_industriels: "M\xe9tiers de la r\xe9alisation d'ensembles m\xe9caniques et industriels",
    relation_client: "M\xe9tiers de la relation client",
    etudes_modelistation_du_batiment: "M\xe9tiers des \xe9tudes et de la mod\xe9lisation num\xe9rique du batiment",
    industries_graphiques_et_communication: "M\xe9tiers des industries graphiques et de la communication",
    productions: "M\xe9tiers des productions",
    transition_numerique_et_energetique: "M\xe9tiers des transitions num\xe9rique et \xe9nerg\xe9tique",
    conseil_vente: "M\xe9tiers du conseil vente",
    installations_automatisees: "M\xe9tiers du pilotage et de la maintenance d’installations automatis\xe9es",
    hors_famille: "Sp\xe9cialit\xe9s hors familles de m\xe9tiers",
    hors_famille_agricole: "Sp\xe9cialit\xe9s hors familles de m\xe9tiers (enseignement agricole)"
};
// export const BACS_PROS_GATL_RC = [
export var BACS_PROS_GATL_RC = [
    "relation_client",
    "ga_transport_logistique"
];
export var BACS_PROS_AGRO = [
    "alimentation_bio",
    "nature_jardin_paysage",
    "productions",
    "conseil_vente",
    "hors_famille_agricole", 
];
var FAMILLES_TO_FILIERES = {
    aeronautique: [
        "aeronautique-option-avionique",
        "aeronautique-option-systeme",
        "aeronautique-option-structure",
        "aviation-generale", 
    ],
    agencement_menuiserie_ameublement: [
        "etude-et-realisation-dagencement",
        "technicien-de-fabrication-bois-et-materiaux-associes",
        "technicien-menuisier-agenceur", 
    ],
    alimentation: [
        "boucher-charcutier-traiteur",
        "boulanger-patissier",
        "poissonnier-ecailler-traiteur", 
    ],
    alimentation_bio: [
        "laboratoire-controle-qualite",
        "production-en-industries-pharmaceutiques", 
    ],
    hotellerie_restauration: [
        "commercialisation-et-services-en-restauration",
        "cuisine", 
    ],
    beaute_bien_etre: [
        "esthetique-cosmetique-parfumerie",
        "metiers_coiffure"
    ],
    construction_durable: [
        "amenagement-et-finition-du-batiment",
        "interventions-sur-le-patrimoine-bati-A-maconnerie",
        "interventions-sur-le-patrimoine-bati-B-charpente",
        "interventions-sur-le-patrimoine-bati-C-couverture",
        "menuiserie-aluminium-verre",
        "ouvrage-du-batiment-metallerie",
        "technicien-du-batiment-organisation-et-realisation-du-gros-oeuvre",
        "travaux-publics", 
    ],
    ga_transport_logistique: [
        "assistance_gestion_organisations",
        "logistique",
        "transport", 
    ],
    maintenance_materiels_et_vehicules: [
        "maintenance-des-materiels-A-materiels-agricoles",
        "maintenance-des-materiels-B-materiels-de-construction-et-manutention",
        "maintenance-des-materiels-C-materiels-despaces-verts",
        "maintenance-des-vehicules-A-voitures-particulieres",
        "maintenance-des-vehicules-B-vehicules-de-transport-routier",
        "maintenance-des-vehicules-C-motocycles", 
    ],
    mer: [
        "conduite-et-gestion-des-entreprises-maritimes-commerce-plaisance-professionnelle",
        "conduite-et-gestion-des-entreprises-maritimes-peche",
        "electromecanicien-de-marine",
        "polyvalent-navigant-pont-machine", 
    ],
    nature_jardin_paysage: [
        "amenagements-paysagers",
        "foret",
        "gestion-des-milieux-naturels-et-de-la-faune", 
    ],
    ensembles_mecaniques_industriels: [
        "fonderie",
        "microtechniques",
        "technicien-en-chaudronnerie-industrielle",
        "technicien-en-realisation-de-produits-m\xe9caniques-option-realisation-et-suivi-de-productions",
        "technicien-en-realisation-de-produits-mecaniques-option-realisation-et-maintenance-des-outillages",
        "technicien-modeleur",
        "traitements-des-materiaux", 
    ],
    relation_client: [
        "accueil",
        "commerce_option_A",
        "commerce_option_B"
    ],
    etudes_modelistation_du_batiment: [
        "technicien-detudes-du-batiment-A-etudes-et-economie",
        "technicien-detudes-du-batiment-B-assistant-en-architecture",
        "technicien-geometre-topographe", 
    ],
    industries_graphiques_et_communication: [
        "faconnage-de-produits-imprimes-routage",
        "realisation-de-produits-imprimes-et-plurimedia-A-productions-graphiques",
        "realisation-de-produits-imprimes-et-plurimedia-B-productions-imprimees", 
    ],
    productions: [
        "agroequipement",
        "conduite-et-gestion-de-lentreprise-agricole",
        "conduite-et-gestion-de-lentreprise-vitivinicole",
        "conduite-et-gestion-da-une-entreprise-du-secteur-canin-et-felin",
        "conduite-et-gestion-de-lentreprise-hippique",
        "conduite-de-productions-aquacoles",
        "conduite-de-productions-horticoles", 
    ],
    transition_numerique_et_energetique: [
        "cybersecurite-informatique-et-reseaux-electronique",
        "installateur-en-chauffage-climatisation-energies-renouvelables",
        "maintenance-et-efficacite-energetiques",
        "metiers-de-lelectricite-et-de-ses-environnements-connectes",
        "metiers-du-froid-et-des-energies-renouvelables", 
    ],
    conseil_vente: [
        "technicien-conseil-vente-en-animalerie",
        "technicien-conseil-vente-en-alimentation",
        "technicien-conseil-vente-univers-jardinerie", 
    ],
    installations_automatisees: [
        "maintenance-des-systemes-de-production-connectes",
        "pilote-de-ligne-de-production",
        "procedes-de-la-chimie-de-leau-et-des-papiers-cartons",
        "technicien-de-scierie", 
    ],
    hors_famille: [
        "accompagnement-soins-services",
        "animation-enfance-personnes-agees",
        "artisanat-metiers-dart-a-organier",
        "artisanat-metiers-dart-a-tuyautier",
        "artisanat-metiers-dart-communication-visuelle-pluri-media",
        "artisanat-metiers-dart-marchandisage-visuel",
        "artisanat-metiers-dart-metiers-enseigne-signaletique",
        "artisanat-metiers-dart-tapisserie-dameublement",
        "artisanat-metiers-dart-verrerie-scientifique-technique",
        "conducteur-transport-routier-marchandises",
        "construction-des-carrosseries",
        "cultures-marines",
        "gestion-des-pollutions-et-protection-de-lenvironnement",
        "hygiene-proprete-et-sterilisation",
        "maintenance-nautique",
        "metiers-de-la-mode-vetements",
        "metiers-de-la-securite",
        "metiers-du-cuir-chaussures",
        "metiers-du-cuir-maroquinerie",
        "metiers-du-cuir-sellerie-garnissage",
        "metiers-entretien-textiles-A-blanchisserie",
        "metiers-entretien-textiles-B-pressing",
        "metiers-et-arts-de-la-pierre",
        "modelisation-prototypage-3D",
        "optique-lunetterie",
        "optique-photonique-technologies-lumiere",
        "perruquier-posticheur",
        "photographie",
        "plastiques-et-composites",
        "production-en-industries-pharmaceutiques",
        "reparation-des-carrosseries",
        "technicien-constructeur-bois",
        "technicien-en-appareillage-orthopedique",
        "technicien-en-prothese-dentaire",
        "technicien-gaz",
        "techniques-dinterventions-sur-installations-nucleaires",
        "transport-fluvial",
        "transports-cables-remontees-mecaniques", 
    ],
    hors_famille_agricole: [
        "service-aux-personnes-et-aux-territoires",
        "technicien-en-experimentation-animale", 
    ]
};
var FILIERES_WITH_FAMILLE = flatten(Object.keys(FAMILLES_TO_FILIERES).map(function(k) {
    return FAMILLES_TO_FILIERES[k];
}));
export var FilieresForFamille = function(famille) {
    if (famille in FAMILLES_TO_FILIERES) {
        return FAMILLES_TO_FILIERES[famille];
    }
    return without(Object.keys(BacsPro), FILIERES_WITH_FAMILLE);
};
export var FamilleFromFiliere = function(filiere) {
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = Object.entries(FAMILLES_TO_FILIERES)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var famille = _step.value;
            if (famille[1].find(function(filiereInfamille) {
                return filiere === filiereInfamille;
            })) {
                return FamillesMetiers[famille[0]];
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return "";
};
export var FamilleFromKey = function(key) {
    return FamillesMetiers[key];
};
