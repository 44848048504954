import { Box, Text, SimpleGrid, Alert, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ClickableImage from 'components/general/ClickableImage';
import Image from 'next/image';
import Span from '@inspire/ui/chakra/Span';
import InfographieVoiePro from '../../../../../public/img/infographies/infographie-voie-pro.png';
import { useMyProfile, useUser } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { FormikConfig, FormikValues, Form, Formik } from 'formik';

const FormationRouteOptions = {
  carrefour:
    "Le carrefour, j'ai l'impression d'avoir plusieurs choix possibles.",
  autoroute: "L'autoroute du bonheur, j'ai l'impression de savoir où je vais.",
  labyrinthe: "Le labyrinthe, j'ai l'impression de devoir chercher mon chemin.",
};

export const SiTaFormationEtaitUneRoute = ({ goNext }) => {
  const { isProf } = useUser();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  let initialAllowNext = false;
  let initialCarrefour = true;
  let initialAutouroute = true;
  let initialLabyrinthe = true;
  if (myProfile.formationRoute) {
    const initialFormationRoute = myProfile.formationRoute;
    if (initialFormationRoute === 'carrefour') {
      initialAutouroute = false;
      initialLabyrinthe = false;
    } else if (initialFormationRoute === 'autoroute') {
      initialCarrefour = false;
      initialLabyrinthe = false;
    } else if (initialFormationRoute === 'labyrinthe') {
      initialCarrefour = false;
      initialAutouroute = false;
    }
    initialAllowNext = true;
  }
  const initialValues = {
    formationRoute: myProfile?.formationRoute ?? 'none',
  };

  const [_carrefour, setCarrefour] = useState(initialCarrefour);
  const [_autoroute, setAutoroute] = useState(initialAutouroute);
  const [_labyrinthe, setLabyrinthe] = useState(initialLabyrinthe);
  const [allowNext, setAllowNext] = useState(initialAllowNext);

  useEffect(() => {
    if (!(_carrefour && _autoroute && _labyrinthe)) {
      setAllowNext(true);
    }
  }, [_carrefour, _autoroute, _labyrinthe]);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    onSubmit: (values) => {
      if (_carrefour) values.formationRoute = 'carrefour';
      else if (_autoroute) values.formationRoute = 'autoroute';
      else if (_labyrinthe) values.formationRoute = 'labyrinthe';
      setAndRefetchMyProfile(values);
    },
  };

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <Text>
            <b>
              Choisis le GIF qui correspond le plus à ta vision de ta formation
              :{' '}
            </b>
            <Text as="span" color="red.500" fontWeight="bold">
              *
            </Text>
            <br></br>

            <i>Il n'y a pas de bonne réponse, il s'agit de ton ressenti.</i>
          </Text>
          <br />

          <SimpleGrid columns={[1, null, 3]} spacing={10}>
            <Box textAlign={'center'}>
              <Image
                src="/img/gif/carrefour.gif"
                width="300px"
                height="300px"
                alt="carrefour"
                onClick={() => {
                  setCarrefour(true);
                  setAutoroute(false);
                  setLabyrinthe(false);
                }}
                style={{
                  filter: _carrefour ? 'none' : 'grayscale(100%)',
                }}
              />
              <Text
                textAlign={'center'}
                style={
                  _carrefour && !_autoroute && !_labyrinthe
                    ? { fontWeight: 'bold' }
                    : { fontWeight: 'normal' }
                }
              >
                {FormationRouteOptions.carrefour}
              </Text>
            </Box>
            {window.innerWidth < 760 &&
              _carrefour &&
              !(_autoroute && _labyrinthe) && (
                <Alert mb={5}>
                  <Span>
                    Top ! En effet, un parcours ne ressemble pas forcément à une
                    ligne droite. Plusieurs formations peuvent te permettre
                    d’atteindre un même but, renseigne-toi pour savoir celle qui
                    te conviendra le mieux. Tu peux avoir plusieurs projets en
                    tête, dans ce cas concentre-toi d’abord sur celui qui
                    correspond le plus à tes qualités.
                  </Span>
                </Alert>
              )}

            <Box textAlign={'center'}>
              <Image
                src="/img/gif/autoroute.gif"
                width="300px"
                height="300px"
                alt="autoroute"
                onClick={() => {
                  setCarrefour(false);
                  setAutoroute(true);
                  setLabyrinthe(false);
                }}
                style={{
                  filter: _autoroute ? 'none' : 'grayscale(100%)',
                }}
              />
              <Text
                textAlign={'center'}
                style={
                  _autoroute && !_carrefour && !_labyrinthe
                    ? { fontWeight: 'bold' }
                    : { fontWeight: 'normal' }
                }
              >
                {FormationRouteOptions.autoroute}
              </Text>
            </Box>
            {window.innerWidth < 760 &&
              _autoroute &&
              !(_carrefour && _labyrinthe) && (
                <Alert mb={5}>
                  <Span>
                    Cool, tu as les idées au clair et apparemment tu as trouvé
                    ta voie ! Maintenant, trouve des conseils dans notre site
                    pour te préparer à la vie étudiante ou professionnelle. On
                    te conseille de parler à des étudiants et des professionnels
                    du secteur qui t’intéressent : ils auront plein de bons
                    conseils pour te faciliter la vie 😉
                  </Span>
                </Alert>
              )}

            <Box textAlign={'center'}>
              <Image
                src="/img/gif/labirynthe.gif"
                width="300px"
                height="300px"
                alt="labyrinthe"
                onClick={() => {
                  setCarrefour(false);
                  setAutoroute(false);
                  setLabyrinthe(true);
                }}
                style={{
                  filter: _labyrinthe ? 'none' : 'grayscale(100%)',
                }}
              />
              <Text
                textAlign={'center'}
                style={
                  _labyrinthe && !_carrefour && !_autoroute
                    ? { fontWeight: 'bold' }
                    : { fontWeight: 'normal' }
                }
              >
                {FormationRouteOptions.labyrinthe}
              </Text>
            </Box>
            {window.innerWidth < 760 &&
              _labyrinthe &&
              !(_carrefour && _autoroute) && (
                <Alert mb={5}>
                  <Span>
                    Tu as l’impression de chercher ton chemin ? Pas de panique,
                    tu n’es pas seul(e). Tu peux te rapprocher de ton
                    professeur, du ou de la PsyEn de ton établissement pour
                    t’accompagner dans ce questionnement. Mais tu peux également
                    questionner ton entourage sur leurs parcours, rencontrer des
                    professionnels pour t’aider à trouver ta voie.
                  </Span>
                </Alert>
              )}
          </SimpleGrid>

          {window.innerWidth > 760 &&
            !(_carrefour && _autoroute && _labyrinthe) && (
              <Alert mb={5}>
                {_carrefour && (
                  <Span>
                    Top ! En effet, un parcours ne ressemble pas forcément à une
                    ligne droite. Plusieurs formations peuvent te permettre
                    d’atteindre un même but, renseigne-toi pour savoir celle qui
                    te conviendra le mieux. Tu peux avoir plusieurs projets en
                    tête, dans ce cas concentre-toi d’abord sur celui qui
                    correspond le plus à tes qualités.
                  </Span>
                )}

                {_autoroute && (
                  <Span>
                    Cool, tu as les idées au clair et apparemment tu as trouvé
                    ta voie ! Maintenant, trouve des conseils dans notre site
                    pour te préparer à la vie étudiante ou professionnelle. On
                    te conseille de parler à des étudiants et des professionnels
                    du secteur qui t’intéressent : ils auront plein de bons
                    conseils pour te faciliter la vie 😉
                  </Span>
                )}

                {_labyrinthe && (
                  <Span>
                    Tu as l’impression de chercher ton chemin ? Pas de panique,
                    tu n’es pas seul(e). Tu peux te rapprocher de ton
                    professeur, du ou de la PsyEn de ton établissement pour
                    t’accompagner dans ce questionnement. Mais tu peux également
                    questionner ton entourage sur leurs parcours, rencontrer des
                    professionnels pour t’aider à trouver ta voie.{' '}
                  </Span>
                )}
              </Alert>
            )}
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const AvantagesVoieProfessionnelle = () => (
  <>
    <Text textAlign="center">
      Pour te conforter dans ta vision de ta formation ou pour te permettre d’y
      voir plus clair, regarde cette infographie sur les points forts de la voie
      Pro’ et cite à l’oral un ou deux avantages.
    </Text>
    <br />

    <Box textAlign="center">
      <ClickableImage
        src={InfographieVoiePro}
        href="/img/infographies/infographie-voie-pro.png"
        alt="Infographie Voie Pro"
      />
    </Box>
  </>
);

export const RecapJeFaisLePoint = ({ profile }) => {
  const formationRoute = profile.formationRoute;

  return (
    <>
      {profile && formationRoute ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Si ta formation était une route ...'}
          </Heading>
          <Text>{FormationRouteOptions[formationRoute]}</Text>
        </Box>
      ) : null}
    </>
  );
};
