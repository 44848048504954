import SimpleSchema from "simpl-schema";
import { map, fromPairs, includes, extend, get, set, cloneDeep, mapValues, keyBy, isUndefined, isNull } from "lodash";
SimpleSchema.extendOptions([
    "autoform"
]);
export function markSchemaFieldsAsRequired(fields, except) {
    var exceptList = except || [];
    return fromPairs(map(fields, function(v, k) {
        return [
            k,
            extend(v, {
                optional: !!includes(exceptList, k)
            }), 
        ];
    }));
}
export function markSchemaFieldsAsOptional(fields, except) {
    var exceptList = except || [];
    return fromPairs(map(fields, function(v, k) {
        return [
            k,
            extend(v, {
                optional: !includes(exceptList, k)
            }), 
        ];
    }));
}
export var FrenchPhoneRegEx = /^0[6-7]([-\/. ]?[0-9]{2}){4}$/;
export var validateSchema = function(schema, values) {
    var ctx = schema.newContext();
    ctx.validate(schema.clean(values, {
        mutate: false,
        autoConvert: true
    }));
    var ik = ctx.validationErrors().map(function(o) {
        return extend({
            message: ctx.keyErrorMessage(o.name)
        }, o);
    });
    var err = {};
    ik.forEach(function(messageError) {
        set(err, messageError.name, messageError.message);
    });
    return err;
};
export var initFormikValuesFromSchema = function(schema, data) {
    if (data) {
        var cleanData = schema.clean(data, {
            mutate: false,
            autoConvert: true
        });
        schema._schemaKeys.forEach(function(k) {
            if (isUndefined(cleanData[k]) || isNull(cleanData[k])) {
                if ([
                    "objectArray",
                    "stringArray"
                ].includes(schema.getQuickTypeForKey(k))) {
                    cleanData[k] = [];
                } else {
                    cleanData[k] = "";
                }
            } else if (schema.getQuickTypeForKey(k) === "boolean") {
                cleanData[k] = cleanData[k] ? "true" : "false";
            }
        });
        return cleanData;
    }
    return mapValues(keyBy(schema._schemaKeys), function(k) {
        return [
            "objectArray",
            "stringArray"
        ].includes(schema.getQuickTypeForKey(k)) ? [] : "";
    });
};
// convert all the [fields] in {data} to strings if they are numbers
// useful for initializing formik or radio or checkbox
export var convertNumbersToStrings = function(data, fields) {
    if (!data) {
        return null;
    }
    var clone = cloneDeep(data);
    fields === null || fields === void 0 ? void 0 : fields.forEach(function(f) {
        // dealing with an array (eg: matieres.$.like)
        // careful: doesn't work with double nested array (x.$.y.$.z)
        if (f.includes("$")) {
            var path = f.split(".");
            var arrayPath = path.slice(0, path.indexOf("$")).join(".");
            var endPath = path.slice(path.indexOf("$" + 1)).join(".");
            var array = get(data, arrayPath);
            if (!array) return;
            array.forEach(function(o, i) {
                var value = get(o, endPath);
                if (typeof value === "number") {
                    set(clone, "".concat(arrayPath, "[").concat(i, "]").concat(endPath), value.toString());
                }
            });
        } else {
            var value = get(data, f);
            if (!value) return;
            if (typeof value === "number") {
                set(clone, f, value.toString());
            }
        }
    });
    return clone;
};
