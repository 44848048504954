var FrenchRegions = [
    "Auvergne-Rh\xf4ne-Alpes",
    "Hauts-de-France",
    "Provence-Alpes-C\xf4te d'Azur",
    "Grand-Est",
    "Occitanie",
    "Normandie",
    "Nouvelle-Aquitaine",
    "Centre-Val de Loire",
    "Bourgogne-Franche-Comt\xe9",
    "Bretagne",
    "Pays-de-la-Loire",
    "Ile-de-France",
    "Corse",
    "Guadeloupe",
    "Martinique",
    "Guyane",
    "La R\xe9union",
    "Saint-Pierre-et-Miquelon",
    "Mayotte",
    "Antarctique",
    "Polyn\xe9sie",
    "Oc\xe9anie",
    "Etranger", 
];
var DepsToRegions = {
    "01": "Auvergne-Rh\xf4ne-Alpes",
    "02": "Hauts-de-France",
    "03": "Auvergne-Rh\xf4ne-Alpes",
    "04": "Provence-Alpes-C\xf4te d'Azur",
    "05": "Provence-Alpes-C\xf4te d'Azur",
    "06": "Provence-Alpes-C\xf4te d'Azur",
    "07": "Auvergne-Rh\xf4ne-Alpes",
    "08": "Grand-Est",
    "09": "Occitanie",
    10: "Grand-Est",
    11: "Occitanie",
    12: "Occitanie",
    13: "Provence-Alpes-C\xf4te d'Azur",
    14: "Normandie",
    15: "Auvergne-Rh\xf4ne-Alpes",
    16: "Nouvelle-Aquitaine",
    17: "Nouvelle-Aquitaine",
    18: "Centre-Val de Loire",
    19: "Nouvelle-Aquitaine",
    20: "Corse",
    21: "Bourgogne-Franche-Comt\xe9",
    22: "Bretagne",
    23: "Nouvelle-Aquitaine",
    24: "Nouvelle-Aquitaine",
    25: "Bourgogne-Franche-Comt\xe9",
    26: "Auvergne-Rh\xf4ne-Alpes",
    27: "Normandie",
    28: "Centre-Val de Loire",
    29: "Bretagne",
    30: "Occitanie",
    31: "Occitanie",
    32: "Occitanie",
    33: "Nouvelle-Aquitaine",
    34: "Occitanie",
    35: "Bretagne",
    36: "Centre-Val de Loire",
    37: "Centre-Val de Loire",
    38: "Auvergne-Rh\xf4ne-Alpes",
    39: "Bourgogne-Franche-Comt\xe9",
    40: "Nouvelle-Aquitaine",
    41: "Centre-Val de Loire",
    42: "Auvergne-Rh\xf4ne-Alpes",
    43: "Auvergne-Rh\xf4ne-Alpes",
    44: "Pays-de-la-Loire",
    45: "Centre-Val de Loire",
    46: "Occitanie",
    47: "Nouvelle-Aquitaine",
    48: "Occitanie",
    49: "Pays-de-la-Loire",
    50: "Normandie",
    51: "Grand-Est",
    52: "Grand-Est",
    53: "Pays-de-la-Loire",
    54: "Grand-Est",
    55: "Grand-Est",
    56: "Bretagne",
    57: "Grand-Est",
    58: "Bourgogne-Franche-Comt\xe9",
    59: "Hauts-de-France",
    60: "Hauts-de-France",
    61: "Normandie",
    62: "Hauts-de-France",
    63: "Auvergne-Rh\xf4ne-Alpes",
    64: "Nouvelle-Aquitaine",
    65: "Occitanie",
    66: "Occitanie",
    67: "Grand-Est",
    68: "Grand-Est",
    69: "Auvergne-Rh\xf4ne-Alpes",
    70: "Bourgogne-Franche-Comt\xe9",
    71: "Bourgogne-Franche-Comt\xe9",
    72: "Pays-de-la-Loire",
    73: "Auvergne-Rh\xf4ne-Alpes",
    74: "Auvergne-Rh\xf4ne-Alpes",
    75: "Ile-de-France",
    76: "Normandie",
    77: "Ile-de-France",
    78: "Ile-de-France",
    79: "Nouvelle-Aquitaine",
    80: "Hauts-de-France",
    81: "Occitanie",
    82: "Occitanie",
    83: "Provence-Alpes-C\xf4te d'Azur",
    84: "Provence-Alpes-C\xf4te d'Azur",
    85: "Pays-de-la-Loire",
    86: "Nouvelle-Aquitaine",
    87: "Nouvelle-Aquitaine",
    88: "Grand-Est",
    89: "Bourgogne-Franche-Comt\xe9",
    90: "Bourgogne-Franche-Comt\xe9",
    91: "Ile-de-France",
    92: "Ile-de-France",
    93: "Ile-de-France",
    94: "Ile-de-France",
    95: "Ile-de-France",
    "2A": "Corse",
    "2B": "Corse",
    971: "Guadeloupe",
    972: "Martinique",
    973: "Guyane",
    974: "La R\xe9union",
    975: "Saint-Pierre-et-Miquelon",
    976: "Mayotte",
    977: "Guadeloupe",
    978: "Guadeloupe",
    984: "Antarctique",
    986: "Polyn\xe9sie",
    987: "Polyn\xe9sie",
    988: "Oc\xe9anie",
    99: "Etranger"
};
export { FrenchRegions, DepsToRegions };
