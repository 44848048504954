import removeAccents from 'remove-accents';

import { ACTIVITE_STATUS_COMPLETED } from '@inspire/data/static/activites';
import { MatieresLyceeForBac } from '@inspire/data/static/static';
import { SpesForBacPro } from '@inspire/data/static/bacs-pro';

import { Matiere } from 'lib/generated';

export const calculateProgress = (
  moduleActivites = [],
  activiteStatuses = []
) => {
  const completedActivites = moduleActivites.filter(
    (ma) =>
      activiteStatuses?.find((a) => a.activiteId === ma.activiteId)?.status ===
      ACTIVITE_STATUS_COMPLETED
  );

  return completedActivites.length / moduleActivites.length;
};

export const getPercent = (value) => Math.round(value * 100);
export const formatProgress = (value) => getPercent(value) + '%';

export const lowerAscii = (value: string) => removeAccents(value.toLowerCase());

/* eslint-disable camelcase */
export function updateMatieres(
  {
    niveau,
    filiere,
    filiereBacPro,
    filiereTechno,
    spes_premiere,
    spes_terminale,
    spe_abandonnee_premiere,
  }: {
    niveau?: string;
    filiere?: string;
    filiereBacPro?: string;
    filiereTechno?: string;
    spes_premiere?: string[];
    spes_terminale?: string[];
    spe_abandonnee_premiere?: string[];
  },
  existingMatieres: Matiere[],
  newMatieres: Matiere[]
): Matiere[] {
  if (!filiere || !niveau) {
    throw new Error('Missing filiere or niveau');
  }
  const matieresList = MatieresLyceeForBac({
    filiere,
    classe: niveau,
    filiereBacPro,
    filiereTechno,
  })
    .concat(
      niveau === 'Première'
        ? spes_premiere
        : spes_terminale?.concat(spe_abandonnee_premiere)
    )
    .concat(
      filiere === 'bac_pro' && SpesForBacPro(filiereBacPro)
        ? Object.keys(SpesForBacPro(filiereBacPro))
        : []
    );
  const newMatieresList = newMatieres?.map((m) => m.matiere);
  const updatedMatieres = existingMatieres
    ? [
        ...existingMatieres.filter((m) => !newMatieresList.includes(m.matiere)),
        ...newMatieres,
      ]
    : newMatieres;
  return updatedMatieres.filter((m) => matieresList.includes(m.matiere));
}

//github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
export const debounce = (
  func: (any) => any,
  wait: number,
  immediate: boolean
) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    if (immediate && !timeout) func.apply(context, args);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
};
