import { FormControlProps } from '@chakra-ui/react';

import { diplomeOptions } from '@inspire/ui/select-options';
import CheckboxGroupWithOther from '../CheckboxGroupWithOther';

const DiplomeControl = (formControlProps: FormControlProps) => (
  <CheckboxGroupWithOther
    name="diplomes"
    label="Quel diplôme as-tu obtenu ?"
    options={diplomeOptions}
    {...formControlProps}
  />
);

export default DiplomeControl;
