// Matieres for lyceens
export var MatieresLycee = {
    francais: "Fran\xe7ais",
    litterature: "Litt\xe9rature",
    histoire_geo: "Histoire-G\xe9o",
    lv1: "LV1",
    lv2: "LV2",
    litterature_en_langue_etrangere: "Litt\xe9rature en langue \xe9trang\xe8re",
    philosophie: "Philosophie",
    mathematiques: "Math\xe9matiques",
    physique_chimie: "Physique-Chimie",
    physique_chimie_maths: "Physique-Chimie-Math\xe9matiques",
    svt: "SVT",
    eat: "EAT",
    sciences_economiques_et_sociales: "Sciences \xe9conomiques et sociales",
    etude_de_gestion: "Etude de Gestion",
    economie_droit: "Economie-Droit",
    management_des_organisations: "Management des organisations",
    design_et_arts_appliques: "Design et Arts Appliqu\xe9s",
    design_et_arts_appliques_en_langue_vivante_1: "Design et arts appliqu\xe9s en langue vivante 1",
    enseignements_technologiques_et_transversaux: "Enseignements technologiques et transversaux",
    enseignements_technologiques_et_transversaux_en_lv1: "Enseignements technologiques et transversaux en LV1",
    systemes_dinformation_et_numerique: "Syst\xe8mes d'information et num\xe9rique",
    architecture_et_construction: "Architecture et construction",
    sciences_sociales_et_politiques: "Sciences sociales et politiques",
    economie_approfondie: "Economie approfondie",
    arts: "Arts",
    lv1_ou_lv2_approfondie: "LV1 ou LV2 approfondie",
    latin_grec: "Langues et Culture de l'Antiquit\xe9 (Latin, Grec)",
    lv3: "LV3",
    droits_et_enjeux_du_monde_contemporain: "Droits et grands enjeux du monde contemporain",
    arts_du_cirque: "Arts du cirque",
    informatique_et_sciences_du_numerique: "Informatique et sciences du num\xe9rique",
    economie_agronomie_et_territoires: "Economie, agronomie et territoires",
    gestion_et_finance: "Gestion et finance",
    mercatique: "Mercatique",
    systemes_dinformation_de_gestion: "Syst\xe8mes d'information de gestion",
    ressources_humaines_et_communication: "Ressources humaines et communication",
    arts_plastiques: "Arts plastiques",
    cinema_audiovisuel: "Cin\xe9ma-audiovisuel",
    histoire_arts: "Histoire des arts",
    musique: "Musique",
    theatre: "Th\xe9\xe2tre",
    danse: "Danse",
    no_specialty: "Pas de sp\xe9cialit\xe9",
    sciences: "Sciences",
    langues_cultures_antiquite: "Langues et Cultures de l'Antiquit\xe9",
    langues_vivantes: "Langues Vivantes",
    biologie_et_physiopathologie: "Biologie et physiopathologie humaines",
    sciences_physiques: "Sciences Physiques",
    st2s: "Sciences Techniques Sanitaires et Sociales",
    histoire_geo_touristique: "Histoire-G\xe9o Touristique",
    sciencesAppliquees: "Sciences appliqu\xe9es",
    techniques_accueil: "Techniques d'accueil, d'h\xe9bergement et de communication professionnelle",
    technologie_culinaire: "Technologie et m\xe9thodes culinaires",
    commercialisation: "Service de Commercialisation",
    gestion_hoteliere: "Gestion hoteli\xe8re",
    sciences_de_la_matiere: "Sciences de la Mati\xe8re",
    sciencesHumaines: "Sciences Humaines",
    sciences_du_vivant: "Sciences du Vivant",
    chimie_biochimie: "Chimie - Biochimie - Sciences du vivant",
    biotechnologie: "Biotechnologies",
    sciences_physiques_et_chimiques: "Sciences physiques et chimiques en labo",
    mesures_et_instrumentation: "Mesures et instrumentation",
    histoire_art_civilisation: "Histoire de l'art et des civilisations",
    initiation_monde_contemporain: "Initiation au monde contemporain",
    execution_instrumentale: "Ex\xe9cution instrumentale et ex\xe9cution chor\xe9graphie",
    arts_appliques_cultures_artistiques: "Arts appliqu\xe9s et cultures artistiques",
    eps: "EPS",
    prevention_sante_environnement: "Pr\xe9vention Sant\xe9 Environnement",
    eco_droit: "Economie - Droit",
    eco_gestion: "Economie - Gestion",
    histoire_geo_scpo: "Histoire-g\xe9ographie, g\xe9opolitique et sciences politiques",
    eppcs: "\xc9ducation phyiques, pratiques et culture sportives",
    // Legacy fields
    francais_oral: "Fran\xe7ais oral",
    francais_ecrit: "Fran\xe7ais \xe9crit",
    litterature_philo: "Humanit\xe9s, litt\xe9rature et philosophie",
    langues_litterature_culture_etrangere: "Langues, litt\xe9ratures et cultures \xe9trang\xe8res",
    litterature_langue_culture_antiquite: "Litt\xe9rature, langues et cultures de l'Antiquit\xe9",
    numerique_info: "Num\xe9rique et sciences informatiques",
    si: "Sciences de l'ing\xe9nieur",
    enseignements_scientifiques: "Enseignements Scientifiques",
    bio: "Biologie et \xe9cologie",
    ses: "Sciences \xe9conomiques et sociales",
    innovation_technologique_et_eco_conception: "Innovation technologique et \xe9co-conception",
    energies_et_environnement: "\xc9nergies et environnement",
    // Matieres rentrée 2019
    STMG_gestion: "Sciences de gestion et num\xe9rique",
    STMG_management: "Management",
    "STMG_droit-economie": "Droit et \xe9conomie",
    "STMG_management-gestion": "Management et Sciences de gestion",
    "ST2S_pc-sante": "Physique-Chimie pour la sant\xe9",
    "ST2S_bio-physio": "Biologie et physiopathologie humaines",
    "ST2S_sanitaire-sociale": "Sciences et techniques sanitaires et sociales",
    "ST2S_chimie-bio-physio": "Chimie, biologie et physiopathologie humaines",
    "STHR_alimentation-env": "Enseignement scientifique alimentation environnement",
    "STHR_tech-culinaire": "Sciences et technologies culinaires et des services",
    "STHR_gestion-hoteliere": "\xc9conomie – gestion h\xf4teli\xe8re",
    "STHR_alimentation-env-tech-culinaire": "Sciences et technologies culinaires et des services – Enseignement scientifique alimentation - environnement",
    "STI2D_innovation-techno": "Innovation technologique",
    "STI2D_ing-dev-durable": "Ing\xe9nerie et D\xe9veloppement durable",
    "STI2D_innovation-techno-durable": "Ing\xe9nerie, Innovation et D\xe9veloppement durable",
    "Physique-chimie-maths": "Physique-Chimie et Math\xe9matiques",
    "STL_biochimie-biologie": "Biochimie-Biologie",
    STL_biotech: "Biotechnologie ou Sciences physiques et chimiques sanitaires en laboratoire",
    "STL_biochimie-biotech": "Biochimie-Biologie-Biotechnologie ou Sciences physiques et chimiques sanitaires en laboratoire",
    "STD2A_physique-chimie": "Physique-Chimie",
    STD2A_numerique: "Outils et langages num\xe9riques",
    "STD2A_design-arts": "Design et m\xe9tiers d’art",
    "STD2A_method-design": "Analyse et m\xe9thodes en design",
    STD2A_conception: "Conception et cr\xe9ation en design et m\xe9tiers d’art",
    TMD_instrument_danse: "Instrument ou Danse",
    STAV_gestion_alimentation: "Gestion des ressources et de l'alimentation",
    STAV_territoire_societe: "Territoires et soci\xe9t\xe9s",
    STAV_technologie: "Technologies de la production agricole",
    STAV_territoire_techno: "Territoires et technologies",
    // Options terminale
    mathematiques_expertes: "Math\xe9matiques expertes",
    mathematiques_complementaires: "Math\xe9matiques compl\xe9mentaires",
    // Tronc commun BacPro
    unites_facultatives: "Unit\xe9s facultatives",
    // Spés bacPro Transport
    prepa_operation_transport: "Preparation operations de transports",
    mise_en_oeuvre_suivi_transport: "Mise en oeuvre et suivi operations transports",
    amelioration_transport: "Contribution \xe0 l'amelioration activit\xe9 transport",
    // Spés bacPro assistanceGestionOrganisations
    gestion_relation_client: "Gestion des relations avec les clients, les usagers et les adh\xe9rents",
    etude_situations_organisation_production: "Etude de situations professionnelles li\xe9es \xe0 l'organisation et au suivi de l'activit\xe9 de production",
    administration_personnel: "Administration du personnel",
    // Spés bacPro Logistique
    etude_situations_pros: "Etude de situations professionnelles",
    pratique_logistique_milieu_pro: "Pratique de la logistique en milieu professionnel",
    conduite_engins_manutention: "Conduite d’engins de Manutention",
    // Spés bacPro commerceOptionA / commerceOptionB
    vente_conseil: "Vente - Conseil",
    suivi_ventes: "Suivi des ventes",
    fideliser_clientele: "Fid\xe9liser la client\xe8le et d\xe9velopper la relation client",
    animer_gerer_espace_comercial: "Animer et g\xe9rer l’espace commercial",
    prospecter_valoriser_offre_commerciale: "Prospecter et valoriser l’offre commerciale",
    // Spés bacPro Accueil
    analyse_situation_pro_relation_commerciale: "Analyses de situations profesionnelles li\xe9es \xe0 la relation comerciale",
    gestion_accueil_multicanal: "Gestion de l'accueil multicanal",
    gestion_information_preventions: "Gestion de l'information et des pr\xe9ventions"
};
