import { ReactElement, ReactNode } from 'react';

import { useUser } from 'lib/hooks';
import { ProfTipFrame } from '@inspire/ui/chakra/layout/Frame';
import { BoxProps } from '@chakra-ui/react';

const ProfTips = ({
  children,
  isShortVersion,
  ...rest
}: {
  children: ReactElement | ReactNode;
  isShortVersion?: boolean;
} & BoxProps) => {
  const { isProf, isUserLoading } = useUser();
  return (
    !isUserLoading &&
    isProf && (
      <ProfTipFrame isShortVersion={isShortVersion} {...rest}>
        {children}
      </ProfTipFrame>
    )
  );
};

export default ProfTips;
