import { useState, ReactElement, useEffect } from 'react';

import {
  Box,
  Checkbox,
  Center,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Flex,
  Input,
} from '@chakra-ui/react';
import pluralize from 'pluralize';

import { StartStack } from '@inspire/ui/chakra/layout/Stack';

import Confetti from 'components/general/Confetti';
import { useIterator } from 'lib/hooks';

import type { StaticImageData } from 'next/image';

type Option = {
  fullName?: string;
  shortName: string | JSX.Element;
  value: string;
};

// text and subject are made mutually exclusive here

type StandaloneQuestion = {
  text: string;
  textInfo?: string;
  subject?: never;
};

interface SubjectQuestion {
  text?: never;
  textInfo?: never;
  subject: string;
}

type Question = {
  options?: Option[];
} & (StandaloneQuestion | SubjectQuestion);

type MultiAnswerQuestion = {
  answerValues: string[];
  extraInfo?: string | JSX.Element;
} & Question;

type SingleAnswerQuestion = {
  answerValue: string;
  profTips?: string;
  // eslint-disable-next-line no-undef
  image?: StaticImageData;
  imageAlt?: string;
  extraInfo?: string;
  isPlural?: boolean;
} & Question;

interface Props<Q> {
  questions: Q[];
  onFinished?: (...params: unknown[]) => unknown;
  onNext?: (...params: unknown[]) => unknown;
  getOptions?: (question?: Q, questionIndex?: number) => Option[];
  introSection?: ReactElement;
  infoSection?: ReactElement;
  getQuestionSection?: (question?: Q, questionIndex?: number) => ReactElement;
  getSummarySection?: (
    question?: Q,
    questionIndex?: number
  ) => ReactElement | ReactElement[];
  isVertical?: boolean;
  sideSection?: (question?: Q, questionIndex?: number) => ReactElement;
}

export const RadioGame = ({
  questions,
  onFinished,
  getOptions,
  introSection,
  infoSection,
  getSummarySection,
  getQuestionSection,
  isVertical,
  sideSection,
}: Props<SingleAnswerQuestion>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished });
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [result, setResult] = useState(null);

  const handleChange = (value) => {
    // If we are showing "Correct", don't allow any change
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (value === currentQuestion.answerValue) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  return (
    <Box pos="relative">
      {!isFinished && introSection}
      {infoSection}
      <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
      <Flex
        direction={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
        {!isFinished && (
          <Box flex={1} pr={[0, 0, 0, sideSection ? 4 : 0]}>
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <RadioGroup onChange={handleChange} value={currentAnswer}>
                <Stack direction={isVertical ? 'column' : 'row'} spacing={4}>
                  {getOptions(currentQuestion, currentQuestionIndex).map(
                    (o) => (
                      <Radio key={o.value} value={o.value}>
                        {o.shortName}
                      </Radio>
                    )
                  )}
                </Stack>
              </RadioGroup>
              <Box mt={3}>
                {result ? (
                  <Box color="green.500">Correct !</Box>
                ) : result === false ? (
                  <Box color="red.500">Raté !</Box>
                ) : (
                  <>&nbsp;</>
                )}
              </Box>
            </Center>
          </Box>
        )}
        {!isFinished && sideSection && (
          <Box w="full" h="full" flex={1} mb={6}>
            {sideSection(currentQuestion, currentQuestionIndex)}
          </Box>
        )}
      </Flex>
      {getSummarySection?.(currentQuestion, currentQuestionIndex)}
    </Box>
  );
};

export const CustomAgroRadioGame = ({
  questions,
  onFinished,
  getOptions,
  introSection,
  infoSection,
  getSummarySection,
  getQuestionSection,
  isVertical,
  sideSection,
}: Props<SingleAnswerQuestion>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished });
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [result, setResult] = useState(null);

  const handleChange = (value) => {
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (
      value === currentQuestion.answerValue ||
      currentQuestion.customAnswer === 'Vrai et Faux'
    ) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  return (
    <Box pos="relative">
      {introSection}
      {infoSection}
      <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
      <Flex
        direction={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
        {!isFinished && (
          <Box flex={1} pr={[0, 0, 0, sideSection ? 4 : 0]}>
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <RadioGroup onChange={handleChange} value={currentAnswer}>
                <Stack direction={isVertical ? 'column' : 'row'} spacing={4}>
                  {getOptions(currentQuestion, currentQuestionIndex).map(
                    (o) => (
                      <Radio key={o.value} value={o.value}>
                        {o.shortName}
                      </Radio>
                    )
                  )}
                </Stack>
              </RadioGroup>
              <Box mt={3}>
                {result ? (
                  <Box color="green.500">Correct !</Box>
                ) : result === false ? (
                  <Box color="red.500">Raté !</Box>
                ) : (
                  <>&nbsp;</>
                )}
              </Box>
            </Center>
          </Box>
        )}
        {!isFinished && sideSection && (
          <Box w="full" h="full" flex={1} mb={6}>
            {sideSection(currentQuestion, currentQuestionIndex)}
          </Box>
        )}
      </Flex>
      {!isFinished &&
        getSummarySection?.(currentQuestion, currentQuestionIndex)}
      {isFinished &&
        getSummarySection?.(questions[questions.length], questions.length)}
    </Box>
  );
};

export const CheckboxGame = ({
  questions,
  onFinished,
  onNext,
  getOptions,
  introSection,
  infoSection,
  getQuestionSection,
  getSummarySection,
}: Props<MultiAnswerQuestion>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished, onNext });

  const initialCheckboxValues = getOptions(
    currentQuestion,
    currentQuestionIndex
  )?.reduce((acc, option) => ({ ...acc, [option.value]: false }), {});
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues);

  const answeredCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key]
  ).length;
  const correctAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && currentQuestion?.answerValues.includes(key)
  ).length;
  const wrongAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && !currentQuestion?.answerValues.includes(key)
  ).length;

  const tooManyAnswersCount =
    answeredCount - currentQuestion?.answerValues?.length;
  const tooFewAnswersCount =
    currentQuestion?.answerValues.length - answeredCount;
  const hasRightCountOfAnswers =
    answeredCount === currentQuestion?.answerValues.length;
  const isCurrentQuestionDone =
    correctAnswerCount === currentQuestion?.answerValues.length &&
    wrongAnswerCount === 0;

  useEffect(() => {
    if (isCurrentQuestionDone) {
      setTimeout(() => {
        next();
        setCheckboxValues(initialCheckboxValues);
      }, 2000);
    }
  }, [isCurrentQuestionDone]);

  const handleChange = (e) => {
    setCheckboxValues((cvs) => ({
      ...cvs,
      [e.target.value]: e.target.checked,
    }));
  };

  return (
    <Box pos="relative">
      {!isFinished && introSection}
      {infoSection}
      <Confetti fire={isCurrentQuestionDone} extraStyles={{ marginTop: -32 }} />
      {!isFinished && (
        <Center flexDir="column" pos="relative">
          {!isFinished &&
            getQuestionSection(currentQuestion, currentQuestionIndex)}
          <StartStack spacing={4}>
            {getOptions(currentQuestion, currentQuestionIndex).map((o) => (
              <Checkbox
                key={o.value}
                value={o.value}
                isChecked={checkboxValues[o.value]}
                onChange={handleChange}
              >
                {o.shortName}
              </Checkbox>
            ))}
          </StartStack>
          {answeredCount > 0 ? (
            <Text color={isCurrentQuestionDone ? 'green' : 'red'}>
              {hasRightCountOfAnswers && (
                <>
                  {correctAnswerCount} / {currentQuestion.answerValues.length}{' '}
                  {`${pluralize('bonne', correctAnswerCount)} ${pluralize(
                    'réponse',
                    correctAnswerCount
                  )}`}
                  .
                </>
              )}
              {tooFewAnswersCount > 0 && (
                <>
                  Il manque {tooFewAnswersCount}{' '}
                  {pluralize('réponse', tooFewAnswersCount)}.
                </>
              )}
              {tooManyAnswersCount > 0 && (
                <>
                  {tooManyAnswersCount}{' '}
                  {`${pluralize('réponse', tooManyAnswersCount)} de trop`}
                </>
              )}
            </Text>
          ) : (
            <Text>&nbsp;</Text>
          )}
        </Center>
      )}
      {getSummarySection?.(currentQuestion, currentQuestionIndex)}
    </Box>
  );
};

export const InputGame = ({
  questions,
  onFinished,
  introSection,
  infoSection,
  getSummarySection,
  getQuestionSection,
}: Props<
  MultiAnswerQuestion & {
    displayAnswer: string;
  }
>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished });
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [result, setResult] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value;
    // If we are showing "Correct", don't allow any change
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (
      currentQuestion.answerValues.includes(
        value?.toLowerCase().replace('é', 'e')
      )
    ) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  return (
    <Box pos="relative">
      {!isFinished && introSection}
      {infoSection}
      <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
      {!isFinished && (
        <Center flexDir="column" pos="relative">
          {!isFinished &&
            getQuestionSection(currentQuestion, currentQuestionIndex)}
          <Input
            value={currentAnswer}
            onChange={handleChange}
            placeholder="Tape ta réponce ici..."
          />
          <Box mt={3}>
            {(result && <Box color="green.500">Correct !</Box>) || <>&nbsp;</>}
          </Box>
        </Center>
      )}
      {getSummarySection?.(currentQuestion, currentQuestionIndex)}
    </Box>
  );
};

export const MixedGame = ({
  questions,
  onFinished,
  introSection,
  infoSection,
  getSummarySection,
  getQuestionSection,
  getOptions,
  isVertical,
  sideSection,
  onNext,
}: Props<
  MultiAnswerQuestion & {
    displayAnswer: string;
  }
>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished, onNext });
  const [currentAnswer, setCurrentAnswer] = useState(undefined);
  const [result, setResult] = useState(null);

  // For checkboxes
  let initialCheckboxValues = undefined;
  if (!isFinished) {
    initialCheckboxValues = getOptions(
      currentQuestion,
      currentQuestionIndex
    )?.reduce((acc, option) => ({ ...acc, [option.value]: false }), {});
  }
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues);

  const answeredCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key]
  ).length;
  const correctAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && currentQuestion?.answerValues.includes(key)
  ).length;
  const wrongAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && !currentQuestion?.answerValues.includes(key)
  ).length;

  const tooManyAnswersCount =
    answeredCount - currentQuestion?.answerValues?.length;
  const tooFewAnswersCount =
    currentQuestion?.answerValues.length - answeredCount;
  const hasRightCountOfAnswers =
    answeredCount === currentQuestion?.answerValues.length;
  const isCurrentQuestionDone =
    correctAnswerCount === currentQuestion?.answerValues.length &&
    wrongAnswerCount === 0;

  useEffect(() => {
    if (isCurrentQuestionDone) {
      setTimeout(() => {
        next();
        setCheckboxValues(initialCheckboxValues);
      }, 2000);
    }
  }, [isCurrentQuestionDone]);

  // Handling values change for each question type
  const handleCheckboxChange = (e) => {
    setCheckboxValues((cvs) => ({
      ...cvs,
      [e.target.value]: e.target.checked,
    }));
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    console.log('e.target', e.target);
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (
      currentQuestion.answerValues.includes(
        value?.toLowerCase().replace('é', 'e')
      )
    ) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  const handleRadioChange = (value) => {
    // If we are showing "Correct", don't allow any change
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (value === currentQuestion.answerValues[0]) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  return (
    <>
      {!isFinished && introSection}
      {infoSection}
      {!isFinished && currentQuestion.inputType === 'text' && (
        <Box pos="relative">
          <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
          {!isFinished && (
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <Input
                value={currentAnswer}
                onChange={handleTextChange}
                placeholder="Tape ta réponce ici..."
              />
              <Box mt={3}>
                {(result && <Box color="green.500">Correct !</Box>) || (
                  <>&nbsp;</>
                )}
              </Box>
            </Center>
          )}
        </Box>
      )}
      {!isFinished && currentQuestion.inputType === 'checkbox' && (
        <Box pos="relative">
          <Confetti
            fire={isCurrentQuestionDone}
            extraStyles={{ marginTop: -32 }}
          />
          {!isFinished && (
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <StartStack spacing={4}>
                {getOptions(currentQuestion, currentQuestionIndex).map((o) => (
                  <Checkbox
                    key={o.value}
                    value={o.value}
                    isChecked={checkboxValues[o.value]}
                    onChange={handleCheckboxChange}
                  >
                    {o.shortName}
                  </Checkbox>
                ))}
              </StartStack>
              {answeredCount > 0 ? (
                <Text color={isCurrentQuestionDone ? 'green' : 'red'}>
                  {hasRightCountOfAnswers && (
                    <>
                      {correctAnswerCount} /{' '}
                      {currentQuestion.answerValues.length}{' '}
                      {`${pluralize('bonne', correctAnswerCount)} ${pluralize(
                        'réponse',
                        correctAnswerCount
                      )}`}
                      .
                    </>
                  )}
                  {tooFewAnswersCount > 0 && (
                    <>
                      Il manque {tooFewAnswersCount}{' '}
                      {pluralize('réponse', tooFewAnswersCount)}.
                    </>
                  )}
                  {tooManyAnswersCount > 0 && (
                    <>
                      {tooManyAnswersCount}{' '}
                      {`${pluralize('réponse', tooManyAnswersCount)} de trop`}
                    </>
                  )}
                </Text>
              ) : (
                <Text>&nbsp;</Text>
              )}
            </Center>
          )}
        </Box>
      )}
      {!isFinished && currentQuestion.inputType === 'radio' && (
        <Box pos="relative">
          <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
          <Flex
            direction={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            {!isFinished && (
              <Box flex={1} pr={[0, 0, 0, sideSection ? 4 : 0]}>
                <Center flexDir="column" pos="relative">
                  {!isFinished &&
                    getQuestionSection(currentQuestion, currentQuestionIndex)}
                  <RadioGroup
                    onChange={handleRadioChange}
                    value={currentAnswer}
                  >
                    <Stack
                      direction={isVertical ? 'column' : 'row'}
                      spacing={4}
                    >
                      {getOptions(currentQuestion, currentQuestionIndex).map(
                        (o) => (
                          <Radio key={o.value} value={o.value}>
                            {o.shortName}
                          </Radio>
                        )
                      )}
                    </Stack>
                  </RadioGroup>
                  <Box mt={3}>
                    {result ? (
                      <Box color="green.500">Correct !</Box>
                    ) : result === false ? (
                      <Box color="red.500">Raté !</Box>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </Box>
                </Center>
              </Box>
            )}
            {!isFinished && sideSection && (
              <Box w="full" h="full" flex={1} mb={6}>
                {sideSection(currentQuestion, currentQuestionIndex)}
              </Box>
            )}
          </Flex>
        </Box>
      )}
      {getSummarySection?.(currentQuestion, currentQuestionIndex)}
    </>
  );
};

export const CustomAgroMixedGame = ({
  questions,
  onFinished,
  introSection,
  infoSection,
  getSummarySection,
  getQuestionSection,
  getOptions,
  isVertical,
  sideSection,
  onNext,
}: Props<
  MultiAnswerQuestion & {
    displayAnswer: string;
  }
>) => {
  const {
    item: currentQuestion,
    index: currentQuestionIndex,
    isFinished,
    next,
  } = useIterator(questions, { onFinished, onNext });
  const [currentAnswer, setCurrentAnswer] = useState(undefined);
  const [result, setResult] = useState(null);

  // For checkboxes
  let initialCheckboxValues = undefined;
  if (!isFinished) {
    initialCheckboxValues = getOptions(
      currentQuestion,
      currentQuestionIndex
    )?.reduce((acc, option) => ({ ...acc, [option.value]: false }), {});
  }
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues);

  const answeredCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key]
  ).length;
  const correctAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && currentQuestion?.answerValues.includes(key)
  ).length;
  const wrongAnswerCount = Object.keys(checkboxValues).filter(
    (key) => checkboxValues[key] && !currentQuestion?.answerValues.includes(key)
  ).length;

  const tooManyAnswersCount =
    answeredCount - currentQuestion?.answerValues?.length;
  const tooFewAnswersCount =
    currentQuestion?.answerValues.length - answeredCount;
  const hasRightCountOfAnswers =
    answeredCount === currentQuestion?.answerValues.length;
  const isCurrentQuestionDone =
    correctAnswerCount === currentQuestion?.answerValues.length &&
    wrongAnswerCount === 0;

  useEffect(() => {
    if (isCurrentQuestionDone) {
      setTimeout(() => {
        next();
        setCheckboxValues(initialCheckboxValues);
      }, 2000);
    }
  }, [isCurrentQuestionDone]);

  // Handling values change for each question type
  const handleCheckboxChange = (e) => {
    setCheckboxValues((cvs) => ({
      ...cvs,
      [e.target.value]: e.target.checked,
    }));
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    console.log('e.target', e.target);
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (
      currentQuestion.answerValues.includes(
        value?.toLowerCase().replace('é', 'e')
      )
    ) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  const handleRadioChange = (value) => {
    // If we are showing "Correct", don't allow any change
    if (result) {
      return;
    }
    setCurrentAnswer(value);
    if (value === currentQuestion.answerValues[0]) {
      setResult(true);
      setTimeout(() => {
        next();
        setCurrentAnswer('');
        setResult(null);
      }, 2000);
    } else {
      setResult(false);
    }
  };

  return (
    <>
      {introSection}
      {infoSection}
      {!isFinished && currentQuestion.inputType === 'text' && (
        <Box pos="relative">
          <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
          {!isFinished && (
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <Input
                value={currentAnswer}
                onChange={handleTextChange}
                placeholder="Tape ta réponce ici..."
              />
              <Box mt={3}>
                {(result && <Box color="green.500">Correct !</Box>) || (
                  <>&nbsp;</>
                )}
              </Box>
            </Center>
          )}
        </Box>
      )}
      {!isFinished && currentQuestion.inputType === 'checkbox' && (
        <Box pos="relative">
          <Confetti
            fire={isCurrentQuestionDone}
            extraStyles={{ marginTop: -32 }}
          />
          {!isFinished && (
            <Center flexDir="column" pos="relative">
              {!isFinished &&
                getQuestionSection(currentQuestion, currentQuestionIndex)}
              <StartStack spacing={4}>
                {getOptions(currentQuestion, currentQuestionIndex).map((o) => (
                  <Checkbox
                    key={o.value}
                    value={o.value}
                    isChecked={checkboxValues[o.value]}
                    onChange={handleCheckboxChange}
                  >
                    {o.shortName}
                  </Checkbox>
                ))}
              </StartStack>
              {answeredCount > 0 ? (
                <Text color={isCurrentQuestionDone ? 'green' : 'red'}>
                  {hasRightCountOfAnswers && (
                    <>
                      {correctAnswerCount} /{' '}
                      {currentQuestion.answerValues.length}{' '}
                      {`${pluralize('bonne', correctAnswerCount)} ${pluralize(
                        'réponse',
                        correctAnswerCount
                      )}`}
                      .
                    </>
                  )}
                  {tooFewAnswersCount > 0 && (
                    <>
                      Il manque {tooFewAnswersCount}{' '}
                      {pluralize('réponse', tooFewAnswersCount)}.
                    </>
                  )}
                  {tooManyAnswersCount > 0 && (
                    <>
                      {tooManyAnswersCount}{' '}
                      {`${pluralize('réponse', tooManyAnswersCount)} de trop`}
                    </>
                  )}
                </Text>
              ) : (
                <Text>&nbsp;</Text>
              )}
            </Center>
          )}
        </Box>
      )}
      {!isFinished && currentQuestion.inputType === 'radio' && (
        <Box pos="relative">
          <Confetti fire={result} extraStyles={{ marginTop: -32 }} />
          <Flex
            direction={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            {!isFinished && (
              <Box flex={1} pr={[0, 0, 0, sideSection ? 4 : 0]}>
                <Center flexDir="column" pos="relative">
                  {!isFinished &&
                    getQuestionSection(currentQuestion, currentQuestionIndex)}
                  <RadioGroup
                    onChange={handleRadioChange}
                    value={currentAnswer}
                  >
                    <Stack
                      direction={isVertical ? 'column' : 'row'}
                      spacing={4}
                    >
                      {getOptions(currentQuestion, currentQuestionIndex).map(
                        (o) => (
                          <Radio key={o.value} value={o.value}>
                            {o.shortName}
                          </Radio>
                        )
                      )}
                    </Stack>
                  </RadioGroup>
                  <Box mt={3}>
                    {result ? (
                      <Box color="green.500">Correct !</Box>
                    ) : result === false ? (
                      <Box color="red.500">Raté !</Box>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </Box>
                </Center>
              </Box>
            )}
            {!isFinished && sideSection && (
              <Box w="full" h="full" flex={1} mb={6}>
                {sideSection(currentQuestion, currentQuestionIndex)}
              </Box>
            )}
          </Flex>
        </Box>
      )}
      {!isFinished &&
        getSummarySection?.(currentQuestion, currentQuestionIndex)}
      {isFinished &&
        getSummarySection?.(questions[questions.length], questions.length)}
    </>
  );
};
