import { useEffect, useState } from 'react';

import { exists } from '@v1v2/util';
import { usePrevious } from 'react-use';

import type { BannerOptions } from '@inspire/ui/chakra/layout/Banner';

import { useMemoryStore } from 'lib/store';
import { useLocalStoreItem } from 'lib/hooks/localstore';

export const useBackButton = (
  path: string,
  { label, enabled = true }: { label?: string; enabled?: boolean } = {
    enabled: true,
  }
) => {
  const setBackButton = useMemoryStore((s) => s.setBackButton);
  const clearBackButton = useMemoryStore((s) => s.clearBackButton);

  useEffect(() => {
    enabled && setBackButton({ path, label });
    return () => clearBackButton();
  }, [path, enabled]);
};

// If there is any concurrency problem with useBackButton or useBanner,
// like the cleanup happening after the next update which causes the
// button or banner to disappear, move the cleanup to a useEffect
// with [] dependencies (or useUnmount).

export const useBanner = ({
  message,
  color,
  icon,
  center,
  content,
  containerWidth,
  isLoading,
  enabled = true,
}: BannerOptions & { enabled?: boolean }) => {
  const setBanner = useMemoryStore((s) => s.setBanner);
  const clearBanner = useMemoryStore((s) => s.clearBanner);

  useEffect(() => {
    enabled &&
      setBanner({
        message,
        content,
        color,
        icon,
        center,
        containerWidth,
        isLoading,
      });
    return () => clearBanner();
  }, [
    message,
    content,
    color,
    icon,
    center,
    containerWidth,
    enabled,
    isLoading,
  ]);
};

export const useSlider = ({
  backendValueConvertedToSliderValue,
  onChangeEnd,
}) => {
  const [sliderValue, setSliderValue] = useState(null);
  const previousSliderValue = usePrevious(sliderValue);

  useEffect(() => {
    if (exists(backendValueConvertedToSliderValue)) {
      setSliderValue(backendValueConvertedToSliderValue);
    }
  }, [backendValueConvertedToSliderValue]);

  const handleChangeEnd = async (value) => {
    if (previousSliderValue !== value) {
      setSliderValue(null);
    }
    await onChangeEnd(value);
  };

  const handleChange = (value) => setSliderValue(value);

  return { onChangeEnd: handleChangeEnd, onChange: handleChange, sliderValue };
};

export const useBeacon = (key: string) => {
  const { value, setFalse } = useLocalStoreItem(`beacon-${key}`);

  return { enabled: value !== false, disable: setFalse };
};
