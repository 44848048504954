import { useState, useEffect, ReactElement } from 'react';
import { useMount } from 'react-use';

import {
  Box,
  Flex,
  Heading,
  Text,
  RadioGroup,
  Radio,
  VStack,
} from '@chakra-ui/react';

import Span from '@inspire/ui/chakra/Span';

import VideoPlayer from 'components/general/VideoPlayer';

export const IdentifierSoftSkillInstructions = () => {
  return (
    <Text textAlign={'center'}>
      Selon toi, quelles compétences sont illustrées dans ces extraits de films
      ?
    </Text>
  );
};

const IdentifierSoftSkill = ({
  allowNext,
  url,
  values,
  correctIndex,
  credits,
}: {
  allowNext: (value: boolean) => void;
  url: string;
  values: string[];
  correctIndex: number;
  credits?: ReactElement;
}) => {
  const [selectedIndexStr, setSelectedIndexStr] = useState<string>(null);

  const isCorrect = selectedIndexStr === String(correctIndex);

  useMount(() => allowNext(false));
  useEffect(() => {
    allowNext(isCorrect);
  }, [isCorrect]);

  return (
    <Box mb={6}>
      <Flex
        direction={['column', null, null, 'row-reverse']}
        mb={6}
        align="center"
      >
        <Box w="full" h="full">
          <VideoPlayer url={url} />
          {credits}
        </Box>
        <Box
          w={['100%', null, null, '700px']}
          pr={[0, null, null, 5]}
          pt={[4, null, null, 0]}
          alignSelf={'start'}
        >
          <Heading size="sm" mb={5}>
            De quelle compétence le personnage fait-il usage dans cet extrait ?
          </Heading>
          <RadioGroup onChange={setSelectedIndexStr} value={selectedIndexStr}>
            <VStack align="stretch">
              {values.map((v, i) => (
                <Radio key={v} value={String(i)}>
                  {v}{' '}
                  {selectedIndexStr === String(i) &&
                    (isCorrect ? (
                      <Span color="green.500" ml={2}>
                        Correct !
                      </Span>
                    ) : (
                      <Span color="red.500" ml={2}>
                        Raté !
                      </Span>
                    ))}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        </Box>
      </Flex>
    </Box>
  );
};

export const IdentifierSoftSkill1 = ({ allowNext }) => (
  <IdentifierSoftSkill
    allowNext={allowNext}
    url="/videos-bacpro/Video+1+Toy+Story+1.mp4"
    values={[
      'Prise de conscience et de recul',
      'Communication visuelle',
      'Autonomie',
    ]}
    correctIndex={0}
    credits={
      <Text fontSize="sm">
        Toy Story
        <br />
        De John Lasseter
        <br /> Sociétés de productions : Pixar Animation Studios - Walt Disney
        Pictures
        <br />
        Année de production : 1995
      </Text>
    }
  />
);

export const IdentifierSoftSkill2 = ({ allowNext }) => (
  <IdentifierSoftSkill
    allowNext={allowNext}
    url="/videos-bacpro/video+2.+Le+Coach.mp4"
    values={[
      'Imagination',
      'Influence et persuasion',
      'Mobilisation de réseau',
    ]}
    correctIndex={1}
    credits={
      <Text fontSize="sm">
        Le coach
        <br />
        De Olivier Doran
        <br /> Sociétés de production : Alter Films - Outsider Productions -
        Studio 37 - M6 Films - Canal + - CinéCinéma - La Banque postale Image
        <br />
        Année de production : 2009{' '}
      </Text>
    }
  />
);

export const IdentifierSoftSkill3 = ({ allowNext }) => (
  <IdentifierSoftSkill
    allowNext={allowNext}
    url="/videos-bacpro/video+3+Rocky+Balboa.mp4"
    values={[
      'Ouverture socioculturelle',
      'Stratégie',
      'Motivation et implication',
    ]}
    correctIndex={2}
    credits={
      <Text fontSize="sm">
        Rocky Balboa
        <br />
        De Sylvester Stallone
        <br /> Sociétés de production : Chartoff-Winkler Productions - Metro
        Goldwyn Mayer - Columbia Pictures - Revolutions Studios
        <br />
        Année de production : 2006{' '}
      </Text>
    }
  />
);
