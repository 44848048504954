import { createContext } from 'react';

export const StepContext = createContext({
  isLastStep: null,
  goNext: null,
  allowNext: null,
  isNextAllowed: null,
});

export const StepProvider = StepContext.Provider;
