import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { CheckboxGame } from 'components/activites/iterative-games';
import ConsigneTag from 'components/activites/ConsigneTag';
import ClickableImage from 'components/general/ClickableImage';
import CalendrierParcoursupInfographieSrc from '../../../../public/img/infographies/calendrier-parcoursup.png';
import ReponsesFormationsInfographieSrc from '../../../../public/img/infographies/rep-des-formations-sur-parcoursup.png';
import RepondrePropositionSelectiveInfographieSrc from '../../../../public/img/infographies/rep-aux-propositions-selectives.png';
import RepondrePropositionNonSelectiveInfographieSrc from '../../../../public/img/infographies/rep-aux-propositions-non-selectives.png';
import AndreaSrc from '../../../../public/img/andrea.jpg';
import OuiParcoursupSrc from '../../../../public/img/infographies/schema-OUI-parcoursup.png';
import SchemaParcoursup1Src from '../../../../public/img/infographies/schema-parcoursup-1.png';
import SchemaParcoursup2Src from '../../../../public/img/infographies/schema-parcoursup-2.png';
import SchemaParcoursup3Src from '../../../../public/img/infographies/schema-parcoursup-3.png';
import SchemaParcoursup4Src from '../../../../public/img/infographies/schema-parcoursup-4.png';
import PhotoAndreaSrc from '../../../../public/img/andrea.jpg';
import CasParcoursup1Src from '../../../../public/img/cas-pratique-parcoursup-1.png';
import CasParcoursup2Src from '../../../../public/img/cas-pratique-parcoursup-2.png';
import CasParcoursup3Src from '../../../../public/img/cas-pratique-parcoursup-3.png';
import CasParcoursup4Src from '../../../../public/img/cas-pratique-parcoursup-4.png';
import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import ProfTips from 'components/activites/ProfTips';
import StandaloneCheckbox from '@inspire/ui/chakra/forms/StandaloneCheckbox';
import Span from '@inspire/ui/chakra/Span';
import ReflexionOralHeader from '../ReflexionOralHeader';
import VideoPlayer from 'components/general/VideoPlayer';

export const EtapesParcoursupTerm1 = (props) => (
  <EtapesParcoursupTerm
    {...props}
    questions={[
      {
        subject:
          'À partir de quand je peux consulter les formations actualisées sur Parcoursup ?',
        answerValues: ['dec'],
        options: [
          { shortName: 'À partir de décembre', value: 'dec' },
          { shortName: 'À partir de janvier', value: 'jan' },
          { shortName: 'À partir de février', value: 'fev' },
          { shortName: 'À partir de mars', value: 'mars' },
        ],
      },
      {
        subject: 'À partir de quand je peux faire mes voeux sur Parcoursup ?',
        answerValues: ['jan'],
        options: [
          { shortName: 'À partir de décembre', value: 'dec' },
          { shortName: 'À partir de janvier', value: 'jan' },
          { shortName: 'À partir de février', value: 'fev' },
          { shortName: 'À partir de mars', value: 'mars' },
        ],
      },
      {
        subject:
          'À partir de quand je peux compléter mon dossier sur Parcoursup ?',
        answerValues: ['jan'],
        options: [
          { shortName: 'À partir de janvier', value: 'jan' },
          { shortName: 'À partir de février', value: 'fev' },
          { shortName: 'À partir de mars', value: 'mars' },
          { shortName: 'À partir de d’avril', value: 'avril' },
        ],
      },
      {
        subject: "Jusqu'à quand je peux faire mes vœux sur Parcoursup ?",
        answerValues: ['mars'],
        options: [
          { shortName: "Jusqu'en mars", value: 'mars' },
          { shortName: "Jusqu'en d’avril", value: 'avril' },
          { shortName: "Jusqu'en mai", value: 'mai' },
          { shortName: "Jusqu'en juin", value: 'juin' },
        ],
      },
    ]}
    withSummary={false}
  />
);

export const EtapesParcoursupTerm2 = (props) => (
  <EtapesParcoursupTerm
    {...props}
    questions={[
      {
        subject:
          'Je peux faire des vœux uniquement lors de la phase d’admission complémentaire à partir de juillet :',
        answerValues: ['false'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Faux, la phase d’admission complémentaire c’est pour les élèves qui
            ont fait des vœux dans la 1ère phase mais qui n’ont reçu aucune
            proposition de formation fin juin (ou une proposition qui ne leur
            convient pas), ils pourront bénéficier d’une procédure
            complémentaire.
          </Text>
        ),
      },
    ]}
    withSummary={true}
  />
);

export const EtapesParcoursupTerm3 = (props) => (
  <EtapesParcoursupTerm
    {...props}
    questions={[
      {
        subject:
          'Je dois passer par Parcoursup pour toutes les demandes de formation :',
        answerValues: ['false'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Faux, toutes les inscriptions pour les formations post-bac ne
            passent pas par Parcoursup. Un certain nombre de formations
            organisent leurs propres inscriptions et/ou concours d’admission,
            sans passer par Parcoursup. Par exemple : certaines écoles
            spécialisées (ou écoles de métiers), écoles de commerce,
            d’ingénieurs, certaines formations paramédicales et sociales, écoles
            d’art, et certaines formations courtes (mentions complémentaires,
            certificat de spécialisation,...). Pense toujours à vérifier les
            modalités d’inscription !
          </Text>
        ),
      },
    ]}
    withSummary={true}
  />
);

export const EtapesParcoursupTerm = ({
  questions,
  allowNext,
  goNext,
  withSummary,
}) => {
  console.log(withSummary);
  useEffect(() => {
    allowNext(false);
  }, []);
  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : En t’aidant de cette infographie, réponds aux
        questions suivantes.
      </Text>

      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={CalendrierParcoursupInfographieSrc}
          alt="Infographie Soft skills"
          href="/img/infographies/calendrier-parcoursup.png"
        />
        <Text>Calendrier Parcoursup</Text>
      </Box>
      <CheckboxGame
        questions={questions}
        getOptions={(q) => q?.options}
        onFinished={withSummary ? () => allowNext(true) : goNext}
        getQuestionSection={(q) => (
          <Text mt={0}>
            <i>{q?.subject}</i>
          </Text>
        )}
        getSummarySection={
          withSummary
            ? (_, questionIndex) =>
                questionIndex !== 0 && (
                  <SummaryFrame>
                    {questions.slice(0, questionIndex).map((q) => q.summary)}
                  </SummaryFrame>
                )
            : () => null
        }
      />
    </>
  );
};

export const DossierParcoursup = ({ questions, allowNext }) => {
  const options = [
    { shortName: 'Mon profil', value: 'profil' },
    { shortName: 'Ma scolarité', value: 'scolarite' },
    { shortName: 'Mes activités', value: 'activites' },
    { shortName: 'Mes vœux', value: 'vœux' },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : Dans quelles rubriques je parle des éléments suivants
        ? (plusieurs réponses possibles) :
      </Text>
      <CheckboxGame
        questions={questions}
        getOptions={() => options}
        onFinished={() => allowNext(true)}
        getQuestionSection={(q) => (
          <Text mt={0}>
            <i>{q?.subject}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame>
              {questions.slice(0, questionIndex).map((q) => q.summary)}
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const DossierParcoursup1 = (props) => (
  <DossierParcoursup
    {...props}
    questions={[
      {
        subject:
          'Mon bac, mes spécialités, mes bulletins scolaires, mes notes du bac.',
        answerValues: ['scolarite'],
        summary: (
          <Text>
            Les informations concernant ton profil et ta scolarité sont
            remontées automatiquement au moment de ton inscription sur
            Parcoursup. La plupart du temps, tes bulletins scolaires sont
            transmis par ton lycée et tes notes du baccalauréat sont transmises
            par le rectorat. Pense à vérifier les notes pré-renseignées et à
            signaler à ton établissement les éventuelles erreurs afin qu'il
            puisse les rectifier.
          </Text>
        ),
      },
    ]}
  />
);

export const DossierParcoursup2 = (props) => (
  <>
    <DossierParcoursup
      {...props}
      questions={[
        {
          subject:
            "Informations importantes sur ma scolarité (stages, année à l'étranger ou des problèmes de santé ou familiaux...) que je souhaite faire connaître aux formations.",
          answerValues: ['scolarite'],
          summary: (
            <Text>
              A indiquer dans la rubrique "Eléments liés à la scolarité", ces
              informations sont facultatives et peuvent permettre aux formations
              pour lesquelles tu as fait des voeux de mieux comprendre ton
              parcours scolaire.
            </Text>
          ),
        },
      ]}
    />
    <ProfTips mt={10}>
      <Text>
        Les lycéens ont la possibilité d’indiquer des informations importantes
        qui ont affecté leurs scolarité (en positif ou non), permettant une
        juste appréciation de leur candidature. Par exemple : stages,
        participation à une Cordée de la réussite, années à l’étranger,
        scolarité atypique en raison de problèmes de santé ou de problèmes
        familiaux.
      </Text>
    </ProfTips>
  </>
);

export const DossierParcoursup3 = (props) => (
  <DossierParcoursup
    {...props}
    questions={[
      {
        subject:
          'Mes préférences entre les vœux formulés, le domaine d’études que je privilégie et mes candidatures hors Parcoursup.',
        answerValues: ['vœux'],
        summary: (
          <Text>
            A renseigner dans la rubrique “Préférence et autres projets”. Ces
            informations sont confidentielles et ne seront pas regardées par les
            formations pour lesquelles tu as fait des vœux. Elles seront utiles
            pour les commissions académiques d’accès à l’enseignement supérieur
            (CAES), chargées de faire des propositions à des candidats n’ayant
            pas reçu de proposition d’admission.
          </Text>
        ),
      },
    ]}
  />
);

export const DossierParcoursup4 = (props) => (
  <DossierParcoursup
    {...props}
    questions={[
      {
        subject: 'Ma motivation pour les formations auxquelles je candidate.',
        answerValues: ['vœux'],
        summary: (
          <Text>
            Pour chaque voeu tu devras rédiger un “projet de formation motivé”.
            Celui-ci explique, en quelques lignes, ce qui te motive dans cette
            formation, les démarches que tu as faites pour te renseigner et en
            quoi tu penses avoir le profil pour rejoindre la formation (ce qui
            t’intéresse, tes qualités et expériences en lien avec la formation).
          </Text>
        ),
      },
    ]}
  />
);

export const DossierParcoursup5 = (props) => (
  <DossierParcoursup
    {...props}
    questions={[
      {
        subject:
          'Mes qualités, mes compétences transversales, mes expériences.',
        answerValues: ['vœux', 'activites'],
        summary: (
          <Text>
            A renseigner dans la rubrique “Mes activités et centres d'intérêt”.
            Ces informations sont facultatives mais sont un vrai plus pour ton
            dossier car elles permettent de te démarquer, de parler de toi et de
            mettre en avant des qualités ou des expériences qui ne figurent pas
            sur des bulletins scolaires. C’est dans cette rubrique que tu peux
            parler de tes compétences transversales (soft skills). Tu peux aussi
            parler de tes compétences dans tes projets de formation motivés pour
            chacun de tes vœux.
          </Text>
        ),
      },
    ]}
  />
);

export const DossierParcoursup6 = () => {
  const profTipsTexts = [
    `Vous avez plusieurs possibilités pour lancer les vidéos :`,
    `Utiliser un vidéoprojecteur pour une diffusion collective.`,
    `Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou sur mobile
      (dans l’idéal les élèves ont chacun des écouteurs).`,
    `La vidéo présente les conseils de Taous Benchabane, DDFPT dans un lycée professionnel,
      à destination des lycéens concernant leurs candidatures Parcoursup.`,
  ];
  const questions = [
    'Quels sont les différents moyens pour te renseigner sur les formations ?',
    'Quelles sont les erreurs que tu dois éviter en rédigeant ta lettre de motivation pour une formation ?',
    'Cite un ou deux conseils donnés par Taous pour se démarquer des autres candidats.',
  ];
  const VIDEO_URL = '/videos-bacpro/Vid%2007%20Taous.mp4';
  const VIDEO_THUMBNAIL = '/videos-bacpro/Thumbnail/thumbnail_Taous.png';

  return (
    <>
      <ProfTips>
        {profTipsTexts.map((tip, index) => (
          <Text key={`proftips--dossier-parcoursup6${index}`}>{tip}</Text>
        ))}
      </ProfTips>
      <ReflexionOralHeader />
      <Text fontWeight={'bold'}>
        Pour te préparer à envoyer tes candidatures sur Parcoursup et apprendre
        à valoriser celles-ci, écoute les conseils donnés dans ce témoignage,
        puis réponds oralement aux questions.
      </Text>
      <Box margin="auto">
        <VideoPlayer url={VIDEO_URL} light={VIDEO_THUMBNAIL} />
      </Box>
      <UnorderedList>
        {questions.map((question, index) => (
          <ListItem key={`question-dossier-parcoursup6-${index}`}>
            {question}
          </ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export const PropositionsFormations = ({ questions, allowNext }) => {
  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : Regarde cette infographie, puis réponds aux questions.
      </Text>
      <Box textAlign="center" maxW="400px" margin="auto">
        <ClickableImage
          src={ReponsesFormationsInfographieSrc}
          alt="Les réponses des formations sur Parcoursup"
          href="/img/infographies/rep-des-formations-sur-parcoursup.png"
        />
        <Text>Les réponses des formations sur Parcoursup</Text>
      </Box>
      <CheckboxGame
        questions={questions}
        getOptions={(q) => q?.options}
        onFinished={() => allowNext(true)}
        getQuestionSection={(q) => (
          <Text mt={0}>
            <i>{q?.subject}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame>
              {questions.slice(0, questionIndex).map((q) => q.summary)}
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const PropositionsFormations1 = (props) => (
  <PropositionsFormations
    {...props}
    questions={[
      {
        subject: 'Les formations peuvent uniquement répondre OUI ou NON :',
        answerValues: ['false'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Faux, les formations peuvent te mettre sur liste d’attente. Des
            places pourront peut-être se libérer. Une alerte te sera envoyée dès
            que tu recevras une proposition.
          </Text>
        ),
      },
    ]}
  />
);

export const PropositionsFormations2 = (props) => (
  <PropositionsFormations
    {...props}
    questions={[
      {
        subject: 'Que signifie la réponse OUI, SI ?',
        answerValues: ['remise-niveau'],
        options: [
          { shortName: 'Oui, si une place se libère', value: 'place' },
          {
            shortName:
              'Oui, si je suis une remise à niveau en parallèle de la formation',
            value: 'remise-niveau',
          },
        ],
        summary: (
          <Text>
            "Oui si" est une proposition d’admission avec l’obligation de suivre
            un dispositif d’accompagnement intégré à la formation pour renforcer
            certaines compétences et t’aider à réussir.
          </Text>
        ),
      },
    ]}
  />
);

export const PropositionsFormations3 = (props) => (
  <PropositionsFormations
    {...props}
    questions={[
      {
        subject:
          'Toutes les formations donnent leurs réponses définitives en même temps sur Parcoursup :',
        answerValues: ['false'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Faux, les réponses des formations vont arriver au fur et à mesure
            sur Parcoursup, mais de ton côté tu auras un délai maximum pour
            répondre à chaque proposition (parfois ce délai est très court,
            quelques jours). Certaines formations te mettront sur liste
            d’attente, le temps que les candidats de Parcoursup acceptent leur
            formation préférée. La date limite pour accepter une proposition
            d’admission est affichée en face de chaque vœu dans ton dossier
            Parcoursup.
          </Text>
        ),
      },
    ]}
  />
);

export const ReponsesCandidatsInfographie = () => {
  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : Regarde ces infographies, puis réponds aux questions.
      </Text>
      <Box textAlign="center" maxW="600px" margin="auto">
        <Text>Répondre aux propositions sélectives</Text>
        <ClickableImage
          src={RepondrePropositionSelectiveInfographieSrc}
          alt="Répondre aux propositions sélectives"
          href="/img/infographies/rep-aux-propositions-selectives.png"
        />
      </Box>
      <Box textAlign="center" maxW="600px" margin="auto">
        <Text>Répondre aux propositions non sélectives</Text>
        <ClickableImage
          src={RepondrePropositionNonSelectiveInfographieSrc}
          alt="Répondre aux propositions non sélectives"
          href="/img/infographies/rep-aux-propositions-non-selectives.png"
        />
      </Box>
    </>
  );
};

export const ReponsesCandidats = ({ questions, allowNext }) => {
  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <CheckboxGame
        questions={questions}
        getOptions={(q) => q?.options}
        onFinished={() => allowNext(true)}
        getQuestionSection={(q) => (
          <Text mt={0}>
            <i>{q?.subject}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame>
              {questions.slice(0, questionIndex).map((q) => q.summary)}
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const ReponsesCandidats1 = (props) => (
  <ReponsesCandidats
    {...props}
    questions={[
      {
        subject:
          "Si je reçois une proposition d'admission (un OUI) et que les autres formations sont en attentes je peux (plusieurs réponses possibles) :",
        answerValues: ['accepter', 'refuser', 'maintenir', 'renoncer'],
        options: [
          { shortName: 'Accepter la formation', value: 'accepter' },
          { shortName: 'Refuser la formation', value: 'refuser' },
          { shortName: 'Maintenir mes voeux en attente', value: 'maintenir' },
          { shortName: 'Renoncer à mes voeux en attente', value: 'renoncer' },
        ],
        summary: (
          <Text>
            Tu peux effectivement décider d’accepter ou de refuser une
            proposition de formation, et tu peux décider de maintenir ou de
            renoncer à tes voeux en attente.
          </Text>
        ),
      },
    ]}
  />
);

export const ReponsesCandidats2 = (props) => (
  <ReponsesCandidats
    {...props}
    questions={[
      {
        subject:
          'Les formations non-sélective (les licences par exemple) ne peuvent pas répondre NON :',
        answerValues: ['true'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Vrai, les formations non-sélective peuvent répondre “OUI”, “OUI SI”
            ou “En attente”
          </Text>
        ),
      },
    ]}
  />
);

export const PasDePropositions = ({
  questions,
  allowNext,
  withSummary,
  goNext,
}) => {
  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : Réponds aux questions suivantes.
      </Text>
      <CheckboxGame
        questions={questions}
        getOptions={(q) => q?.options}
        onFinished={withSummary ? () => allowNext(true) : goNext}
        getQuestionSection={(q) => (
          <Text mt={0}>
            <i>{q?.subject}</i>
          </Text>
        )}
        getSummarySection={
          withSummary
            ? (_, questionIndex) =>
                questionIndex !== 0 && (
                  <SummaryFrame>
                    {questions.slice(0, questionIndex).map((q) => q.summary)}
                  </SummaryFrame>
                )
            : () => null
        }
      />
    </>
  );
};

export const PasDePropositions1 = (props) => (
  <PasDePropositions
    {...props}
    questions={[
      {
        subject:
          'Si je n’ai pas reçu de retour positif pour des admissions, que peut-on faire ? (plusieurs réponses possibles)',
        answerValues: ['accompagnement', 'cio'],
        options: [
          {
            shortName:
              'Demander un accompagnement individuel ou collectif dans mon lycée',
            value: 'accompagnement',
          },
          {
            shortName: 'Aller dans un CIO pour envisager d’autres formations',
            value: 'cio',
          },
          { shortName: 'Rien, tout est fini', value: 'rien' },
        ],
        summary: (
          <Text>
            Si tu n’as pas obtenu d’admissions tu peux solliciter ton
            établissement ou un CIO qui vont t’aider à préparer ce qu’on appelle
            la phase complémentaire. Cette phase permet de postuler dans les
            formations ayant des places disponibles, toujours depuis Parcoursup.
          </Text>
        ),
      },
    ]}
    withSummary={true}
  />
);

export const PasDePropositions2 = (props) => (
  <PasDePropositions
    {...props}
    questions={[
      {
        subject:
          'Une fois la phase complémentaire initiée, à combien de vœux supplémentaires peut-on postuler ?',
        answerValues: ['10'],
        options: [
          { shortName: '5', value: '5' },
          { shortName: '10', value: '10' },
          { shortName: '8', value: '8' },
        ],
        summary: (
          <Text>
            La phase complémentaire permet de postuler à 10 voeux
            supplémentaires dans les formations ayant des places disponibles,
            toujours depuis Parcoursup.
          </Text>
        ),
      },
    ]}
    withSummary={true}
  />
);

export const PasDePropositions3 = (props) => (
  <PasDePropositions
    {...props}
    questions={[
      {
        subject:
          'Si on n’a pas de retour dans la phase complémentaire, a-t-on encore une possibilité de recours ?',
        answerValues: ['true'],
        options: [
          { shortName: 'Vrai', value: 'true' },
          { shortName: 'Faux', value: 'false' },
        ],
        summary: (
          <Text>
            Les candidats qui n’ont pas obtenu de proposition d’admission sur
            Parcoursup peuvent solliciter la CAES (Commission d’Accès à
            l’Enseignement Supérieur) de leur académie, elle sera chargée de
            faire de nouvelles propositions.
          </Text>
        ),
      },
    ]}
    withSummary={true}
  />
);

export const PasDePropositions4 = (props) => (
  <PasDePropositions
    {...props}
    questions={[
      {
        subject:
          'Comment peut-on saisir la CAES (Commission d’Accès à l’Enseignement Supérieur) ? (plusieurs réponses possibles)',
        answerValues: ['bouton', 'equipe'],
        options: [
          {
            shortName:
              'Sur le bouton « Je sollicite la CAES » dans le dossier Parcoursup',
            value: 'bouton',
          },
          { shortName: 'Par téléphone', value: 'telephone' },
          {
            shortName:
              'Ce sont les équipes du CAES et de Parcoursup qui me contactent et m’informent de la marche à suivre',
            value: 'equipe',
          },
        ],
      },
    ]}
    withSummary={false}
  />
);

export const CommentRepondreSurParcoursup = ({}) => {
  return (
    <>
      <Text textAlign="center">
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>
      <ClickableImage
        src={AndreaSrc}
        alt="Répondre aux propositions sélectives"
        href="/img/andrea.jpg"
      />
      <Text>
        Andréa a reçu une proposition positive d’admission et d’autres
        propositions sont encore en attente dont sa sa formation préférée (le
        BTS).
      </Text>
    </>
  );
};

export const InfosParcoursup1 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Dans cette activité, tu devras aider Andréa à répondre
        à ses propositions sur Parcoursup. Avant cela, voici quelques
        informations pour comprendre comment ça marche sur Parcoursup.
      </Text>
      <Text mb={8}>
        Lorsque tu reçois une proposition d’admission (un OUI) tu as{' '}
        <Span color="red.500" fontWeight="bold">
          une date limite
        </Span>{' '}
        pour répondre. Tu dois répondre à chaque proposition avant cette date
        limite, sinon la formation sera proposée à quelqu’un d’autre.
      </Text>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={OuiParcoursupSrc}
          alt="Je respecte la date limite"
          href="/img/infographies/schema-OUI-parcoursup.png"
        />
        <Text>Je respecte la date limite</Text>
      </Box>
    </>
  );
};

export const InfosParcoursup2 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Pour t’aider à répondre sur Parcoursup voici quelques
        informations.
      </Text>
      <Text mb={8}>
        Lorsque tu acceptes une proposition d’admission, rassure-toi, tu ne
        perds pas tes vœux en attente, la plateforme te demande d’indiquer ceux
        que tu souhaites maintenir.
      </Text>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={SchemaParcoursup1Src}
          alt="J’accepte et je maintiens des voeux en attente "
          href="/img/infographies/schema-parcoursup-1.png"
        />
        <Text>Ici, j’accepte et je maintiens des voeux en attente </Text>
      </Box>
      <Text mt={8}>
        Garde uniquement en attente les vœux qui t'intéressent toujours, car
        cela permet de libérer des places lorsque tu renonces à une formation
        qui ne t'intéresse plus.
      </Text>
    </>
  );
};

export const InfosParcoursup3 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Pour t’aider à répondre sur Parcoursup voici quelques
        informations.
      </Text>
      <Text mb={8}>
        Si tu reçois plusieurs propositions d’admission tu ne peux en accepter
        qu’
        <Span color="red.500" fontWeight="bold">
          une seule
        </Span>
        , en acceptant l’une tu renonces automatiquement aux autres OUI.
      </Text>

      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={SchemaParcoursup2Src}
          alt="J’accepte qu’une seule proposition et je maintiens mes voeux en attente"
          href="/img/infographies/schema-parcoursup-2.png"
        />
        <Text>
          Ici, j’accepte qu’une seule proposition et je maintiens mes voeux en
          attente
        </Text>
      </Box>
      <Text mt={8}>
        Et n’oublie pas de maintenir tes vœux en attente qui t'intéressent
        toujours.
      </Text>
    </>
  );
};

export const InfosParcoursup4 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Pour t’aider à répondre sur Parcoursup voici quelques
        informations.
      </Text>
      <Text>
        Enfin, si tu reçois une proposition d’admission pour ta formation
        favorite, accepte-la définitivement.
      </Text>
      <Text mb={8}>
        Dans ce cas, tu renonces à tous tes autres voeux en attente.
      </Text>
      <Box textAlign="center" maxW="600px" margin="auto" mb={10}>
        <ClickableImage
          src={SchemaParcoursup3Src}
          alt="J’accepte définitivement un voeu - Schéma Parcoursup 3"
          href="/img/infographies/schema-parcoursup-3.png"
        />
      </Box>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={SchemaParcoursup4Src}
          alt="J’accepte définitivement un voeu - Schéma Parcoursup 4"
          href="/img/infographies/schema-parcoursup-4.png"
        />
        <Text>Ici, j’accepte définitivement un voeu</Text>
      </Box>
    </>
  );
};

// ------------------------------------------------------------------------------------------------------------

export const CasPratique1 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={6} mb={8}>
        <ClickableImage src={PhotoAndreaSrc} alt="Photo Andréa" />
        <Box>
          <Text>
            Andréa a reçu une proposition positive d’admission, un refus et
            d’autres propositions sont encore en attente dont sa formation
            préférée (le BTS).
          </Text>
        </Box>
      </SimpleGrid>
      <Box mb={8}>
        <Text fontWeight="bold">Ordre de préférence des vœux d’Andréa :</Text>
        <Text>N°1 : Lycée Grenoble - BTS commerce</Text>
        <Text>N°2 : IUT Grenoble - BUT technique de commercialisation</Text>
        <Text>N°3 : IUT Valence - BUT technique de commercialisation</Text>
        <Text>N°4 : IUT Lyon - BUT technique de commercialisation</Text>
        <Text>N°5 : Université Lyon - Licence STAPS</Text>
      </Box>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={CasParcoursup1Src}
          alt="Cas pratique"
          href="/img/infographies/cas-pratique-parcoursup-1.png"
          width={'sm'}
        />
      </Box>
    </>
  );
};

const ChoixParcoursup = ({ setChoix, choix, label, isVoeu }) => {
  return (
    <Box
      alignItems="center"
      backgroundColor={isVoeu ? '#444' : 'blue'}
      mb={3}
      pb={3}
    >
      <Text
        color={'white'}
        marginLeft={3}
        marginRight={6}
        pt={3}
        fontWeight="bold"
      >
        {label}
      </Text>
      <Flex flexDir="row" alignItems="center" marginLeft={3} marginRight={6}>
        <StandaloneCheckbox
          color={'white'}
          label={isVoeu ? 'JE MAINTIENS' : "J'ACCEPTE"}
          maxW="150px"
          isChecked={choix === 'yes'}
          onChange={async () => {
            if (choix === 'yes') setChoix('');
            else setChoix('yes');
          }}
        />
        <StandaloneCheckbox
          color={'white'}
          label={isVoeu ? 'JE RENONCE' : 'JE REFUSE'}
          maxW="150px"
          isChecked={choix === 'no'}
          onChange={async () => {
            if (choix === 'no') setChoix('');
            else setChoix('no');
          }}
        />
      </Flex>
    </Box>
  );
};

const InfoComplementaires = ({}) => {
  return (
    <>
      <Text>
        Pour avoir des informations complémentaires, il faut cliquer sur le
        bouton “liste d’attente” pour connaître notamment :
      </Text>
      <UnorderedList>
        <ListItem>sa position</ListItem>
        <ListItem>
          le nombre total de candidats dans la liste d'attente
        </ListItem>
        <ListItem>le nombre de places dans la formation</ListItem>
      </UnorderedList>
    </>
  );
};

export const CasPratique2 = ({}) => {
  const [voeuGrenoble, setVoeuGrenoble] = useState('');
  const [voeuValence, setVoeuValence] = useState('');

  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>
      <Text>
        Andréa reçoit ses premiers résultats : OUI pour le BUT à Lyon et NON
        pour le BUT à Grenoble.
      </Text>
      <Text>Elle a 4 jours pour répondre.</Text>
      <Text>
        Andréa, décide <b>d’accepter</b> la proposition de l’IUT de Lyon et de{' '}
        <b>renoncer</b> à la licence de STAPS, car cette formation l’intéressait
        moins. Ainsi, elle libère la place pour d’autres candidats.
      </Text>
      <Text>Que lui conseilles-tu pour les 2 formations en attente ?</Text>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage src={CasParcoursup2Src} alt="Photo Andréa" />
      </Box>
      <Box>
        <Text textAlign="center" mb={8}>
          <ConsigneTag /> : Pour chaque formation, sélectionne l’une des
          possibilités :
        </Text>
        <ChoixParcoursup
          setChoix={setVoeuGrenoble}
          choix={voeuGrenoble}
          label="Voeu 1 en attente - BTS commerce à Grenoble :"
          isVoeu={true}
        />
        <ChoixParcoursup
          setChoix={setVoeuValence}
          choix={voeuValence}
          label="Voeu 2 en attente - BUT technique de commercialisation à Valence :"
          isVoeu={true}
        />
      </Box>
      <Box>
        {voeuGrenoble === 'no' && voeuValence === 'no' && (
          <>
            <Text>
              Dommage, l’IUT de Valence et le BTS intéressent davantage Andréa
              que l’IUT de Lyon, il aurait été préférable qu’elle maintienne son
              vœu pour voir si on lui propose finalement une place.
            </Text>
            <Text>
              Puisqu’elle a accepté définitivement la formation à l’IUT de Lyon
              et qu’elle a renoncé aux autres, elle va maintenant procéder à son
              inscription dans l’établissement.
            </Text>
          </>
        )}

        {voeuGrenoble === 'yes' && voeuValence === 'no' && (
          <>
            <Text mb={6}>
              Dommage, l’IUT de Valence intéresse davantage Andréa que l’IUT de
              Lyon, il vaudrait mieux qu’elle maintienne son vœu pour voir si on
              lui propose finalement une place.
            </Text>
            <InfoComplementaires />
          </>
        )}

        {voeuGrenoble === 'no' && voeuValence === 'yes' && (
          <>
            <Text mb={6}>
              Dommage, le BTS intéresse davantage Andréa que l’IUT de Lyon, il
              vaudrait mieux qu’elle maintienne son vœu pour voir si on lui
              propose finalement une place.
            </Text>
            <InfoComplementaires />
          </>
        )}

        {voeuGrenoble === 'yes' && voeuValence === 'yes' && (
          <>
            <Text mb={6}>
              Bonne idée, si on est en liste d’attente, c’est toujours mieux de
              maintenir les vœux qui nous intéressent davantage que la
              proposition d'admission acceptée.
            </Text>
            <InfoComplementaires />
          </>
        )}

        {voeuGrenoble !== '' && voeuValence !== '' && (
          <Text fontWeight="bold">
            N’hésite pas à recommencer et sélectionner de nouveaux choix pour
            voir ce que ça donne !
          </Text>
        )}
      </Box>
    </>
  );
};

export const CasPratique3 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>

      <Text>
        Finalement, Andréa décide de maintenir ses vœux pour l’IUT de Valence et
        le BTS pour lesquelles elle est sur liste d’attente.
      </Text>
    </>
  );
};

export const CasPratique4 = ({}) => {
  const [propositionLyon, setPropositionLyon] = useState('');
  const [propositionValence, setPropositionValence] = useState('');
  const [voeuGrenoble, setVoeuGrenoble] = useState('');

  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>

      <Text>
        Andréa reçoit une réponse positive pour l’IUT de Valence qui l'intéresse
        plus que celui de Lyon.
      </Text>
      <Text>
        Que lui conseilles-tu ? N’oublie pas qu'elle a le droit d’accepter qu’
        <b>UNE SEULE</b> proposition.
      </Text>

      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={CasParcoursup3Src}
          alt="Cas Parcoursup 3"
          href="/img/infographies/cas-pratique-parcoursup-3.png"
        />
      </Box>
      <Box>
        <Text textAlign="center" mb={8}>
          <ConsigneTag /> : Pour chaque formation, sélectionne l’une des
          possibilités, n’oublie pas, tu n’as le droit d’accepter qu’
          <b>une seule</b> formation :
        </Text>
        <ChoixParcoursup
          setChoix={setPropositionLyon}
          choix={propositionLyon}
          label="Proposition 1 - BUT technique de commercialisation à Lyon :"
          isVoeu={false}
        />
        <ChoixParcoursup
          setChoix={setPropositionValence}
          choix={propositionValence}
          label="Proposition 2 - BUT technique de commercialisation à Valence :"
          isVoeu={false}
        />
        <ChoixParcoursup
          setChoix={setVoeuGrenoble}
          choix={voeuGrenoble}
          label="Voeu 1 en attente - BTS commerce à Grenoble :"
          isVoeu={true}
        />
      </Box>
      <Box>
        {propositionLyon === 'no' &&
          propositionValence === 'no' &&
          voeuGrenoble === 'no' && (
            <Text>
              Dommage, Andréa n’a plus aucune possibilité de formation !
            </Text>
          )}

        {propositionLyon === 'yes' &&
          propositionValence === 'no' &&
          voeuGrenoble === 'no' && (
            <Text>
              Dommage, Andréa préfère l’IUT de Valence à celui de Lyon ! Il
              aurait été préférable qu’elle accepte celui de Valence et qu’elle
              maintienne son vœu en attente pour le BTS, elle aurait peut-être
              été finalement acceptée.
            </Text>
          )}

        {propositionLyon === 'no' &&
          propositionValence === 'no' &&
          voeuGrenoble === 'yes' && (
            <Text>
              C’est risqué, si jamais Andréa ne reçoit pas de proposition pour
              le BTS elle n’aura aucune formation. Il aurait été préférable
              qu’elle accepte au moins l’un des deux IUT.
            </Text>
          )}

        {propositionLyon === 'no' &&
          propositionValence === 'yes' &&
          voeuGrenoble === 'no' && (
            <Text>
              Pas mal, Andréa préfère l’IUT de Valence à celui de Lyon, elle
              peut donc refuser celui de Lyon. En revanche, il aurait été
              préférable qu’elle maintienne son vœu en attente pour le BTS, sa
              formation préférée. Elle aurait peut-être été finalement acceptée.
            </Text>
          )}

        {propositionLyon === 'yes' &&
          propositionValence === 'no' &&
          voeuGrenoble === 'yes' && (
            <Text>
              Dommage, Andréa préfère l’IUT de Valence à celui de Lyon ! Il
              aurait été préférable qu’elle accepte celui de Valence. Elle a
              bien fait de maintenir son vœu pour le BTS car c’est sa formation
              préférée, elle sera peut-être finalement acceptée.
            </Text>
          )}

        {propositionLyon === 'no' &&
          propositionValence === 'yes' &&
          voeuGrenoble === 'yes' && (
            <Text>
              Bonne idée, Andréa préfère l’IUT de Valence à celui de Lyon, elle
              a bien fait de l'accepter et de maintenir son vœu pour le BTS car
              c’est sa formation préférée, elle sera peut-être finalement
              acceptée.
            </Text>
          )}

        {propositionLyon === 'yes' && propositionValence === 'yes' && (
          <Text color="red">
            Attention : tu ne peux accepter qu'une seule proposition
          </Text>
        )}

        {propositionLyon !== '' &&
          propositionValence !== '' &&
          voeuGrenoble !== '' && (
            <Text fontWeight="bold">
              N’hésite pas à recommencer et sélectionner de nouveaux choix pour
              voir ce que ça donne !
            </Text>
          )}
      </Box>
    </>
  );
};

export const CasPratique5 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>

      <Text>
        Andréa décide d’accepter l’IUT de Valence, qu’elle préfère à celui de
        Lyon et de maintenir son vœu en attente pour le BTS de Grenoble.
      </Text>
      <Box textAlign="center" maxW="600px" margin="auto">
        <ClickableImage
          src={CasParcoursup4Src}
          alt="Cas pratique"
          href="/img/cas-pratique-parcoursup-4.png"
          width={'sm'}
        />
      </Box>
    </>
  );
};

export const CasPratique6 = ({}) => {
  return (
    <>
      <Text textAlign="center" mb={8}>
        <ConsigneTag /> : Aide Andréa à répondre aux propositions sur
        Parcoursup.
      </Text>

      <Text>
        Finalement, à la fin de la phase d’admission principale, Andréa n’a pas
        reçu de proposition pour le BTS, il n’y a pas eu assez de places
        libérées. Elle va donc s’inscrire en BUT, à l’IUT de Valence. Elle est
        contente car c'était tout de même son choix n°2 !
      </Text>
    </>
  );
};
