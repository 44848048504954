import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import Image from 'next/image';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import ImageLM from '@inspire/ui/assets/activites/recherche-stage/lm-seconde.png';

import { CheckboxGame } from 'components/activites/iterative-games';
import TodoActivite from 'components/activites/TodoActivite';

export const QuizzLM = ({ allowNext }) => {
  const questions = [
    {
      subject:
        'Si j’envoie mon CV, je dois aussi envoyer une lettre de motivation.',
      answerValues: ['2'],
      options: [
        { shortName: 'Non, le CV suffit', value: '1' },
        {
          shortName:
            'Oui, le CV doit être accompagné d’une lettre de motivation',
          value: '2',
        },
      ],
    },
    {
      subject:
        'Dans la lettre de motivation, on reprend exactement ce qu’il y a dans le CV en faisant des phrases.',
      answerValues: ['2'],
      options: [
        {
          shortName: 'Oui, CV et LM c’est quasiment la même chose',
          value: '1',
        },
        { shortName: 'Non le CV et la LM se complètent', value: '2' },
      ],
    },
    {
      subject:
        'Quels sont les éléments présents dans une lettre de motivation ?',
      answerValues: ['1', '2', '5', '8', '9'],
      options: [
        { shortName: 'Mes coordonnées', value: '1' },
        { shortName: 'Les coordonnées de l’entreprise', value: '2' },
        { shortName: "Des fautes d'orthographes", value: '3' },
        { shortName: 'Une liste de mes compétences', value: '4' },
        {
          shortName:
            'Un paragraphe expliquant en quoi mes compétences et expériences peuvent être utile pour le stage',
          value: '5',
        },
        { shortName: 'Mes défauts', value: '6' },
        { shortName: 'Ma photo', value: '7' },
        {
          shortName: 'La date et le lieu de rédaction de la lettre',
          value: '8',
        },
        { shortName: 'Une formule de politesse à la fin', value: '9' },
      ],
    },
    {
      subject: 'L’objectif de la lettre de motivation c’est :',
      answerValues: ['1', '2'],
      options: [
        {
          shortName: 'Montrer ma motivation pour décrocher un entretien',
          value: '1',
        },
        {
          shortName:
            'Se présenter et mettre en valeur mon parcours et mes compétences',
          value: '2',
        },
      ],
    },
    {
      subject: 'Quelle taille doit faire ma lettre de motivation ?',
      answerValues: ['2'],
      options: [
        { shortName: '4 lignes', value: '1' },
        { shortName: '1 page', value: '2' },
        { shortName: '2 pages', value: '3' },
      ],
    },
    {
      subject: 'Quelles formule n’est pas correcte ?',
      answerValues: ['2'],
      options: [
        { shortName: 'A l’intention de...', value: '1' },
        {
          shortName: 'A l’attention de…',
          value: '2',
        },
      ],
    },
    {
      subject:
        'Quelles sont les formules de politesse correctes pour une lettre de motivation ?',
      answerValues: ['3', '4', '5'],
      options: [
        {
          shortName: 'Au revoir et merci.',
          value: '1',
        },
        {
          shortName: 'Merci et à bientôt.',
          value: '2',
        },
        {
          shortName:
            'En espérant que ma candidature retiendra votre attention, veuillez agréer, Madame, Monsieur, mes sincères salutations.',
          value: '3',
        },
        {
          shortName:
            'Dans l’attente de vous lire, veuillez recevoir, Madame, Monsieur, mes sincères salutations.',
          value: '4',
        },

        {
          shortName:
            'Dans l’attente d’un retour favorable de votre part, veuillez agréer l’expression de mes sentiments distingués.',
          value: '5',
        },
        {
          shortName: 'Bien à toi',
          value: '6',
        },
      ],
    },
    {
      subject:
        "Je dois évoquer que je souhaite avoir un entretien avec l'entreprise dans ma lettre de motivation ?",
      answerValues: ['1'],
      options: [
        { shortName: 'Oui, cela montre ma motivation', value: '1' },
        {
          shortName: "Non, cela me donne l'air trop sûr de moi",
          value: '2',
        },
      ],
    },
  ];
  return (
    <CheckboxGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Réponds aux questions du quiz pour tester tes connaissances sur la
          rédaction d'une lettre de motivation.{' '}
        </Text>
      }
      getQuestionSection={(question) => (
        <Text mt={6}>
          <i>{question?.subject}</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={5}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.subject}>
                  <b>{q.subject} :</b>{' '}
                  {q.answerValues
                    .map(
                      (av) => q.options.find((o) => o.value === av).shortName
                    )
                    .join(', ')}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

const optionsTodoLM = {
  coordonnees: 'J’ai indiqué mes coordonnées (nom, prénom, contact, etc.)',
  entreprise: 'J’ai indiqué les coordonnées de l’entreprise (nom, adresse)',
  date: 'J’ai indiqué la date et le lieu de rédaction de la lettre',
  objet:
    'J’ai indiqué l’objet (par exemple “Candidature spontanée pour un stage dans…”)',
  personne:
    'J’ai adressé ma lettre à la bonne personne (Monsieur le Responsable de…, Madame la Responsable RH…)',
  accroche:
    'J’ai une bonne phrase d’accroche pour attirer l’attention du recruteur et l’inciter à lire ma lettre',
  formations:
    'J’ai montré que je m’étais renseigné sur l’entreprise et que je comprenais son activité',
  competences:
    'J’ai parlé de mes compétences et justifier d’expériences dans lesquelles j’ai pu les mobiliser',
  attentes:
    'J’ai évoqué mes attentes et ce que je souhaite découvrir dans l’entreprise',
  conclusion:
    'J’ai travaillé ma conclusion pour laisser une bonne dernière impression',
  formule: 'J’ai mis une formule de politesse à la fin',
  signe: 'J’ai signé ma lettre',
  comprehensible:
    'Mes phrases sont compréhensibles, pas trop longues et dynamiques',
  page: 'Ma lettre de motivation ne fait pas plus d’une page',
};

export const ChecklistLM = ({ goNext }) => (
  <>
    <Text mb={6}>
      Inspire-toi de ce modèle pour compléter ta lettre de motivation, puis
      coche les éléments de la liste pour t’assurer de n’avoir rien oublié.
    </Text>
    <Flex flexDirection={['column', null, 'column', 'row']}>
      <Box flex={2} mx={3} textAlign={'center'} mb={6}>
        <Image src={ImageLM} alt="Lettre de Motivation" />
      </Box>
      <Box flex={3}>
        <TodoActivite
          goNext={goNext}
          options={optionsTodoLM}
          fieldName={'checklistLM'}
        />
      </Box>
    </Flex>
  </>
);
