import { useState } from 'react';

import {
  Box,
  Radio,
  RadioGroup,
  Text,
  Heading,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { exists } from '@v1v2/util';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxContainer, RadioGroupControl } from 'formik-chakra-ui';
import { map } from 'lodash';

import {
  validateSchema,
  initFormikValuesFromSchema,
  convertNumbersToStrings,
} from '@inspire/data/helpers/schemas';
import { AttitudesOptions } from '@inspire/data/static/static';
import { SpesForBacPro } from '@inspire/data/static/bacs-pro';
import { MotivationOptionsBacPro } from '@inspire/data/static/motivations';
import { Diplomes } from '@inspire/data/static/bacs-pro';

import { BoostSchema } from '@inspire/data/schemas/user/profile/boost';
import {
  FormFrame,
  FormStack,
  CheckboxControlOptions,
  FormIntro,
} from '@inspire/ui/chakra/forms/Form';
import { StartStack } from '@inspire/ui/chakra/layout/Stack';
import { ExploreSchema } from '@inspire/data/schemas/user/profile/explore';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { MatieresLyceeForBac } from '@inspire/data/static/static';

import { LabelledSliderControl } from 'components/forms/LabelledSliderField';
import RessentiFormationControl from 'components/forms/controls/RessentiFormationControl';
import FaciliteTrouverStageControl from 'components/forms/controls/FaciliteTrouverStageControl';
import MatieresControl from 'components/forms/controls/MatieresControl';
import DiplomeControl from 'components/forms/controls/DiplomeControl';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { useClasse, useMyProfile } from 'lib/hooks';
import { updateMatieres } from 'lib/util';
import {
  GTM_CATEGORY_QUESTIONNAIRE,
  GTM_ACTION_QUESTION_4,
  pushEvent,
} from 'lib/gtm';

export const IntroQuestionnaire = () => (
  <>
    <Text textAlign="center">
      Nous allons te poser une série de questions qui serviront à te suggérer
      des pistes pour ton avenir.
    </Text>
    <Text textAlign="center">Clique sur Suivant pour commencer !</Text>
  </>
);

// TODO: replace by more generic MatieresForm
export const MatieresTroncCommun = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });
  const { classe } = useClasse();

  const matieresTC = MatieresLyceeForBac({
    filiere: classe?.filiere,
    classe: classe?.niveau,
    filiereBacPro: classe?.filiereBacPro,
  });

  const schema = ExploreSchema.pick('matieres', 'matieres.$').requireFields();

  const initValues = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, myProfile),
    ['matieres.$.like']
  );

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      matieres: matieresTC?.map((m) => ({
        matiere: m,
        like: initValues?.matieres?.find((o) => o.matiere === m)?.like || '',
        grade: initValues?.matieres?.find((o) => o.matiere === m)?.grade,
      })),
    },
    enableReinitialize: true,
    validate: (values) => {
      const incompleteFields = values.matieres.map(
        (m) => (!m.like || !m.grade) && 'required'
      );
      // need to return an empty object for isValid to be true
      return incompleteFields.filter((f) => !!f)?.length
        ? {
            matieres: incompleteFields,
          }
        : {};
    },
    onSubmit: (values) => {
      const existingMatieres = myProfile?.matieres;
      const newMatieres = schema.clean(values, {
        mutate: false,
        autoConvert: true,
      })?.matieres;
      setAndRefetchMyProfile({
        matieres: updateMatieres(classe, existingMatieres, newMatieres),
      });
      // this .tsx is not used though
      pushEvent(GTM_CATEGORY_QUESTIONNAIRE, GTM_ACTION_QUESTION_4);
    },
  };
  return (
    <Formik {...formik}>
      <Form>
        <FormIntro>
          Indique si tu apprécies les matières suivantes ainsi que tes moyennes.
        </FormIntro>
        <MatieresControl matieresForBacPro={matieresTC} />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

// TODO: replace by more generic MatieresForm
export const MatieresSpes = ({ goNext }) => {
  const { classe } = useClasse();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const spesForBacPro = Object.keys(SpesForBacPro(classe?.filiereBacPro));
  const schema = ExploreSchema.pick('matieres', 'matieres.$').requireFields();

  const initValues = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, myProfile),
    ['matieres.$.like']
  );

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      matieres: spesForBacPro?.map((m) => ({
        matiere: m,
        like: initValues?.matieres?.find((o) => o.matiere === m)?.like || '',
        grade: initValues?.matieres?.find((o) => o.matiere === m)?.grade,
      })),
    },
    enableReinitialize: true,
    validate: (values) => {
      const incompleteFields = values.matieres.map(
        (m) => (!m.like || !m.grade) && 'required'
      );
      // need to return an empty object for isValid to be true
      return incompleteFields.filter((f) => !!f)?.length
        ? {
            matieres: incompleteFields,
          }
        : {};
    },
    onSubmit: (values) => {
      const existingMatieres = myProfile?.matieres;
      const newMatieres = schema.clean(values, {
        mutate: false,
        autoConvert: true,
      })?.matieres;
      setAndRefetchMyProfile({
        matieres: updateMatieres(classe, existingMatieres, newMatieres),
      });
      pushEvent(GTM_CATEGORY_QUESTIONNAIRE, GTM_ACTION_QUESTION_4);
    },
  };

  return (
    <Formik {...formik}>
      <Form>
        <FormIntro>
          Indique si tu apprécies les matières suivantes ainsi que tes moyennes.
        </FormIntro>
        <MatieresControl matieresForBacPro={spesForBacPro} />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const Etudes = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = ExploreSchema.pick('motivation', 'motivation.$')
    .extend(
      LyceenBacProProfileSchema.pick(
        'diplomes',
        'diplomes.$',
        'ressentiFormation',
        'difficulteTrouverStage'
      )
    )
    .requireFields(['ressentiFormation', 'difficulteTrouverStage']);

  const initialValues = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, myProfile),
    ['ressentiFormation', 'difficulteTrouverStage']
  );

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      <Form>
        <FormStack>
          <DiplomeControl />
          <CheckboxContainer
            as="fieldset"
            name="motivation"
            label="Pendant tes années lycées, tu as :"
            stackProps={{ pl: 0 }}
          >
            <CheckboxControlOptions
              name="motivation"
              options={Object.keys(MotivationOptionsBacPro).map((k) => ({
                label: MotivationOptionsBacPro[k],
                value: k,
              }))}
            />
          </CheckboxContainer>
          <FormFrame>
            <RessentiFormationControl isRequired />
            <FaciliteTrouverStageControl isRequired />
          </FormFrame>
        </FormStack>
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const Attitude = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'attitudes',
    'attitudes.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      attitudes: map(AttitudesOptions, (value, key) => ({
        key,
        value: (initValues.attitudes || []).find((ia) => ia.key === key)?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormFrame
            legend="Choisis les qualificatifs correspondants à ton attitude en classe :"
            stackProps={{ spacing: 5 }}
          >
            {Object.keys(AttitudesOptions).map((a, i) => (
              <LabelledSliderControl
                key={i}
                name={`attitudes[${i}].value`}
                label={AttitudesOptions[a]}
                value={values.attitudes?.[i]?.value}
                sliderProps={{ lowLabel: 'Pas du tout', highLabel: 'Très' }}
                isRequired
              />
            ))}
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

export const PrevisionPostBac = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'previsionOccupationPostBac',
    'previsionAnneesEtudesPostBac'
  ).requireFields(['previsionOccupationPostBac']);

  const initialValues = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, myProfile),
    ['previsionAnneesEtudesPostBac']
  );
  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      {({ values, setFieldValue }) => (
        <Form>
          <FormFrame stackProps={{ spacing: 5 }}>
            <RadioGroupControl
              name="previsionOccupationPostBac"
              label="Tu t'imagines :"
              stackProps={{ direction: 'column' }}
              isRequired
            >
              <Radio value="etudes">Poursuivre des études après le Bac</Radio>
              <Radio
                value="travail"
                onChange={() => {
                  setFieldValue('previsionAnneesEtudesPostBac', undefined);
                  setFieldValue('previsionOccupationPostBac', 'travail');
                }}
              >
                Travailler directement après le Bac
              </Radio>
              <Radio value="ne-sait-pas">Je ne sais pas encore</Radio>
            </RadioGroupControl>

            {(values.previsionOccupationPostBac === 'etudes' ||
              values.previsionOccupationPostBac === 'ne-sait-pas') && (
              <RadioGroupControl
                name="previsionAnneesEtudesPostBac"
                label="Tu te vois étudier pendant :"
                stackProps={{ direction: 'column' }}
                isRequired
              >
                <Radio value="1">1 année après le Bac</Radio>
                <Radio value="2">2 années après le Bac</Radio>
                <Radio value="3">3 années après le Bac</Radio>
                <Radio value="5">5 années ou plus après le Bac</Radio>
              </RadioGroupControl>
            )}
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

export const LieuDeVie = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'previsionQuitterDomicileParental',
    'previsionEloignementDomicileParental',
    'previsionTempsMaxTrajetParJour',
    'previsionTypeTransport',
    'previsionTypeTransport.$'
  ).requireFields([
    'previsionQuitterDomicileParental',
    'previsionTempsMaxTrajetParJour',
    'previsionTypeTransport',
    'previsionTypeTransport.$',
  ]);

  const initValues = initFormikValuesFromSchema(schema, myProfile);
  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      previsionQuitterDomicileParental: exists(
        myProfile?.previsionQuitterDomicileParental
      )
        ? // The conversion gives false => '', but we need 'false' here because it's a radio
          String(initValues.previsionQuitterDomicileParental || false)
        : '',
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      {({ values, setFieldValue }) => (
        <Form>
          <FormStack>
            <FormFrame>
              <RadioGroupControl
                name="previsionQuitterDomicileParental"
                label="Es-tu prêt.e à quitter le domicile parental après ton Bac ?"
                stackProps={{ spacing: 3 }}
                isRequired
              >
                <Radio value="true">Oui</Radio>
                <Radio
                  value="false"
                  onChange={() => {
                    setFieldValue(
                      'previsionEloignementDomicileParental',
                      undefined
                    );
                    setFieldValue('previsionQuitterDomicileParental', 'false');
                  }}
                >
                  Non
                </Radio>
              </RadioGroupControl>
              {values.previsionQuitterDomicileParental === 'true' && (
                <RadioGroupControl
                  name="previsionEloignementDomicileParental"
                  label="Jusqu'où es-tu prêt.e à aller ?"
                  stackProps={{ direction: 'column' }}
                  isRequired
                >
                  <Radio value="departement">Dans ton département</Radio>
                  <Radio value="region">Dans ta région</Radio>
                  <Radio value="france">
                    Dans n'importe quelle ville de France
                  </Radio>
                  <Radio value="etranger">A l'étranger</Radio>
                </RadioGroupControl>
              )}
            </FormFrame>
            <FormFrame>
              <RadioGroupControl
                name="previsionTempsMaxTrajetParJour"
                label="Pour ton projet d’orientation, tu es prêt.e à faire au maximum :"
                stackProps={{ direction: 'column' }}
                isRequired
              >
                <Radio value="30m">30 minutes de trajet par jour</Radio>
                <Radio value="1h">1 heure de trajet par jour</Radio>
                <Radio value="2h">2 heures de trajet par jour</Radio>
                <Radio value="2h+">Plus de 2 heures de trajet par jour</Radio>
              </RadioGroupControl>

              <CheckboxContainer
                name="previsionTypeTransport"
                label="Avec quel moyen de transport ?"
                stackProps={{ pl: 0 }}
              >
                <CheckboxControlOptions
                  name="previsionTypeTransport"
                  options={[
                    { label: 'A pied', value: 'pied' },
                    {
                      label:
                        'Transport en commun (bus, car, métro, train, etc.)',
                      value: 'transports',
                    },
                    { label: 'Voiture', value: 'voiture' },
                    { label: 'Covoiturage', value: 'covoiturage' },
                    { label: 'Moto, scooter, etc', value: 'moto' },
                    { label: 'Vélo', value: 'velo' },
                  ]}
                />
              </CheckboxContainer>
            </FormFrame>
          </FormStack>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

export const Finances = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'connaitCoutEtudes',
    'financementEtudesFrein'
  )
    .extend(BoostSchema.pick('isBoursierSecondaire'))
    .requireFields();

  const initialValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormFrame>
            <LabelledSliderControl
              name="connaitCoutEtudes"
              label="Tu connais le montant nécessaire pour poursuivre
                tes études (frais de scolarité, logement, nourriture, etc.) :"
              value={values.connaitCoutEtudes}
              isRequired
            />
            <LabelledSliderControl
              label="Penses-tu que ta situation financière sera un frein à la poursuite de tes études ?"
              name="financementEtudesFrein"
              value={values.financementEtudesFrein}
              isRequired
            />
            <RadioGroupControl
              label="Es-tu boursier.ère ?"
              name="isBoursierSecondaire"
              isRequired
            >
              <Radio value="oui">Oui</Radio>
              <Radio value="non">Non</Radio>
            </RadioGroupControl>
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

const DebouchesOptions = {
  debouchesFaciliteTrouverEmploi: 'Je vais facilement trouver un emploi',
  debouchesEvolutionDeCarriere: 'Je peux envisager des évolutions de carrière',
  debouchesBonSalaire: 'Je vais avoir un bon salaire',
  debouchesRecherches: 'J’aurai accès à des métiers recherchés',
  debouchesValorises: 'J’aurai accès à des métiers valorisés',
  debouchesUtiles: 'J’aurai accès à des métiers utiles',
};

export const Debouches = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'debouches',
    'debouches.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      debouches: map(DebouchesOptions, (value, key) => ({
        key: key,
        value: (initValues.debouches || []).find((ef) => ef.key === key)?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormFrame legend="Grâce à ma formation...">
            {Object.keys(DebouchesOptions).map((key, i) => (
              <LabelledSliderControl
                key={key}
                name={`debouches[${i}].value`}
                label={DebouchesOptions[key]}
                value={values.debouches[i]?.value}
                isRequired
              />
            ))}
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

export const Encouragement = () => {
  const [cavaValue, setCavaValue] = useState(null);

  return (
    <FormFrame legend="Tu viens de faire la moitié du questionnaire. Ça va ?">
      <RadioGroup onChange={setCavaValue} value={cavaValue}>
        <StartStack>
          <Radio value="super">Ça va super.</Radio>
          <Radio value="bien-sur">Bien sûr, envoie la suite !</Radio>
          <Radio value="hate-de-finir">
            Oui, mais j'ai hâte d’avoir terminé.
          </Radio>
          <Radio value="groot">Je suis Groot.</Radio>
        </StartStack>
      </RadioGroup>
    </FormFrame>
  );
};

export const Continue = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'connaitPersonnesMemeDomaine',
    'prevoitCreerEntreprise'
  ).requireFields();

  const initValues = convertNumbersToStrings(
    initFormikValuesFromSchema(schema, myProfile),
    ['connaitPersonnesMemeDomaine']
  );

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      ...initValues,
      prevoitCreerEntreprise: exists(myProfile?.prevoitCreerEntreprise)
        ? // The conversion gives false => '', but we need 'false' here because it's a radio
          String(initValues.prevoitCreerEntreprise || false)
        : '',
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      <Form>
        <FormFrame>
          <RadioGroupControl
            name="connaitPersonnesMemeDomaine"
            label="Connais-tu des personnes (hors lycée et stages) qui travaillent dans le même domaine que toi ?"
            stackProps={{ direction: 'column' }}
            isRequired
          >
            <Radio value="0">Aucune</Radio>
            <Radio value="1">1 personne</Radio>
            <Radio value="2">2 personnes</Radio>
            <Radio value="3">3 personnes</Radio>
            <Radio value="4">4 personnes</Radio>
            <Radio value="5">5 personnes ou plus</Radio>
          </RadioGroupControl>
          <RadioGroupControl
            name="prevoitCreerEntreprise"
            label="Dans ton futur professionnel, prévois-tu de créer ta propre entreprise ?"
            stackProps={{ spacing: 3 }}
            isRequired
          >
            <Radio value="true">Oui</Radio>
            <Radio value="false">Non</Radio>
          </RadioGroupControl>
        </FormFrame>
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

export const AvenirProfessionnel = ({ goNext, options }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'avenirPro',
    'avenirPro.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);
  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      avenirPro: map(options, (value, key) => ({
        key: key,
        value: (initValues.avenirPro || []).find((a) => a.key === key)?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      const input = schema.clean(values, {
        mutate: false,
        autoConvert: true,
      });
      const avenirPro = (
        myProfile?.avenirPro?.filter(
          (a) => !Object.keys(options).includes(a.key)
        ) || []
      )?.concat(input.avenirPro);
      return setAndRefetchMyProfile({ avenirPro });
    },
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormFrame legend="Donne ton avis sur les affirmations suivantes :">
            {Object.keys(options).map((key, i) => (
              <LabelledSliderControl
                key={i}
                label={options[key]}
                name={`avenirPro[${i}].value`}
                value={values.avenirPro[i].value}
                isRequired
              />
            ))}
          </FormFrame>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

const AvenirProOptions1 = {
  avisCapableRealiserProjetsPro:
    'Je suis capable de réaliser mes projets professionnels',
  avisChoixMetierDependDeMoi: 'Choisir mon métier, cela ne dépend que de moi',
  avisConnaitEtapesProjetPro:
    'Je connais les étapes pour construire mon projet professionnel',
};

const AvenirProOptions2 = {
  avisSaitCeQuiMinteresseAvenirPro:
    'Je sais ce qui m’intéresse pour mon avenir professionnel',
  avisConfiantSuiteParcours:
    'Je suis confiant en la suite de mon parcours scolaire et professionnel',
  avisDoutesBloquants:
    'Mon angoisse me bloque dans la recherche de mon avenir professionnel',
};

const QualiteOptions = {
  avisConnaitPointsForts: 'J’arrive à déterminer mes points forts',
  avisConnaitPointsFaibles: 'J’arrive à déterminer mes points faibles',
  avisPrendLeTempsAnalyserSituation:
    'Je prends le temps d’analyser la situation pour prendre une décision',
};

export const AvenirProfessionnel1 = ({ goNext }) => (
  <AvenirProfessionnel goNext={goNext} options={AvenirProOptions1} />
);
export const AvenirProfessionnel2 = ({ goNext }) => (
  <AvenirProfessionnel goNext={goNext} options={AvenirProOptions2} />
);

export const Qualites = ({ goNext }) => (
  <AvenirProfessionnel goNext={goNext} options={QualiteOptions} />
);

export const RecapQuestionnairePremiere = ({ profile }) => (
  <>
    {profile.diplomes && (
      <Box>
        <Heading size="sm" as="h3">
          Diplômes
        </Heading>
        <UnorderedList>
          {profile.diplomes.map((d, i) => (
            <ListItem key={i}>{Diplomes[d] ?? d}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    )}
    {profile.difficulteTrouverStage && (
      <Box>
        <Heading size="sm" as="h3">
          Difficulté à trouver un stage :
        </Heading>
        <Box>
          {profile.difficulteTrouverStage} / 7{' '}
          <Box display="inline-block" ml={1}>
            (7 = Très facile)
          </Box>
        </Box>
      </Box>
    )}
    {profile.ressentiFormation && (
      <Box>
        <Heading size="sm" as="h3">
          Se sent bien dans cette formation
        </Heading>
        <Box>
          {profile.ressentiFormation} / 7{' '}
          <Box display="inline-block" ml={1}>
            (7 = Très bien)
          </Box>
        </Box>
      </Box>
    )}
  </>
);

export const RecapQuestionnaireTerminale = RecapQuestionnairePremiere;
