import { useEffect } from 'react';

import {
  Heading,
  Text,
  ListItem,
  UnorderedList,
  Radio,
  AspectRatio,
  Box,
  Progress,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { RadioGroupControl, TextareaControl } from 'formik-chakra-ui';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import { FormStack } from '@inspire/ui/chakra/forms/Form';
import { inspireYellow } from '@inspire/ui/static';
import Span from '@inspire/ui/chakra/Span';

import { CheckboxGame, RadioGame } from 'components/activites/iterative-games';
import ConsigneTag from 'components/activites/ConsigneTag';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import ClickableImage from 'components/general/ClickableImage';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import VideoPlayer from 'components/general/VideoPlayer';
import { RecapTextArea } from 'components/activites/recap-helpers';
import ProfTips from 'components/activites/ProfTips';
import { useMyProfile, useStepContext, useUser } from 'lib/hooks';
import { getPercent } from 'lib/util';

import softSkillsInfographieSrc from '../../../../public/img/infographies/soft-skills-terminale.png';

export const DefinitionCompetencesTransversales = ({ allowNext }) => {
  const questions = [
    {
      subject: null,
      answerValues: [
        'creativite',
        'empathie',
        'rigueur',
        'organisation',
        'fiabilite',
        'adaptabilite',
      ],
    },
  ];

  const options = [
    { shortName: 'Créativité', value: 'creativite' },
    {
      shortName: 'Gestion des réseaux sociaux',
      value: 'gestion-reseaux-sociaux',
    },
    { shortName: 'Maçonnerie', value: 'maconnerie' },
    { shortName: 'Empathie', value: 'empathie' },
    { shortName: 'Maîtrise d’Excel', value: 'excel' },
    { shortName: 'Rigueur', value: 'rigueur' },
    { shortName: 'Organisation', value: 'organisation' },
    { shortName: 'Fiabilité', value: 'fiabilite' },
    { shortName: 'Couture', value: 'couture' },
    { shortName: 'Adaptabilité', value: 'adaptabilite' },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <Heading size="sm" as="h4">
        Définition des compétences transversales/soft skills
      </Heading>
      <Text mb={8}>
        Les <b>soft skills</b> sont des capacités acquises durant toutes les
        expériences vécues (y compris en dehors de l’école et/ou du travail).
        Elles sont aussi appelées <b>compétences transversales</b> car elles
        peuvent être utilisées dans des contextes très différents.
        <br />
        <b>Par exemple</b> : l’autonomie et le travail en équipe.
      </Text>
      <Text mb={8}>
        Les compétences transversales/soft skills se distinguent des{' '}
        <b>hard skills</b> qui sont des compétences techniques, c’est-à-dire des
        connaissances et savoirs-faire spécifiques à un métier ou à un secteur
        d’activité.
        <br />
        <b>Par exemple</b> : la maîtrise d’une langue étrangère ou d’un logiciel
        informatique.
      </Text>
      <CheckboxGame
        questions={questions}
        getOptions={() => options}
        onFinished={() => allowNext(true)}
        introSection={
          <Text textAlign="center">
            <ConsigneTag /> : Parmi les propositions suivantes, lesquelles sont
            des compétences transversales/soft skills ?
          </Text>
        }
        getQuestionSection={() => null}
        getSummarySection={(_, index) =>
          index !== 0 && (
            <SummaryFrame mt={8}>
              La <b>créativité</b>, l'<b>empathie</b>, la <b>rigueur</b>, l'
              <b>organisation</b>, la <b>fiabilité</b>, et l'<b>adaptabilité</b>{' '}
              sont des compétences transversales/soft skills.
            </SummaryFrame>
          )
        }
      />
    </>
  );
};

export const ImportanceSoftSkillsRecruteurs = ({ allowNext }) => {
  const questions = [{ subject: null, answerValue: '97' }];

  const options = [
    { shortName: '52%', value: '52' },
    { shortName: '84%', value: '84' },
    { shortName: '97%', value: '97' },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <>
      <RadioGame
        questions={questions}
        getOptions={() => options}
        onFinished={() => allowNext(true)}
        introSection={
          <Text textAlign="center">
            <ConsigneTag /> : A ton avis, combien de recruteurs tiennent
            comptent des compétences transversales/soft skills lorsqu’ils
            recrutent ?
          </Text>
        }
        getQuestionSection={() => null}
        getSummarySection={(_, index) =>
          index !== 0 && (
            <ClickableImage
              src={softSkillsInfographieSrc}
              alt="Infographie Soft skills"
              href="/img/infographies/soft-skills-terminale.png"
            />
          )
        }
      />
    </>
  );
};

export const TemoignageSoftSkills = () => (
  <>
    <ReflexionOralHeader />
    <Text>
      <ConsigneTag /> : Découvre ces témoignages sur les compétences
      transversales/soft skills qui peuvent être développées dans des activités
      extra-scolaires et utiles pour le monde du travail.
    </Text>
    <AspectRatio ratio={1} maxW="400px" mx="auto" my={5}>
      <VideoPlayer url="/videos-bacpro/soft-skills.mp4" />
    </AspectRatio>
    <Text>Puis réponds oralement aux questions :</Text>
    <UnorderedList>
      <ListItem>
        Quelles sont les compétences présentées par les deux personnes qui
        témoignent ?
      </ListItem>
      <ListItem>
        Comment Cheik a-t-il utilisé ses compétences dans ses loisirs ?
      </ListItem>
      <ListItem>
        Comment Imtinen a-t-elle utilisé ses compétences dans ses activités
        professionnelles ?
      </ListItem>
    </UnorderedList>
  </>
);

export const ExperienceStage = () => {
  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const schema = LyceenBacProProfileSchema.pick(
    'experienceStage'
  ).requireFields(['experienceStage']);

  const initialValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <Formik {...formik}>
      <Form>
        <TextareaControl
          label="Décris ton expérience de stage et tes tâches réalisées en une ou deux phrases."
          name="experienceStage"
        />
        <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
      </Form>
    </Formik>
  );
};

const RadioStep = ({ fieldName, text, options }) => {
  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const schema = LyceenBacProProfileSchema.pick(fieldName).requireFields([
    fieldName,
  ]);

  const initialValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <>
      <Text>
        <ConsigneTag /> : Sélectionne l'une de ces compétences que tu penses
        avoir appliquée durant ton expérience de stage.
      </Text>
      <Formik {...formik}>
        <Form>
          <RadioGroupControl
            stackProps={{ direction: 'column' }}
            label={text}
            name={fieldName}
          >
            {options.map(({ key, title, description }) => (
              <Radio key={key} value={key}>
                <b>{title}</b> : {description}
              </Radio>
            ))}
          </RadioGroupControl>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

const capacitesSocialesTopics = [
  {
    key: 'communication-ecrite',
    title: 'Communication écrite',
    description: 'J’ai écris des textes, des mails, etc.',
    questions: [
      {
        key: 'procedures-ecrites',
        text: 'Je comprends bien les procédures écrites qui me sont données durant mon stage.',
      },
      {
        key: 'reflexion-autres',
        text: 'Je pense à ce dont les personnes ont besoin de savoir pour formuler mon message.',
      },
      {
        key: 'sources',
        text: 'Je sais dire d’où viennent les sources d’informations que j’utilise si je dois faire un rapport/une présentation.',
      },
      {
        key: 'redaction',
        text: 'Je pense aux personnes qui vont me lire pour travailler ma rédaction (orthographe, grammaire, vocabulaire, etc.).',
      },
    ],
  },
  {
    key: 'communication-orale',
    title: 'Communication orale',
    description:
      'J’ai pris la parole à l’oral, j’ai fait des appels téléphoniques, etc.',
    questions: [
      {
        key: 'ma-presentation-claire',
        text: "Je me présente et j'explique mon parcours avec clarté.",
      },
      {
        key: 'expression-personnes-connues',
        text: "Je m'exprime facilement face aux personnes que je connais.",
      },
      {
        key: 'pas-couper-la-parole',
        text: "Je ne coupe pas la parole aux personnes qui s'expriment.",
      },
      {
        key: 'confiant-presente-groupe',
        text: 'Je ne suis pas déstabilisé.e lorsque je présente un travail à un groupe de personnes.',
      },
    ],
  },
  {
    key: 'communication-visuelle',
    title: 'Communication visuelle',
    description: 'J’ai fait des schémas, j’ai réalisé des affiches, etc.',
    questions: [
      {
        key: 'modeles-originaux',
        text: "Je m'inspire de modèles originaux pour réaliser les formats de mes présentations.",
      },
      {
        key: 'trouve-images-videos',
        text: 'Je trouve facilement des images ou des vidéos qui illustrent mon sujet.',
      },
      {
        key: 'realiser-plan-schema',
        text: 'Je sais réaliser un plan ou un schéma pour résumer quelque chose.',
      },
      {
        key: 'forme-documents',
        text: "Je prends du temps pour soigner la forme de mes documents (organisation, paragraphes, ponctuation couleurs, police d'écriture, etc.).",
      },
    ],
  },
  {
    key: 'communication-non-verbale',
    title: 'Communication non verbale',
    description:
      'J’ai adopté des comportements ou codes vestimentaires spécifiques, etc.',
    questions: [
      {
        key: 'decontracte-rencontre',
        text: 'Je reste souriant.e et décontracté.e lorsque je rencontre une nouvelle personne.',
      },
      {
        key: 'langage-corporel',
        text: 'Je peux comprendre la signification du langage corporel de mon interlocuteur.trice (regards, hochements de tête, bras croisés, etc.).',
      },
      {
        key: 'distance-convenable',
        text: "Je pense à garder une distance physique convenable avec les personnes à qui je m'adresse.",
      },
      {
        key: 'attention-apparence',
        text: "Je fais généralement attention à ma façon de m'habiller, à ma posture et à mes gestes lorsque je m'adresse à des personnes.",
      },
    ],
  },
  {
    key: 'communication-numerique',
    title: 'Communication numérique',
    description: 'J’ai communiqué sur les réseaux sociaux, etc.',
    questions: [
      {
        key: 'partage-reseaux-sociaux',
        text: "Je partage de l'information sur les réseaux sociaux.",
      },
      {
        key: 'ressources-internet',
        text: "Je sais mobiliser des ressources sur Internet pour m'aider à avancer dans mon stage.",
      },
      {
        key: 'communication-differente-perso-pro',
        text: 'Je sais que je dois communiquer différemment sur les réseaux sociaux pour ma communication personnelle et ma communication professionnelle.',
      },
      {
        key: 'differents-type-documents',
        text: 'Je sais transmettre différents types de documents par internet (mails, sites, etc.).',
      },
    ],
  },
  {
    key: 'ecoute-active',
    title: 'Écoute active',
    description:
      'J’ai écouté sans interrompre, j’ai compris et j’ai posé des questions, etc.',
    questions: [
      {
        key: 'reperer-probleme-personne',
        text: 'Je peux facilement repérer que la personne qui me parle rencontre un problème dont elle ne me parle pas.',
      },
      {
        key: 'temps-ecouter',
        text: 'Je prends du temps pour écouter les besoins des autres.',
      },
      {
        key: 'pose-questions',
        text: 'Je pose des questions afin de mieux comprendre la personne qui me parle.',
      },
      {
        key: 'cherche-comprendre-emotions',
        text: "Je cherche à comprendre les émotions de la personne qui s'adresse à moi.",
      },
    ],
  },
  {
    key: 'travail-en-equipe',
    title: 'Travail en équipe',
    description: 'J’ai travaillé avec le reste de l’équipe, etc.',
    questions: [
      {
        key: 'investir-projets-groupe',
        text: "Je m'investis facilement dans les projets ou les activités en groupe.",
      },
      {
        key: 'exprimer-opinion-fonctionnement-groupe',
        text: "Je suis capable d'exprimer mon opinion sur le fonctionnement du groupe aux autres membres.",
      },
      {
        key: 'travail-groupe-differentes-manieres',
        text: "Je sais travailler en groupe de différentes manières (en présentiel, à distance, à l'aide d'outils collaboratifs, etc.).",
      },
      {
        key: 'aide-responsables',
        text: 'J’aide les personnes responsables du groupe dans l’organisation.',
      },
    ],
  },
  {
    key: 'perseverance',
    title: 'Persévérance',
    description:
      'J’ai dû recommencer plusieurs fois une même tâche sans abandonner, etc.',
    questions: [
      {
        key: 'desaccord-motivation',
        text: 'Si on n’est pas d’accord avec une de mes propositions, je ne me démotive pas et j’arrive à en proposer d’autres.',
      },
      {
        key: 'efforts-reguliers',
        text: 'Je fais des efforts régulièrement lors de mon activité professionnelle.',
      },
      {
        key: 'capable-debat',
        text: 'Je suis capable de m’engager dans un débat.',
      },
      {
        key: 'temps-objectif',
        text: 'J’arrive à trouver du temps en plus pour atteindre un objectif.',
      },
    ],
  },
];

const capacitesPersonnellesTopics = [
  {
    key: 'confiance-en-soi',
    title: 'Confiance en soi',
    description:
      'J’ai donné mon avis, j’ai fait une présentation devant mon équipe, j’ai identifié mes points forts, etc.',
    questions: [
      {
        key: 'exprimer-opinion-groupe-idees-inconnues',
        text: 'Je sais exprimer mon opinion face à un groupe dont je ne connais pas les idées.',
      },
      {
        key: 'exprimer-opinion-inconnu',
        text: 'Je suis capable d’exprimer mon opinion à quelqu’un que je ne connais pas.',
      },
      {
        key: 'mettre-en-avant-mes-qualites',
        text: 'Je sais mettre en avant mes qualités lorsque je parle de moi.',
      },
      {
        key: 'nouvelles-activites',
        text: "Je peux facilement m'engager dans de nouvelles activités.",
      },
    ],
  },
  {
    key: 'responsabilite',
    title: 'Responsabilité',
    description: 'J’étais responsable d’une ou de plusieurs missions, etc.',
    questions: [
      {
        key: 'opinions-claires-groupe',
        text: 'Dans un groupe, je sais exprimer clairement mes opinions et mes intentions.',
      },
      {
        key: 'reconnaitre-erreur-difficulte',
        text: 'Je sais reconnaître ma part de responsabilité lorsque j’ai fait une erreur, ou reconnaître que j’ai rencontré une difficulté ou un échec, etc.',
      },
      {
        key: 'attention-entourage',
        text: "Quand je fais quelque chose, je fais attention à ce que cela pourrait provoquer autour de moi (les personnes qui m'entourent, le lieu où je suis, etc.).",
      },
      {
        key: 'difference-concerne-moi-autres',
        text: 'Quand je prends une décision, je fais la différence entre ce qui ne concerne que moi et ce qui engage mon groupe ou mon organisation.',
      },
    ],
  },
  {
    key: 'autonomie',
    title: 'Autonomie',
    description:
      'J’ai travaillé seul, je devais m’organiser seul pour effectuer mes tâches, etc.',
    questions: [
      {
        key: 'seul-efficace',
        text: 'Je sais travailler tout seul de manière efficace.',
      },
      {
        key: 'concevoir-presenter-idee',
        text: 'Je sais concevoir et présenter une idée.',
      },
      {
        key: 'travail-sans-surveillance',
        text: "Je sais réaliser une tâche sans que l'on me surveille.",
      },
      {
        key: 'difference-prive-travail',
        text: 'Je sais faire la différence entre ce qui est du domaine de ma vie privée et ce qui est du domaine de mon travail.',
      },
    ],
  },
  {
    key: 'motivation-implication',
    title: 'Motivation et implication',
    description:
      'Je me suis investi malgré des difficultés, j’ai argumenté ma motivation pour le stage, etc.',
    questions: [
      {
        key: 'ne-se-decourage-pas',
        text: 'Je ne me décourage pas si j’ai des difficultés ou si j’ai du mal à rester motivé durant mon stage.',
      },
      {
        key: 'renseignements-entreprise',
        text: "Je me suis renseigné sur l'histoire et les activités de l'entreprise que j’ai rejoint pour mon stage.",
      },
      {
        key: 'autres-activites-que-prevues',
        text: 'Je suis prêt à faire d’autres activités que celles prévues dans mon stage si on me le demande.',
      },
      {
        key: 'positif-difficulte',
        text: 'Je sais rester positif lorsque je rencontre une difficulté.',
      },
    ],
  },
  {
    key: 'gestion-stress',
    title: 'Gestion du stress',
    description: 'J’ai été confronté à des situations stressantes, etc.',
    questions: [
      {
        key: 'reconnait-personne-stressee',
        text: "Je sais voir qu'une personne est stressée.",
      },
      {
        key: 'concentration-pression',
        text: "J'arrive à me concentrer quand je suis sous pression.",
      },
      {
        key: 'difficulte-recul-solution',
        text: "Dans une situation difficile, j'arrive à prendre du recul pour trouver une solution.",
      },
      {
        key: 'aide-groupe-serenite',
        text: "J'aide le groupe à rester le plus calme et serein possible en situation d'urgence.",
      },
    ],
  },
  {
    key: 'reactivite',
    title: 'Réactivité',
    description: 'J’ai dû travailler rapidement, etc.',
    questions: [
      {
        key: 'presence-imprevu',
        text: 'Je serais là si une situation imprévue apparaît.',
      },
      {
        key: 'tient-compte-info-imprevu',
        text: 'Je tiens compte des informations qui apportent un changement dans mes tâches.',
      },
      {
        key: 'classer-mes-reponses',
        text: 'Je sais classer mes réponses selon leur degré d’importance.',
      },
      {
        key: 'adaptation-changement-contexte',
        text: 'Je sais m’adapter à un changement de contexte.',
      },
    ],
  },
];

const capacitesMethodologiquesTopics = [
  {
    key: 'rigueur-fiabilite',
    title: 'Rigueur et fiabilité',
    description:
      'J’ai réalisé mes tâches correctement, on pouvait compter sur moi, etc.',
    questions: [
      {
        key: 'methodes-precises-groupe',
        text: "J'applique des méthodes précises au sein de mon groupe.",
      },
      {
        key: 'verifie-resultats',
        text: 'Je prends du temps pour vérifier mes résultats avant de les transmettre.',
      },
      {
        key: 'recherche-procedures-amelioration',
        text: "Je recherche des procédures ou méthodes fiables afin d'améliorer mon travail.",
      },
      {
        key: 'hygiene-securite-sante',
        text: "J'ai conscience des règles d'hygiène, de sécurité et de protection de la santé dans l'environnement où je réalise une activité.",
      },
    ],
  },
  {
    key: 'organisation',
    title: 'Organisation',
    description:
      'J’ai utilisé un calendrier, un emploi du temps, une liste de tâches, etc.',
    questions: [
      {
        key: 'methode-organisation-actions',
        text: 'Je sais utiliser une méthode pour organiser différentes actions.',
      },
      {
        key: 'repartir-priorites',
        text: 'Face à un problème, je sais répartir les actions à faire des plus prioritaires au moins prioritaires.',
      },
      {
        key: 'anticiper-risques-imprevus',
        text: "Dans un projet ou dans la résolution d'un problème, je sais m'y prendre pour anticiper les risques et les imprévus.",
      },
      {
        key: 'trouver-solutions-probleme-innatendu',
        text: 'Je sais trouver des solutions pour régler un problème inattendu.',
      },
    ],
  },
  {
    key: 'strategie',
    title: 'Stratégie',
    description:
      'J’ai pris des décisions en ayant des objectifs, j’ai mis en place un plan d’action, etc.',
    questions: [
      {
        key: 'decouper-etapes-mission',
        text: 'Je sais découper en plusieurs étapes la réalisation d’une mission.',
      },
      {
        key: 'trouver-outils-methodes-problemes',
        text: 'Je sais trouver des outils ou des méthodes pour résoudre un problème.',
      },
      {
        key: 'identifier-facteurs-reussite-stage',
        text: 'Je sais identifier les facteurs qui peuvent me permettre de bien réussir mon stage.',
      },
      {
        key: 'anticiper-court-moyen-long-terme',
        text: "Je suis capable d'exécuter une mission avec une vision à court, moyen et long terme (anticipation).",
      },
    ],
  },
  {
    key: 'prise-de-decision',
    title: 'Prise de décision',
    description: 'J’ai fait des choix assumés, etc.',
    questions: [
      {
        key: 'evaluation-risques-decision',
        text: "J'évalue les risques lorsque je prends une décision.",
      },
      {
        key: 'pointe-risques-desaccord',
        text: 'Je pointe les risques lorsque le groupe n’arrive pas à se mettre d’accord.',
      },
      {
        key: 'infos-fiables-decisions',
        text: 'Pour prendre mes décisions, je me base sur des informations fiables.',
      },
      {
        key: 'consulte-decision-importante',
        text: 'Je consulte une ou plusieurs personnes pour prendre des décisions importantes.',
      },
    ],
  },
  {
    key: 'gestion-du-temps',
    title: 'Gestion du temps',
    description:
      'J’avais des dates butoires à respecter, j’avais un calendrier, etc.',
    questions: [
      {
        key: 'realisation-temps-prevu',
        text: "Je réalise généralement mes tâches et mes activités dans les temps que j'ai prévus.",
      },
      {
        key: 'anticipation-retard-tache',
        text: "J'anticipe les risques de retard sur une tâche prévue.",
      },
      {
        key: 'solutions-gain-de-temps',
        text: 'Je cherche des solutions pour gagner du temps sur mes activités sans perdre en qualité.',
      },
      {
        key: 'organisation-prevoyance-imprevus',
        text: "J'organise mon temps en prévoyant qu’il ait potentiellement des imprévus.",
      },
    ],
  },
  {
    key: 'curiosite',
    title: 'Curiosité',
    description: 'J’ai posé des questions, j’ai cherché des informations, etc.',
    questions: [
      {
        key: 'observation-nouvel-environnement',
        text: "J'observe attentivement lorsque j’arrive dans un nouvel environnement.",
      },
      {
        key: 'applique-conseils',
        text: "J’applique les conseils qu’on me donne pour m'améliorer dans mes activités.",
      },
      {
        key: 'au-courant-nouveautes',
        text: "Je suis au courant des nouveautés dans les domaines qui m'intéressent.",
      },
      {
        key: 'comprendre-situation-inhabituelle',
        text: 'Je cherche à comprendre une situation inhabituelle.',
      },
    ],
  },
  {
    key: 'imagination',
    title: 'Imagination',
    description:
      'J’ai donné de nouvelles idées, j’ai tenté de créer des choses différentes, etc.',
    questions: [
      {
        key: 'exemple-comprehension',
        text: 'Je sais donner un exemple afin de bien me faire comprendre.',
      },
      {
        key: 'imaginer-situation-decrite',
        text: 'J’arrive facilement à m’imaginer et me mettre dans une situation que l’on me décrit.',
      },
      {
        key: 'images-supports-comprehension',
        text: 'J’arrive à utiliser des images ou d’autres supports qui peuvent faciliter la compréhension de mon idée.',
      },
      {
        key: 'association-idees-situations',
        text: 'J’arrive à trouver des points communs à deux idées ou situations différentes afin de les associer.',
      },
    ],
  },
];

// For a quick check that there are no duplicate keys:
// const all = [...capacitesSocialesTopics, ...capacitesPersonnellesTopics, ...capacitesMethodologiquesTopics]
// all.reduce((acc, cur) => [...acc, ...cur.questions], []).sort((a, b) => b.key - a.key).map(x => x.key).sort()

export const CapacitesSociales = () => (
  <RadioStep
    text="Capacités sociales"
    fieldName="stageCapacitesSociales"
    options={capacitesSocialesTopics}
  />
);

export const CapacitesPersonnelles = () => (
  <RadioStep
    text="Capacités personnelles"
    fieldName="stageCapacitesPersonnelles"
    options={capacitesPersonnellesTopics}
  />
);

export const CapacitesMethodologiques = () => (
  <RadioStep
    text="Capacités méthodologiques"
    fieldName="stageCapacitesMethodologiques"
    options={capacitesMethodologiquesTopics}
  />
);

type SoftSkillEvaluation = { key?: string; value?: string };

const updateSoftSkillEvaluations = (
  existingEvaluations: SoftSkillEvaluation[],
  newEvaluations: SoftSkillEvaluation[]
): SoftSkillEvaluation[] => [
  ...existingEvaluations.filter(
    (ee) => !newEvaluations.map((ne) => ne.key).includes(ee.key)
  ),
  ...newEvaluations,
];

const getNiveau = (evaluations: SoftSkillEvaluation[]) => {
  const values = evaluations.map(
    (e) => ({ oui: 2, plutot: 1, 'pas-vraiment': -1, non: -2 }[e.value] ?? 0)
  );
  const unknownCount = values.filter((v) => v === 0).length;

  if (unknownCount >= 3) {
    return { score: null, label: '?' };
  }

  const score = values.reduce((acc, cur) => acc + cur, 0);
  const label =
    score >= 7
      ? 'Expert'
      : score >= 3
      ? 'Confirmé'
      : score <= -2
      ? 'Débutant'
      : 'Intermédiaire';

  return { score, label };
};

const EvaluationStep = ({ fieldName, topics }) => {
  const { isProf } = useUser();

  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const schema = LyceenBacProProfileSchema.pick(
    'softSkillEvaluations',
    'softSkillEvaluations.$'
  ).requireFields();

  const initValues = initFormikValuesFromSchema(schema, myProfile);

  const foundTopic = isProf
    ? topics[0]
    : topics.find(({ key }) => key === myProfile[fieldName]);

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      softSkillEvaluations: foundTopic?.questions.map((q) => ({
        key: q.key,
        value: initValues.softSkillEvaluations?.find(({ key }) => key === q.key)
          ?.value,
      })),
    },
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile({
        softSkillEvaluations: updateSoftSkillEvaluations(
          myProfile?.softSkillEvaluations ?? [],
          schema.clean(values)?.softSkillEvaluations ?? []
        ),
      });
    },
  };

  return (
    <>
      <ProfTips mb={8}>
        Sur cet écran l'élève peut s'évaluer sur les compétences précédemment
        sélectionnées.
      </ProfTips>
      <Text>
        <ConsigneTag /> : Évalue-toi sur la compétence que tu as choisi, en
        sélectionnant les réponses qui te correspondent le plus.
      </Text>
      {foundTopic && (
        <>
          <Heading as="h4" size="md">
            Tu as choisi : {foundTopic.title}
          </Heading>
          <Formik {...formik}>
            {({ values }) => {
              const { score, label } = getNiveau(values.softSkillEvaluations);

              return (
                <Form>
                  <FormStack>
                    {foundTopic.questions.map((q, i) => (
                      <RadioGroupControl
                        key={q.key}
                        name={`softSkillEvaluations[${i}].value`}
                        label={q.text}
                        stackProps={{ direction: 'column', spacing: 1 }}
                        isRequired
                      >
                        {[
                          { value: 'oui', label: 'Oui' },
                          { value: 'plutot', label: 'Plutôt' },
                          { value: 'pas-vraiment', label: 'Pas vraiment' },
                          { value: 'non', label: 'Non' },
                          { value: 'non-observe', label: 'Non observé' },
                        ].map((o) => (
                          <Radio key={o.value} value={o.value}>
                            {o.label}
                          </Radio>
                        ))}
                      </RadioGroupControl>
                    ))}
                  </FormStack>
                  <Heading as="h4" size="sm" textAlign="center">
                    Ton niveau en{' '}
                    <Span textDecor="underline">{foundTopic.title}</Span> :{' '}
                    <Span bg={inspireYellow} px={1} py={0.5}>
                      {label}
                    </Span>
                  </Heading>
                  <Progress
                    // shift the minimum from -8 to 0, divide by max score of 16
                    value={score === null ? 0 : getPercent((8 + score) / 16)}
                    mb={8}
                    maxW="400px"
                    mx="auto"
                    borderRadius={999}
                  />
                  <FormActiviteFooter
                    isLoading={isSettingAndRefetchingMyProfile}
                  />
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export const EvaluationSociale = () => (
  <EvaluationStep
    topics={capacitesSocialesTopics}
    fieldName="stageCapacitesSociales"
  />
);
export const EvaluationPersonnelle = () => (
  <EvaluationStep
    topics={capacitesPersonnellesTopics}
    fieldName="stageCapacitesPersonnelles"
  />
);
export const EvaluationMethodologique = () => (
  <EvaluationStep
    topics={capacitesMethodologiquesTopics}
    fieldName="stageCapacitesMethodologiques"
  />
);

export const PlanAction = () => {
  const { goNext } = useStepContext();
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, { onSuccess: goNext });

  const schema = LyceenBacProProfileSchema.pick(
    'softSkillsPlanAction'
  ).requireFields(['softSkillsPlanAction']);

  const initialValues = initFormikValuesFromSchema(schema, myProfile);

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) =>
      setAndRefetchMyProfile(
        schema.clean(values, { mutate: false, autoConvert: true })
      ),
  };

  return (
    <>
      <ConsigneTag /> : Maintenant que tu connais tes compétences transversales
      (soft skills), cite deux actions que tu peux mener pour renforcer
      celles-ci.
      <Text>
        <b>Mes soft skills</b>:{' '}
        {myProfile.stageCapacitesSociales &&
          myProfile.stageCapacitesPersonnelles &&
          myProfile.stageCapacitesMethodologiques && (
            <>
              {
                capacitesSocialesTopics.find(
                  (t) => t.key === myProfile.stageCapacitesSociales
                )?.title
              }
              ,{' '}
              {
                capacitesPersonnellesTopics.find(
                  (t) => t.key === myProfile.stageCapacitesPersonnelles
                )?.title
              }
              ,{' '}
              {
                capacitesMethodologiquesTopics.find(
                  (t) => t.key === myProfile.stageCapacitesMethodologiques
                )?.title
              }
            </>
          )}
      </Text>
      <Formik {...formik}>
        <Form>
          <TextareaControl
            label="Mon plan d'action"
            name="softSkillsPlanAction"
          />
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

const getEvaluationLabel = (value) =>
  ({
    oui: 'Oui',
    plutot: 'Plutôt',
    'pas-vraiment': 'Pas vraiment',
    non: 'Non',
    'non-observe': 'Non observé',
  }[value] ?? 'Non renseigné');

export const RecapIdentifierEvaluerSoftSkills = ({ profile }) => {
  const socialEvaluations = capacitesSocialesTopics
    .find((t) => t.key === profile.stageCapacitesSociales)
    ?.questions.map((q) => ({
      text: q.text,
      key: q.key,
      value: profile.softSkillEvaluations?.find((sse) => sse.key === q.key)
        ?.value,
    }));

  const personnelEvaluations = capacitesPersonnellesTopics
    .find((t) => t.key === profile.stageCapacitesPersonnelles)
    ?.questions.map((q) => ({
      text: q.text,
      key: q.key,
      value: profile.softSkillEvaluations?.find((sse) => sse.key === q.key)
        ?.value,
    }));

  const methodoEvaluations = capacitesMethodologiquesTopics
    .find((t) => t.key === profile.stageCapacitesMethodologiques)
    ?.questions.map((q) => ({
      text: q.text,
      key: q.key,
      value: profile.softSkillEvaluations?.find((sse) => sse.key === q.key)
        ?.value,
    }));

  return (
    <>
      <RecapTextArea
        profile={profile}
        fieldName="experienceStage"
        header="Mon expérience de stage"
      />
      <Box my={6}>
        <Text>
          <Span textDecor="underline">Capacité sociale utilisée en stage</Span>{' '}
          :{' '}
          <b>
            {
              capacitesSocialesTopics.find(
                ({ key }) => key === profile.stageCapacitesSociales
              )?.title
            }
          </b>{' '}
          (niveau {getNiveau(socialEvaluations ?? []).label})
        </Text>
        {socialEvaluations?.map((q) => (
          <Text key={q.key}>
            {q.text.slice(0, -1)} :{' '}
            <b>
              {getEvaluationLabel(
                profile.softSkillEvaluations.find((e) => e.key === q.key)?.value
              )}
            </b>
          </Text>
        ))}
      </Box>
      <Box mb={6}>
        <Text>
          <Span textDecor="underline">
            Capacité personnelle utilisée en stage
          </Span>{' '}
          :{' '}
          <b>
            {
              capacitesPersonnellesTopics.find(
                ({ key }) => key === profile.stageCapacitesPersonnelles
              )?.title
            }
          </b>{' '}
          (niveau {getNiveau(personnelEvaluations ?? []).label})
        </Text>
        {personnelEvaluations?.map((q) => (
          <Text key={q.key}>
            {q.text.slice(0, -1)} :{' '}
            <b>
              {getEvaluationLabel(
                profile.softSkillEvaluations.find((e) => e.key === q.key)?.value
              )}
            </b>
          </Text>
        ))}
      </Box>
      <Box mb={6}>
        <Text>
          <Span textDecor="underline">
            Capacité méthodologique utilisée en stage
          </Span>{' '}
          :{' '}
          <b>
            {
              capacitesMethodologiquesTopics.find(
                ({ key }) => key === profile.stageCapacitesMethodologiques
              )?.title
            }
          </b>{' '}
          (niveau {getNiveau(methodoEvaluations ?? []).label})
        </Text>
        {methodoEvaluations?.map((q) => (
          <Text key={q.key}>
            {q.text.slice(0, -1)} :{' '}
            <b>
              {getEvaluationLabel(
                profile.softSkillEvaluations.find((e) => e.key === q.key)?.value
              )}
            </b>
          </Text>
        ))}
      </Box>
      <RecapTextArea
        profile={profile}
        fieldName="softSkillsPlanAction"
        header="Mon plan d'action"
      />
    </>
  );
};
