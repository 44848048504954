import { useEffect, useRef } from 'react';

import { Checkbox, HStack, Input } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { CheckboxContainer } from 'formik-chakra-ui';

import { CheckboxControlOptions } from '@inspire/ui/chakra/forms/Form';

const CheckboxGroupWithOther = ({
  name,
  label,
  options,
  ...formControlProps
}) => {
  const { values, setFieldValue } = useFormikContext();
  const value = values[name];

  const autreInputRef = useRef(null);

  const valueArray = value || [];

  const valueArrayWithoutAutre = valueArray.filter((x) =>
    options.map((o) => o.value).includes(x)
  );
  const autreValue = valueArray.filter(
    (x) => !options.map((o) => o.value).includes(x)
  )[0];

  const hasAutre = valueArrayWithoutAutre.length + 1 === valueArray.length;

  useEffect(() => {
    autreInputRef.current.value = autreValue ?? '';
  }, [autreValue]);

  return (
    <CheckboxContainer
      as="fieldset"
      name={name}
      label={label}
      data-test={name}
      stackProps={{ pl: 0 }}
      {...formControlProps}
    >
      <CheckboxControlOptions name={name} options={options} />
      <HStack>
        <Checkbox
          onChange={() => autreInputRef.current.focus()}
          isChecked={hasAutre}
        />
        <Input
          ref={autreInputRef}
          size="sm"
          required={false}
          placeholder="Autre"
          maxW="250px"
          onChange={(e) =>
            setFieldValue(
              name,
              e.target.value
                ? [...valueArrayWithoutAutre, e.target.value]
                : valueArrayWithoutAutre
            )
          }
        />
      </HStack>
    </CheckboxContainer>
  );
};

export default CheckboxGroupWithOther;
