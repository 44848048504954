import { useEffect } from 'react';

import Image from 'next/image';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { RadioGame } from 'components/activites/iterative-games';
import ProfTips from 'components/activites/ProfTips';

import Photo1 from '@inspire/ui/assets/activites/language-pro/photo-1.jpg';
import Photo2 from '@inspire/ui/assets/activites/language-pro/photo-2.jpg';
import Photo3 from '@inspire/ui/assets/activites/language-pro/photo-3.jpg';

const LanguageProCasPratiques = ({ allowNext }) => {
  const questions = [
    {
      text: 'Je viens de rater mon train et je risque d’arriver en retard à mon stage, que dois-je faire ?',
      answerValue: '2',
      image: Photo1,
      alt: 'Train raté',
      options: [
        {
          shortName:
            "Je ne préviendrai mon responsable que lorsque j'arriverai",
          value: '1',
        },
        {
          shortName:
            "J'appelle mon responsable et lui fait part de la situation et nous convenons de ce que je dois faire",
          value: '2',
        },
        {
          shortName:
            "Je ne préviendrais mon responsable que s'il me pose une question sur mon retard, pas besoin de le déranger pour ça",
          value: '3',
        },
      ],
    },
    {
      text: 'Pendant une réunion d’équipe je reçois un message personnel sur mon téléphone, que dois-je faire ?',
      answerValue: '3',
      image: Photo2,
      imageAlt: 'Message personnel sur mon téléphone',
      options: [
        {
          shortName: 'Je réponds tout de suite discrètement',
          value: '1',
        },
        {
          shortName: 'Je m’excuse et je sors de la salle',
          value: '2',
        },
        {
          shortName: 'J’attends la fin de la réunion pour répondre',
          value: '3',
        },
      ],
    },
    {
      text: 'J’ai fini mes activités du jour et je n’ai plus rien à faire, que dois-je faire ?',
      answerValue: '3',
      image: Photo3,
      imageAlt: 'Plus rien à faire',
      options: [
        {
          shortName:
            'J’attend la fin de la journée et je fais des trucs personnels',
          value: '1',
        },
        {
          shortName:
            'Je fais semblant d’être occupé pour qu’on me laisse un peu tranquille',
          value: '2',
        },
        {
          shortName:
            'Je vais voir ma tutrice de stage et je demande de nouvelles choses à faire',
          value: '3',
        },
      ],
    },
  ];

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      questions={questions}
      getOptions={(question) => question?.options}
      onFinished={() => allowNext(true)}
      introSection={
        <Text textAlign={'center'}>
          Connais-tu les attitudes à avoir dans un cadre professionnel ?
        </Text>
      }
      sideSection={(question) => (
        <Box>
          <Image src={question.image} alt={question.imageAlt} />
        </Box>
      )}
      isVertical
      getQuestionSection={(question) => (
        <Box>
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
          {question.profTips && (
            <ProfTips mt={2} mb={6} isShortVersion>
              <Text>{question.profTips}</Text>
            </ProfTips>
          )}
        </Box>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8}>
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.text}>
                  <b>{q.text}</b>{' '}
                  {q.options.find((c) => c.value === q.answerValue).shortName}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export default LanguageProCasPratiques;
