import { Wrap, WrapItem } from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxContainer, CheckboxControl } from 'formik-chakra-ui';

import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';

import { useMyProfile } from 'lib/hooks';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';

const SelectCheckboxActivite = ({
  goNext,
  fieldName,
  label,
  options,
  wrapProps = {},
}) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    fieldName,
    fieldName + '.$'
  ).requireFields();
  const formik: FormikConfig<FormikValues> = {
    initialValues: initFormikValuesFromSchema(schema, myProfile),
    enableReinitialize: true,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <CheckboxContainer
            as="fieldset"
            name={fieldName}
            label={label}
            stackProps={{ pl: 0 }}
          >
            <Wrap>
              {Object.keys(options)
                .map((k) => ({
                  label: options[k],
                  value: k,
                }))
                .map((o) => (
                  <WrapItem
                    key={o.value}
                    width={['100%', '45%', '45%', '30%']}
                    {...wrapProps}
                  >
                    <CheckboxControl
                      name={fieldName}
                      value={o.value}
                      isDisabled={
                        values[fieldName]?.length >=
                          schema.get(fieldName, 'maxCount') &&
                        !values[fieldName]?.includes(o.value)
                      }
                    >
                      {o.label}
                    </CheckboxControl>
                  </WrapItem>
                ))}
            </Wrap>
          </CheckboxContainer>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      )}
    </Formik>
  );
};

export default SelectCheckboxActivite;
