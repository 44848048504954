export const hideIfNarrowerThan = (breakpoint: string) => ({
  [`@media screen and (max-width: ${breakpoint})`]: {
    display: 'none',
  },
});

export const hideIfWiderThan = (breakpoint: string) => ({
  [`@media screen and (min-width: ${breakpoint})`]: {
    display: 'none',
  },
});
