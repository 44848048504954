import { useEffect } from 'react';

import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import { RadioGame } from 'components/activites/iterative-games';
import TodoActivite from 'components/activites/TodoActivite';
import { RecapCheckbox } from 'components/activites/recap-helpers';
import ClickableImage from 'components/general/ClickableImage';
import { useMyProfile } from 'lib/hooks';

import InfographieSrc from '../../../../../public/img/infographies/parcoursup.png';

const EtapesProjetOptions = {
  echangeProf:
    'J’ai échangé avec mon professeur ou un spécialiste de l’orientation pour savoir si je souhaite plutôt une poursuite d’études ou une insertion professionnelle',
  fonctionnementParcoursup:
    'Je me suis informé sur le mode de fonctionnement de Parcoursup',
  siteParcoursup:
    'Je suis allé sur le site Parcoursup pour m’informer sur les formations qui m’ont été conseillé',
  etablissements:
    'J’ai recherché les établissements où ces formations se déroulaient',
  conditionsAcces: 'Je connais les conditions d’accès à ces formations',
  debouchesPro:
    'Je connais les débouchés professionnels et poursuites d’études que ces formations me permettent',
};

export const GererMonProjet = ({ goNext }) => (
  <>
    <Text>
      L’année prochaine, c’est Parcoursup. Dès la première, tu peux commencer à
      t’y préparer. Pour t’aider à voir où tu en es, coche les étapes que tu as
      déjà réalisées de cette liste et inspire-toi des étapes suivantes pour tes
      prochaines actions.
    </Text>
    <TodoActivite
      goNext={goNext}
      options={EtapesProjetOptions}
      fieldName={'etapesProjet'}
      todoLabel="LES ÉTAPES :"
    />
  </>
);

export const RecapProjetOrientation = ({ profile }) => (
  <>
    <RecapCheckbox
      profile={profile}
      fieldName="etapesProjet"
      header="Les étapes déjà faites de mon projet d'orientation"
      options={EtapesProjetOptions}
    />
    <RecapCheckbox
      profile={profile}
      fieldName="etapesProjet"
      header="Les étapes à faire de mon projet d'orientation"
      options={EtapesProjetOptions}
      inverseValues={true}
    />
  </>
);

export const MyRecapProjetOrientation = () => {
  const { myProfile } = useMyProfile();
  return <RecapProjetOrientation profile={myProfile} />;
};

export const EtapesOrientation = ({ allowNext }) => {
  const questions = [
    {
      text: 'Renseigner ma fiche de dialogue et commencer à consulter le moteur de recherche des formations sur Parcoursup',
      answerValue: 'decembre',
    },
    {
      text: 'Commencer à formuler mes voeux et ma motivation sur Parcoursup',
      answerValue: 'janvier',
    },
    {
      text: 'Terminer de formuler mes voeux sur Parcoursup',
      answerValue: 'mars',
    },
    {
      text: 'Terminer de remplir mes dossiers (rédiger mes lettres de motivation) sur Parcoursup',
      answerValue: 'avril',
    },
    {
      text: 'Recevoir les réponses à mes vœux et commencer à répondre au fur et à mesure aux propositions d’admission',
      answerValue: 'juin',
    },
    {
      text: "Terminer de confirmer les vœux qui m'intéressent toujours sur Parcoursup",
      answerValue: 'juillet',
    },
  ];

  const options = [
    'Octobre',
    'Novembre',
    'Décembre',
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
  ].map((m) => ({
    value: m.replace('é', 'e').toLowerCase(),
    shortName: m,
    fullName: m,
  }));

  useEffect(() => {
    allowNext(false);
  }, []);

  return (
    <RadioGame
      isVertical={true}
      questions={questions}
      getOptions={() => options}
      onFinished={() => allowNext(true)}
      introSection={
        <Box textAlign={'center'}>
          <Text mb={8}>
            Associe chaque grande étape de Parcoursup au mois correspondant.
          </Text>
          <Text>
            <b>A quelle période je dois...</b>
          </Text>
        </Box>
      }
      getQuestionSection={(question) => (
        <Text mt={0}>
          <i>{question?.text} ?</i>
        </Text>
      )}
      getSummarySection={(_, questionIndex) =>
        questionIndex !== 0 && (
          <SummaryFrame mt={8} label="Les étapes de Parcoursup">
            <UnorderedList>
              {questions.slice(0, questionIndex).map((q) => (
                <ListItem key={q.answerValue}>
                  <b>
                    {options.find((c) => c.value === q.answerValue).fullName} :
                  </b>{' '}
                  {q.text + '.'}
                </ListItem>
              ))}
            </UnorderedList>
          </SummaryFrame>
        )
      }
    />
  );
};

export const InfographieParcoursup = () => (
  <Box textAlign="center">
    <ClickableImage
      src={InfographieSrc}
      href="/img/infographies/parcoursup.png"
      alt="Infographie études supérieurs"
    />
  </Box>
);
