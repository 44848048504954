export var haveCommonItems = function(arr1, arr2) {
    if (arr1 === undefined || arr2 === undefined) {
        return false;
    }
    if (!Array.isArray(arr1)) {
        throw Error("Parameter of haveCommonItems is not an array: ".concat(arr1));
    }
    if (!Array.isArray(arr2)) {
        throw Error("Parameter of haveCommonItems is not an array: ".concat(arr2));
    }
    return arr1.some(function(x) {
        return arr2 === null || arr2 === void 0 ? void 0 : arr2.includes(x);
    });
};
