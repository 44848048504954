import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import ProfTips from 'components/activites/ProfTips';
import VideoPlayer from 'components/general/VideoPlayer';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';

const questions = [
  'Quel a été le parcours scolaire de Jeremy ?',
  'Quels sont les différents métiers exercés durant son alternance ?',
  'Quelles sont les compétences évoquées dans son témoignage ?',
];

const VIDEO_URL = '/videos-bacpro/vid%C3%A9o%20Jeremy%20version%20courte.mp4';
const VIDEO_THUMBNAIL = '/videos-bacpro/Thumbnail/thumbail_jeremy.png';

export const BeneficesAlternance = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>Regarde la vidéo puis répond oralement aux questions.</i>
    </Text>
    <Box margin="auto">
      <VideoPlayer url={VIDEO_URL} light={VIDEO_THUMBNAIL} />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      {questions.map((question, index) => (
        <ListItem key={`question-benefices-alternance-${index}`}>
          {question}
        </ListItem>
      ))}
    </UnorderedList>
  </>
);
