import { extendTheme } from "@chakra-ui/react";
import { inspireYellow, serifFonts, sansFonts } from "@inspire/ui/static";
export var roundButtonProps = {
    px: "20px",
    borderRadius: 999
};
export var emphasisButtonProps = {
    shadow: "base"
};
var theme = extendTheme({
    fonts: {
        heading: serifFonts,
        body: sansFonts
    },
    styles: {
        global: {
            ":not(.chakra-dont-set-collapse) > .chakra-collapse": {
                overflow: "initial !important"
            },
            ":root": {
                "--toastify-color-progress-light": inspireYellow,
                "--toastify-color-info": "#48a8ff",
                "--toastify-color-error": "#ff5240",
                "--toastify-color-success": "#60d80a",
                "--toastify-color-warning": "#ffb300"
            },
            html: {
                boxSizing: "border-box",
                scrollBehavior: "smooth"
            },
            "*, *:before, *:after": {
                boxSizing: "inherit"
            },
            body: {
                margin: 0
            },
            "#nprogress .bar": {
                backgroundColor: inspireYellow
            },
            "#nprogress .peg": {
                boxShadow: "0 0 10px ".concat(inspireYellow, ", 0 0 5px ").concat(inspireYellow, ";")
            },
            "label[data-disabled]": {
                cursor: "no-drop"
            },
            label: {
                cursor: "pointer"
            },
            ".Toastify__toast": {
                color: "#333",
                fontFamily: sansFonts
            },
            ".hr-text": {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center"
            },
            ".hr-text:before, .hr-text:after": {
                content: "''",
                borderTop: "2px solid #eee",
                margin: "0 20px 0 0",
                flex: "1 0 20px"
            },
            ".hr-text:after": {
                margin: "0 0 0 20px"
            },
            // This would normally be part of Chakra's CSS Reset, but we disable it
            ".js-focus-visible :focus:not(.focus-visible), .js-focus-visible :focus:not(.focus-visible) + [data-focus]": {
                outline: "none",
                shadow: "none"
            },
            fieldset: {
                borderColor: "gray.200",
                m: 0,
                px: [
                    0,
                    5
                ],
                py: [
                    0,
                    4
                ],
                borderRadius: 4,
                borderWidth: [
                    0,
                    "1px"
                ]
            },
            ".chakra-form-control > label": {
                fontWeight: "semibold"
            },
            ".chakra-form-control .chakra-checkbox": {
                fontWeight: "normal"
            },
            "fieldset > legend": {
                pr: 2,
                mb: 0,
                pb: 0
            },
            ".chakra-breadcrumb__list": {
                pl: 0,
                m: 0
            },
            'ul[role="list"]': {
                ml: 0,
                pl: 6
            }
        }
    },
    colors: {
        // Generated via https://themera.vercel.app/
        yellow: {
            50: "#FFFCE5",
            100: "#FFF6B8",
            200: "#FFF08A",
            300: "#FFEB5C",
            400: "#FFDF00",
            500: inspireYellow,
            600: "#CCB200",
            700: "#998600",
            800: "#665900",
            900: "#332D00"
        },
        black: {
            100: "#666",
            200: "#555",
            300: "#444",
            400: "#333",
            500: "black",
            600: "black",
            700: "black",
            800: "black",
            900: "black"
        }
    },
    components: {
        Accordion: {
            baseStyle: {
                container: {
                    button: {
                        borderTop: "1px solid ",
                        borderColor: "gray.200",
                        _expanded: {
                            bg: "yellow.100"
                        }
                    },
                    _first: {
                        button: {
                            borderColor: "transparent"
                        }
                    }
                }
            }
        },
        Button: {
            baseStyle: {
                borderRadius: "999px",
                px: "20px",
                border: 0,
                fontWeight: 400,
                textDecoration: "none",
                WebkitTapHighlightColor: "transparent",
                whiteSpace: "normal"
            },
            defaultProps: {
                colorScheme: "yellow"
            },
            variants: {
                ghost: {
                    color: "#333",
                    _hover: {
                        background: "yellow.200"
                    },
                    _active: {
                        background: "yellow.200"
                    },
                    _focus: {
                        background: "yellow.200"
                    }
                },
                ghostInverse: {
                    color: "white",
                    _hover: {
                        color: "#333",
                        bg: "yellow.200"
                    },
                    _active: {
                        color: "#333",
                        bg: "yellow.200"
                    },
                    _focus: {
                        color: "#333",
                        bg: "yellow.200"
                    }
                }
            },
            sizes: {
                sm: {
                    h: 8,
                    fontSize: 16
                }
            }
        },
        Heading: {
            baseStyle: function(param) {
                var colorMode = param.colorMode;
                return {
                    fontFamily: sansFonts,
                    fontWeight: 600,
                    color: colorMode === "dark" ? "white" : "#333"
                };
            }
        },
        Link: {
            baseStyle: function(param) {
                var colorMode = param.colorMode;
                return {
                    textDecoration: "underline",
                    WebkitTapHighlightColor: "transparent",
                    color: colorMode === "dark" ? "white" : "#333"
                };
            },
            variants: {
                dotted: {
                    textDecorationStyle: "dotted",
                    textUnderlineOffset: "2px",
                    _hover: {
                        textDecorationStyle: "dotted"
                    },
                    _focus: {
                        textDecorationStyle: "dotted"
                    },
                    _active: {
                        textDecorationStyle: "dotted"
                    }
                }
            }
        },
        Checkbox: {
            baseStyle: {
                icon: {
                    color: "black"
                },
                control: {
                    borderColor: "gray.300"
                }
            },
            defaultProps: {
                colorScheme: "yellow"
            }
        },
        Radio: {
            defaultProps: {
                colorScheme: "yellow"
            },
            baseStyle: {
                // If using the yellow color scheme, this adds more contrast:
                control: {
                    borderColor: "gray.300",
                    "&[data-checked]:before": {
                        background: "yellow.900"
                    }
                }
            }
        },
        Slider: {
            defaultProps: {
                colorScheme: "yellow"
            },
            baseStyle: {
                thumb: {
                    // We globally disable the a11y box-shadow focus ring on mouse interactions.
                    // This breaks the box-shadow of the slider thumb, so we fix it here.
                    shadow: "0px 1px 3px rgb(0 0 0 / 50%) !important",
                    ".js-focus-visible &[data-focus-visible-added]": {
                        shadow: "var(--chakra-shadows-outline) !important"
                    }
                }
            }
        },
        Menu: {
            baseStyle: {
                item: {
                    WebkitTapHighlightColor: "transparent",
                    _hover: {
                        background: "yellow.200"
                    },
                    _focus: {
                        background: "yellow.200"
                    },
                    _active: {
                        background: "yellow.500"
                    }
                }
            }
        },
        Switch: {
            defaultProps: {
                colorScheme: "yellow"
            },
            baseStyle: {
                track: {
                    boxShadow: "0 0 0 1px #aaa"
                },
                thumb: {
                    boxShadow: "inset 0 0 0 1px #666"
                }
            }
        },
        Skeleton: {
            defaultProps: {
                startColor: "#fff",
                endColor: "#eee"
            }
        },
        Progress: {
            defaultProps: {
                colorScheme: "yellow",
                size: "lg"
            },
            baseStyle: {
                track: {
                    backgroundColor: "#e7e7e7",
                    boxShadow: "0 1px 0 #ccc inset"
                },
                filledTrack: {
                    boxShadow: "0px 1px 1px #ccb200, inset 0px 1px 1px #ffec66",
                    height: "calc(100% - 1px)",
                    transition: "0.4s ease-in-out"
                }
            }
        },
        Modal: {
            baseStyle: {
                overlay: {
                    bg: "blackAlpha.500"
                }
            }
        },
        Persona: {
            baseStyle: {
                details: {
                    ms: 2
                },
                label: {
                    fontSize: "sm"
                },
                secondaryLabel: {
                    fontSize: "xs",
                    color: "gray"
                },
                tertiaryLabel: {
                    fontSize: "xs"
                }
            }
        }
    }
});
export default theme;
