import { useState, useEffect } from 'react';

import Confetti from 'react-canvas-confetti';

const InspireConfetti = ({
  fire,
  extraStyles,
  ...rest
}: {
  fire: boolean;
  extraStyles?: any;
}) => {
  const [fireConfetti, setFireConfetti] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setFireConfetti(fire);
    if (fire) {
      setIsVisible(true);
      setTimeout(() => setIsVisible(false), 2000);
    }
  }, [fire]);

  return (
    <Confetti
      style={{
        position: 'absolute',
        left: 'calc(50% - 150px)',
        display: fire && isVisible ? 'block' : 'none',
        zIndex: 999,
        ...extraStyles,
      }}
      fire={fireConfetti}
      origin={{ y: 1 }}
      startVelocity={20}
      {...rest}
    />
  );
};

export default InspireConfetti;
