import { useEffect } from 'react';
import { loadCrisp } from 'lib/trackers';
import { useUser } from 'lib/hooks';

const Crisp = () => {
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      loadCrisp(user);
    }
  }, [user]);
  return null;
};

export default Crisp;
