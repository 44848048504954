import { Box, Text } from '@chakra-ui/react';

import { SummaryFrame } from '@inspire/ui/chakra/layout/Frame';

import ProfTips from 'components/activites/ProfTips';
import { RadioGame } from 'components/activites/iterative-games';
import ClickableImage from 'components/general/ClickableImage';

import InfographieSoftSkillsSrc from '../../../../../public/img/infographies/soft-skills.png';

const SoftSkillsRecruteurs = ({ allowNext }) => (
  <>
    <ProfTips>
      <Text>
        L’infographie ici présente renforce l’idée que les softs skills sont un
        facteur clé dans le processus de recrutement.
      </Text>
    </ProfTips>
    <Box>
      <RadioGame
        questions={[
          {
            text: 'Vrai ou Faux - Les recruteurs regardent les compétences techniques en premier ?',
            answerValue: '2',
            options: [
              {
                shortName: 'Vrai',
                value: '1',
              },
              {
                shortName: 'Faux',
                value: '2',
              },
            ],
          },
        ]}
        getOptions={(question) => question?.options}
        onFinished={() => allowNext(true)}
        introSection={
          <Text textAlign={'center'}>Réponds à la question suivante :</Text>
        }
        getQuestionSection={(question) => (
          <Text mt={0}>
            <i>{question.text}</i>
          </Text>
        )}
        getSummarySection={(_, questionIndex) =>
          questionIndex !== 0 && (
            <SummaryFrame mt={8}>
              <Text>
                Les recruteurs regardent les soft skills / compétences
                transversales avant de regarder les compétences techniques.
              </Text>
            </SummaryFrame>
          )
        }
      />
      <ClickableImage
        src={InfographieSoftSkillsSrc}
        href="/img/infographies/soft-skills.png"
        alt="L’importance des soft skills / compétences transversales pour les recruteurs"
      />
    </Box>
  </>
);

export default SoftSkillsRecruteurs;
