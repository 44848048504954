import {
  Center,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Span from '@inspire/ui/chakra/Span';
import { ExternalIcon } from '@inspire/ui/icons';

import { cdnURL } from '@inspire/util/cdn';

import LinkButton from 'components/general/LinkButton';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';

const RaconteToiGroupe = () => (
  <>
    <ReflexionOralHeader />
    <Text mt={6}>
      Partage une de tes expériences à tes camarades. Ils devront identifier les
      compétences transversales (soft skills) que tu as acquises à travers cette
      expérience.
    </Text>
    <ProfTips my={8}>
      Mettez les élèves en petits groupes et demandez à chacun de présenter une
      expérience. Les autres élèves devront déterminer quelles compétences ont
      pu être apprises au travers de celle-ci.
    </ProfTips>
    <Flex direction={['column', null, null, 'row']}>
      <Table mt={6} border="1px solid" borderColor={'gray.100'} size="sm">
        <Thead h={'80px'}>
          <Tr height="100px">
            <Th>Compétences sociales (ou interpersonnelles)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr height="100px">
            <Td>
              <b>Communication :</b> communication écrite, communication orale,
              communication visuelle, communication non verbale, communication
              numérique
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Travail en équipe:</b> intégration au groupe, coordination de
              groupe, empathie, partage et réception de feedback
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Gestion des conflits :</b> écoute active, médiation,
              objectivité
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Négociation :</b> influence et persuasion, persévérance,
              diplomatie
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Table mt={6} border="1px solid" borderColor={'gray.100'} size="sm">
        <Thead h={'80px'}>
          <Tr height="100px">
            <Th>Compétences personnelles (ou intrapersonnelles)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr height="100px">
            <Td>
              <b>Leadership positif :</b> confiance en soi, responsabilité
              autonomie, motivation et implication, mobilisation de réseau
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Auto-évaluation :</b> introspection et réflexivité, éthique,
              contrôle de soi, fixation d’objectifs
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Adaptabilité :</b> gestion de l’incertitude, gestion du stress,
              réactivité
            </Td>
          </Tr>
          <Tr height="100px">
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
      <Table mt={6} border="1px solid" borderColor={'gray.100'} size="sm">
        <Thead h={'80px'}>
          <Tr height="100px">
            <Th>Compétences méthodologiques</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr height="100px">
            <Td>
              <b>Apprendre à apprendre :</b> apprentissage individuel,
              apprentissage collectif, ouverture socioculturelle, pédagogie
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Compétences analytiques :</b> collecte et traitement de
              données, problématisation, analyse de l’information, synthèse de
              l’information, esprit critique
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Créativité :</b> curiosité, imagination, audace
            </Td>
          </Tr>
          <Tr height="100px">
            <Td>
              <b>Résolution de problèmes :</b> organisation, stratégie, prise de
              décisions, gestion du temps, prise de conscience et de recul
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
    <Center mt={[5, null, 8]}>
      <LinkButton
        leftIcon={<Icon as={ExternalIcon} boxSize={5} />}
        // @ts-ignore
        target="_blank"
        rel="noopener noreferrer"
        href={cdnURL(
          '/bac-pro/De%CC%81finition_compe%CC%81tences_JR_-_version_lyce%CC%81ens.pdf'
        )}
      >
        En savoir plus
        <Span display={['none', 'inline']} ml={1}>
          sur ces soft skills
        </Span>
      </LinkButton>
    </Center>
  </>
);

export default RaconteToiGroupe;
