import { useEffect, useState } from 'react';
import { Box, Flex, Text, Icon, Badge } from '@chakra-ui/react';
import { cloneDeep, shuffle } from 'lodash';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { CheckIcon, DragHandleIcon } from '@inspire/ui/icons';
import Confetti from 'components/general/Confetti';

const reorder = (list, startIndex, endIndex) => {
  const result = cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const SortingGame = ({ label, sortOptions, allowNext }) => {
  const [orderedList, setOrderedList] = useState([...shuffle(sortOptions)]);
  const correctAnswers = orderedList.filter((item) => item.isCorrect).length;
  const isDone = correctAnswers === sortOptions.length;

  useEffect(() => {
    allowNext(false);
  }, []);

  useEffect(() => {
    allowNext(!!isDone);
  }, [isDone]);

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }
    const newList = reorder(orderedList, source.index, destination.index);
    setOrderedList(
      newList.map((item, index) => ({
        ...item,
        isCorrect: sortOptions.findIndex((l) => l.id === item.id) === index,
      }))
    );
  };

  return (
    <Box maxWidth={'400px'} margin="auto" pos="relative">
      <Text textAlign={'center'}>
        <i>{label}</i>
      </Text>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="topics">
          {(droppableProvided) => (
            <Box
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {orderedList?.length > 0 &&
                orderedList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(draggableProvided) => (
                      <Flex
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        ref={draggableProvided.innerRef}
                        align="center"
                        py={1}
                      >
                        <Icon as={DragHandleIcon} mr={2} />
                        <Badge
                          fontSize={['0.9em', '1em']}
                          colorScheme={item.isCorrect ? 'green' : 'gray'}
                        >
                          {item.label}
                        </Badge>
                        {item.isCorrect && (
                          <Icon
                            as={CheckIcon}
                            ml={2}
                            color="green.500"
                            boxSize={5}
                          />
                        )}
                      </Flex>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Confetti fire={isDone} extraStyles={{ marginTop: -150 }} />
    </Box>
  );
};

export default SortingGame;
