import Span from '@inspire/ui/chakra/Span';
import { Flex, Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

const Required = ({
  hasTooltip,
  label,
  condition = true,
  children,
}: {
  hasTooltip?: boolean;
  label?: string;
  condition?: boolean;
  children: ReactElement;
}) =>
  !condition ? (
    children
  ) : hasTooltip ? (
    <Tooltip label={label || 'Ce champ est obligatoire.'}>
      <Flex alignItems="center">
        {children}{' '}
        <Span color="red" ml={1} role="presentation" aria-hidden="true">
          *
        </Span>
      </Flex>
    </Tooltip>
  ) : (
    <>
      {children}{' '}
      <Span color="red" ml={1} role="presentation" aria-hidden="true">
        *
      </Span>
    </>
  );

export default Required;
