import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  VStack,
  Text,
  Link,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormControl, RadioGroupControl, SubmitButton } from 'formik-chakra-ui';
import { omit } from 'lodash';
import { useRouter } from 'next/router';

import Select from '@inspire/ui/chakra/forms/Select';
import { FilieresTechnos } from '@inspire/data/static/static';
import { FilieresForFamille } from '@inspire/data/static/bacs-pro';
import { getLatestSchoolYear } from '@inspire/data/helpers/static';
import { AlgoSchema } from '@inspire/data/schemas/user/profile/explore';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { Level } from '@inspire/data/static/types';

import { useMyProfile, useSetLyceeStudyYear } from 'lib/hooks';
import moment from 'moment';
import { DEVENIR_ECLAIREUR_PATH, QUESTIONNAIRE_PATH } from 'lib/paths';
import {
  familleMetiersOptions,
  filiereOptions,
} from '@inspire/ui/select-options';
import LevelOptions from './LevelOptions';

// Note: This is almost a duplicate of the same form in [step].tsx

const filiereTechnoOptions = Object.keys(FilieresTechnos).map((k) => ({
  label: FilieresTechnos[k],
  value: k,
}));

const NiveauModal = ({ isOpen, closeModal }) => {
  const { push } = useRouter();
  const { setLyceeStudyYearAsync } = useSetLyceeStudyYear();
  // @ts-ignore
  const {
    myProfile: { niveau },
  }: { myProfile: { niveau: Level } } = useMyProfile();

  const schema = AlgoSchema.pick(
    'niveau',
    'filiere',
    'filiereTechno',
    'filiereBacPro',
    'familleMetiersPro'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues: {
      niveau: niveau === Level.PREMIERE ? Level.PREMIERE : '',
      filiere: '',
      filiereTechno: '',
      filiereBacPro: '',
      familleMetiersPro: '',
    },
    validate: (v) =>
      omit(
        validateSchema(schema, v),
        (v.filiere !== 'bac_techno' ||
          niveau === Level.SECONDE ||
          niveau === Level.PREMIERE) &&
          'filiereTechno',
        (v.filiere !== 'bac_pro' ||
          niveau === Level.SECONDE ||
          niveau === Level.PREMIERE) &&
          'filiereBacPro',
        (v.filiere !== 'bac_pro' ||
          niveau === Level.SECONDE ||
          niveau === Level.PREMIERE) &&
          'familleMetiersPro',
        (niveau === Level.PREMIERE || niveau === Level.SECONDE) && 'filiere'
      ),
    onSubmit: async (v) => {
      await setLyceeStudyYearAsync({
        niveau: v.niveau,
        filiere: v.filiere,
        filiereTechno: v.filiereTechno,
        filiereBacPro: v.filiereBacPro,
      });
      closeModal();
      if (v?.niveau === 'Post-bac') {
        push(DEVENIR_ECLAIREUR_PATH);
      }
    },
  };

  const labelModal = () => {
    switch (niveau) {
      case Level.SECONDE:
      case Level.PREMIERE:
        return `en ${Level.SECONDE} ou en ${Level.PREMIERE}`;
      case Level.TERMINAL:
        return `en ${Level.PREMIERE} ou en ${Level.TERMINAL}`;
      default:
        return `en ${Level.TERMINAL} ou t'as eu ton bac`;
    }
  };

  const questionnaireLink = (
    <Link href={QUESTIONNAIRE_PATH}>le questionnaire</Link>
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent maxWidth="lg">
        <Formik {...formik}>
          {({ values, setFieldValue }) => (
            <Form>
              <ModalHeader textAlign="center">
                Un petit point rapide avant de continuer !
              </ModalHeader>
              <ModalBody>
                Les informations de ton profil ont été mises à jour.
                {niveau === Level.TERMINAL && (
                  <Text fontSize="sm">
                    Si nos calculs sont bons, tu es maintenant en Terminale.
                    <br />
                    Si ce n’est pas le cas, tu peux modifier cela en faisant{' '}
                    {questionnaireLink} <br />
                    Si tu es maintenant en Terminale, il faut quand même que tu
                    refasses le questionnaire pour mettre à jour tes notes,
                    choisir ta spécialité et mettre tes notes dans les nouvelles
                    matières. <br />
                  </Text>
                )}
                Tu peux les modifier en répondant aux questions ci-dessous.
                <br />
                <br />
                <VStack
                  spacing={5}
                  alignItems="start"
                  width={[null, 'max-content']}
                >
                  <RadioGroupControl
                    name="niveau"
                    label={`À la rentrée de septembre ${getLatestSchoolYear()}, ${
                      moment().month() === 7
                        ? 'seras-tu'
                        : moment().month() === 8
                        ? 'es-tu'
                        : 'étais-tu'
                    } ${labelModal()} ?`}
                    isRequired
                    stackProps={{ direction: ['column', null, 'row'] }}
                  >
                    <LevelOptions level={niveau} />
                  </RadioGroupControl>
                  {niveau !== Level.PREMIERE && niveau !== Level.SECONDE && (
                    <RadioGroupControl
                      name="filiere"
                      label={
                        values?.niveau === Level.POST_BAC
                          ? 'Quel bac as-tu eu ?'
                          : 'Quel bac fais-tu ?'
                      }
                      isRequired
                      stackProps={{ direction: ['column', null, 'row'] }}
                    >
                      <Radio value="bac_general">Bac Général</Radio>
                      <Radio value="bac_techno">Bac Technologique</Radio>
                      <Radio value="bac_pro">Bac Pro</Radio>
                    </RadioGroupControl>
                  )}
                  {values?.niveau === Level.PREMIERE && (
                    <Text as="b">
                      Tu peux toujours refaire{' '}
                      <Link href={QUESTIONNAIRE_PATH}>le questionnaire</Link> .
                    </Text>
                  )}
                  {values?.filiere === 'bac_techno' && (
                    <FormControl
                      name="filiereTechno"
                      label="Quelle série as-tu choisie ?"
                      isRequired
                      maxWidth="450px"
                    >
                      <Select
                        instanceId="filiereTechno"
                        openMenuOnFocus
                        options={filiereTechnoOptions}
                        onChange={(selectedOption) =>
                          setFieldValue('filiereTechno', selectedOption.value)
                        }
                        value={filiereTechnoOptions?.find(
                          (o) => o.value === values.filiereTechno
                        )}
                      />
                    </FormControl>
                  )}
                  {values?.filiere === 'bac_pro' && (
                    <FormControl
                      name="familleMetiersPro"
                      isRequired
                      label={
                        values?.niveau === 'Post-bac'
                          ? 'Quel bac pro as-tu eu ?'
                          : 'Quel bac pro fais-tu ?'
                      }
                      maxWidth="450px"
                      labelProps={{
                        htmlFor: 'react-select-familleMetiersPro-input',
                      }}
                    >
                      <Select
                        instanceId="familleMetiersPro"
                        openMenuOnFocus
                        options={familleMetiersOptions}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'familleMetiersPro',
                            selectedOption.value
                          );
                          // clear filiereBacPro field to avoid inconsistant famille/filiere
                          if (
                            !FilieresForFamille(selectedOption.value)?.includes(
                              values.filiere
                            )
                          ) {
                            setFieldValue('filiereBacPro', '');
                          }
                        }}
                        value={familleMetiersOptions.find(
                          (o) => o.value === values.familleMetiersPro
                        )}
                        isSearchable
                      />
                    </FormControl>
                  )}

                  {values?.niveau !== 'seconde' &&
                    values.filiere === 'bac_pro' &&
                    values?.familleMetiersPro && (
                      <FormControl
                        name="filiereBacPro"
                        isRequired
                        label="Spécialité de Bac professionnel :"
                        labelProps={{ htmlFor: 'react-select-filiere-input' }}
                      >
                        <Select
                          key={`filiereBacPro-${values.filiere}`}
                          instanceId="filiereBacPro"
                          openMenuOnFocus
                          options={filiereOptions(values.familleMetiersPro)}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              'filiereBacPro',
                              selectedOption.value
                            );
                          }}
                          value={filiereOptions(values.familleMetiersPro).find(
                            (o) => o.value === values.filiereBacPro
                          )}
                          isSearchable
                        />
                      </FormControl>
                    )}
                </VStack>
              </ModalBody>
              <ModalFooter>
                <SubmitButton colorScheme="yellow">Enregistrer</SubmitButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default NiveauModal;
