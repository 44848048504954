export var inspireYellow = "#ffdf00";
// Achille's fallback looks better in sans-serif
export var serifFonts = "Achille II FY, sans-serif";
export var sansFonts = "Roboto Condensed, Arial Narrow, sans-serif";
export var mainContainerMinHeightValues = [
    450,
    500,
    550
];
export var lightGrayBg = "#f8f8f8";
export var darkBg = "#333";
export var lightBeigeBg = "#F2EBE5";
export var lightYellowBg = "#FFF9CC";
export var lightBlueBg = "#A0DCCB66";
