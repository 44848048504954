import { useEffect, useRef } from 'react';

import { HStack, Input, Radio, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { RadioGroupControl } from 'formik-chakra-ui';

const RadioGroupWithOther = ({ name, label, options, ...formControlProps }) => {
  const { values, setFieldValue } = useFormikContext();
  const value = values[name];

  const autreInputRef = useRef(null);
  const autreValue = options.map((o) => o.value).includes(value) ? '' : value;
  const hasAutre = !!value && !options.map((o) => o.value).includes(value);

  useEffect(() => {
    autreInputRef.current.value = autreValue ?? '';
  }, [autreValue]);

  return (
    <RadioGroupControl
      as="fieldset"
      name={name}
      label={label}
      stackProps={{ pl: 0 }}
      {...formControlProps}
    >
      <VStack alignItems="start">
        {options.map((o, i) => (
          <Radio key={i} value={o.value}>
            {o.label}
          </Radio>
        ))}
        <HStack>
          <Radio
            onChange={() => autreInputRef.current.focus()}
            isChecked={hasAutre}
          ></Radio>
          <Input
            ref={autreInputRef}
            size="sm"
            required={false}
            placeholder="Autre"
            maxW="250px"
            onChange={(e) => setFieldValue(name, e.target.value)}
          />
        </HStack>
      </VStack>
    </RadioGroupControl>
  );
};

export default RadioGroupWithOther;
