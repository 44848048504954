// https://inspire-uploads.s3-eu-west-1.amazonaws.com/4551-quantumcrypto.jpg
/*
  XX: since we moved from s3 to Minio, we want ot rewrite legacy URLs to point to Minio based bucket

  redirection is configured using :

    process.env.NEXT_PUBLIC_CDN_BASE_URL

  examples: 

    https://inspire-uploads.s3-eu-west-1.amazonaws.com/4551-quantumcrypto.jpg => NEXT_PUBLIC_CDN_BASE_URL/4551-quantumcrypto.jpg
    https://inspire-orientation-uploads.s3-eu-west-1.amazonaws.com/4545-les-licences-slectives-avec-des-sciences.jpg => NEXT_PUBLIC_CDN_BASE_URL/4545-les-licences-slectives-avec-des-sciences.jpg
    https://inspire-s3.s3-eu-west-1.amazonaws.com/4247-hypokhagne-bl-v2.jpg => NEXT_PUBLIC_CDN_BASE_URL/4247-hypokhagne-bl-v2.jpg
*/ import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var relativeURLRx = /^[^\/]+\/[^\/].*$|^\/[^\/].*$/gim;
var makeURL = function(baseURL, pathname) {
    var parts = "".concat(baseURL.replace(/\/$/gi, ""), "/").concat(pathname.replace(/^\//gi, "")).split("/");
    return _to_consumable_array(parts.slice(0, parts.length - 1)).concat([
        encodeURIComponent(decodeURIComponent(parts[parts.length - 1]).replace(/\+/gi, " ")), 
    ]).join("/");
};
export function cdnURL(src) {
    var baseURL = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : process.env.NEXT_PUBLIC_CDN_BASE_URL;
    if (!baseURL || !src) {
        return src;
    }
    if (src.match(relativeURLRx)) {
        return makeURL(baseURL, src);
    }
    try {
        var url = new URL(src);
        if (src.match(/amazonaws/gi)) {
            return makeURL(baseURL, url.pathname);
        }
        return src;
    } catch (err) {
        return src;
    }
}
export function cleanupText(content) {
    var baseURL = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : process.env.NEXT_PUBLIC_CDN_BASE_URL;
    if (!content || !baseURL) {
        return content;
    }
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return content.replace(urlRegex, function(url) {
        if (url.indexOf("amazonaws.com") === -1) {
            return url;
        }
        return cdnURL(url, baseURL);
    });
}
