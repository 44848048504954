import { wait } from '@v1v2/util';
import { getSession } from 'next-auth/client';

import gcl from 'lib/graphql-client';
import { networkErrorToast } from 'lib/toasts';
import { SimpleAPIError } from 'lib/errors';

export class InspireRequestError extends Error {
  errors?: SimpleAPIError[];
  data: unknown;
  constructor(response: { data: unknown; errors: SimpleAPIError[] }) {
    super(JSON.stringify(response));
    this.name = 'InspireRequestError';
    this.errors = response.errors;
    this.data = response.data;
  }
}

export const gqlRequest =
  <Data, Variables>(query: string, variables?: Variables) =>
  async () => {
    const session = await getSession();

    // Useful to see loading states
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_ARTIFICIAL_API_DELAY
    ) {
      await wait(Number(process.env.NEXT_PUBLIC_ARTIFICIAL_API_DELAY));
    }

    try {
      // We await within this function for the error to get caught in the try catch
      return await gcl.request<Data, Variables>(
        query,
        variables,
        session as any
      );
    } catch (gqlReqErr) {
      if (gqlReqErr.response?.errors) {
        throw new InspireRequestError({
          data: gqlReqErr.response?.data,
          errors: gqlReqErr.response?.errors?.map((e) => ({
            code: e.extensions?.code,
            message: e.extensions?.exception?.reason ?? e.message,
            displayMessage: e.extensions?.displayMessage,
          })),
        });
      }
      networkErrorToast();
      throw gqlReqErr;
    }
  };
