import { useContext } from 'react';

import { StepContext } from 'lib/contexts';

export const useStepContext = () => {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error('useStepContext must be used within a StepProvider');
  }
  return context;
};
