export var PedagogieRoles = {
    professeurPrincipal: "Professeur principal",
    professeur: "Professeur",
    psyen: "Psychologue de l'\xc9ducation nationale (PsyEN)",
    professeurDocumentaliste: "Professeur documentaliste",
    cpe: "Conseiller principal d'\xe9ducation",
    proviseur: "Proviseur",
    proviseurAdjoint: "Proviseur adjoint",
    autre: "Autre"
};
export var CiviliteTitles = {
    mr: "Monsieur",
    mme: "Madame"
};
export var PedagogieFiliere = {
    pedagogie_filiere_generale: "G\xe9n\xe9rale",
    pedagogie_filiere_technologique: "Technologique",
    pedagogie_filiere_professionnelle: "Professionnelle"
};
