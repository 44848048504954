import {
  Box,
  FormLabel,
  FormControl,
  Input,
  ListItem,
  Text,
  UnorderedList,
  Heading,
  CheckboxGroup,
  Checkbox,
  VStack,
} from '@chakra-ui/react';
import { FormFrame } from '@inspire/ui/chakra/forms/Form';
import Frame from '@inspire/ui/chakra/layout/Frame';
import Span from '@inspire/ui/chakra/Span';
// import ClickableImage from 'components/general/ClickableImage';
import VideoPlayer from 'components/general/VideoPlayer';
import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ClickableImage from 'components/general/ClickableImage';
import ConsigneTag from 'components/activites/ConsigneTag';

import InfographieSrc from '../../../../public/img/infographies/decrypter-offre.png';
import Euros from 'components/general/Euros';

export const TonAnalyseOffreEmploi = () => (
  <>
    <Text fontStyle="italic">
      <ConsigneTag /> : Lis cette offre d’emploi, réponds aux questions, puis
      découvre les réponses dans la vidéo page suivante.
    </Text>

    <Box as={Frame} py={2} px={6} label="" bgColor="gray.200">
      <Heading size="sm">OFFRE D’EMPLOI</Heading>
      <Text fontWeight="bold">Conseiller en location H/F</Text>
      <Text>
        L’entreprise Joli Immeuble recrute un conseiller ou une conseillère en
        location pour son agence de Bordeaux. Agence Ouverte en 2021, notre
        agence compte aujourd’hui 8 collaborateurs couvrant tous les métiers de
        l’immobilier : Vente, Gestion Locative, Location, Habitations et Locaux
        Commerciaux.
      </Text>
      <Text fontWeight="bold">Vos missions principales</Text>
      <UnorderedList>
        <ListItem>
          Prospection (physique et téléphonique) de biens immobiliers à louer
        </ListItem>
        <ListItem>Réalisation des estimations locatives</ListItem>
        <ListItem>Négociation et signature des mandats</ListItem>
        <ListItem>Mise en publicité des biens</ListItem>
        <ListItem>Organiser et effectuer des visites de biens</ListItem>
        <ListItem>Etude de la solvabilité des dossiers</ListItem>
        <ListItem>Rédaction des baux</ListItem>
        <ListItem>
          Etablissement des états des lieux d’entrée et de sortie
        </ListItem>
      </UnorderedList>

      <Text fontWeight="bold">Profil recherché</Text>
      <Text m={0}>Personne dynamique ayant le sens du contact</Text>
      <Text m={0}>
        Tempérament commercial, vous avez un goût prononcé pour le challenge.
      </Text>
      <Text m={0}>Avec ou sans expérience.</Text>
      <Text m={0}>
        Sous la responsabilité directe du Directeur de l’agence.
      </Text>
      <Text>
        <Span fontWeight="bold">Type d'emploi :</Span> Temps plein, CDI
      </Text>
      <Text>
        <Span fontWeight="bold">Salaire :</Span> <Euros>1 300</Euros> à{' '}
        <Euros>3 000</Euros> par mois
      </Text>
      <Text>
        <Span fontWeight="bold">Horaires :</Span> Périodes de travail de 8
        Heures sur 5 jours, soit 40 heures par semaine
      </Text>
      <Text>
        <Span fontWeight="bold">Permis/certificat:</Span> Permis B (Optionnel)
      </Text>
      <Text>
        <Span fontWeight="bold">Date de début prévue :</Span> dès que possible
      </Text>
      <Text>
        Envoyer votre candidature à l’attention de Monsieur Antonin, responsable
        RH, à antonin@joliimmeuble.br
      </Text>
    </Box>

    <FormFrame mt={5} legend="Informations sur l'entreprise :">
      <FormControl>
        <FormLabel>Nom de l’entreprise, secteur d’activité</FormLabel>
        <Input />
      </FormControl>
      <FormControl>
        <FormLabel>Contact (nom, poste, adresse mail, etc.)</FormLabel>
        <Input />
      </FormControl>
    </FormFrame>

    <FormFrame mt={5} legend="Informations sur le poste :">
      <FormControl>
        <FormLabel>Les missions/ les activités proposées</FormLabel>
        <Input />
      </FormControl>
      <FormControl>
        <FormLabel>
          L’environnement/ les conditions de travail (mobilité, horaires,
          salaires, etc.)
        </FormLabel>
        <Input />
      </FormControl>
      <FormControl>
        <FormLabel>
          Relation interne et externe (collaboration, hiérarchie, partenariats,
          etc.)
        </FormLabel>
        <Input />
      </FormControl>
    </FormFrame>

    <FormFrame mt={5} legend="Informations sur le profil du candidat :">
      <FormControl>
        <FormLabel>Formation et expériences</FormLabel>
        <Input />
      </FormControl>
      <FormControl>
        <FormLabel>Compétences techniques</FormLabel>
        <Input />
      </FormControl>

      <FormControl>
        <FormLabel>Compétences transversales</FormLabel>
        <Input />
      </FormControl>

      <FormControl>
        <FormLabel>Qualités</FormLabel>
        <Input />
      </FormControl>
    </FormFrame>
  </>
);

export const AnalyseOffrePro = () => (
  <>
    <ReflexionOralHeader />
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        L’offre d’emploi est ici analysée par une professionnelle des Ressources
        Humaines qui donne son interprétation sur celle-ci.
      </Text>
    </ProfTips>

    <Text fontStyle="italic" textAlign="center">
      <ConsigneTag /> : Regarde la vidéo et compare ton interprétation à celle
      de la professionnelle en répondant aux questions.
    </Text>

    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/terminale-que-dit-cette-offre.mp4'} />
      <Text textAlign="center" mt={2}>
        Que dit cette offre ?
      </Text>
    </Box>

    <UnorderedList>
      <ListItem>
        Avais-tu fais attention aux informations sur la taille et l’ancienneté
        d’existence de l’entreprise ?
      </ListItem>
      <ListItem>
        Qu’est-ce qu’implique le fait que la structure soit récente et de petite
        taille ?
      </ListItem>
      <ListItem>
        Quelles sont les missions / activités que la professionnelle a
        identifiées pour le poste ?
      </ListItem>
      <ListItem>
        Avais-tu remarqué la polyvalence demandée par ce poste demande ?
      </ListItem>
      <ListItem>
        Avais-tu vu que ce poste demandait surtout des compétences transversales
        (soft skills) plutôt que des compétences techniques ?
      </ListItem>
      <ListItem>
        Est-ce que tu penses qu’il est possible de postuler sans diplôme ?
      </ListItem>
    </UnorderedList>
  </>
);

export const InfographieDecrypterOffre = () => (
  <>
    <Text textStyle="italic">
      <ConsigneTag /> : Regarde cette infographie pour avoir des conseils pour
      décrypter une offre d’emploi puis réponds à la question.
    </Text>

    <Box textAlign="center" maxW="600px" margin="auto">
      <ClickableImage
        src={InfographieSrc}
        href="/img/infographies/decrypter-offre.png"
        alt="Infographie études supérieurs"
      />
    </Box>

    <Text fontStyle="italic">
      Et toi, lorsque que tu lis une offre, quelle(s) information(s) tu regardes
      en premier ? (Plusieurs réponses possibles)
    </Text>
    <CheckboxGroup>
      <VStack alignItems={'start'}>
        <Checkbox>La localisation </Checkbox>
        <Checkbox>La rémunération </Checkbox>
        <Checkbox>Les compétences techniques demandées </Checkbox>
        <Checkbox>Les compétences transversale demandées </Checkbox>
        <Checkbox>
          L’expérience du candidat Les conditions de travail du poste
        </Checkbox>
        <Checkbox>Les conditions de travail du poste</Checkbox>
      </VStack>
    </CheckboxGroup>
  </>
);
