import { useLocalStore } from 'lib/store';

export const useLocalStoreItem = (key: string) => {
  const value = useLocalStore((s) => s[key]);
  const set = useLocalStore((s) => s.set);
  const setTrue = useLocalStore((s) => s.setTrue);
  const setFalse = useLocalStore((s) => s.setFalse);

  return {
    value,
    set: (val) => set(key, val),
    setTrue: () => setTrue(key),
    setFalse: () => setFalse(key),
  };
};
