import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { omit } from "lodash";
export var MotivationOptions = {
    sport_en_club: "Pratiqu\xe9 un sport en club",
    art_en_club: "Pratiqu\xe9 une activit\xe9 artistique hors du temps scolaire",
    association: "Et\xe9 b\xe9n\xe9vole dans une asso",
    stage: "Effectu\xe9 un stage/job/service civique en lien avec ton projet d'orientation",
    bafa: "Effectu\xe9 une formation BAFA",
    premiers_secours: "Effectu\xe9 une formation aux premiers secours",
    pompier: "Pris des cours ou servi comme pompier volontaire",
    mooc: "Suivi un MOOC de pr\xe9paration \xe0 l'enseignement sup\xe9rieur"
};
export var MotivationOptionsBacPro = _object_spread_props(_object_spread({}, omit(MotivationOptions, [
    "stage",
    "mooc"
])), {
    delegueDeClasse: "\xc9t\xe9 d\xe9l\xe9gu\xe9 de classe",
    representant: "\xc9t\xe9 repr\xe9sentant (syndicats, associations,etc.)",
    travailler: "Travaill\xe9 (babysitting, aide aux devoirs, etc)"
});
export var AllMotivationsOptions = _object_spread({}, MotivationOptions, MotivationOptionsBacPro);
