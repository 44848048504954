import {
  Box,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderProps,
  StackProps,
  FormControlProps,
  SliderThumbProps,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { FormControl } from 'formik-chakra-ui';

import Span from '@inspire/ui/chakra/Span';

import { hideIfNarrowerThan, hideIfWiderThan } from 'styles/style-utils';

interface Props {
  name: string;
  value?: string;
  options?: { value: string; label: string }[];
  lowLabel?: string;
  lowLabelNarrow?: string;
  lowLabelThreshold?: number;
  highLabel?: string;
  highLabelNarrow?: string;
  highLabelThreshold?: number;
  sliderProps?: SliderProps;
  min?: number;
  max?: number;
  defaultValue?: number;
  valueLabels?: object;
  thumbProps?: SliderThumbProps;
}

const LabelledSliderField = ({
  name,
  value,
  lowLabel = 'Pas du tout',
  lowLabelNarrow = 'Non',
  lowLabelThreshold = 340,
  highLabel = 'Tout à fait',
  highLabelNarrow = 'Oui',
  highLabelThreshold = 340,
  min = 1,
  max = 7,
  defaultValue = 4,
  sliderProps,
  valueLabels,
  thumbProps,
  ...hstackProps
}: Props & StackProps) => (
  <HStack direction="row" spacing={6} maxW="400px" {...hstackProps}>
    <Box whiteSpace="nowrap">
      <Span sx={hideIfNarrowerThan(`${lowLabelThreshold}px`)}>{lowLabel}</Span>
      <Span sx={hideIfWiderThan(`${lowLabelThreshold + 1}px`)}>
        {lowLabelNarrow ?? lowLabel}
      </Span>
    </Box>
    <Field name={name} value={value}>
      {({ field, form }) => (
        <Slider
          {...field}
          value={field.value || undefined}
          onChangeStart={(val) => form.setFieldValue(name, val)}
          onChange={(val) => form.setFieldValue(name, val)}
          defaultValue={defaultValue}
          min={min}
          max={max}
          {...sliderProps}
        >
          <SliderTrack h={2} borderRadius={999}>
            <Box position="relative" right={10} />
            <SliderFilledTrack
              bgColor={
                value === undefined || value === null || value === ''
                  ? 'gray.200'
                  : undefined
              }
            />
          </SliderTrack>
          {valueLabels ? (
            <SliderThumb fontSize="xs" h={6} w={'80px'} {...thumbProps}>
              {value ? valueLabels[value] : 'Déplacer'}
            </SliderThumb>
          ) : (
            <SliderThumb boxSize={5} />
          )}
        </Slider>
      )}
    </Field>
    <Box whiteSpace="nowrap">
      <Span sx={hideIfNarrowerThan(`${highLabelThreshold}px`)}>
        {highLabel}
      </Span>
      <Span sx={hideIfWiderThan(`${highLabelThreshold + 1}px`)}>
        {highLabelNarrow ?? highLabel}
      </Span>
    </Box>
  </HStack>
);

export const LabelledSliderControl = ({
  name,
  value,
  label,
  sliderProps,
  ...formControlProps
}: {
  name: string;
  value: string;
  label: string;
  sliderProps?: Omit<Props, 'name'>;
} & FormControlProps) => (
  <FormControl name={name} label={label} {...formControlProps}>
    <LabelledSliderField name={name} value={value} {...sliderProps} />
  </FormControl>
);

export default LabelledSliderField;
