import {
  Box,
  Checkbox,
  CheckboxGroup,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  SimpleGrid,
  Radio,
  Heading,
} from '@chakra-ui/react';
// import VideoPlayer from 'components/general/VideoPlayer';
// import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
// import ProfTips from 'components/activites/ProfTips';
import { useState, useEffect } from 'react';
import { Qualites } from '@inspire/data/static/qualites';
import Frame from '@inspire/ui/chakra/layout/Frame';
import { useMyProfile, useUser } from 'lib/hooks';
import { Form, Formik, FormikConfig, FormikValues, FieldArray } from 'formik';
import { FormStack } from '@inspire/ui/chakra/forms/Form';
import { FormControl, InputControl, RadioGroupControl } from 'formik-chakra-ui';
import { FormActiviteFooter } from 'components/activites/ActiviteFooter';
import { useMount } from 'react-use';
import { validateSchema } from '@inspire/data/helpers/schemas';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import Required from 'components/general/Required';
import {
  RecapCheckbox,
  RecapOptionsFields,
} from 'components/activites/recap-helpers';

const MesCentresDinteretsOptions = [
  {
    label: 'Sport (préciser lequel)',
    value: 'sport',
    hasTextInput: true,
  },
  {
    label: 'Danse (préciser laquelle)',
    value: 'danse',
    hasTextInput: true,
  },
  {
    label: 'Les jeux vidéo',
    value: 'jeux-video',
  },
  {
    label: 'Les voyages',
    value: 'voyages',
  },
  {
    label: 'Ecouter de la musique',
    value: 'musique',
  },
  {
    label: 'Pratiquer un instrument de musique',
    value: 'instrument',
  },
  {
    label: 'Le chant',
    value: 'chant',
  },
  {
    label: 'Ecrire des textes',
    value: 'textes',
  },
  {
    label: 'Le dessin',
    value: 'dessin',
  },
  {
    label: 'Prendre des photos',
    value: 'photos',
  },
  {
    label: 'Regarder des films, des séries',
    value: 'films',
  },
  {
    label: 'Lire des livres, des magazines',
    value: 'livres',
  },
  {
    label: 'Cuisiner',
    value: 'cuisiner',
  },
  {
    label:
      'Rendre service aux autres (engagement associatif, maraude, bénévolat…)',
    value: 'service',
  },
  {
    label: 'Autre (à préciser)',
    value: 'autre',
    hasTextInput: true,
  },
];

export const CentresDinteretsLabel = (value) => {
  for (const centreDinteret of MesCentresDinteretsOptions) {
    if (centreDinteret.value === value) {
      return centreDinteret.label;
    }
  }
  return '';
};

export const MesPreferencesDapprentissageOptions = [
  {
    label: 'J’aime apprendre seul(e)',
    value: 'seul',
  },
  {
    label: 'J’aime apprendre en groupe avec mes camarades',
    value: 'groupe',
  },
  {
    label:
      'J’ai besoin d’être encadré(e) pour apprendre (par un professeur, mes parents,...)',
    value: 'encadre',
  },
  {
    label:
      'Je préfère apprendre en autonomie (sans être encadré(e) par un professeur ou mes parents)',
    value: 'autonomie',
  },
  {
    label: 'J’aime les matières plus généralistes et théoriques',
    value: 'generalistes',
  },
  {
    label: 'J’aime les matières professionnelles et pratiques',
    value: 'pratiques',
  },
  {
    label:
      'J’aime apprendre directement auprès des professionnels (en stage par exemple)',
    value: 'professionnels',
  },
];

export const PreferencesDapprentissageLabel = (value) => {
  for (const preference of MesPreferencesDapprentissageOptions) {
    if (preference.value === value) {
      return preference.label;
    }
  }
  return '';
};

export const MesPrioritesPourMonAvenirOptions = [
  {
    label:
      'L’épanouissement personnel (pratiquer des matières que tu aimes et un métier qui correspond à tes valeurs et dans lequel tu es épanoui(e))',
    value: 'epanouissement',
  },
  {
    label: 'La sécurité financière (gagner suffisamment d’argent)',
    value: 'securite',
  },
  {
    label:
      'La reconnaissance des autres (pratiquer un métier qui renvoie une bonne image de toi)',
    value: 'reconnaissance',
  },
  {
    label:
      'L’équilibre entre le temps de travail et les loisirs (un métier qui te laisse du temps pour t’amuser).',
    value: 'equilibre',
  },
];

export const PrioritesPourMonAvenirLabel = (value) => {
  for (const priorite of MesPrioritesPourMonAvenirOptions) {
    if (priorite.value === value) {
      return priorite.label;
    }
  }
  return '';
};

const MetiersEtSecteursOptions = [
  {
    label: 'Agriculture, agroalimentaire, environnement',
    value: 'agriculture',
  },
  {
    label: 'Arts, culture',
    value: 'arts',
  },
  {
    label: 'Bâtiments, travaux publics',
    value: 'batiments',
  },
  {
    label: 'Commerce/ vente, gestion, économie, management',
    value: 'commerce',
  },
  {
    label: 'Droits, sciences politiques, sécurité',
    value: 'droits',
  },
  {
    label: 'Enseignement, formation',
    value: 'enseignement',
  },
  {
    label: 'Hôtellerie, restauration, tourisme',
    value: 'hotellerie',
  },
  {
    label: 'Industrie',
    value: 'industrie',
  },
  {
    label: 'Information, communication',
    value: 'information',
  },
  {
    label: 'Lettres, langues, sciences humaines',
    value: 'lettres',
  },
  {
    label: 'Santé',
    value: 'sante',
  },
  {
    label: 'Sciences',
    value: 'sciences',
  },
  {
    label: 'Social',
    value: 'social',
  },
  {
    label: 'Sport, animation',
    value: 'sport',
  },
  {
    label: 'Transport, Logistique',
    value: 'transport',
  },
];

export const MetiersEtSecteursLabel = (value) => {
  for (const metierSecteur of MetiersEtSecteursOptions) {
    if (metierSecteur.value === value) {
      return metierSecteur.label;
    }
  }
  return '';
};

// export const TemoignageVideo = () => (
//   <>
//     <ProfTips mb={3}>
//       <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
//       <UnorderedList>
//         <ListItem>
//           Utiliser un vidéoprojecteur pour une diffusion collective.
//         </ListItem>
//         <ListItem>
//           Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
//           sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
//         </ListItem>
//       </UnorderedList>
//       <Text>
//         Ces vidéos présentent des témoignages d’anciens élèves de bac
//         professionnel illustrant la manière dont ils ont réussi à faire de leur
//         parcours une force, en développant leurs compétences pour atteindre
//         leurs objectifs.
//       </Text>
//     </ProfTips>
//     <ReflexionOralHeader />
//     <Text my={4} textAlign={'center'}>
//       <i>
//         Regarde la vidéo en étant attentif aux parcours de ces anciens élèves de
//         bac pro et aux objectifs fixés qu’ils ont pu atteindre. Tu répondras à
//         l’oral aux questions.
//       </i>
//     </Text>
//     <Box maxWidth={'400px'} margin="auto">
//       <VideoPlayer url={'/videos-bacpro/1.+Parcours+Une+Force-1.mp4'} />
//     </Box>
//     <UnorderedList mt={8} spacing={3}>
//       <ListItem>
//         Qu’est-ce qui a motivé Amara à poursuivre dans son parcours alors qu’il
//         ne l’avait pas choisi ?
//       </ListItem>
//       <ListItem>
//         Quels sont les points forts mis en avant par les témoins pour parler de
//         leurs parcours ?
//       </ListItem>
//       <ListItem>Qu’est-ce qui a permis à Océane de s’améliorer ?</ListItem>
//       <ListItem>
//         Quels sont les points faibles mis en avant par Amara ? Est-ce qu’il a
//         réussi à les surmonter ?
//       </ListItem>
//     </UnorderedList>
//   </>
// );

export const MesCentresDinterets = ({
  goNext,
}: {
  allowNext: (value: boolean) => void;
  goNext: any;
}) => {
  const { isProf } = useUser();
  const [allowNext, setAllowNext] = useState(false);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    centresDinterets: myProfile?.centresDinterets ?? {},
  };

  const schema =
    LyceenBacProProfileSchema.pick('centresDinterets').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  useMount(() => {
    if (myProfile?.centresDinterets?.centresDinterets.length)
      setAllowNext(true);
  });

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormStack>
            <FormControl
              name={'centresDinterets'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>Quels sont tes centres d'intérêts ?</>
                  </Required>
                </Text>
              }
            >
              <Text marginBottom={'40px'} fontStyle={'italic'}>
                Coche les réponses de ton choix en complétant si besoin.
              </Text>
              <FieldArray
                name={'centresDinterets.centresDinterets'}
                render={(arrayHelpers) => (
                  <VStack alignItems={'start'}>
                    {MesCentresDinteretsOptions.map((o) => (
                      <>
                        <Checkbox
                          key={o.value}
                          value={o.value}
                          isChecked={values?.centresDinterets?.centresDinterets?.includes(
                            o.value
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              arrayHelpers.push(o.value);
                              if (
                                values?.centresDinterets?.centresDinterets
                                  ?.length +
                                  1 >=
                                  0 ||
                                !values?.centresDinterets?.centresDinterets
                                  ?.length
                              )
                                setAllowNext(true);
                            } else {
                              const idx =
                                values?.centresDinterets?.centresDinterets?.indexOf(
                                  o.value
                                );
                              arrayHelpers.remove(idx);
                              if (
                                values?.centresDinterets?.centresDinterets
                                  ?.length -
                                  1 <=
                                0
                              )
                                setAllowNext(false);
                            }
                          }}
                        >
                          {o.label}
                        </Checkbox>
                        {o.hasTextInput && (
                          <InputControl name={'centresDinterets.' + o.value} />
                        )}
                      </>
                    ))}
                  </VStack>
                )}
              />
            </FormControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const MesPreferencesDapprentissage = ({ goNext }) => {
  const { isProf } = useUser();
  const [allowNext, setAllowNext] = useState(false);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    preferencesApprentissage: myProfile?.preferencesApprentissage,
  };

  const schema = LyceenBacProProfileSchema.pick(
    'preferencesApprentissage'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  useMount(() => {
    if (myProfile?.preferencesApprentissage?.length) setAllowNext(true);
  });

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormStack>
            <FormControl
              name={'preferencesApprentissage'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>De quelle façon préfères-tu apprendre ?</>
                  </Required>
                </Text>
              }
            >
              <Text marginBottom={'40px'} fontStyle={'italic'}>
                Coche les réponses de ton choix (plusieurs réponses possibles).
              </Text>
              <FieldArray
                name={'preferencesApprentissage'}
                render={(arrayHelpers) => (
                  <VStack alignItems={'start'}>
                    {MesPreferencesDapprentissageOptions.map((o) => (
                      <Checkbox
                        key={o.value}
                        value={o.value}
                        isChecked={values?.preferencesApprentissage?.includes(
                          o.value
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(o.value);
                            if (
                              values?.preferencesApprentissage?.length + 1 >=
                                0 ||
                              !values?.preferencesApprentissage?.length
                            )
                              setAllowNext(true);
                          } else {
                            const idx =
                              values?.preferencesApprentissage?.indexOf(
                                o.value
                              );
                            arrayHelpers.remove(idx);
                            if (
                              values?.preferencesApprentissage?.length - 1 <=
                              0
                            )
                              setAllowNext(false);
                          }
                        }}
                      >
                        {o.label}
                      </Checkbox>
                    ))}
                  </VStack>
                )}
              />
            </FormControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const MesPrioritesPourMonAvenir = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });
  const { isProf } = useUser();
  let initialPrioriteAvenir = '';
  let allow = false;
  if (myProfile && myProfile.prioritesAvenir) {
    initialPrioriteAvenir = myProfile.prioritesAvenir[0];
    allow = true;
  }

  const [allowNext, setAllowNext] = useState(allow);

  const initialValues = {
    prioritesAvenir: initialPrioriteAvenir,
  };

  const schema =
    LyceenBacProProfileSchema.pick('prioritesAvenir').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <RadioGroupControl
            name="prioritesAvenir"
            label="Dans tes études et ton futur métier, tu préfères prioriser :"
            stackProps={{ direction: 'column' }}
            isRequired
          >
            <Text marginBottom={'40px'} fontStyle={'italic'}>
              Coche la réponse de ton choix{' '}
            </Text>

            {MesPrioritesPourMonAvenirOptions.map((o) => (
              <Radio
                key={o.value}
                value={o.value}
                onChange={() => {
                  setAllowNext(true);
                }}
              >
                {' '}
                {o.label}{' '}
              </Radio>
            ))}
          </RadioGroupControl>

          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const MesMatieresPreferees = ({ goNext }) => {
  const { isProf } = useUser();
  const [allowNext, setAllowNext] = useState(false);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    matieresPreferes: myProfile?.matieresPreferes ?? [],
  };

  const schema =
    LyceenBacProProfileSchema.pick('matieresPreferes').requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  let initialMat1 = '';
  let initialMat2 = '';
  let initialMat3 = '';
  initialMat1 = myProfile?.matieresPreferes?.[0];
  initialMat2 = myProfile?.matieresPreferes?.[1];
  initialMat3 = myProfile?.matieresPreferes?.[2];
  const [mat1, setMat1] = useState(initialMat1);
  const [mat2, setMat2] = useState(initialMat2);
  const [mat3, setMat3] = useState(initialMat3);

  useEffect(() => {
    if (mat1?.length > 0 && mat2?.length > 0 && mat3?.length > 0) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [mat1, mat2, mat3]);

  return (
    <Formik {...formik}>
      {() => (
        <Form>
          <FormStack>
            <FormControl
              name={'matieresPreferes'}
              label={'Quelles sont tes trois matières préférées ?'}
            >
              <InputControl
                name="matieresPreferes.0"
                label="Matière préférée 1"
                isRequired
                onChange={(e: any) => {
                  setMat1(e.target.value);
                }}
                mt={7}
              />
              <InputControl
                name="matieresPreferes.1"
                label="Matière préférée 2"
                isRequired
                onChange={(e: any) => {
                  setMat2(e.target.value);
                }}
                mt={5}
              />
              <InputControl
                name="matieresPreferes.2"
                label="Matière préférée 3"
                isRequired
                onChange={(e: any) => {
                  setMat3(e.target.value);
                }}
                mt={5}
              />
            </FormControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const QualitesForm = ({ goNext }) => {
  const { isProf } = useUser();
  const [allowNext, setAllowNext] = useState(false);
  const [countOptionsChecked, setCountOptionsChecked] = useState(0);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const initialValues = {
    meConnaitreQualites: myProfile?.meConnaitreQualites,
  };

  const schema = LyceenBacProProfileSchema.pick(
    'meConnaitreQualites'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(values),
  };

  useMount(() => {
    if (myProfile?.meConnaitreQualites?.length >= 5) setAllowNext(true);
    setCountOptionsChecked(myProfile?.meConnaitreQualites?.length ?? 0);
  });

  useEffect(() => {
    if (countOptionsChecked >= 5) setAllowNext(true);
    else setAllowNext(false);
  }, [countOptionsChecked]);

  const filteredQualities: any = Object.keys(Qualites).reduce(
    (accumulator, key) => {
      const value = Qualites[key];
      if (
        key !== 'observateur' &&
        key !== 'ouvert' &&
        key !== 'concentration' &&
        key !== 'determine' &&
        key !== 'souriant' &&
        key !== 'discipline' &&
        key !== 'stratege' &&
        key !== 'equipe'
      ) {
        accumulator[key] = value;
      }
      return accumulator;
    },
    {}
  );
  filteredQualities.collaboratif = 'Collaboratif(ve)';

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormStack>
            <FormControl
              name={'meConnaitreQualites'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>Mes qualités :</>
                  </Required>
                </Text>
              }
            >
              <Text marginBottom={'40px'} fontStyle={'italic'}>
                Coche les 5 qualités qui te correspondent le plus
              </Text>
              <FieldArray
                name={'meConnaitreQualites'}
                render={(arrayHelpers) => (
                  <>
                    <SimpleGrid minChildWidth="150px" spacing={6}>
                      {Object.keys(filteredQualities).map((q) => (
                        <Checkbox
                          key={q}
                          value={q}
                          isChecked={values?.meConnaitreQualites?.includes(q)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              arrayHelpers.push(q);
                              setCountOptionsChecked(countOptionsChecked + 1);
                            } else {
                              const idx =
                                values?.meConnaitreQualites?.indexOf(q);
                              arrayHelpers.remove(idx);
                              setCountOptionsChecked(countOptionsChecked - 1);
                            }
                          }}
                          disabled={
                            !values?.meConnaitreQualites?.includes(q) &&
                            countOptionsChecked >= 5
                          }
                        >
                          {filteredQualities[q]}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                    <Frame
                      label={
                        'Pour t’aider, voici quelques définitions supplémentaires : '
                      }
                      mt={'50px'}
                    >
                      <UnorderedList>
                        <ListItem>
                          <b>Meneur(euse) :</b> Qui sait diriger et emmener les
                          autres vers une idée ou un projet
                        </ListItem>
                        <ListItem>
                          <b>Autonome :</b> Qui sait se gérer seul(e)
                        </ListItem>
                        <ListItem>
                          <b>Bienveillant(e) :</b> Attentif(ve) au bien-être des
                          autres
                        </ListItem>
                        <ListItem>
                          <b>Perséverant(e) :</b> Qui ne laisse pas tomber
                        </ListItem>
                        <ListItem>
                          <b>Collaboratif(ve) :</b> Qui sait travailler avec les
                          autres
                        </ListItem>
                      </UnorderedList>
                    </Frame>
                  </>
                )}
              />
            </FormControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const MetierOuSecteur = ({ goNext }) => {
  const { isProf } = useUser();
  const [allowNext, setAllowNext] = useState(false);

  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const [saitMetierOuSecteur, setSaitMetierOuSecteur] = useState('none');
  const [metierOuSecteur, setMetierOuSecteur] = useState('');
  const [countOptionsChecked, setCountOptionsChecked] = useState(0);

  const initialValues = {
    metierOuSecteurReve: myProfile?.metierOuSecteurReve ?? {},
  };

  const schema = LyceenBacProProfileSchema.pick(
    'metierOuSecteurReve'
  ).requireFields();

  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => {
      setAndRefetchMyProfile(values);
    },
  };

  useMount(() => {
    if (myProfile?.metierOuSecteurReve) {
      setSaitMetierOuSecteur(
        myProfile?.metierOuSecteurReve?.saitMetierOuSecteur ? 'yes' : 'no'
      );
    } else setSaitMetierOuSecteur('none');
  });

  useEffect(() => {
    if (
      saitMetierOuSecteur === 'yes' &&
      (metierOuSecteur !== '' ||
        myProfile?.metierOuSecteurReve?.metierOuSecteur)
    ) {
      setAllowNext(true);
    } else if (
      saitMetierOuSecteur === 'no' &&
      countOptionsChecked >= 1 &&
      countOptionsChecked <= 3
    ) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [saitMetierOuSecteur]);

  useMount(() => {
    const secteursLength =
      myProfile?.metierOuSecteurReve?.pourraitInteresser?.length;
    if (secteursLength && secteursLength >= 1 && secteursLength <= 3)
      setAllowNext(true);
    setCountOptionsChecked(secteursLength ?? 0);
  });

  useEffect(() => {
    if (countOptionsChecked >= 1 && countOptionsChecked <= 3)
      setAllowNext(true);
    else setAllowNext(false);
  }, [countOptionsChecked]);

  return (
    <Formik {...formik}>
      {({ values }) => (
        <Form>
          <FormStack>
            <FormControl
              name={'metierOuSecteurReve'}
              label={
                <Text fontWeight={'bold'}>
                  <Required>
                    <>
                      As-tu un métier que tu rêverais d’exercer ou connais-tu le
                      secteur dans lequel tu aimerais travailler ?
                    </>
                  </Required>
                </Text>
              }
            >
              <CheckboxGroup>
                <VStack alignItems={'start'}>
                  <Checkbox
                    isChecked={saitMetierOuSecteur === 'yes'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSaitMetierOuSecteur('yes');
                        values.metierOuSecteurReve.saitMetierOuSecteur = true;
                      } else {
                        setSaitMetierOuSecteur('none');
                        values.metierOuSecteurReve.saitMetierOuSecteur =
                          undefined;
                      }
                    }}
                  >
                    Oui
                  </Checkbox>
                  <Checkbox
                    isChecked={saitMetierOuSecteur === 'no'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSaitMetierOuSecteur('no');
                        values.metierOuSecteurReve.saitMetierOuSecteur = false;
                      } else {
                        setSaitMetierOuSecteur('none');
                        values.metierOuSecteurReve.saitMetierOuSecteur =
                          undefined;
                      }
                    }}
                  >
                    Non
                  </Checkbox>
                </VStack>
              </CheckboxGroup>
              {saitMetierOuSecteur === 'yes' && (
                <>
                  <Text fontWeight="bold" mt={10}>
                    Lequel ?
                  </Text>
                  <Text fontStyle={'italic'}>
                    Réponds en remplissant le champ libre.
                  </Text>
                  <InputControl
                    name="metierOuSecteurReve.metierOuSecteur"
                    label="Métier ou secteur visé :"
                    isRequired
                    onChange={(e: any) => {
                      if (e.target.value.length) setAllowNext(true);
                      else setAllowNext(false);
                      setMetierOuSecteur(e.target.value);
                    }}
                  />
                </>
              )}
              {saitMetierOuSecteur === 'no' && (
                <>
                  <Text mt={10}>
                    <i>
                      Pour t’aider voici quelques exemples de secteurs
                      professionnels :
                    </i>
                  </Text>
                  <Text fontWeight="bold">
                    <Required>
                      <>
                        Coche ceux qui pourraient t'intéresser (3 choix
                        possibles maximum).
                      </>
                    </Required>
                  </Text>
                  <FieldArray
                    name={'metierOuSecteurReve.pourraitInteresser'}
                    render={(arrayHelpers) => (
                      <VStack alignItems={'start'}>
                        {MetiersEtSecteursOptions.map((o) => (
                          <>
                            <Checkbox
                              key={o.value}
                              value={o.value}
                              isChecked={values?.metierOuSecteurReve?.pourraitInteresser?.includes(
                                o.value
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(o.value);
                                  setCountOptionsChecked(
                                    countOptionsChecked + 1
                                  );
                                } else {
                                  const idx =
                                    values?.metierOuSecteurReve?.pourraitInteresser?.indexOf(
                                      o.value
                                    );
                                  arrayHelpers.remove(idx);
                                  setCountOptionsChecked(
                                    countOptionsChecked - 1
                                  );
                                }
                              }}
                              disabled={
                                !values?.metierOuSecteurReve?.pourraitInteresser?.includes(
                                  o.value
                                ) && countOptionsChecked >= 3
                              }
                            >
                              {o.label}
                            </Checkbox>
                          </>
                        ))}
                      </VStack>
                    )}
                  />
                </>
              )}
            </FormControl>
          </FormStack>
          <FormActiviteFooter
            isLoading={isSettingAndRefetchingMyProfile}
            allowNext={isProf ? true : allowNext}
          />
        </Form>
      )}
    </Formik>
  );
};

export const RecapJapprendsMeConnaitre = ({ profile }) => {
  const centresDinterets = profile.centresDinterets?.centresDinterets;
  const matieresPreferes = profile.matieresPreferes;
  const metierOuSecteurReve = profile.metierOuSecteurReve;

  return (
    <>
      {profile && centresDinterets && centresDinterets.length > 0 ? (
        <Box>
          <Heading size="sm" as="h3">
            {"Mes centres d'intérêts"}
          </Heading>
          <UnorderedList>
            {centresDinterets?.map((q, i) => (
              <ListItem key={i}>
                {q === 'sport' ? (
                  <>
                    Sport
                    {profile.centresDinterets?.sport &&
                      profile.centresDinterets?.sport.length && (
                        <> / {profile.centresDinterets?.sport}</>
                      )}
                  </>
                ) : q === 'danse' ? (
                  <>
                    Danse
                    {profile.centresDinterets?.danse &&
                      profile.centresDinterets?.danse.length && (
                        <> / {profile.centresDinterets?.danse}</>
                      )}
                  </>
                ) : q === 'autre' ? (
                  <>
                    Autre
                    {profile.centresDinterets?.autre &&
                      profile.centresDinterets?.autre.length && (
                        <> / {profile.centresDinterets?.autre}</>
                      )}
                  </>
                ) : (
                  CentresDinteretsLabel(q)
                )}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      ) : null}

      <RecapOptionsFields
        profile={profile}
        fieldName="preferencesApprentissage"
        header="Mes préférences d'apprentissage"
        valueToLabelMethod={PreferencesDapprentissageLabel}
      />

      <RecapOptionsFields
        profile={profile}
        fieldName="prioritesAvenir"
        header="Mes priorités pour mon avenir"
        valueToLabelMethod={PrioritesPourMonAvenirLabel}
      />

      {profile && matieresPreferes && matieresPreferes.length > 0 ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Mes matières préférées'}
          </Heading>
          <UnorderedList>
            {matieresPreferes?.map((q, i) => (
              <ListItem key={i}>{q}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      ) : null}

      <RecapCheckbox
        profile={profile}
        fieldName="meConnaitreQualites"
        header=" Mes qualités"
        options={Qualites}
      />

      {profile &&
      metierOuSecteurReve &&
      metierOuSecteurReve.saitMetierOuSecteur !== undefined ? (
        <Box>
          <Heading size="sm" as="h3">
            {'Mon métier ou secteur visé'}
          </Heading>
          <UnorderedList>
            <ListItem>
              {' '}
              As-tu un métier que tu rêverais d’exercer ou connais-tu le secteur
              dans lequel tu aimerais travailler ?{' '}
              {metierOuSecteurReve.saitMetierOuSecteur ? 'Oui' : 'Non'}{' '}
            </ListItem>
            {metierOuSecteurReve.saitMetierOuSecteur &&
              metierOuSecteurReve.metierOuSecteur && (
                <ListItem>
                  {' '}
                  Lequel ? {metierOuSecteurReve.metierOuSecteur}{' '}
                </ListItem>
              )}
          </UnorderedList>
          {!metierOuSecteurReve.saitMetierOuSecteur &&
          metierOuSecteurReve.pourraitInteresser &&
          metierOuSecteurReve.pourraitInteresser.length > 0 ? (
            <>
              <Box>
                <Heading size="sm" as="h3">
                  {"Secteurs qui pourraient t'intéresser ?"}
                </Heading>
                <UnorderedList>
                  {metierOuSecteurReve?.pourraitInteresser?.map((q, i) => (
                    <ListItem key={i}>{MetiersEtSecteursLabel(q)}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};
