import { FormControlProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { LabelledSliderControl } from 'components/forms//LabelledSliderField';

const FaciliteTrouverStageControl = (formControlProps: FormControlProps) => {
  const { values } = useFormikContext<{ difficulteTrouverStage: any }>();

  return (
    <LabelledSliderControl
      label="C'est facile pour moi de trouver un stage"
      name="difficulteTrouverStage"
      value={values.difficulteTrouverStage}
      {...formControlProps}
    />
  );
};

export default FaciliteTrouverStageControl;
