import { forEach } from "lodash";
import moment from "moment";
import { Departments } from "../static/departments";
import { DepsToRegions } from "../static/regions";
export var StaticHelpers = {
    getDepartmentFromNumber: function(nbr) {
        return Departments["".concat(nbr)];
    },
    getDepartmentFromPostalCode: function(codePostal) {
        var frenchCodePostal = new RegExp(/^[0-9][0-9AB][0-9]{3}$/gi);
        if (!codePostal || !frenchCodePostal.test(codePostal)) {
            return null;
        }
        var dep = codePostal.substring(0, 2);
        if (dep === "97" || dep === "98") {
            // special case where postal code is 3 digits
            dep = codePostal.substring(0, 3);
        }
        return dep;
    },
    getRegionFromPostalCode: function(postalCode) {
        var dep = StaticHelpers.getDepartmentFromPostalCode(postalCode);
        return DepsToRegions[dep];
    },
    getPrettyDepartments: function() {
        var prettyDepartements = {};
        forEach(Departments, function(value, key) {
            prettyDepartements[key] = key + (Departments[key] && " - ".concat(Departments[key]));
        });
        return prettyDepartements;
    },
    /* returns the last possible school year */ /* eg: if current date is Jan 2016, returns 2015 */ /* if current date is October 2016, returns 2016 */ getLatestSchoolYear: function() {
        // transition date for the current year
        var transitionDate = moment(Meteor.settings.public.schoolYear, "DD/MM");
        if (transitionDate.isBefore()) {
            // if we passed transition date,
            // latest school year is current year
            return moment().year();
        }
        // otherwise, its last year
        return moment().subtract(1, "year").year();
    },
    getCurrentAnneeScolaire: function() {
        var schoolYear = StaticHelpers.getLatestSchoolYear();
        return "".concat(schoolYear, "/").concat(schoolYear + 1);
    }
};
// Date (format DD/MM) after which we consider new signups as part
// of the new school year
var SCHOOL_YEAR_TRANSITION = "01/08";
export var getLatestSchoolYear = function() {
    var transitionDate = moment(SCHOOL_YEAR_TRANSITION, "DD/MM");
    return transitionDate.isBefore() ? moment().year() : moment().subtract(1, "year").year();
};
export var getCreatedAtSchoolYear = function(createdAt) {
    var transitionDate = moment(SCHOOL_YEAR_TRANSITION, "DD/MM");
    return transitionDate.isBefore() ? new Date(createdAt).getFullYear() : new Date(createdAt).getFullYear() - 1;
};
