import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { omit, groupBy, pick, range } from "lodash";
import { BacsPro, Diplomes, Niveaux, FamillesMetiers, FilieresForFamille, FamilleFromFiliere, FamilleFromKey } from "@inspire/data/static/bacs-pro";
import { Bacs2019, Sexe, BoursierOptions, TypesBac, OptionsEclaireur, FilieresTechnos, OptionsEclaireurCMI } from "@inspire/data/static/static";
import { Departments } from "@inspire/data/static/departments";
import { CiviliteTitles, PedagogieRoles } from "@inspire/data/static/pedagogie";
import { PisteTypes } from "@inspire/data/static/etablissement-types";
import { DepsToRegions, FrenchRegions } from "@inspire/data/static/regions";
import { ExtendedBacs } from "@inspire/data/static/static";
import { Domaines } from "@inspire/data/static/domaines";
export var niveauOptions = Object.entries(Niveaux).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var filiereOptions = function(familleMetiers) {
    return Object.entries(pick.apply(void 0, [
        BacsPro
    ].concat(_to_consumable_array(FilieresForFamille(familleMetiers))))).sort().map(function(param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        return {
            label: value,
            value: key
        };
    });
};
export var familleFromFiliere = function(filiere) {
    return FamilleFromFiliere(filiere);
};
export var familleMetiersFromKey = function(familleKey) {
    return FamilleFromKey(familleKey);
};
export var familleMetiersOptions = Object.entries(FamillesMetiers).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var diplomeOptions = Object.keys(Diplomes).map(function(d) {
    return {
        value: d,
        label: Diplomes[d]
    };
});
export var filieresTechnoOptions = Object.entries(FilieresTechnos).sort().map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var titleOptions = Object.entries(CiviliteTitles).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var departementOptions = Object.entries(omit(Departments, [
    "A l'\xe9tranger"
])).sort().map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: "".concat(key, " - ").concat(value),
        value: key
    };
});
export var departementOptionsInt = departementOptions.concat([
    {
        label: "A l'\xe9tranger",
        value: "A l'\xe9tranger"
    }, 
]);
export var genderOptions = Object.keys(Sexe).map(function(k) {
    return {
        label: Sexe[k],
        value: k
    };
});
export var formatLyceesOptions = function(lycees) {
    var groupByCity = groupBy(lycees || [], "ville");
    return Object.keys(groupByCity).sort().map(function(k) {
        return {
            label: k,
            options: groupByCity[k].map(function(l) {
                return {
                    value: l._id,
                    label: "".concat(l.nom),
                    city: l.ville,
                    // @ts-ignore
                    isPrioritaire: l.isPrioritaire
                };
            })
        };
    });
};
export var formationsOptions = function(formations) {
    return formations === null || formations === void 0 ? void 0 : formations.map(function(f) {
        return {
            label: f.name,
            value: f._id
        };
    }).concat([
        {
            label: "Autre",
            value: "autres"
        }
    ]);
};
export var etablissementsOptions = function(etablissements) {
    return etablissements === null || etablissements === void 0 ? void 0 : etablissements.map(function(e) {
        return {
            label: "".concat(e.name, " (").concat(e.commune, ")"),
            value: e._id,
            name: e.name,
            ville: e.commune,
            onisepId: e.idEtablissementOnisep
        };
    }).concat([
        {
            label: "Autre",
            value: "autres"
        }
    ]);
};
export var formationYearOptions = [
    {
        value: 1,
        label: "1re ann\xe9e"
    }, 
].concat(_to_consumable_array(range(2, 10).map(function(a) {
    return {
        value: a,
        label: "".concat(a, "eme ann\xe9e")
    };
})), [
    {
        value: "",
        label: "Non applicable"
    }, 
]);
export var typeFormationOptions = Object.entries(PisteTypes).sort(function(a, b) {
    return a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
}).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var boursierLyceeOptions = Object.keys(BoursierOptions).map(function(k) {
    return {
        value: k,
        label: BoursierOptions[k]
    };
});
export var filiereBacOptions = Object.entries(Bacs2019).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var filiereBacLegacyOptions = Object.entries(TypesBac).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
var departementForRegion = groupBy(Object.keys(DepsToRegions), function(dep) {
    return DepsToRegions[dep];
});
export var regionToDepsOptions = Object.keys(departementForRegion).sort(function(a, b) {
    return a.localeCompare(b);
}).map(function(k) {
    return {
        label: k,
        value: departementForRegion[k]
    };
});
export var regionOptions = FrenchRegions.map(function(r) {
    return {
        label: r,
        value: r
    };
});
export var domainesOptions = Object.entries(Domaines).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var bacsOptions = Object.entries(ExtendedBacs).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var optionsEclaireurOptions = Object.entries(OptionsEclaireur).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var optionsEclaireurCMIOptions = Object.entries(OptionsEclaireurCMI).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
export var pedagogieRoleOptions = Object.entries(PedagogieRoles).map(function(param) {
    var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
    return {
        label: value,
        value: key
    };
});
