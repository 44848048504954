import {
  Box,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
} from '@chakra-ui/react';
import Select from '@inspire/ui/chakra/forms/Select';
import { exists } from '@v1v2/util';
import { useFormikContext } from 'formik';
import { FormControl, BaseProps } from 'formik-chakra-ui';
import get from 'lodash/get';

import { useSlider } from 'lib/hooks';

import isAndroid from 'is-android';

interface Props {
  name: string;
  label?: string;
}

export const defaultGradeIntervals = [
  { label: '0 à 5', formikValue: 2.5, match: (v) => v < 5 },
  { label: '5 à 8', formikValue: 6.5, match: (v) => v < 8 },
  { label: '8 à 10', formikValue: 9, match: (v) => v < 10 },
  { label: '10 à 12', formikValue: 11, match: (v) => v < 12 },
  { label: '12 à 14', formikValue: 13, match: (v) => v < 14 },
  { label: '14 à 16', formikValue: 15, match: (v) => v < 16 },
  { label: '16 à 20', formikValue: 18, match: (v) => v <= 20 },
];

const GradeControl = ({
  label,
  name,
  options = defaultGradeIntervals,
  ...formControlProps
}: {
  options?: {
    label: string;
    formikValue: number;
    match: (number) => boolean;
  }[];
} & Props &
  BaseProps) => {
  const { values, setFieldValue } = useFormikContext();
  const formikValue = get(values, name);
  const { sliderValue, onChange, onChangeEnd } = useSlider({
    backendValueConvertedToSliderValue: exists(formikValue)
      ? // select the matching interval if user value isn't one of the default values
        options.findIndex((o) => o.match(formikValue)) ?? null
      : null,
    onChangeEnd: (value) => setFieldValue(name, options[value]?.formikValue),
  });

  const thumbLabel = options[sliderValue]?.label ?? 'Déplacer';

  const handleSelectChange = (selectedOption) =>
    onChangeEnd(selectedOption.value);

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <Box px={8}>
        {isAndroid ? (
          <Select
            openMenuOnFocus
            options={options.map((item, index) => ({
              value: index,
              label: item.label,
            }))}
            placeholder="Sélectionner"
            isSearchable
            onChange={handleSelectChange}
            value={options[sliderValue]}
          />
        ) : (
          <Slider
            flex="1"
            focusThumbOnChange={false}
            value={sliderValue ?? 5}
            min={0}
            max={options.length - 1}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
          >
            <SliderTrack h={2}>
              {exists(formikValue) && <SliderFilledTrack />}
            </SliderTrack>
            <SliderThumb
              fontSize="sm"
              h={6}
              w={16}
              left={
                exists(sliderValue) ? undefined : 'calc(50% - 32px) !important'
              }
            >
              {thumbLabel}
            </SliderThumb>
          </Slider>
        )}
      </Box>
    </FormControl>
  );
};

export default GradeControl;
