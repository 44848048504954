import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import ProfTips from 'components/activites/ProfTips';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import VideoPlayer from 'components/general/VideoPlayer';
import ClickableImage from 'components/general/ClickableImage';

import InfographieConseilSrc from '../../../../../public/img/infographies/conseils-entretien.png';

export const TemoignagesAnecdotes = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        La vidéo ici est issue des témoignages de nos Éclaireurs Bac Pros qui
        parleront de leurs anecdotes au cours d’un entretien d’embauche pour un
        stage.
      </Text>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>
        Regarde la vidéo et prépare toi à répondre oralement aux questions
        posées.
      </i>
    </Text>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer
        url={'/videos-bacpro/7.+Anecdotes+D+Entretien+Part+Bac+Pro.mp4'}
      />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      <ListItem>Pourquoi l’entretien de Mariam s’est-il mal passé ?</ListItem>
      <ListItem>
        Qu'aurait pu dire Amara, pour rebondir sur la question qu’on lui a posée
        ?
      </ListItem>
      <ListItem>
        Qu’est-ce qui selon vous a plu au recruteur de Taysir pour qu’elle
        obtienne le poste ?
      </ListItem>
    </UnorderedList>
  </>
);

export const TémoignagesProRH = () => (
  <>
    <ProfTips mb={3}>
      <Text>Vous avez plusieurs possibilités pour lancer les vidéos :</Text>
      <UnorderedList>
        <ListItem>
          Utiliser un vidéoprojecteur pour une diffusion collective.
        </ListItem>
        <ListItem>
          Chaque élève lance les vidéos depuis la plateforme sur ordinateur ou
          sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
        </ListItem>
      </UnorderedList>
      <Text>
        La vidéo ici est issue des témoignages de deux professionnels des
        Ressources Humaines qui donnent leurs conseils pour bien préparer les
        entretiens d’embauche et racontent quelques anecdotes.
      </Text>
    </ProfTips>
    <ReflexionOralHeader />
    <Text my={4} textAlign={'center'}>
      <i>
        Regarde la vidéo et prépare toi à répondre oralement aux questions
        posées.
      </i>
    </Text>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer
        url={'/videos-bacpro/6.+Anecdotes+D+Entretien+Partie+Rh.mp4'}
      />
    </Box>
    <UnorderedList mt={8} spacing={3}>
      <ListItem>
        Quels sont les conseils donnés par Véronique Lugiery ?
      </ListItem>
      <ListItem>Quels sont les conseils donnés par Julien Ride ?</ListItem>
      <ListItem>
        Qu’est-ce que le dress code? En quoi est-ce important ?
      </ListItem>
    </UnorderedList>
  </>
);

export const InfographieConseilEntretien = () => (
  <>
    <Text textAlign={'center'}>
      <i>Sais-tu tout ce qu’il faut faire avant et après un entretien ?</i>
    </Text>
    <ClickableImage
      src={InfographieConseilSrc}
      href="/img/infographies/conseils-entretien.png"
      alt="Infographie Conseils Entretien Embauche"
    />
  </>
);
