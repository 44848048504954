import ProfTips from 'components/activites/ProfTips';
import {
  Alert,
  Box,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import ConsigneTag from 'components/activites/ConsigneTag';
import VideoPlayer from 'components/general/VideoPlayer';

export const MetierQuiADuSens = ({}) => {
  return (
    <>
      <ProfTips mb={3}>
        <Text>Vous avez plusieurs possibilités pour lancer la vidéo :</Text>
        <UnorderedList>
          <ListItem>
            Utiliser un vidéoprojecteur pour une diffusion collective
          </ListItem>
          <ListItem>
            Chaque élève lance la vidéo depuis la plateforme sur ordinateur ou
            sur mobile (dans l’idéal les élèves ont chacun des écouteurs).
          </ListItem>
        </UnorderedList>
        <Text>
          Cette vidéo présente des témoignages d’anciens élèves de bac
          professionnel qui parlent de leur recherche d’emploi et de leur choix
          de métier.
        </Text>
      </ProfTips>
      <ConsigneTag />: Regarde la vidéo de James. Tu pourras ensuite répondre
      aux questions à l’oral.
      <UnorderedList>
        <ListItem>
          Pourquoi James a-t-il choisi de se former pour travailler dans le
          secteur de l’aménagement paysager ? 
        </ListItem>
        <ListItem>
          Pourquoi le secteur agricole, et particulièrement l’aménagement
          paysager, résonne avec des ambitions et aspirations personnelles ? 
        </ListItem>
        <ListItem>
          A quels enjeux, vus précédemment, répond le métier de James ?
        </ListItem>
      </UnorderedList>
      <Box margin="auto">
        <VideoPlayer
          url={'/videos-bacpro/Vidéo_1_JAMES_HD.mp4'}
          light={'/videos-bacpro/Thumbnail/thumbnail_james.png'}
        />
      </Box>
    </>
  );
};
