import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import ProfTips from 'components/activites/ProfTips';

import VideoPlayer from 'components/general/VideoPlayer';

const VideoDecouverteMetiers = () => (
  <>
    <Text textAlign="center">
      <i>
        Découvre le témoignage de professionnels, afin d’en savoir plus sur leur
        métier.
      </i>
    </Text>
    <SimpleGrid mt={6} columns={[1, 2, 2, 3]} spacing="40px">
      {[
        {
          title: 'Agent immobilier',
          url: "/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+d'agent+immobilier+en+2+minutes+!.mp4",
        },
        {
          title: 'Assistante juridique',
          url: "/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+d'assistante+juridique+en+2+minutes+!.mp4",
        },
        {
          title: 'Commerçant en épicerie',
          url: '/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+de+commerc%CC%A7ant+en+e%CC%81picerie+en+2+minutes+!.mp4',
        },
        {
          title: 'Commercial',
          url: '/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+de+commercial+en+2+minutes+!.mp4',
        },
        {
          title: 'Comptable',
          url: '/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+de+comptable+en+2+minutes+!.mp4',
        },
        {
          title: 'Conducteur routier',
          url: '/Conducteur en transport routier Marie Teaser 2m.mp4',
        },
        {
          title: 'Contrôleur de gestion',
          url: '/Controleur de gestion Elodie teaser 2m.mp4',
        },
        {
          title: 'Courtier',
          url: '/videos-bacpro/De%CC%81couvre+le+me%CC%81tier+de+courtier+en+2+minutes+!.mp4',
        },
        {
          title: 'Responsable de ressources humaines',
          url: '/Responsable des ressources humaines teaser 2m.mp4',
        },
        {
          title: 'Vendeur automobile',
          url: '/Vendeur automobile Axel Teaser 2m.mp4',
        },
      ].map((v, i) => (
        <Box key={i}>
          <VideoPlayer url={v.url} />
          <Text textAlign={'center'}>
            <b>{v.title}</b>
          </Text>
        </Box>
      ))}
    </SimpleGrid>
    <ProfTips mt={8}>
      <Text>
        Parmi les vidéos proposées, vous pouvez sélectionner les 3 vidéos les
        plus pertinentes en fonction de la formation des élèves. Vous avez
        plusieurs possibilités pour lancer les vidéos : Utiliser un
        vidéoprojecteur pour une diffusion collective Chaque élève lance les
        vidéos depuis la plateforme sur ordinateur ou sur mobile (dans l’idéal
        les élèves ont chacun des écouteurs).
      </Text>
      <Text>
        À la suite de la diffusion, voici une proposition de questions pour
        récapituler avec les élèves :
      </Text>
      <Text>
        Sur les vidéos que tu viens de voir, le parcours scolaire du
        professionnel est-il linéaire ou pas ? A-t-il fait ses études sans
        interruption ou bien il y a eu des temps d’arrêts ? Quelles sont les
        missions principales du métier présenté ? Peux tu en présenter au moins
        deux ? Quelles sont les compétences requises pour faire ce métier ?
        Cites-en trois.
      </Text>
    </ProfTips>
  </>
);

export default VideoDecouverteMetiers;
