import { useEffect, useState } from 'react';
import Image from 'next/image';
import {
  Box,
  Input,
  ListItem,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  UnorderedList,
  RadioGroup,
  Radio,
  VStack,
  FormHelperText,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import PhotoAndrea from '@inspire/ui/assets/activites/etudes-sup/andrea.jpg';
import { LyceenBacProProfileSchema } from '@inspire/data/schemas/user/profile/lyceen';
import {
  validateSchema,
  initFormikValuesFromSchema,
} from '@inspire/data/helpers/schemas';

import Link from 'components/general/Link';
import VideoPlayer from 'components/general/VideoPlayer';
import ClickableImage from 'components/general/ClickableImage';
import Confetti from 'components/general/Confetti';
import ReflexionOralHeader from 'components/activites/ReflexionOralHeader';
import ProfTips from 'components/activites/ProfTips';
import { kitDeSurviePath } from 'lib/paths';
import { useMyProfile } from 'lib/hooks';

import InfographieSrc from '../../../../public/img/infographies/demenager.png';
import { FormActiviteFooter } from '../ActiviteFooter';
import { RecapCheckbox, RecapRadio } from '../recap-helpers';
import CheckboxGroupWithOther from 'components/forms/CheckboxGroupWithOther';
import RadioGroupWithOther from 'components/forms/RadioGroupWithOther';
import ConsigneTag from 'components/activites/ConsigneTag';
import Euros from 'components/general/Euros';

export const DepensesEtudiant = () => (
  <>
    <ReflexionOralHeader />
    <Text textAlign="center" fontStyle="italic">
      Réponds aux questions à l’oral.
    </Text>

    <UnorderedList>
      <ListItem>
        A ton avis, quelles sont les dépenses courantes des étudiants ?
        <ProfTips my={8}>
          <Text>Les dépenses courantes des étudiants peuvent être :</Text>
          <UnorderedList>
            <ListItem>Loyer</ListItem>
            <ListItem>Nourriture</ListItem>
            <ListItem>Assurance</ListItem>
            <ListItem>Mutuelle</ListItem>
            <ListItem>Lavomatique</ListItem>
            <ListItem>Transport</ListItem>
            <ListItem>Abonnement téléphone + internet</ListItem>
          </UnorderedList>
        </ProfTips>
      </ListItem>
      <ListItem>
        A ton avis, quels sont les différents types de logements possibles pour
        des étudiants ? (
        <Link href={kitDeSurviePath('logement')} target="_blank">
          plus d'infos sur les logements
        </Link>
        )
        <ProfTips my={8}>
          <Text>
            Les différents type de logements possibles pour les étudiants sont :
          </Text>
          <UnorderedList>
            <ListItem>Chambre dans une résidence du Crous</ListItem>
            <ListItem>Colocation étudiante</ListItem>
            <ListItem>Colocation Intergénérationnelle</ListItem>
            <ListItem>Chambre chez l’habitant</ListItem>
            <ListItem>Appartement / studio</ListItem>
            <ListItem>Foyer</ListItem>
            <ListItem>Internat</ListItem>
            <ListItem>Résidence étudiante</ListItem>
          </UnorderedList>
        </ProfTips>
      </ListItem>
      <ListItem>
        A ton avis, quels revenus et quelles aides peuvent avoir les étudiants ?
        (
        <Link href={kitDeSurviePath('aides-jeunes-actifs')} target="_blank">
          plus d’infos sur les aides étudiantes
        </Link>
        )
        <ProfTips my={8}>
          <Text>Les revenus et aides que peuvent avoir les étudiants :</Text>
          <UnorderedList>
            <ListItem>Bourses (Crous)</ListItem>
            <ListItem>APL</ListItem>
            <ListItem>Aide des parents</ListItem>
            <ListItem>Chambre chez l’habitant</ListItem>
            <ListItem>Jobs, Service civique, alternance</ListItem>
          </UnorderedList>
        </ProfTips>
      </ListItem>
    </UnorderedList>
  </>
);

export const BudgetAndrea = ({ allowNext }) => {
  const [montantBourse, setMontantBourse] = useState('');
  const [logement, setLogement] = useState('');
  const [jobEtudiant, setJobEtudiant] = useState('');
  const {
    isOpen: isOpenCrous,
    onOpen: onOpenCrous,
    onClose: onCloseCrous,
  } = useDisclosure();

  const {
    isOpen: isOpenLogement,
    onOpen: onOpenLogement,
    onClose: onCloseLogement,
  } = useDisclosure();

  const {
    isOpen: isOpenBudget,
    onOpen: onOpenBudget,
    onClose: onCloseBudget,
  } = useDisclosure();

  const [isQuestionCorrect, setIsQuestionCorrect] = useState({
    montantBourse: false,
    logement: false,
    jobEtudiant: false,
  });

  useEffect(() => {
    allowNext(false);
  }, []);

  useEffect(() => {
    if (montantBourse.includes('259')) {
      setIsQuestionCorrect((prev) => ({
        ...prev,
        montantBourse: true,
      }));
    }
  }, [montantBourse, setIsQuestionCorrect]);

  useEffect(() => {
    if (logement === 'crous') {
      setIsQuestionCorrect((prev) => ({
        ...prev,
        logement: true,
      }));
    }
  }, [logement, setIsQuestionCorrect]);

  useEffect(() => {
    if (jobEtudiant.includes('211')) {
      setIsQuestionCorrect((prev) => ({
        ...prev,
        jobEtudiant: true,
      }));
    }
  }, [jobEtudiant, setIsQuestionCorrect]);

  useEffect(() => {
    if (
      isQuestionCorrect.montantBourse &&
      isQuestionCorrect.logement &&
      isQuestionCorrect.jobEtudiant
    ) {
      allowNext(true);
    }
  }, [isQuestionCorrect]);

  return (
    <>
      <Text textAlign="center" fontStyle="italic">
        Aide Andréa à compléter son budget étudiant mensuel.
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={6}>
        <Image
          src={PhotoAndrea}
          width="1500px"
          height="1000px"
          alt="Photo Andréa"
        />
        <Box>
          <Text>
            Andréa vit actuellement chez ses parents, elle vient d’avoir son bac
            et va poursuivre ses études dans une ville éloignée de chez elle,
            elle doit donc déménager.
          </Text>
          <Text>
            ➔ Andréa aime être entourée d’amis, tout en étant indépendante avec
            sa propre chambre.
          </Text>
          <Text>
            ➔ Andréa pourra prétendre à une bourse échelon 2 et pourra demander
            un logement au CROUS.
          </Text>
        </Box>
      </SimpleGrid>

      <Text textAlign="center" fontStyle="italic" mt={8}>
        Réponds aux questions suivantes en t’aidant de la description ci-dessus.
      </Text>

      <Box mt={6}>
        <Confetti
          fire={isQuestionCorrect.montantBourse}
          extraStyles={{ marginTop: -32 }}
        />
        <FormControl>
          <FormLabel>1. Quel sera son montant de bourse ?</FormLabel>
          <FormHelperText>
            <Button
              variant="link"
              color="black"
              textDecoration="underline"
              onClick={onOpenCrous}
            >
              Clique ici pour voir le tableau du montant des bourses du CROUS
              2021-2022.
            </Button>
          </FormHelperText>
          &nbsp;
          <Input
            value={montantBourse}
            onChange={(e) => {
              if (!isQuestionCorrect.montantBourse)
                setMontantBourse(e.target.value);
            }}
            placeholder="Tape ta réponce ici..."
          />
        </FormControl>
        <Box mt={3}>
          {(isQuestionCorrect.montantBourse && (
            <Box color="green.500">Correct !</Box>
          )) ||
            (!isQuestionCorrect.montantBourse && montantBourse !== '' && (
              <Box color="red.500">Incorrect</Box>
            ))}
        </Box>
        <ProfTips my={6}>
          <Text>
            La bourse du CROUS est attribuée en fonction des ressources et des
            charges du foyer fiscal de l’étudiant, du nombre d'enfants à charge
            des parents et de l'éloignement entre le domicile et le lieu
            d'études. Il faut remplir son Dossier Social Étudiant (DSE) sur
            Internet avant mi-mai de l’année de la Terminale.
          </Text>
        </ProfTips>
        <Modal isOpen={isOpenCrous} onClose={onCloseCrous}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Montant des bourses du CROUS 2021-2022</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableContainer whiteSpace="normal" my={6}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Échelon</Th>
                      <Th isNumeric>Montant mensuel</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>0 bis</Td>
                      <Td isNumeric>
                        <Euros>104</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>1</Td>
                      <Td isNumeric>
                        <Euros>172</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td isNumeric>
                        <Euros>259</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>3</Td>
                      <Td isNumeric>
                        <Euros>332</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>4</Td>
                      <Td isNumeric>
                        <Euros>405</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>5</Td>
                      <Td isNumeric>
                        <Euros>465</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>6</Td>
                      <Td isNumeric>
                        <Euros>493</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>7</Td>
                      <Td isNumeric>
                        <Euros>573</Euros>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onCloseCrous}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      <Box mt={6}>
        <Confetti
          fire={isQuestionCorrect.logement}
          extraStyles={{ marginTop: -32 }}
        />
        <FormControl>
          <FormLabel>
            2. Quel sera le logement idéal pour Andréa, sachant qu’elle veut
            dépenser le moins possible ?
          </FormLabel>
          <FormHelperText>
            <Button
              variant="link"
              color="black"
              textDecoration="underline"
              onClick={onOpenLogement}
            >
              Clique ici pour voir le tableau récapitulatif des logements
            </Button>
          </FormHelperText>
          &nbsp;
          <RadioGroup
            onChange={(e) => {
              if (!isQuestionCorrect.logement) setLogement(e);
            }}
            value={logement}
          >
            <VStack alignItems="start">
              <Radio value="crous">Chambre dans une résidence du Crous</Radio>
              <Radio value="colocation">Colocation</Radio>
              <Radio value="apartement">Appartement individuel / studio</Radio>
            </VStack>
          </RadioGroup>
        </FormControl>
        <Box mt={3}>
          {(isQuestionCorrect.logement && (
            <Box color="green.500">Correct !</Box>
          )) ||
            (!isQuestionCorrect.logement && logement !== '' && (
              <Box color="red.500">Incorrect</Box>
            ))}
        </Box>

        <Modal isOpen={isOpenLogement} onClose={onCloseLogement} size="xl">
          <ModalOverlay />
          <ModalContent maxWidth="800px">
            <ModalHeader>Logements étudiants</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableContainer whiteSpace="normal" my={6}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Type de logement</Th>
                      <Th>Caractéristiques</Th>
                      <Th isNumeric>Coût mensuel*</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Colocation</Td>
                      <Td>Partager un logement privé à plusieurs</Td>
                      <Td isNumeric>
                        <Euros>400</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Appartement individuel / studio</Td>
                      <Td>
                        Location auprès d'un particulier ou d'une agence
                        immobilière.
                      </Td>
                      <Td isNumeric>
                        <Euros>500</Euros>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Résidence étudiante CROUS</Td>
                      <Td>
                        Logements destinés aux étudiants à faibles ressources,
                        attribués en fonction de l’éloignement géographique, des
                        moyens de la famille et de l’étudiant, de la composition
                        de la famille, etc.
                      </Td>
                      <Td isNumeric>
                        <Euros>300</Euros>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Text fontSize="sm">
                  {' '}
                  * NB : Le coût des logements varie selon les régions, les prix
                  proposés ici sont indicatifs.
                </Text>
              </TableContainer>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onCloseLogement}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      <Box mt={6}>
        <Confetti
          fire={isQuestionCorrect.jobEtudiant}
          extraStyles={{ marginTop: -32 }}
        />
        <FormControl>
          <FormLabel>
            3. Combien à t’elle besoin de gagner avec son job étudiant pour
            couvrir toutes ses dépenses mensuelles ?
          </FormLabel>
          <FormHelperText>
            <Button
              variant="link"
              color="black"
              textDecoration="underline"
              onClick={onOpenBudget}
            >
              Clique ici pour voir le tableau récapitulatif du budget d'Andréa
            </Button>
          </FormHelperText>
          &nbsp;
          <Input
            value={jobEtudiant}
            onChange={(e) => {
              if (!isQuestionCorrect.jobEtudiant)
                setJobEtudiant(e.target.value);
            }}
            placeholder="Tape ta réponce ici..."
          />
        </FormControl>
        <Box mt={3}>
          {(isQuestionCorrect.jobEtudiant && (
            <Box color="green.500">Correct !</Box>
          )) ||
            (!isQuestionCorrect.jobEtudiant && jobEtudiant !== '' && (
              <Box color="red.500">Incorrect</Box>
            ))}
        </Box>
        <Modal isOpen={isOpenBudget} onClose={onCloseBudget} size="xl">
          <ModalOverlay />

          <ModalContent maxWidth="80%">
            <ModalHeader>Le budget d'Andréa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid columns={[1, null, null, 2]} spacing={4} my={8}>
                <TableContainer>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>RECETTES (ce que je gagne)</Th>
                        <Th isNumeric></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Aide au logement (APL)</Td>
                        <Td isNumeric>
                          <Euros>110</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Apport des parents</Td>
                        <Td isNumeric>
                          <Euros>120</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Bourse du CROUS</Td>
                        <Td isNumeric>
                          <Euros>259</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Job étudiant</Td>
                        <Td isNumeric>
                          <Euros>?</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total</Td>
                        <Td isNumeric>
                          <Euros>700</Euros>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>DÉPENSES (ce que je paye)</Th>
                        <Th isNumeric></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Type de logement : Résidence CROUS</Td>
                        <Td isNumeric>
                          <Euros>300</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          Nourriture (environ <Euros>7</Euros>/jour)
                        </Td>
                        <Td isNumeric>
                          <Euros>120</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Transport</Td>
                        <Td isNumeric>
                          <Euros>40</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Autres (loisirs, santé, abonnements...)</Td>
                        <Td isNumeric>
                          <Euros>150</Euros>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total</Td>
                        <Td isNumeric>
                          <Euros>700</Euros>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onCloseBudget}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <ProfTips my={6}>
          <UnorderedList>
            <ListItem>
              Les APL (aides personnalisées au logement) sont distribuées par la
              caisse d’allocations familiales (CAF) et destinées à réduire le
              montant des loyers.
            </ListItem>
            <ListItem>
              Pour se nourrir à moindre coût, les étudiants peuvent se rendre
              dans les restaurants CROUS (même si on n’est pas étudiant à
              l’université) : repas à <Euros>3,30</Euros> pour les étudiants,{' '}
              <Euros>1</Euros> pour les boursiers. La carte étudiante offre
              aussi certaines réductions dans les magasins et restaurants.
            </ListItem>
            <ListItem>
              Pour avoir un budget équilibré, il faut gagner autant que ce que
              je dépense. S’il y a du surplus, c’est de l’épargne. S’il y a un
              déficit, c’est de l’endettement.
            </ListItem>
          </UnorderedList>
        </ProfTips>
      </Box>
      <Box mt={6}>
        <ReflexionOralHeader />
        <Text fontWeight="bold">
          4. Donne à l’oral des exemples de jobs étudiants possibles.
        </Text>
        <ProfTips my={6}>
          <UnorderedList>
            <ListItem>
              Quelques exemples de jobs étudiants : baby sitting, animation,
              restauration…
            </ListItem>
            <ListItem>
              Le statut d’étudiant salarié peut être demandé auprès de son
              établissement pour les étudiants qui travaillent à côté (environ
              15 heures par semaine) afin d’avoir des aménagements (par exemple
              : aménagement d’emploi du temps, contrôle final au lieu d'un
              contrôle continu).
            </ListItem>
            <ListItem>
              Le Service Civique : mission de volontariat d'intérêt général de 6
              à 12 mois, pour les jeunes de 16 à 25 ans, possible à côté des
              études , indemnisé <Euros>580</Euros>/mois.
            </ListItem>
          </UnorderedList>
        </ProfTips>
      </Box>
    </>
  );
};

export const ParcoursEtudiants = () => (
  <>
    <Text textAlign="center" fontStyle="italic">
      <ConsigneTag /> : Regarde ces vidéos d’anciens bac pro qui ont déménagé
      pour poursuivre leurs études et réponds aux questions à l’oral :
    </Text>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/terminale-lyvio-mobilite.mp4'} />
      <Text textAlign="center" mt={2}>
        <b>Lyvio</b>, étudiant qui a quitté la Guadeloupe vers Paris pour
        étudier puis est parti en région
      </Text>
    </Box>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer
        url={'/videos-bacpro/terminale-jean-baptiste-mobilite.mp4'}
      />
      <Text textAlign="center" mt={2}>
        <b>Jean-Baptiste</b>, a quitté son département pour ses études
      </Text>
    </Box>
    <Box maxWidth={'400px'} margin="auto">
      <VideoPlayer url={'/videos-bacpro/terminale-nathanael-mobilite.mp4'} />
      <Text textAlign="center" mt={2}>
        <b>Nathanaël</b>, a quitté sa ville pour ses études{' '}
      </Text>
    </Box>
    <UnorderedList>
      <ListItem>
        Quels étaient leurs doutes par rapport au fait de changer d’endroit pour
        la poursuite de leurs études ?
      </ListItem>
      <ListItem>
        Comment se sont-ils organisés ? Ont-ils obtenu des aides ?
      </ListItem>
      <ListItem>Ont-ils regretté ce choix ?</ListItem>
      <ListItem>Te sentirais-tu prêt à faire la même chose ?</ListItem>
    </UnorderedList>
  </>
);

const typeLogementOptions = {
  crous: 'Dans une résidence du Crous',
  colocEtudiante: 'Dans une colocation étudiante',
  studio: 'Dans un studio indépendant',
  internat: 'Dans un internat',
  colocIntergen: 'En colocation intergénérationnelle',
};

const gererBudgetOptions = {
  habitude: 'A l’aise, j’ai déjà l’habitude !',
  apprendre: 'Je n’ai pas l’habitude mais je vais vite apprendre !',
  pasPret: 'Je ne suis pas prêt, j’ai peur d’avoir un budget déséquilibré !',
};

const rencontrerDuMondeOptions = {
  associationEtudiante:
    "Je m'inscris dans une association étudiante pour rencontrer des bénévoles",
  clubSport:
    'Je m’inscris dans un club de sport (de l’établissement et/ou de la ville)',
  soireeEtudiante: 'Je participe à la première soirée étudiante',
  reseauxSociaux:
    'Je rejoins le groupe d’étudiants de mon établissement sur les réseaux sociaux',
  activiteCulturelle:
    'Je m’inscris à une activité culturelle (musique, théâtre, etc.)',
  groupesDeTravail:
    'Je participe à des groupes de travail avec les étudiants de ma classe',
};

const demenagementOptions = {
  partant: 'Je suis partant, mais je ne me sens pas prêt !',
  pasEnvie: 'Je n’ai vraiment pas envie !',
  label: 'J’ai hâte !',
};

const keyValueToArray = (options) =>
  Object.keys(options).map((k) => ({
    label: options[k],
    value: k,
  }));

export const InfographieDemenagement = ({ goNext }) => {
  const { myProfile, setAndRefetchMyProfile, isSettingAndRefetchingMyProfile } =
    useMyProfile(null, {
      onSuccess: goNext,
    });

  const schema = LyceenBacProProfileSchema.pick(
    'demenagement',
    'typeLogement',
    'gererBudget',
    'rencontrerDuMonde'
  ).requireFields();
  const initialValues = initFormikValuesFromSchema(schema, myProfile);
  const formik: FormikConfig<FormikValues> = {
    initialValues,
    validate: (values) => validateSchema(schema, values),
    onSubmit: (values) => setAndRefetchMyProfile(schema.clean(values)),
  };
  return (
    <>
      <Text textAlign="center" fontStyle="italic">
        Regarde cette infographie qui résume les étapes importantes pour
        déménager puis répond aux questions.{' '}
      </Text>
      <Box textAlign="center" maxW="500px" margin="auto">
        <ClickableImage
          src={InfographieSrc}
          href="/img/infographies/parcoursup.png"
          alt="Infographie études supérieurs"
        />
      </Box>

      <Formik {...formik}>
        <Form>
          <FormControl my={6}>
            <RadioGroupWithOther
              name="demenagement"
              label="Es-tu prêt à déménager ?"
              options={keyValueToArray(demenagementOptions)}
            />
          </FormControl>

          <FormControl my={6}>
            <CheckboxGroupWithOther
              name="typeLogement"
              label="Dans quel type de logement te vois-tu ?"
              options={keyValueToArray(typeLogementOptions)}
            />
          </FormControl>

          <FormControl my={6}>
            <RadioGroupWithOther
              name="gererBudget"
              label="Pour gérer ton budget, tu te sens :"
              options={keyValueToArray(gererBudgetOptions)}
            />
          </FormControl>

          <FormControl my={6}>
            <CheckboxGroupWithOther
              name="rencontrerDuMonde"
              label="Je découvre ma nouvelle ville et mon nouvel établissement. Par
                  quoi commencer pour rencontrer du monde ?"
              options={keyValueToArray(rencontrerDuMondeOptions)}
            />
          </FormControl>
          <FormActiviteFooter isLoading={isSettingAndRefetchingMyProfile} />
        </Form>
      </Formik>
    </>
  );
};

export const RecapVieEtudiante = ({ profile }) => (
  <Box>
    <RecapRadio
      profile={profile}
      header="Es-tu prêt à déménager ?"
      fieldName="demenagement"
      options={demenagementOptions}
    />

    <RecapCheckbox
      profile={profile}
      header="Dans quel type de logement te vois-tu ?"
      fieldName="typeLogement"
      options={typeLogementOptions}
    />

    <RecapRadio
      profile={profile}
      header="Pour gérer ton budget, tu te sens :"
      fieldName="gererBudget"
      options={gererBudgetOptions}
    />

    <RecapCheckbox
      profile={profile}
      header="Je découvre ma nouvelle ville et mon nouvel établissement. Par
      quoi commencer pour rencontrer du monde ?"
      fieldName="rencontrerDuMonde"
      options={rencontrerDuMondeOptions}
    />
  </Box>
);
